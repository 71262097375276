/* eslint-disable sonarjs/no-duplicate-string */
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl, { EventData, Map, MapMouseEvent } from 'mapbox-gl';
import { mapboxAccessToken } from '../../utils/mapbox-api-utils.service';
import { generateGeocoderResult } from './generateGeocoderResult';
import { wktToGeoJSON } from '@terraformer/wkt'; // Import du parser WKT
import { Geometry } from 'geojson';
import { getCenterFromGeometry } from './getCenterFromGeometry';
import { sourceDoGenericAddOrUpdate } from './sourceDoGenericAddOrUpdate';
import { enableGenericLayer } from './enableGenericLayer';
import { mapboxLayerOptions } from './mapbox.interface';
import { setupClickEventListeners } from './layerAddOrUpdate';
import { ToolsService } from '../tools.service';
import { removeLayer } from './removeLayer';
import { removeSource } from './removeSource';

/**
 * Adds a Mapbox Geocoder control to the provided map instance.
 *
 * @param {Map} map - The Mapbox map instance to which the Geocoder control will be added.
 *
 * The Geocoder control allows users to search for locations using a search box.
 * It includes a custom local geocoder that provides additional results when the search query
 * contains a comma-separated latitude and longitude.
 *
 * The local geocoder parses the search query to extract latitude and longitude values,
 * and generates additional geocoder results based on these coordinates.
 *
 * The control is positioned at the top-left corner of the map.
 */
let searchMode: 'all' | 'wkt' | 'coords' = 'all';
export function addMapSearchGeocoder(
  map: Map,
  clickCallback: (
    e: MapMouseEvent & EventData,
    clickedFeature: any
  ) => void = () => {},
  tools: ToolsService | null = null
) {
  const geocoder = new MapboxGeocoder({
    accessToken: mapboxAccessToken,
    marker: true,
    mapboxgl: mapboxgl,
    placeholder: $localize`Chercher`,

    filter: (item: MapboxGeocoder.Result) => {
      if (searchMode === 'wkt' && item.place_type.includes('wkt')) return true;
      if (searchMode === 'coords' && item.place_type.includes('place_local'))
        return true;
      return searchMode === 'all';
    },
    localGeocoder: (search: string): MapboxGeocoder.Result[] => {
      searchMode = 'all';

      const additionalResults: MapboxGeocoder.Result[] = [];
      // CAS : recherche WKT
      try {
        generateGeocoderResultFromWKT(
          search,
          calculateBBoxForGeometry,
          additionalResults
        );
        searchMode = 'wkt';
      } catch (e) {}

      // CAS : recherche par coordinates complexe (geojson (linestring, multilinestring, polygon, multipolygon) as string with coordinates)
      if (search.includes('[') && search.includes(']')) {
        try {
          generateGeocoderResultFromGeojson(
            search,
            calculateBBoxForGeometry,
            additionalResults
          );
          searchMode = 'wkt';
        } catch (e) {}
      }

      // CAS : recherche par coordonnées
      if (search.includes(',') && searchMode !== 'wkt') {
        const lat = parseFloat(search.split(',')[0].trim());
        const lng = parseFloat(search.split(',')[1].trim());
        if (isNaN(lat) || isNaN(lng)) return [];

        const bbox: [number, number, number, number] = [
          lng - 0.01,
          lat - 0.01,
          lng + 0.01,
          lat + 0.01,
        ];
        additionalResults.push(generateGeocoderResult(bbox, lng, lat));

        const inverseBbox: [number, number, number, number] = [
          lat - 0.01,
          lng - 0.01,
          lat + 0.01,
          lng + 0.01,
        ];
        additionalResults.push(generateGeocoderResult(inverseBbox, lat, lng));
        searchMode = 'coords';
      }

      return additionalResults;
    },
  });

  geocoder.on('result', (e) => {
    const feature = e.result.geometry;

    if (feature.type !== 'Point') {
      // Ajouter la géométrie à la carte
      const callback: (
        e: MapMouseEvent & EventData,
        clickedFeature: any
      ) => void = (e, clickedFeature) => {
        // On delete la source et le layer dans les 5 secondes après le clic
        setTimeout(() => {
          removeLayer(map, 'wkt-layer');
          removeSource(map, 'wkt-geometry');
        }, 5000);
        clickCallback(e, clickedFeature);
      };

      addGeometryToMap(map, feature, callback);
    }
  });

  map.addControl(geocoder, 'top-left');

  if (tools) {
    const container = (geocoder as any).container;
    const icon = container.querySelector(
      '.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search'
    );
    if (icon) {
      icon.style.cursor = 'pointer';
      const content = $localize`Vous pouvez rechercher : <br><br>
          - des adresses / lieux<br>
          -> Exemple : "4 rue de la paix"<br><br>
          - des coordonnées géographiques "latitude, longitude" <br>
          -> Exemple : "2.33333, 48.866669"<br><br>
          - des géométries en utilisant le format WKT (Well-Known Text)<br>
          -> Exemple : <pre>POLYGON((
  -1.5747 47.2225,
  -1.5747 47.2425,
  -1.5547 47.2425,
  -1.5547 47.2225,
  ))</pre> <br>
          - des géométries en utilisant le format GeoJSON<br>
          -> Exemple : <pre>{
  "type": "Polygon",
  "coordinates": [
    [
      [-1.5747, 47.2225],
      [-1.5747, 47.2425],
      [-1.5547, 47.2425],
      [-1.5547, 47.2225],
      [-1.5747, 47.2225]
    ]
  ]
}</pre><br>`;
      icon.addEventListener('click', () => {
        tools.launchAlert(
          $localize`Information sur la recherche`,
          content,
          [
            {
              text: $localize`Test adresse`,
              handler: () => {
                geocoder.clear();
                geocoder.setInput('4 rue de la paix', true);
              },
            },
            {
              text: $localize`Test lat/lng`,
              handler: () => {
                geocoder.clear();
                geocoder.setInput('2.33333, 48.866669', true);
              },
            },
            {
              text: $localize`Test WKT`,
              handler: () => {
                geocoder.clear();
                geocoder.setInput(
                  'POLYGON((-1.5747 47.2225, -1.5747 47.2425, -1.5547 47.2425, -1.5547 47.2225, -1.5747 47.2225))',
                  true
                );
              },
            },
            {
              text: $localize`Test GeoJSON`,
              handler: () => {
                geocoder.clear();
                geocoder.setInput(
                  '{"type":"Polygon","coordinates":[[[-1.5747,47.2225],[-1.5747,47.2425],[-1.5547,47.2425],[-1.5547,47.2225],[-1.5747,47.2225]]]}',
                  true
                );
              },
            },
            { text: $localize`Ok`, handler: () => {} },
          ],
          false,
          true
        );
      });
    }
  }
}

// Fonction pour ajouter la géométrie à la carte
function addGeometryToMap(
  map: mapboxgl.Map,
  geometry: GeoJSON.Geometry,
  clickCallback:
    | ((e: MapMouseEvent & EventData, clickedFeature: any) => void)
    | undefined = undefined
) {
  // Supprimer la couche précédente si elle existe
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (map.getSource('wkt-geometry')) {
    map.removeLayer('wkt-layer');
    map.removeSource('wkt-geometry');
  }

  const layers: mapboxLayerOptions[] = [];
  // Définir comment la géométrie doit être affichée sur la carte
  sourceDoGenericAddOrUpdate(
    map,
    {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: geometry,
          properties: {},
        },
      ],
    },
    {
      id: 'wkt-geometry',
      filterFunc: () => true,
    }
  );

  if (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon') {
    const layerPolygon: mapboxLayerOptions = {
      id: 'wkt-layer',
      source: 'wkt-geometry',
      type: 'fill',
      layer: {
        id: 'wkt-layer',
        source: 'wkt-geometry',
        type: 'fill',
        layout: {},
        paint: {
          'fill-color': '#BF93E4',
          'fill-opacity': 0.5,
        },
      },
    };
    layers.push(layerPolygon);
    enableGenericLayer(map, layerPolygon);
  }

  if (geometry.type === 'LineString' || geometry.type === 'MultiLineString') {
    const layerLine: mapboxLayerOptions = {
      id: 'wkt-layer',
      source: 'wkt-geometry',
      type: 'line',
      layer: {
        id: 'wkt-layer',
        source: 'wkt-geometry',
        type: 'line',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
          visibility: 'visible',
        },
        paint: {
          'line-color': '#BF93E4',
          'line-width': 5,
        },
      },
    };
    layers.push(layerLine);
    enableGenericLayer(map, layerLine);
  }

  if (clickCallback) {
    // Clic sur les emplacements
    const layersForClick = layers.map((e) => e.id);
    setupClickEventListeners(map, { clickCallback }, layersForClick);
  }

  // Ajuster la vue de la carte pour s'adapter à la géométrie
  const bbox = calculateBBoxForGeometry(geometry);
  map.fitBounds(bbox as mapboxgl.LngLatBoundsLike, {
    padding: 20,
  });
}
function generateGeocoderResultFromGeojson(
  search: string,
  calculateBBoxForGeometry: (
    geometry: GeoJSON.Geometry
  ) => [number, number, number, number],
  additionalResults: MapboxGeocoder.Result[]
) {
  // Le JSON.parse est modifié car ça ne fonctionne pas avec les clés numériques (obj vs array)
  const json = JSON.parse(search, (key, value) => {
    // Si la valeur est un objet et non null, on vérifie s'il a des clés numériques
    if (typeof value === 'object' && value !== null) {
      const keys = Object.keys(value);
      if (keys.every((k) => !isNaN(Number(k)))) {
        // Si toutes les clés sont numériques, les convertir en tableau
        return Object.keys(value).map((k) => value[k]);
      }
    }
    return value; // sinon, on garde la valeur telle qu'elle est
  });

  const geometry = json as Geometry; // Obtenez la Feature
  const center = getCenterFromGeometry(geometry); // Trouver le centre
  const bbox = calculateBBoxForGeometry(geometry); // Calculer la bbox à partir de la géométrie

  additionalResults.push({
    type: 'Feature',
    geometry,
    properties: {
      place_name: 'GeoJSON Geometry',
      text: 'GeoJSON Geometry',
      place_type: ['wkt'],
      center: [center?.lng, center?.lat],
    },
    bbox: bbox,
    center: [center?.lng, center?.lat],
    place_name: 'GeoJSON Geometry',
    text: 'GeoJSON Geometry',
    place_type: ['wkt'],
    relevance: 1,
    context: [],
    address: '',
  } as MapboxGeocoder.Result);
}

function generateGeocoderResultFromWKT(
  search: string,
  calculateBBoxForGeometry: (
    geometry: GeoJSON.Geometry
  ) => [number, number, number, number],
  additionalResults: MapboxGeocoder.Result[]
) {
  const geometry = wktToGeoJSON(search) as Geometry; // Obtenez la Feature
  const center = getCenterFromGeometry(geometry); // Trouver le centre

  const bbox = calculateBBoxForGeometry(geometry); // Calculer la bbox à partir de la géométrie

  additionalResults.push({
    type: 'Feature',
    geometry,
    properties: {
      place_name: 'WKT Geometry',
      text: 'WKT Geometry',
      place_type: ['wkt'],
      center: [center?.lng, center?.lat],
    },
    bbox: bbox,
    center: [center?.lng, center?.lat],
    place_name: 'WKT Geometry',
    text: 'WKT Geometry',
    place_type: ['wkt'],
    relevance: 1,
    context: [],
    address: '',
  } as MapboxGeocoder.Result);
}

// Fonction pour calculer la bounding box d'une géométrie (vous pouvez améliorer cela pour les géométries complexes)
function calculateBBoxForGeometry(
  geometry: GeoJSON.Geometry
): [number, number, number, number] {
  const bounds = {
    minLng: Infinity,
    minLat: Infinity,
    maxLng: -Infinity,
    maxLat: -Infinity,
  };

  // Fonction utilitaire pour mettre à jour les limites
  const updateBounds = (coordinates: [number, number]) => {
    const [lng, lat] = coordinates;
    bounds.minLng = Math.min(bounds.minLng, lng);
    bounds.minLat = Math.min(bounds.minLat, lat);
    bounds.maxLng = Math.max(bounds.maxLng, lng);
    bounds.maxLat = Math.max(bounds.maxLat, lat);
  };

  // Fonction pour parcourir les coordonnées d'une géométrie
  const traverseCoordinates = (coords: any) => {
    if (typeof coords[0] === 'number' && typeof coords[1] === 'number') {
      // Cas d'un point individuel
      updateBounds(coords as [number, number]);
    } else {
      // Cas d'une collection de points (LineString, Polygon, etc.)
      coords.forEach(traverseCoordinates);
    }
  };

  // Traiter chaque type de géométrie
  switch (geometry.type) {
    case 'Point':
      updateBounds(geometry.coordinates as [number, number]);
      break;
    case 'LineString':
    case 'MultiPoint':
      traverseCoordinates(geometry.coordinates);
      break;
    case 'Polygon':
    case 'MultiLineString':
      geometry.coordinates.forEach(traverseCoordinates);
      break;
    case 'MultiPolygon':
      geometry.coordinates.forEach((polygon) =>
        polygon.forEach(traverseCoordinates)
      );
      break;
    case 'GeometryCollection':
      geometry.geometries.forEach((geom) => {
        calculateBBoxForGeometry(geom);
      });
      break;
    default:
      throw new Error('Unsupported geometry type');
  }

  // Retourner la bbox sous la forme [minLng, minLat, maxLng, maxLat]
  return [bounds.minLng, bounds.minLat, bounds.maxLng, bounds.maxLat];
}
