import {
  openDatabase,
  getTransaction,
  handleTransactionError,
} from './indexeddb-utils.service';

const DB_NAME = 'ugau_cache_filter_worker';
const STORE_NAME = 'filters_store_';
const LINE_KEY = 'ACTIVE_FILTERS';
const ERROR_MESSAGE = 'Error opening database';

type SelectedFilters = Record<string, any>;

export function saveActiveFiltersToStorage(
  selectedFilters: any,
  userId: string | undefined
) {
  const tableName = STORE_NAME + userId;
  return new Promise((resolve, reject) => {
    openDatabase(DB_NAME, tableName)
      .then((db) => {
        const transaction = getTransaction(db, tableName, 'readwrite');
        const store = transaction.objectStore(tableName);
        store.put(JSON.stringify(selectedFilters), LINE_KEY);

        transaction.oncomplete = () => {
          db.close();
          resolve(null);
        };
        transaction.onerror = () => handleTransactionError(transaction, reject);
      })
      .catch((error) => {
        console.error(ERROR_MESSAGE, error);
        reject(error);
      });
  });
}

export function removeActiveFiltersFromStorage(
  userId: string | undefined
): Promise<void> {
  const tableName = STORE_NAME + userId;
  return new Promise((resolve, reject) => {
    openDatabase(DB_NAME, tableName)
      .then((db) => {
        const transaction = getTransaction(db, tableName, 'readwrite');
        const store = transaction.objectStore(tableName);
        store.delete(LINE_KEY);

        transaction.oncomplete = () => {
          db.close();
          resolve();
        };

        transaction.onerror = () => handleTransactionError(transaction, reject);
      })
      .catch((error) => {
        console.error(ERROR_MESSAGE, error);
        reject(error);
      });
  });
}

export function retrieveActiveFiltersFromIndexedDB(
  userId: string | undefined
): Promise<SelectedFilters> {
  const tableName = STORE_NAME + userId;
  return new Promise((resolve, reject) => {
    openDatabase(DB_NAME, tableName)
      .then((db) => {
        const transaction = getTransaction(db, tableName, 'readonly');
        const store = transaction.objectStore(tableName);
        const requestGet = store.get(LINE_KEY);

        requestGet.onsuccess = () => {
          try {
            const savedFilters = JSON.parse(requestGet.result);
            resolve(savedFilters || {});
          } catch (e) {
            resolve({});
          } finally {
            db.close();
          }
        };

        transaction.oncomplete = () => {
          db.close();
        };

        transaction.onerror = () => handleTransactionError(transaction, reject);
      })
      .catch((error) => {
        console.error(ERROR_MESSAGE, error);
        reject(error);
      });
  });
}
