import { gql } from 'graphql-request';

export const QUERY_GET_PRODUCTFIELDS_W_ACTION_LIB = gql`
  query MyQuery(
    $where: productfields_bool_exp!
    $order_by: [productfields_order_by!]!
    $limit: Int!
  ) {
    productfields(where: $where, order_by: $order_by, limit: $limit) {
      id
      name
      defaultvalue
      libelle_action_historique
      is_for_emplacement
      type
      show_in_title
      filtrable
      mandatory
      order
      updated_at
      deleted_at
      deleted_bool
    }
  }
`;
