import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../utils/localstorage-utils.service';

/**
 * @description
 * Interface pour définir l'état de la page MyAccount
 */
interface MyAccountState {
  mustClose: boolean;
  display: string;
  selectedIndex: number;
}

/**
 * @description
 * Service pour gérer l'état de la modale MyAccount
 *
 * @example
 * ```ts
 * constructor(private myAccountStateService: MyAccountStateService) {}
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class MyAccountStateService {
  private myAccountSubject$ = new BehaviorSubject<MyAccountState>({
    display: 'listing',
    mustClose: false,
    selectedIndex: getFromLocalStorage('myAccountSelectedIndex') ?? 0,
  });
  myAccount$ = this.myAccountSubject$.asObservable();
  close$ = this.myAccountSubject$
    .asObservable()
    .pipe(map((state) => state.mustClose));

  getDisplay() {
    return this.myAccountSubject$.getValue().display;
  }

  close() {
    this.myAccountSubject$.next({
      ...this.myAccountSubject$.getValue(),
      mustClose: true,
    });
  }

  resetClose() {
    this.myAccountSubject$.next({
      ...this.myAccountSubject$.getValue(),
      mustClose: false,
    });
  }

  displayForm() {
    this.myAccountSubject$.next({
      ...this.myAccountSubject$.getValue(),
      display: 'form_user',
    });
  }

  displayListing() {
    this.myAccountSubject$.next({
      ...this.myAccountSubject$.getValue(),
      display: 'listing',
    });
  }

  setSelectedIndex(index: number) {
    setToLocalStorage('myAccountSelectedIndex', index);
    this.myAccountSubject$.next({
      ...this.myAccountSubject$.getValue(),
      selectedIndex: index,
    });
  }
}
