import { typeTagsChoices } from "../enum/typeTagsChoices";


export const beautifyFilterNameFunction = (key: string): string => {
  if (values[key]) {
    return values[key];
  }
  if (key.includes('tag_')) {
    const typeTag = key.replace('tag_', '');
    return typeTagsChoices.find((t) => t.id === typeTag)?.name ?? typeTag;
  }
  return key;
};
const values: Record<string, string> = {
  'Tous les produits': $localize`Tous les composants`,
  'Toutes les familles': $localize`Toutes les catégories`,
  conformites: $localize`Non conformités`,
  formes: $localize`Formes`,
  classes: $localize`Classes`,
  gammes: $localize`Gammes`,
  symboles: $localize`Symboles`,
  panneaux: $localize`Panneaux`,
  supports: $localize`Supports`,
  support_fixations: $localize`Fixations support`,
  balises: $localize`Balises`,
  fabricants: $localize`Fabricants`,
  tags: $localize`Dossiers`,
  date_fabrication: $localize`Date de fabrication`,
  users: $localize`Utilisateurs`,
  filtre_interventions: $localize`Non finalisés`,
  geometrie: $localize`Géométries`,
  ensemble: $localize`Numéro d'ensemble`,
  custom_selection: $localize`Sélection personnalisée`,
  tag_zone: $localize`Zone`,
  address: $localize`Adresses`,
  interventions: $localize`Toutes les interventions`,
  interventions_name: $localize`Interventions`,
  interventions_EN_COURS: $localize`Interventions en cours`,
  interventions_ARCHIVE: $localize`Interventions archivées`,
  interventions_VALIDE: $localize`Interventions commandées`,
  interventions_PREPARATION: $localize`Interventions en étude`,
  interventions_TERMINE: $localize`Interventions terminées`,
  filtre_interventions_bis: $localize`Interventions`,
  emplacement_created_by: $localize`Créé par`,
  emplacement_updated_by: $localize`Modifié par`,
  tag__link_to_me: $localize`Mes dossiers`,
};
