import { KeyValue } from '@angular/common';

export function compareOrderTousFirst(
  e1: KeyValue<string, any>,
  e2: KeyValue<string, any>
) {
  if (e2.key === 'Tous' || e2.key === 'Tous_strict') {
    return 1;
  }
  if (e1.key === 'Tous' || e1.key === 'Tous_strict') {
    return -1;
  }
  return e1.key > e2.key ? 1 : -1;
}

export function compareOrderTousFirstString(e1: string, e2: string) {
  if (e2 === 'Tous' || e2 === 'Tous_strict') {
    return 1;
  }
  if (e1 === 'Tous' || e1 === 'Tous_strict') {
    return -1;
  }
  return e1 > e2 ? 1 : -1;
}
