import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgIf, AsyncPipe } from '@angular/common';
import { UgauButtonComponent } from '../../components/ugau-button/ugau-button.component';
import { MyAccountStateService } from '../my-account/my-account.state';
import { MyAccountGroupState } from './my-account-groups.state';
import { UserAltListComponent } from './useralt-list/useralt-list.component';
import { UserAltFormComponent } from './useralt-form/useralt-form.component';

@Component({
  selector: 'app-my-account-groups',
  templateUrl: './my-account-groups.page.html',
  styles: [],
  standalone: true,
  imports: [
    UserAltListComponent,
    UgauButtonComponent,
    NgIf,
    AsyncPipe,
    UserAltFormComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyAccountGroupsPage {
  private myAccountGroupState = inject(MyAccountGroupState);

  newUser$ = this.myAccountGroupState.newUser$;

  constructor(private myAccountState: MyAccountStateService) {}

  public dismiss() {
    this.myAccountState.close();
  }
}
