import { DeepReadonly } from 'rxdb';
import { emplacementDocType } from '../db/schemas/emplacement.schema';
import { tagDocType, sharedTagDocType } from '../db/schemas/tag.schema';
import { usersDocType } from '../db/schemas/users.schema';

export const hasWriteOnEmplacement = (
  emplacement: emplacementDocType,
  tags: tagDocType[],
  user: usersDocType | DeepReadonly<usersDocType>
): boolean => {
  // Pas de tags, on autorise le write car on bloque le update si pas de tags
  if (emplacement.emplacement_tags.length < 1) {
    return true;
  }

  // Tag présent, on vérifie si l'utilisateur a le droit d'écrire dessus
  return emplacement.emplacement_tags.reduce((acc, emplacementTag) => {
    const tag = tags.find((tag) => tag.id === emplacementTag.id_tag);
    if (!tag) return true;

    const groupIds: string[] = user.user_groupes
      .filter((ug) => ug.role === 'user')
      .map((ug) => ug.groupe.id);

    // Si le tag appartient à l'utilisateur, on retourne true
    if (tag.id_user === user.id) return true;

    // Si le tag appartient à un groupe de l'utilisateur et est gestionnaire, on retourne true
    if (tag.id_groupe && groupIds.includes(tag.id_groupe)) return true;

    if (tag.shared_tags?.length) {
      return tag.shared_tags.some((sharedTag: sharedTagDocType) => {
        // Est-ce que le partage est actif et non readonly et non expiré ?
        const isShareActive =
          sharedTag.active &&
          !sharedTag.readonly &&
          (sharedTag.expires_at
            ? new Date(sharedTag.expires_at).getTime() > new Date().getTime()
            : true);
        const isTargetUser = sharedTag.target_user_id === user.id;
        const isTargetGroup =
          sharedTag.target_group_id &&
          groupIds.includes(sharedTag.target_group_id);

        return (
          // Si le tag est partagé avec l'utilisateur, on retourne true
          isShareActive && (isTargetUser || isTargetGroup)
        );
      });
    }

    return false;
  }, false);
};
