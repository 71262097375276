import { Injectable } from '@angular/core';
import { DatabaseService } from '../db/database.service';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { clearLocalStorageForLogout } from './clearLocalStorageForLogout';
import { sleep } from './../../common-projects/sleep';
import { reloadApp } from './reloadApp.util';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private db: DatabaseService,
    private auth: AuthenticationService,
    private router: Router
  ) {}

  async logout() {
    // Au revoir les données
    await this.db.remove();
    clearLocalStorageForLogout();

    // Au revoir la connexion utilisateur
    await this.auth.logout();
    // Redirige sur la page de logout
    this.router.navigate(['/logout'], { replaceUrl: true });

    sleep(1000);
    return reloadApp('LogoutService::logout');
  }
}