import { INTERVENTION_STATUS } from '../components/intervention-page/intervention-form/INTERVENTION_STATUS';


export const returnInterventionNextStatus = (status: string | undefined) => {
  switch (status) {
    case INTERVENTION_STATUS.PREPARATION:
      return INTERVENTION_STATUS.VALIDE;
    case INTERVENTION_STATUS.VALIDE:
      return INTERVENTION_STATUS.EN_COURS;
    case INTERVENTION_STATUS.EN_COURS:
      return INTERVENTION_STATUS.TERMINE;
    case INTERVENTION_STATUS.TERMINE:
      return INTERVENTION_STATUS.ARCHIVE;
    case INTERVENTION_STATUS.ARCHIVE:
    default:
      return false;
  }
};
