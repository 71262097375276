import { EmplacementDetailStateService } from './../../../state/emplacement-detail-state.service';
import { ProductSearchStateService } from './../../../state/product-search-state.service';
import { extractFirstFamily } from './../../../utils/composant/extractFirstFamily';
import { generateComposantFromFamily } from './../../../utils/composant/generateComposantFromFamily';
import { generateConformites } from './../../../utils/conformite/createConformiteFromReglementationId';
import { getAssociatedFamiliesFromProduct } from './../../../utils/productfamilies-utils.service';
import { ReglementationsStateService } from './../../../state/reglementations-state.service';
import { generateAnyProductfields } from './generateComposantProductfield';
import { ProductFamiliesTreeState } from './../../../state/product-families-tree-state.service';
import { composantDocType } from '../../../db/schemas/emplacement.schema';
import { ProductfieldsStateService } from './../../../state/productfields-state.service';
import { auth } from './../../../services/nhost';
import { getDefaultProductByFirstFamily } from './getDefaultProductByFirstFamily';

// Fonction appelée après la recherche d'un produit pour un composant
// Elle permet de générer les conformités et les champs de saisie pour le composant
// Elle peut être appelée 2 fois de suite pour 2 composants différents, le second appel attendra que le premier soit terminé
export async function doTreatmentAfterProductSearch(
  composant: composantDocType,
  productTreeState: ProductFamiliesTreeState,
  productSearchState: ProductSearchStateService,
  reglementationState: ReglementationsStateService,
  emplacementDetailState: EmplacementDetailStateService,
  productfieldsState: ProductfieldsStateService
) {
  // On récupère l'arbre des familles de produits
  const originalTree = productSearchState.getOriginalTree();
  if (!originalTree.length) return;

  const firstFamily = extractFirstFamily(composant, originalTree);

  // Attention, si la fonction est appelée 2 fois de suite, on refuse le second appel
  if (productSearchState.isWaitingForChooseProduct()) {
    return;
  }

  // On récupère le produit sélectionné
  const selectedProduct = await productSearchState.chooseProduct(firstFamily);
  if (!selectedProduct) {
    return;
  }

  const reglementations = reglementationState.getReglementations();
  const emplacement = emplacementDetailState.getCurrentEmplacement();
  if (!emplacement) return;

  // Génération des conformités pour le composant avec le produit sélectionné
  const conformites = generateConformites(
    composant,
    selectedProduct,
    reglementations,
    auth.getUser()?.id ?? ''
  );

  // Génération des champs de saisie pour le composant et l'emplacement en fonction du produit sélectionné
  const composantProductFields = generateAnyProductfields(
    composant.composant_productfields,
    selectedProduct,
    productfieldsState.getProductfieldsComposant()
  );
  const emplacementProductfields = generateAnyProductfields(
    emplacement.emplacement_productfields,
    selectedProduct,
    productfieldsState.getProductfieldsEmplacement()
  );

  const haveOnly1Composant = emplacement.composants.length === 1;
  // Si le composant est le seul composant de l'emplacement, on génère les composants associés au produit sélectionné
  if (haveOnly1Composant) {
    // On déclare une fonction pour récupérer le produit par défaut pour une famille
    // On récupère les familles associées au produit sélectionné
    const associatedFamilies = getAssociatedFamiliesFromProduct(
      selectedProduct.id,
      originalTree
    );

    if (associatedFamilies.length) {
      // Pour chaque famille associée, on génère un composant
      const assocComposants = associatedFamilies
        .map((family) => {
          return generateComposantFromFamily(
            getDefaultProductByFirstFamily(
              productTreeState,
              family.id,
              originalTree
            ),
            family.id,
            reglementations,
            productfieldsState.getProductfieldsComposant(),
            composant.created_by ?? ''
          );
        })
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        .filter((e): e is composantDocType => e !== undefined);
      if (assocComposants.length) {
        emplacement.composants.push(...assocComposants);
      }
    }
  }

  // On met à jour le composant et les champs de saisie de l'emplacement
  const updatedComposant = {
    product: selectedProduct,
    quantity: selectedProduct.defaultQuantity,
    conformites: conformites,
    composant_productfields: composantProductFields,
  } as Partial<composantDocType>;
  emplacementDetailState.updateComposant(
    { ...updatedComposant },
    composant.id,
    { emplacement_productfields: [...emplacementProductfields] }
  );
}
