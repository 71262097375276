import { ComposantProductType } from '@types_custom/composant';
import {
  ImagesProduct,
  ProductFamiliesType,
  ProductType,
} from '@types_custom/ProductType';
import { productDocType } from '../db/schemas/emplacement.schema';
import { PHOTO_DEFAULT } from '../enum/photo-default';
import { getProductPathFromCache } from './product/getProductPathFromCache';
import {
  addNhostPrefix,
  extractUnitFromPath,
  getImagesFromFamiliesRecursive,
  getProductFromFamilies,
  getDirectParentFamilyForProductId,
} from './productfamilies-utils.service';

const CACHE_STORE_IMAGE = new Map<string, ImagesProduct>();

export function getProductImageByProductId(
  productId: string,
  originalTree: ProductFamiliesType[],
  colorString: keyof ImagesProduct = 'default',
  hasNhostPrefix = true
) {
  const product = getProductFromFamilies(productId, originalTree);
  return getProductImage(product, originalTree, colorString, hasNhostPrefix);
}

export function getProductImages(
  product: productDocType | ProductType | ComposantProductType | undefined,
  originalTree: ProductFamiliesType[]
) {
  if (!product) {
    return { default: PHOTO_DEFAULT.DEFAULT };
  }

  const cachedImage = CACHE_STORE_IMAGE.get(product.id);
  if (cachedImage) {
    return cachedImage;
  }

  const family = getDirectParentFamilyForProductId(product.id, originalTree);
  if (!family) {
    return { default: PHOTO_DEFAULT.DEFAULT };
  }
  const imageFamily = getImagesFromFamiliesRecursive(family.id, originalTree);
  CACHE_STORE_IMAGE.set(product.id, imageFamily);
  return imageFamily;
}

export function getProductImage(
  product: productDocType | ProductType | ComposantProductType | undefined,
  originalTree: ProductFamiliesType[],
  colorString: keyof ImagesProduct = 'default',
  hasNhostPrefix = true
): string {
  if (!product) return PHOTO_DEFAULT.DEFAULT;

  const images: ImagesProduct = getProductImages(product, originalTree);
  const image = images[colorString];

  if (image && image !== PHOTO_DEFAULT.DEFAULT.toString() && hasNhostPrefix)
    return addNhostPrefix(image);
  if (image && image !== PHOTO_DEFAULT.DEFAULT.toString()) return image;

  return PHOTO_DEFAULT.DEFAULT;
}

export function getProductUnit(
  productId: string,
  families: ProductFamiliesType[]
) {
  const path = getProductPathFromCache(productId, families);
  return extractUnitFromPath(path, families);
}
