import { v4 as uuidv4 } from 'uuid';
import { stockDocType } from '../../db/schemas/stock.schema';
import { getUserID } from '../../services/nhost';

interface StockItemParams {
  product_id: string;
  emplacement_id: string;
  groupe_id: string;
  quantity?: number;
  price?: number;
  meta?: any;
  supplier?: string;
  alert_threshold?: number;
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
  deleted_at?: string;
  deleted_by?: string;
  type?: string;
}

export const initNewStockItem = (params: StockItemParams): stockDocType => {
  const finalParams: stockDocType = {
    type: 'stock',
    id: uuidv4(),
    meta: {},
    quantity: 1,
    price: 0,
    supplier: null,
    alert_threshold: 0,
    created_at: new Date().toISOString(),
    created_by: getUserID(),
    updated_at: new Date().toISOString(),
    updated_by: getUserID(),
    deleted_at: null,
    deleted_by: null,
    ...params,
  };

  return finalParams;
};
