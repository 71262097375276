import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { TagService } from './../../services/tag/tag.service';
import { tagDocType } from './../../db/schemas/tag.schema';
import { EventsService } from './../../services/events.service';
import { ToolsService } from './../../services/tools.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { TagsPageState } from './tags-page-state';
import { TagsListComponent } from './tags-list/tags-list.component';
import { TagFormComponent } from './tag-form/tag-form.component';
import { RoutingState } from '../../state/routing-state.service';
import { map, shareReplay } from 'rxjs';
import { sortTags } from '../../utils/tag/sortTags';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-tags',
  template: `
    <div *ngIf="element$ | async as element; else list">
      <app-tags-form
        *ngIf="element"
        [element]="element"
        (elementModify)="elementModify($event)"
        (displayList)="displayList()"
      ></app-tags-form>
    </div>

    <ng-template #list>
      <app-tags-list
        (tagSelected)="elementSelected($event)"
        [type]="'tag'"
        [data$]="tags$"
      ></app-tags-list>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TagsListComponent, TagFormComponent, AsyncPipe],
})
export class TagsPage {
  private tagPageState = inject(TagsPageState);
  private tagService = inject(TagService);
  private events = inject(EventsService);
  private tools = inject(ToolsService);
  private routingState = inject(RoutingState);
  private destroyRef = inject(DestroyRef);
  tags$ = this.tagService.tags$.pipe(
    takeUntilDestroyed(this.destroyRef),
    map((tags) => {
      return tags
        .filter((el: any) => {
          const hasDeleted = el.deleted_at;
          return !hasDeleted && el.type !== 'intervention';
        })
        .sort(sortTags);
    }),
    shareReplay(1)
  );

  element$ = this.tagPageState.element$;

  elementSelected(element: tagDocType | null) {
    this.routingState.navigateTag(element?.id);
  }

  elementModify = async (tag: tagDocType) => {
    const result = await this.tagService.upsert(tag);
    if (result) {
      this.tools.toastError($localize`Vos modifications ont été enregistrées`);
      this.events.publish('filtres:redo');
    } else {
      this.tools.toastError($localize`Erreur lors de la sauvegarde`);
    }
    this.displayList();
  };

  displayList = () => {
    this.routingState.navigateTag();
  };
}

@Component({
  selector: 'app-interventions',
  template: `
    <div *ngIf="element$ | async as element; else list">
      <app-tags-form
        *ngIf="element"
        [element]="element"
        (elementModify)="elementModify($event)"
        (displayList)="displayList()"
      ></app-tags-form>
    </div>

    <ng-template #list>
      <app-tags-list
        (tagSelected)="elementSelected($event)"
        [type]="'intervention'"
        [data$]="tags$"
        [noAddButton]="true"
      ></app-tags-list>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TagsListComponent, TagFormComponent, AsyncPipe],
})
export class InterventionsPage {
  private tagPageState = inject(TagsPageState);
  private tagService = inject(TagService);
  private events = inject(EventsService);
  private tools = inject(ToolsService);
  private routingState = inject(RoutingState);
  private destroyRef = inject(DestroyRef);
  tags$ = this.tagService.tags$.pipe(
    takeUntilDestroyed(this.destroyRef),
    map((tags) => {
      return tags
        .filter((el: any) => {
          const hasDeleted = el.deleted_at;
          return !hasDeleted && el.type === 'intervention';
        })
        .sort(sortTags);
    }),
    shareReplay(1)
  );

  element$ = this.tagPageState.element$;

  elementSelected(element: tagDocType | null) {
    this.routingState.navigateTag(element?.id);
  }

  elementModify = async (tag: tagDocType) => {
    const result = await this.tagService.upsert(tag);
    if (result) {
      this.tools.toastError($localize`Vos modifications ont été enregistrées`);
      this.events.publish('filtres:redo');
    } else {
      this.tools.toastError($localize`Erreur lors de la sauvegarde`);
    }
    this.displayList();
  };

  displayList = () => {
    this.routingState.navigateTag();
  };
}
