import { ISelectItem } from '../../db/interfaces/select-item';
import { COLORS_BTN } from '../../enum/colors';
import { IAutocompleteItem } from '../ugau-input-autocomplete/ugau-input-autocomplete-objects.component';
import { userAltType } from './../../db/schemas/groupe.schema';

export class userAutocompleteItem implements IAutocompleteItem, ISelectItem {
  item: userAltType;

  getLibelle() {
    return this.item.displayName;
  }
  getIdentifier() {
    return this.item.id;
  }
  getColor(): COLORS_BTN {
    return 'primary';
  }
  constructor(item: userAltType) {
    this.item = item;
  }
}