import { Pipe, PipeTransform } from '@angular/core';
import { UsersStateService } from '../../state/users-state.service';
import { groupeDocType } from './../../db/schemas/groupe.schema';
import { getUserDisplayName } from './getUserDisplayName';

@Pipe({
    name: 'user',
    standalone: true,
})
export class UserPipe implements PipeTransform {
  constructor(private userState: UsersStateService) {}
  transform(
    idUser: string | undefined | null,
    property: 'displayName' | 'groupesName' | undefined = undefined
  ): string {
    if (!idUser) return '';
    const users = this.userState.getUsersFromGroupes();

    if (property === 'displayName') return getUserDisplayName(idUser, users);
    if (property === 'groupesName') {
      const groupes = this.userState.getGroupesFromUser(idUser);
      return getGroupesName(idUser, groupes);
    }
    return '';
  }
}

const getGroupesName = (idUser: string, groupes: groupeDocType[]) => {
  return groupes.map((e) => e.libelle).join(', ');
};
