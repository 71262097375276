import { EventData, Map, MapMouseEvent, MapboxGeoJSONFeature } from 'mapbox-gl';

/**
 * Handles the mouse enter event on a Mapbox map by changing the cursor style to 'pointer'.
 *
 * @param map - The Mapbox map instance.
 * @returns A function that handles the mouse enter event.
 */
export function handleMouseEnter(map: Map): (
  ev: MapMouseEvent & {
    features?: MapboxGeoJSONFeature[] | undefined;
  } & EventData
) => void {
  return () => {
    map.getCanvas().style.cursor = 'pointer';
  };
}
