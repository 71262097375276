import { Injectable, inject } from "@angular/core";
import { Observable, map } from "rxjs";
import { DatabaseService } from "../db/database.service";
import { tagDocType } from "../db/schemas/tag.schema";
import { TagsStateService } from "./tags-state.service";


@Injectable({
  providedIn: 'root',
})
export class TagMetasStateService {
  tagProvider = inject(DatabaseService).getTagProvider();
  tags$ = inject(TagsStateService).tags$;

  retrieveTagMetaKeys$: Observable<string[]> = this.tags$.pipe(
    map((tags) => [
      ...this.getTagMetaKeys(tags),
      ...[
        $localize`Prestataire`,
        $localize`Fournisseur`,
        $localize`Observation`,
        $localize`Nom`,
        $localize`Prénom`,
        $localize`Adresse`,
        $localize`Ville`,
        $localize`Code postal`,
        $localize`Email`,
        $localize`Téléphone`,
        $localize`Ville`,
      ],
    ])
  );

  retrieveTagMetaValues$(key: string): Observable<string[]> {
    return this.tags$.pipe(map((tags) => this.getTagMetaValues(tags, key)));
  }

  getTagMetaKeys(tags: tagDocType[]) {
    return tags.reduce((acc: string[], tag: tagDocType) => {
      tag.tag_meta?.forEach((meta) => {
        if (meta.key && !acc.includes(meta.key)) {
          acc.push(meta.key);
        }
      });

      return acc;
    }, []);
  }

  getTagMetaValues(tags: tagDocType[], key: string) {
    return tags.reduce((acc: string[], tag: tagDocType) => {
      tag.tag_meta?.forEach((meta) => {
        if (meta.key === key && meta.value && !acc.includes(meta.value)) {
          acc.push(meta.value);
        }
      });

      return acc;
    }, []);
  }

  remoteRemoveTagMeta(id: string) {
    return this.tagProvider.removeTagMeta(id);
  }
}
