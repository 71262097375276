import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { UgauChartDonutComponent } from './../../ugau-chart-donut/ugau-chart-donut.component';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { D3DatasType } from '@types_custom/d3-datas';
import { AsyncPipe } from '@angular/common';
import { UgauChartDonutComponent as UgauChartDonutComponent_1 } from '../../ugau-chart-donut/ugau-chart-donut.component';

@Component({
  selector: 'app-ugau-statistique-chart-donut',
  templateUrl: './ugau-statistique-chart-donut.container.html',
  styleUrls: ['./ugau-statistique-chart-donut.container.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UgauChartDonutComponent_1, AsyncPipe],
})
export class UgauStatistiqueChartDonutContainer {
  id = 'donut-' + uuidv4();
  className: string = 'ugau-statistique-chart-donut ' + this.id;
  height: number = 450;
  width: number = 350;

  @Input() set inputDatas(value: any) {
    const datas = [
      {
        key: value.name.replace(/ /g, ''),
        value: value.conforme.count,
        color: 'rgb(0, 128, 0)',
        line1: 'Conforme',
        line2:
          (
            (value.conforme.count * 100) /
            (value.conforme.count + value.nonConforme.count)
          ).toFixed(2) + ' %',
      },
      {
        key: value.name.replace(/ /g, ' '),
        value: value.nonConforme.count,
        color: 'rgb(245,61,61)',
        line1: 'Non conforme',
        line2:
          (
            (value.nonConforme.count * 100) /
            (value.conforme.count + value.nonConforme.count)
          ).toFixed(2) + ' %',
      },
    ];
    this.datasSubject$.next(datas);
  }

  private datasSubject$: BehaviorSubject<D3DatasType[]> = new BehaviorSubject<
    D3DatasType[]
  >([]);
  // Delay needed because of material tabs
  // @TODO: Search a better way than 1s delay
  datas$: Observable<D3DatasType[]> = this.datasSubject$
    .asObservable()
    .pipe(delay(1000));

  @ViewChild(UgauChartDonutComponent) donutComponent!: UgauChartDonutComponent;
}
