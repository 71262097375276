import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const stockSchemaLiteral = jsonSchema({
  name: 'stock',
  autoMigrate: true,
  title: 'Stock schema',
  version: 1,
  type: 'object',
  required: [
    'id',
    'product_id',
    'groupe_id',
    'type',
    'quantity',
    'price',
    'created_at',
    'created_by',
  ],
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    product_id: {
      type: 'string',
      maxLength: 36,
    },
    emplacement_id: {
      type: 'string',
      maxLength: 36,
    },
    quantity: {
      type: 'integer',
    },
    meta: {
      type: 'object',
      additionalProperties: true,
    },
    price: {
      type: 'number',
    },
    supplier: {
      type: 'string',
      nullable: true,
    },
    alert_threshold: {
      type: 'integer',
      default: 0,
    },
    created_at: {
      type: 'string',
      format: 'date-time',
    },
    updated_at: {
      type: 'string',
      format: 'date-time',
    },
    created_by: {
      type: 'string',
      maxLength: 36,
    },
    updated_by: {
      type: 'string',
      maxLength: 36,
      nullable: true,
    },
    deleted_at: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    deleted_by: {
      type: 'string',
      maxLength: 36,
      nullable: true,
    },
    groupe_id: {
      type: 'string',
      maxLength: 36,
    },
    type: {
      type: 'string',
      maxLength: 36,
    },
  },
} as const);

const stockSchemaTyped = toTypedRxJsonSchema(stockSchemaLiteral);

export type stockDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof stockSchemaTyped
> & { meta: any };

export const stockSchema: RxJsonSchema<stockDocType> = stockSchemaLiteral;
