import { inject, Injectable } from '@angular/core';
import { stockDocType } from '../../db/schemas/stock.schema';
import { getUserID } from '../../services/nhost';
import { BehaviorSubject, map, Observable, shareReplay } from 'rxjs';
import { ProductfieldsStateService } from '../../state/productfields-state.service';
import { ProductSearchStateService } from '../../state/product-search-state.service';
import {
  flatFamiliesFromFamilyTree,
  flatProductsFromFamilyTree,
  getProductFromFamilies,
} from '../../utils/productfamilies-utils.service';
import { ProductFamiliesTreeState } from '../../state/product-families-tree-state.service';
import { initNewStockItem } from './initNewStockItem';
import { extractUniqueValues } from 'src/app/utils/extractUniqueValues';
import { DatabaseService } from '../../db/database.service';
import { RoutingState } from '../../state/routing-state.service';
import { EmplacementsStateService } from '../../state/emplacements-state.service';
import {
  composantDocType,
  emplacementDocType,
} from '../../db/schemas/emplacement.schema';
import {
  areComposantsBatimentInside,
  isEmplacementBatimentInside,
} from './isEmplacementBatimentInside';

@Injectable({
  providedIn: 'root',
})
export class StockState {
  private db = inject(DatabaseService);
  private stockProvider = this.db.getStockProvider();
  private productfamiliesState = inject(ProductFamiliesTreeState);
  private productSearchState = inject(ProductSearchStateService);
  private productfieldState = inject(ProductfieldsStateService);
  private emplacementsState = inject(EmplacementsStateService);
  private productTreeState = inject(ProductFamiliesTreeState);
  public routingState = inject(RoutingState);

  showDeletedFlag = false; // TODO: Add a method to toggle this flag

  private stockSelectedIndexSubject$ = new BehaviorSubject<string | null>(null);
  stockSelectedIndex$ = this.stockSelectedIndexSubject$.asObservable();

  depots$ = this.emplacementsState.emplacements$.pipe(
    map((emplacements) => {
      const batimentFamilies = this.productTreeState.getTreesByType('batiment');
      const productIds = flatProductsFromFamilyTree(batimentFamilies).map(
        (p) => p.id
      );
      const familiesIds = flatFamiliesFromFamilyTree(batimentFamilies).map(
        (f) => f.id
      );
      return emplacements.filter((e) =>
        isEmplacementBatimentInside(e, productIds, familiesIds)
      );
    })
  );

  stocks$ = this.stockProvider.getAll$.pipe(
    map((stocks) => {
      if (this.showDeletedFlag)
        return stocks.filter((stock) => stock.type === 'stock');
      return stocks.filter(
        (stock) => !stock.deleted_at && stock.type === 'stock'
      );
    }),
    shareReplay(1)
  );

  materiel$: Observable<composantDocType[]> =
    this.emplacementsState.emplacements$.pipe(
      map((emplacements) => {
        const batimentFamilies =
          this.productTreeState.getTreesByType('materiel');
        const productIds = flatProductsFromFamilyTree(batimentFamilies).map(
          (p) => p.id
        );
        const familiesIds = flatFamiliesFromFamilyTree(batimentFamilies).map(
          (f) => f.id
        );

        return emplacements.flatMap((e) => {
          return e.composants
            .filter((c) =>
              areComposantsBatimentInside([c], productIds, familiesIds)
            )
            .map((c) => {
              return {
                ...c,
                id_emplacement: e.id,
              };
            });
        });
      }),
      shareReplay(1)
    );

  optionsDesignation$: Observable<string[]> = this.stocks$.pipe(
    map((stocks) =>
      extractUniqueValues(stocks, (stock) => stock.meta?.designation ?? '')
    )
  );
  optionsSupplier$: Observable<string[]> = this.stocks$.pipe(
    map((stocks) => {
      return extractUniqueValues(stocks, (stock) => stock.supplier ?? '');
    })
  );

  private newStockDepotSubject$ = new BehaviorSubject<
    emplacementDocType | undefined
  >(undefined);
  newStockDepot$ = this.newStockDepotSubject$.asObservable();

  private newStockSubject$ = new BehaviorSubject<stockDocType | undefined>(
    undefined
  );
  newStock$ = this.newStockSubject$.asObservable();

  getStockSelectedIndex() {
    return this.stockSelectedIndexSubject$.getValue();
  }
  setStockSelectedIndex(idGroupe: string | null) {
    this.stockSelectedIndexSubject$.next(idGroupe);
  }

  getStocks() {
    return this.stockProvider.getAllValue();
  }

  getStockItemById$(stockId: string) {
    return this.stocks$.pipe(
      map((stocks) => stocks.find((stock) => stock.id === stockId))
    );
  }
  getStockItemById(stockId: string) {
    return this.stockProvider
      .getAllValue()
      .find((stock) => stock.id === stockId);
  }

  getStockByGroupeIdAndDepotId(groupeIds: string[], depotIds: string[]) {
    return this.stocks$.pipe(
      map((stocks) =>
        stocks.filter((stock) => groupeIds.includes(stock.groupe_id))
      )
    );
  }

  upsertStock(stock: stockDocType) {
    return this.stockProvider.collection.upsert(
      this.doStockDesignationUpdate({
        ...stock,
        updated_at: new Date().toISOString(),
        updated_by: getUserID(),
      })
    );
  }

  // STOCK FORM
  setNewStock(stock: stockDocType) {
    this.newStockSubject$.next(stock);
  }
  clearNewStock() {
    this.newStockSubject$.next(undefined);
  }

  navigateStock(stockId: string | null = null) {
    this.routingState.navigateStock(stockId);
  }

  navigateMateriel(stockId: string | null = null) {
    this.routingState.navigateMateriel(stockId);
  }

  navigateBatiment(depotId: string | null = null) {
    // this.clearNewStockDepot();
    this.routingState.navigateBatiment(depotId);
  }

  deleteStock(stock: stockDocType) {
    return this.upsertStock({
      ...stock,
      deleted_at: new Date().toISOString(),
      deleted_by: getUserID(),
    });
  }

  async addNewStock(
    groupeId: string | null = null,
    type: 'stock' | 'materiel'
  ) {
    if (type === 'materiel') {
      this.productfamiliesState.setShownModuleType('materiel');
    }

    const selectedProduct = await this.productSearchState.chooseProduct(null);
    this.productfamiliesState.setShownModuleType('module');
    if (!selectedProduct) {
      return;
    }

    const isDesignationUpdatable =
      this.productfieldState.hasProductfieldsDesignation(
        selectedProduct.productfields.map((e) => e.productfield.id)
      );

    const newStock = initNewStockItem({
      type,
      groupe_id: groupeId ?? '',
      product_id: selectedProduct.id,
      emplacement_id: '',
      meta: {
        designation: selectedProduct.name,
        isDesignationUpdatable,
      },
    });

    this.setNewStock(newStock);
  }

  getStockDesignation(
    stock:
      | stockDocType
      | { product_id: string; meta: { designation: string | null } }
      | null
  ) {
    if (!stock) return '';

    const families = this.productfamiliesState.getOriginalTree();
    const product = getProductFromFamilies(stock.product_id, families);

    if (stock.meta?.designation) return stock.meta.designation;
    if (product?.name) return product.name;
    return '';
  }

  doStockDesignationUpdate(stock: stockDocType) {
    const families = this.productfamiliesState.getOriginalTree();
    const product = getProductFromFamilies(stock.product_id, families);
    const oldMeta = stock.meta;
    if (!product) {
      return {
        ...stock,
        meta: {
          ...oldMeta,
          designation: stock.meta?.designation ?? null,
          isDesignationUpdatable: false,
        },
      };
    }

    const isDesignationUpdatable =
      this.productfieldState.hasProductfieldsDesignation(
        product.productfields.map((e) => e.productfield.id)
      );

    return {
      ...stock,
      meta: {
        ...oldMeta,
        designation: stock.meta?.designation ?? product.name,
        isDesignationUpdatable,
      },
    };
  }

  // DEPOT FORM
  setNewStockDepot(depot: emplacementDocType) {
    this.newStockDepotSubject$.next(depot);
  }

  clearNewStockDepot() {
    this.newStockDepotSubject$.next(undefined);
  }
}
