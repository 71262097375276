import { PageEvent } from '@angular/material/paginator';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../utils/localstorage-utils.service';

export const PAGE_SIZE_OPTIONS = () => {
  return [5, 10, 20, 50, 100, 200];
};

export const PAGE_CHANGED = ($event: PageEvent) => {
  setToLocalStorage('pageSize', $event.pageSize);
};

export const PAGE_SIZE = () => {
  return getFromLocalStorage('pageSize') || 5;
};
