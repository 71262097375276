import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { COLORS_BTN } from './../../enum/colors';
import { UgauButtonComponent } from '../ugau-button/ugau-button.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-ugau-button-annuler-valider',
    template: `
    <div class="action-buttons">
      <app-ugau-button
        [label]="libelleCancel"
        *ngIf="!disableCancel"
        textAlign="center"
        [classColor]="colorCancel"
        [fill]="true"
        (click)="onCancel()"
        [capitalize]="capitalize"
      ></app-ugau-button>
      <span class="spacer" *ngIf="!disableValid && !disableCancel"></span>
      <app-ugau-button
        *ngIf="!disableValid"
        [fill]="true"
        textAlign="center"
        [classColor]="colorValid"
        [label]="libelleValid"
        (click)="onValid()"
        [capitalize]="capitalize"
      ></app-ugau-button>
    </div>
  `,
    styles: [
        `
      .action-buttons {
        z-index: 1;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, UgauButtonComponent],
})
export class UgauButtonAnnulerValiderContainer {
  @Input() onCancel!: () => void;
  @Input() onValid!: () => void;

  @Input() libelleCancel = $localize`Annuler`;
  @Input() libelleValid = $localize`Valider`;

  @Input() colorCancel: COLORS_BTN = 'grey';
  @Input() colorValid: COLORS_BTN = 'primary';

  @Input() capitalize: boolean = false;

  @Input() disableValid: boolean = false;
  @Input() disableCancel: boolean = false;
}
