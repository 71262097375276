import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductpropertiesProvider } from '../db/providers/productproperties-provider';
import { productpropertiesDocType } from '../db/schemas/productproperties.schema';

@Injectable({
  providedIn: 'root',
})
export class ProductpropertiesStateService {
  productproperties$: Observable<productpropertiesDocType[]> =
    this.productpropertiesProvider.getAll$;

  constructor(private productpropertiesProvider: ProductpropertiesProvider) {}

  getProductpropertie$(
    productpropertyId: string
  ): Observable<productpropertiesDocType | undefined> {
    return this.productproperties$.pipe(
      map((productproperties) =>
        productproperties.find((el) => el.id === productpropertyId)
      )
    );
  }

  getProductproperties() {
    return this.productpropertiesProvider.getAllValue();
  }
}
