export enum REGLEMENTATION {
  'HAUTEUR' = '1',
  'AGE' = '2',
  'ENDOMMAGE' = '3',
  'PROPRETE' = '4',
  'CERTIFICATION' = '5',
  'ACCESSIBILITE' = '6',
  'ACCIDENTE' = '7',
  'TEXTE' = '8',
  'RECURRENCE' = '10',
}

export enum REGLEMENTATION_CHOIX {
  'PROPRE' = 1,
  'SALE' = 2,

  'HOMOLOGUE' = 3,
  'NON_HOMOLOGUE' = 4,

  'ENDOMMAGE' = 6,
  'NON_ENDOMMAGE' = 7,

  'SUPERIEUR_A_1_40M' = 8,
  'INFÉRIEUR_A_1_40M' = 9,

  'SUPÉRIEUR_A_2_20M' = 10,
  'INFÉRIEUR_A_2_20M' = 13,
  'ESPACE_VERT' = 14,
  'CONTRE_MUR' = 15,

  'ACCIDENTE' = 16,
  'CONFORME' = 17,
}