import { EMPLACEMENT_STATUS } from './../../common-projects/emplacement_status';
import {
  URL_MODAL_DISPLAY_ACTION,
  URL_MODAL_PAGE,
  URL_PANEL_ACTION,
  URL_PANEL_DISPLAY_ACTION,
} from '../enum/url-emplacement';
import { NavigationEnd } from '@angular/router';

export class RoutingStateType {
  constructor(
    private _page: PageState | undefined,
    private _modal: ModalState | undefined
  ) {}

  get page(): PageState | undefined {
    return this._page;
  }

  set page(value: PageState) {
    this._page = value;
  }

  get modal(): ModalState | undefined {
    return this._modal;
  }

  set modal(value: ModalState | undefined) {
    this._modal = value;
  }
}

interface ModalStateParams {
  page?: URL_MODAL_PAGE;
  action?: URL_MODAL_DISPLAY_ACTION;
  idFirst?: string | undefined;
  idSecond?: string | undefined;
  idGroupe?: string | undefined;
}

export class ModalState {
  private _page: URL_MODAL_PAGE | undefined;
  private _action: URL_MODAL_DISPLAY_ACTION | undefined;
  private _idFirst: string | undefined;
  private _idSecond: string | undefined;
  private _idGroupe: string | undefined;

  constructor(params: ModalStateParams) {
    this._page = params.page;
    this._action = params.action;
    this._idFirst = params.idFirst;
    this._idSecond = params.idSecond;
    this._idGroupe = params.idGroupe;
  }

  get page(): URL_MODAL_PAGE | undefined {
    return this._page;
  }

  set page(value: URL_MODAL_PAGE | undefined) {
    this._page = value;
  }

  hasPage(): boolean {
    return this._page !== undefined;
  }

  get action(): URL_MODAL_DISPLAY_ACTION | undefined {
    return this._action;
  }

  set action(value: URL_MODAL_DISPLAY_ACTION | undefined) {
    this._action = value;
  }

  hasAction(): boolean {
    return this._action !== undefined;
  }

  get idFirst(): string | undefined {
    return this._idFirst;
  }

  set idFirst(value: string | undefined) {
    this._idFirst = value;
  }

  hasIdFirst(): boolean {
    return this._idFirst !== undefined;
  }

  get idSecond(): string | undefined {
    return this._idSecond;
  }

  set idSecond(value: string | undefined) {
    this._idSecond = value;
  }

  hasIdSecond(): boolean {
    return this._idSecond !== undefined;
  }

  get idGroupe(): string | undefined {
    return this._idGroupe;
  }

  set idGroupe(value: string | undefined) {
    this._idGroupe = value;
  }

  hasIdGroupe(): boolean {
    return this._idGroupe !== undefined;
  }
}

interface PageStateParams {
  page?: URL_PANEL_ACTION;
  action?: URL_PANEL_DISPLAY_ACTION;
  id_emplacement?: string;
  id_emplacement_photo?: string;
  emplacement_status?: EMPLACEMENT_STATUS;
  latitude?: number;
  longitude?: number;
  forceOpenSearch?: boolean;
  source_event?: NavigationEnd;
  familyIds?: string[];
  forceFullWidth?: boolean;
}

export class PageState {
  private _page: URL_PANEL_ACTION | undefined;
  private _action: URL_PANEL_DISPLAY_ACTION | undefined;
  private _id_emplacement: string | undefined;
  private _id_emplacement_photo: string | undefined;
  private _emplacement_status: EMPLACEMENT_STATUS | undefined;
  private _latitude: number | undefined;
  private _longitude: number | undefined;
  private _forceOpenSearch: boolean;
  private _source_event: NavigationEnd | undefined;
  private _familyIds: string[] | undefined;
  private _forceFullWidth: boolean | undefined;

  constructor(params: PageStateParams) {
    this._page = params.page;
    this._action = params.action;
    this._id_emplacement = params.id_emplacement;
    this._id_emplacement_photo = params.id_emplacement_photo;
    this._emplacement_status = params.emplacement_status;
    this._latitude = params.latitude;
    this._longitude = params.longitude;
    this._forceOpenSearch = params.forceOpenSearch ?? false; // Default to false
    this._source_event = params.source_event;
    this._familyIds = params.familyIds;
    this._forceFullWidth = params.forceFullWidth;
  }

  // Getters and Setters for the properties
  get page(): URL_PANEL_ACTION | undefined {
    return this._page;
  }

  set page(value: URL_PANEL_ACTION | undefined) {
    this._page = value;
  }

  hasPage(): boolean {
    return this._page !== undefined;
  }

  get action(): URL_PANEL_DISPLAY_ACTION | undefined {
    return this._action;
  }

  set action(value: URL_PANEL_DISPLAY_ACTION | undefined) {
    this._action = value;
  }

  hasAction(): boolean {
    return this._action !== undefined;
  }

  get id_emplacement(): string | undefined {
    return this._id_emplacement;
  }

  set id_emplacement(value: string | undefined) {
    this._id_emplacement = value;
  }

  hasIdEmplacement(): boolean {
    return this._id_emplacement !== undefined;
  }

  get id_emplacement_photo(): string | undefined {
    return this._id_emplacement_photo;
  }

  set id_emplacement_photo(value: string | undefined) {
    this._id_emplacement_photo = value;
  }

  hasIdEmplacementPhoto(): boolean {
    return this._id_emplacement_photo !== undefined;
  }

  get emplacement_status(): EMPLACEMENT_STATUS | undefined {
    return this._emplacement_status;
  }

  set emplacement_status(value: EMPLACEMENT_STATUS | undefined) {
    this._emplacement_status = value;
  }

  get latitude(): number | undefined {
    return this._latitude;
  }

  set latitude(value: number | undefined) {
    this._latitude = value;
  }

  get longitude(): number | undefined {
    return this._longitude;
  }

  set longitude(value: number | undefined) {
    this._longitude = value;
  }

  get forceOpenSearch(): boolean {
    return this._forceOpenSearch;
  }

  set forceOpenSearch(value: boolean) {
    this._forceOpenSearch = value;
  }

  get source_event(): NavigationEnd | undefined {
    return this._source_event;
  }

  set source_event(value: NavigationEnd | undefined) {
    this._source_event = value;
  }

  get familyIds(): string[] | undefined {
    return this._familyIds;
  }

  set familyIds(value: string[] | undefined) {
    this._familyIds = value;
  }

  get forceFullWidth(): boolean | undefined {
    return this._forceFullWidth;
  }

  set forceFullWidth(value: boolean | undefined) {
    this._forceFullWidth = value;
  }

  // Computed properties as getters
  get isCurrentActionDetail(): boolean {
    return this._page === URL_PANEL_ACTION.DETAIL;
  }

  get isCurrentActionCreation(): boolean {
    return (
      this._page === URL_PANEL_ACTION.CREATE ||
      this._page === URL_PANEL_ACTION.CHOOSE_ADD
    );
  }

  get isEmplacementShown(): boolean {
    return (
      this._page === URL_PANEL_ACTION.DETAIL ||
      this._page === URL_PANEL_ACTION.CREATE ||
      this._page === URL_PANEL_ACTION.CHOOSE_ADD
    );
  }

  get isEmplacementLocationSelectorShown(): boolean {
    return this._action === URL_PANEL_DISPLAY_ACTION.LOCATION_SELECTOR;
  }

  get isEmplacementPhotoFullShown(): boolean {
    return this._action === URL_PANEL_DISPLAY_ACTION.PHOTOS;
  }

  get isEmplacementPhotoSelectorShown(): boolean {
    return (
      this._action === URL_PANEL_DISPLAY_ACTION.PRISE_PHOTO ||
      this._action === URL_PANEL_DISPLAY_ACTION.PRISE_PHOTO_DIRECT
    );
  }

  get isActionPrisePhotoDirect(): boolean {
    return this._action === URL_PANEL_DISPLAY_ACTION.PRISE_PHOTO_DIRECT;
  }
}
