import { Injectable } from '@angular/core';
import { GraphQlService } from '../services/graph-ql.service';
import { DatabaseService } from '../db/database.service';
import { SentryService } from './sentry.service';
import { gql } from 'graphql-request';
import { graphql } from './nhost';
import { ToolsService } from './tools.service';
import { Observable, map } from 'rxjs';
import { usersDocType } from '../db/schemas/users.schema';
import { RxDocument } from 'rxdb';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private gql: GraphQlService,
    private db: DatabaseService,
    private AT: ToolsService
  ) {}

  public changeEmail(idUser: string, email: string) {
    const operationsDoc = `
    mutation MyMutation($id: uuid = "", $email: citext = "") {
      updateUser(pk_columns: {id: $id}, _set: {email: $email}) {
        id
        email
      }
    }
`;
    const variables = {
      id: idUser,
      email,
    };

    return this.gql.doCall(operationsDoc, variables, 'MyMutation');
  }

  public getUserById(id: string) {
    return this.db
      .getUserProvider()
      .collection.findOne()
      .where('id')
      .eq(id)
      .exec();
  }

  public getUserByIdObj(id: string) {
    return this.db
      .getUserProvider()
      .collection.findOne()
      .where('id')
      .eq(id)
      .exec()
      .then((user: RxDocument<usersDocType> | null) => {
        return user?.toJSON(true);
      });
  }

  public getUserByIdObs(
    id: string | undefined
  ): Observable<usersDocType | undefined> {
    return this.db
      .getUserProvider()
      .collection.findOne()
      .where('id')
      .eq(id)
      .$.pipe(
        map((user: any) => {
          if (!user || !id) return undefined;
          return user.toJSON(true);
        })
      );
  }

  public async upsert(user: any) {
    SentryService.captureMessage('Upsert User', {
      extra: user,
    });

    const mutation = gql`
      mutation MyMutation(
        $id: uuid = ""
        $displayName: String = ""
        $phoneNumber: String = ""
      ) {
        updateUser(
          pk_columns: { id: $id }
          _set: { displayName: $displayName, phoneNumber: $phoneNumber }
        ) {
          id
        }
      }
    `;

    const { data, error } = await graphql.request(mutation, {
      id: user.id,
      displayName: user.displayName,
      phoneNumber: user.phoneNumber,
    });

    if (error) {
      SentryService.captureException(error);
      this.AT.toastError('Erreur lors de la mise à jour de votre profil');
      return;
    }

    if (data) {
      this.AT.toastError('Profil mis à jour');
      this.resyncUserAndGroupe();
    }
  }

  public getUsersByNomObs(name: string) {
    const strReg = '.*' + name + '.*';
    // const reg = new RegExp(strReg);
    return this.db
      .getUserProvider()
      .collection.find()
      .where('displayName')
      .regex({
        $regex: strReg,
        $options: 'i',
      }).$;
  }

  public async changeUserType(idUser: string, idGroupe: string, type: string) {
    const mutation = gql`
      mutation MyMutation(
        $idUser: uuid = ""
        $idGroupe: uuid = ""
        $type: String = ""
      ) {
        update_user_groupe(
          where: { id_groupe: { _eq: $idGroupe }, id_user: { _eq: $idUser } }
          _set: { role: $type }
        ) {
          affected_rows
        }
        update_groupe(
          where: { id: { _eq: $idGroupe } }
          _set: { updated_at: "now()" }
        ) {
          affected_rows
        }
      }
    `;
    const variables = {
      idUser,
      idGroupe,
      type,
    };

    const { data, error } = await graphql.request(mutation, variables);

    if (error) {
      SentryService.captureException(error);
      this.AT.toastError("Erreur lors de la mise à jour de l'utilisateur");
      return;
    }

    if (data) {
      this.AT.toastError('Utilisateur mis à jour');
      this.resyncUserAndGroupe();
    }
  }

  public async deleteUserGroupe(idUser: string, idGroupe: string) {
    const mutation = gql`
      mutation MyMutation($idUser: uuid = "", $idGroupe: uuid = "") {
        delete_user_groupe(
          where: { id_groupe: { _eq: $idGroupe }, id_user: { _eq: $idUser } }
        ) {
          affected_rows
        }
        update_groupe(
          where: { id: { _eq: $idGroupe } }
          _set: { updated_at: "now()" }
        ) {
          affected_rows
        }
      }
    `;
    const variables = {
      idUser,
      idGroupe,
    };

    const { data, error } = await graphql.request(mutation, variables);

    if (error) {
      SentryService.captureException(error);
      this.AT.toastError("Erreur lors de la mise à jour de l'utilisateur");
      return;
    }

    if (data) {
      this.AT.toastError('Utilisateur mis à jour');
      this.resyncUserAndGroupe();
    }
  }

  resyncUserAndGroupe() {
    this.db.getUserProvider().replicateState?.reSync();
    this.db.getGroupeProvider().replicateState?.reSync();
  }
}
