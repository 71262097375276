import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-dialog-change-password',
    template: `
    <h1 mat-dialog-title>{{ title }}</h1>
    <mat-dialog-content>
      {{ description }}
      <mat-form-field [style.width.%]="100">
        <input matInput [placeholder]="placeholder" [(ngModel)]="newPassword" />
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="onNoClick()" *ngIf="!disableClose" i18n>
        Annuler
      </button>
      <button
        mat-button
        [mat-dialog-close]="newPassword"
        cdkFocusInitial
        [disabled]="!isValidPassword(newPassword)"
        i18n
      >
        OK
      </button>
    </mat-dialog-actions>
  `,
    styles: [
        `
      :host {
        mat-form-field {
          width: 100%;
        }
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        NgIf,
        MatButtonModule,
    ],
})
export class DialogChangePasswordComponent {
  title: string | undefined = $localize`Changement de mot de passe`;
  placeholder = $localize`Nouveau mot de passe`;
  description = '';

  newPassword!: string;

  disableClose: boolean = false;

  constructor(
    public cdk: OverlayContainer,
    public dialogRef: MatDialogRef<DialogChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      description: string;
      disableClose: boolean;
      placeholder?: string;
    }
  ) {
    if (data.title) this.title = data.title;
    if (data.description) this.description = data.description;
    if (data.disableClose) this.disableClose = data.disableClose;
    if (data.placeholder) this.placeholder = data.placeholder;

    // Petit hack pour que ce dialog soit au dessus des autres
    setTimeout(() => {
      this.cdk.getContainerElement().childNodes.forEach((x: any) => {
        if (x.innerHTML.indexOf('id="' + this.dialogRef.id + '"') <= 0)
          x.style['z-index'] = 1000;
        else x.style['z-index'] = 1001;
      });
    }, 1000);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isValidPassword(password: string): boolean {
    return !!password && password.length >= 8;
  }
}
