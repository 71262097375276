import { Injectable } from '@angular/core';
import { BaseUserMetaState } from './usermeta-abstract.state';
import { typeTagsChoices } from '../enum/typeTagsChoices';
import { TagTypeSelectItem } from '../components/ugau-select-tag-type/TagTypeSelectItem';
import { emplacementDocType } from '../db/schemas/emplacement.schema';

const MAPBOXSTYLE_key = 'mapboxStyle';
const DEFAULTCOMPOSANTOPENED_key = 'defaultComposantOpened';
const TAGTYPEDEFAULT_key = 'tag_type_default';
const ICS_key = 'ics';
const EMPLACEMENTWIDTH_key = 'emplacement_width';
const EMPLACEMENTPHOTOWIDTH_key = 'emplacement_photo_width';

@Injectable({
  providedIn: 'root',
})
export class UserMetaState extends BaseUserMetaState {
  // Mapbox Style
  getMapboxStyle(): string | null {
    return this.getMetadataValue<string>(MAPBOXSTYLE_key);
  }
  setMapboxStyle(value: string) {
    this.setMetadata(MAPBOXSTYLE_key, value);
  }

  // Composant Opened By Default
  getComposantOpenedByDefault(): boolean {
    const value = this.getMetadataValue<string>(DEFAULTCOMPOSANTOPENED_key);
    if (value === null) {
      return true;
    }
    return value === 'true';
  }
  setComposantOpenedByDefault(value: boolean) {
    this.setMetadata(DEFAULTCOMPOSANTOPENED_key, value ? 'true' : 'false');
  }

  // Default tag type
  getDefaultTagType(): string {
    return this.getMetadataValue<string>(TAGTYPEDEFAULT_key) ?? 'zone';
  }
  setDefaultTagType(value: string) {
    this.setMetadata(TAGTYPEDEFAULT_key, value);
  }
  getDefaultTagTypeAsSelectItem() {
    const actual = this.getDefaultTagType();
    const good = typeTagsChoices.find((e) => e.id === actual);
    return new TagTypeSelectItem(good ?? typeTagsChoices[0]);
  }

  // ICS
  getICS(): string | null {
    return this.getMetadataValue<string>(ICS_key);
  }

  // Emplacement Width
  getEmplacementWidth(): boolean {
    return (
      this.getMetadataValue<boolean>(EMPLACEMENTWIDTH_key, false, 'boolean') ??
      false
    );
  }
  setEmplacementWidth(value: boolean) {
    this.setMetadata(EMPLACEMENTWIDTH_key, value);
  }

  // Emplacement Photo Width
  getEmplacementPhotoWidth(): boolean {
    return (
      this.getMetadataValue<boolean>(
        EMPLACEMENTPHOTOWIDTH_key,
        false,
        'boolean'
      ) ?? false
    );
  }
  setEmplacementPhotoWidth(value: boolean) {
    this.setMetadata(EMPLACEMENTPHOTOWIDTH_key, value);
  }
  // List sorting
  getEmplacementListSorting(): {
    key: keyof emplacementDocType;
    order: 'asc' | 'desc';
  } {
    return (
      this.getMetadataValue<{
        key: keyof emplacementDocType;
        order: 'asc' | 'desc';
      }>('emplacementListSorting', true, 'object') ?? {
        key: 'updated_at',
        order: 'desc',
      }
    );
  }

  setEmplacementListSorting(value: {
    key: keyof emplacementDocType;
    order: 'asc' | 'desc';
  }) {
    this.setMetadata('emplacementListSorting', value);
  }
}
