import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { sortBy } from 'lodash-es';
import { map } from 'rxjs';
import { NgIf, AsyncPipe } from '@angular/common';
import { UgauSelectContainer } from '../../ugau-select/ugau-select.container';
import { EmplacementsStateService } from '../../../state/emplacements-state.service';
import { ProductFamiliesTreeState } from '../../../state/product-families-tree-state.service';
import {
  flatFamiliesFromFamilyTree,
  flatProductsFromFamilyTree,
} from '../../../utils/productfamilies-utils.service';
import { EmplacementSelectItem } from '../../ugau-productfield-batiment/EmplacementSelectItem';
import { getProductsOrder } from '../../../utils/product/getProductsOrder';

@Component({
  selector: 'app-ugau-select-emplacement-stock',
  template: `
    <ng-container *ngIf="depotChoices$">
      <app-ugau-select
        [label]="label"
        [options]="(depotChoices$ | async) || []"
        [value]="currentStatusSelectItem"
        (valueChange)="valueChange($event)"
        [readonly]="readOnly"
      ></app-ugau-select>
    </ng-container>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UgauSelectContainer, AsyncPipe],
})
export class UgauSelectEmplacementStockContainer implements OnChanges {
  private emplacementsState = inject(EmplacementsStateService);
  private treeState = inject(ProductFamiliesTreeState);
  private productsOrder = getProductsOrder(this.treeState.getOriginalTree());

  @Input() value!: string | null | undefined;
  @Input() readOnly = false;
  @Input() label = $localize`Type`;

  currentStatusSelectItem!: EmplacementSelectItem;
  depotChoices$ = this.emplacementsState.emplacements$.pipe(
    map((emplacements) => {
      const families = this.treeState.getTreesByType('batiment');
      const productIds = flatProductsFromFamilyTree(families).map((p) => p.id);
      const familiesIds = flatFamiliesFromFamilyTree(families).map((f) => f.id);

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!emplacements || !families) return [];

      // Pour chaque composant de chaque emplacement, on vérifie product.id et type
      const empls = emplacements.filter((e) => {
        return e.composants.some(
          (c) =>
            (c.product?.id && productIds.includes(c.product.id)) ||
            familiesIds.includes(c.type)
        );
      });

      return sortBy(
        empls.map((e) => new EmplacementSelectItem(e, this.productsOrder)),
        (e) => e.getLibelle()
      );
    })
  );
  @Output() depotChange = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value) {
      const choice = this.emplacementsState.getEmplacementById(this.value);
      if (choice)
        this.currentStatusSelectItem = new EmplacementSelectItem(
          choice,
          this.productsOrder
        );
    }
  }

  valueChange($event: EmplacementSelectItem) {
    this.depotChange.emit($event.item.id);
  }
}
