import { WorkerFilterChild } from './worker.types';

export function mapToObject(
  map: Map<string, WorkerFilterChild[] | undefined>
): object {
  const out = Object.create(null);
  map.forEach((value, key) => {
    // No need to check if value is an instance of Map, based on your type definitions
    out[key] = value;
  });
  return out;
}
