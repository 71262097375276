import { gql } from "graphql-request";


export const QUERY_GET_STOCK = gql`
  query GetStock(
    $where: stock_bool_exp!
    $order_by: [stock_order_by!]!
    $limit: Int!
  ) {
    stock(where: $where, order_by: $order_by, limit: $limit) {
      id
      product_id
      groupe_id
      type
      emplacement_id
      quantity
      meta
      price
      supplier
      alert_threshold
      created_at
      updated_at
      created_by
      updated_by
      deleted_at
      deleted_by
    }
  }
`;
