import { findIdFromFieldPropertyType } from '../productproperties-utils.service';
import { PRODUCTFIELD_TYPE } from '../../enum/product-fields-type';
import { productfieldDocType } from '../../db/schemas/productfields.schema';
import {
  composantProductfieldDocType,
  composantDocType
} from '../../db/schemas/emplacement.schema';


export function getComposantFieldValue(
  composant: composantDocType,
  type: PRODUCTFIELD_TYPE,
  productfields: productfieldDocType[]
): any {
  // On récupère l'id du champ
  const idField = findIdFromFieldPropertyType(type, productfields);

  // Si l'id du champ n'est pas trouvé, on retourne undefined
  if (!idField) return undefined;

  const field = composant.composant_productfields.find(
    (f: composantProductfieldDocType) => f.productfield.id === idField
  );

  // Si aucun champ n'est trouvé, on retourne undefined
  if (!field) return undefined;

  // Sinon, on retourne la valeur du champ
  return field.value;
}
