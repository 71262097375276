import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';

// Permet de décoder les / dans l'url
// Ex : http://localhost:8100/?panel=detail%2F00000943-aaaa-aaaa-aaaa-756761755f73
// Res : http://localhost:8100/?panel=detail/00000943-aaaa-aaaa-aaaa-756761755f73
export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    const dus = new DefaultUrlSerializer();
    return dus.parse(url);
  }

  serialize(tree: UrlTree): any {
    const dus = new DefaultUrlSerializer(),
      path = dus.serialize(tree);
    return path.replace(/%2F/g, '/');
  }
}
