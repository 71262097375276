import { GeoJSONSource, Map } from 'mapbox-gl';
import { getCenterFromGeometry } from './getCenterFromGeometry';
import { handleMouseEnter } from './handleMouseEnter';
import { handleMouseleave } from './handleMouseleave';
import { mapboxLayerOptions } from './mapbox.interface';

export function addClickEventOnClusterLayer(
  map: Map,
  layer: mapboxLayerOptions
) {
  const func = (e: any) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [layer.id],
    });
    const clusterId = features[0].properties?.cluster_id;
    const source: GeoJSONSource = map.getSource(layer.source) as GeoJSONSource;

    source.getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
      if (err) return;

      const center = getCenterFromGeometry(features[0].geometry);
      if (!center?.lat) return;

      map.easeTo({
        center: [+center.lng, +center.lat],
        zoom: zoom + 2,
      });
    });
  };
  // Click sur le cluster
  map.off('click', layer.id, func);
  map.on('click', layer.id, func);

  map.on('mouseenter', layer.id, handleMouseEnter(map));
  map.on('mouseleave', layer.id, handleMouseleave(map));
}
