import { Injectable } from '@angular/core';
import {
  ReplicationPullHandlerResult,
  RxReplicationWriteToMasterRow,
  WithDeleted,
  lastOfArray,
} from 'rxdb';
import { AbstractProvider } from './abstract-provider';
import { ensureValidToken, graphql } from './../../services/nhost';
import { SentryService } from '../../services/sentry.service';
import { Checkpoint } from '../interfaces/Checkpoint.type';
import {
  contactDocType,
  contactSchema,
  contactSchemaLiteral,
} from '../schemas/contact.schema';
import { QUERY_GET_CONTACT } from '../queries/contact/QUERY_GET_CONTACT';

@Injectable({
  providedIn: 'root',
})
export class ContactProvider extends AbstractProvider<contactDocType> {
  schema = contactSchema;
  schemaLiteral = contactSchemaLiteral;

  protected migrationStrategies = {
    1: function (oldDoc: contactDocType) {
      return null;
    },
  };

  async getPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<contactDocType, Checkpoint>> {
    const variables = {
      where: {
        _or: this.getOrForQuery(lastCheckpoint),
      },
      order_by: { updated_at: 'asc' },
      limit: batchSize,
    };
    const { data, error } = await graphql.request(QUERY_GET_CONTACT, variables);

    if (error || !data.contact) {
      console.error(
        'Erreur lors de la récupération des contacts sur le serveur',
        error
      );
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.contact.map((doc: any) => {
      return {
        ...doc,
        deleted_bool: !doc.deleted_at ? false : true,
      };
    });

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }

  async getPushQuery(
    docs: RxReplicationWriteToMasterRow<contactDocType>[]
  ): Promise<WithDeleted<contactDocType>[]> {
    await ensureValidToken();

    const datas = docs.reduce(
      (acc: any[], rxDoc: RxReplicationWriteToMasterRow<contactDocType>) => {
        const doc = rxDoc.newDocumentState;

        const contact = {
          id: doc.id,
          name: doc.name,
          email: doc.email,
          user_id: doc.user_id,
          groupe_id: doc.groupe_id,
          type: doc.type,
          contact_meta: {
            data: doc.contact_meta?.map((meta) => ({
              id: meta.id,
              key: meta.key,
              value: meta.value,
              type: meta.type,
              created_at: meta.created_at,
              updated_at: meta.updated_at,
            })),
            on_conflict: {
              constraint: 'meta_pkey',
              update_columns: ['key', 'value', 'type'],
            },
          },
          created_at: doc.created_at,
          updated_at: doc.updated_at,
          created_by: doc.created_by,
          updated_by: doc.updated_by,
          deleted_at: doc.deleted_at,
          deleted_by: doc.deleted_by,
        };

        acc.push(contact);
        return acc;
      },
      []
    );
    try {
      const variables = {
        objects: datas,
        on_conflict: {
          constraint: 'contact_pkey',
          update_columns: [
            'name',
            'email',
            'user_id',
            'groupe_id',
            'type',
            'meta',
            'created_at',
            'updated_at',
            'created_by',
            'updated_by',
            'deleted_at',
            'deleted_by',
          ],
        },
      };
      const query = MUTATION_INSERT_CONTACT;
      const { error } = await graphql.request(query, variables);

      if (error) {
        SentryService.captureException(error);
        console.log('ERREUR Push contact', error);
        this.AT.toastError($localize`Erreur lors de la sauvegarde du contact`);
        return [];
      }

      this.AT.toastError($localize`Contact sauvegardé`);
      return [];
    } catch (error) {
      SentryService.captureException(error);
      console.log('ERREUR Push contact', error);
      this.AT.toastError($localize`Erreur lors de la sauvegarde du contact`);
      return [];
    }
  }
}
import { MUTATION_INSERT_CONTACT } from '../queries/contact/MUTATION_INSERT_CONTACT';
