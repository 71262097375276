import { AbstractProvider } from '../abstract-provider';
import {
  lastOfArray,
  ReplicationPullHandlerResult,
  RxConflictHandler,
  RxReplicationWriteToMasterRow,
  WithDeleted,
} from 'rxdb';
import { Injectable, inject } from '@angular/core';
import { map, BehaviorSubject, shareReplay } from 'rxjs';
import { ensureValidToken, graphql } from './../../../services/nhost';
import { EventsService } from './../../../services/events.service';
import { getEmplacementReplicationPush } from './../../../utils/emplacement/getEmplacementReplicationPush';
import { QUERY_GET_EMPLACEMENTS } from '../../queries/emplacement.queries';
import {
  emplacementDocType,
  emplacementSchema,
  emplacementSchemaLiteral,
} from '../../schemas/emplacement.schema';
import { getEmplacementMigrationStrategies } from './getEmplacementMigrationStrategies';
import { SentryService } from './../../../services/sentry.service';
import { sleep } from './../../../utils/sleep';
import { Checkpoint } from '../../interfaces/Checkpoint.type';

@Injectable({
  providedIn: 'root',
})
export class EmplacementProvider extends AbstractProvider<emplacementDocType> {
  public schema = emplacementSchema;
  public schemaLiteral = emplacementSchemaLiteral;
  public idInfo = {
    schema: this.schemaLiteral.name,
    id: this.idUser,
  };

  BATCH_SIZE = 500;

  totalCount$ = new BehaviorSubject<number | undefined>(undefined);

  public getAllMod$ = this.getAll$.pipe(
    map((e) => e.filter((el) => !el.deleted_at)),
    shareReplay(1)
  );

  events = inject(EventsService);

  constructor() {
    super();
    this.events.subscribe('emplacements:reset', () => {
      this.replicateState?.reSync();
    });
  }

  protected migrationStrategies = getEmplacementMigrationStrategies;

  protected conflictHandler: RxConflictHandler<emplacementDocType> | undefined =
    (i) => {
      const { newDocumentState, realMasterState } = i;
      const newUpdatedAt = newDocumentState.updated_at;
      const masterUpdatedAt = realMasterState.updated_at;

      // Cas où les deux timestamps sont définis
      if (newUpdatedAt && masterUpdatedAt) {
        if (newUpdatedAt === masterUpdatedAt) {
          return Promise.resolve({ isEqual: true });
        }
        return Promise.resolve({
          isEqual: false,
          documentData:
            newUpdatedAt > masterUpdatedAt ? newDocumentState : realMasterState,
        });
      }

      // Cas où l'un des timestamps est manquant
      if (newUpdatedAt && !masterUpdatedAt) {
        return Promise.resolve({
          isEqual: false,
          documentData: newDocumentState,
        });
      }

      if (!newUpdatedAt && masterUpdatedAt) {
        return Promise.resolve({
          isEqual: false,
          documentData: realMasterState,
        });
      }

      // Cas où les deux timestamps sont manquants
      return Promise.resolve({
        isEqual: false,
        documentData: realMasterState, // Par défaut, privilégie l'état master
      });
    };

  async getPushQuery(
    docs: RxReplicationWriteToMasterRow<emplacementDocType>[]
  ): Promise<WithDeleted<emplacementDocType>[]> {
    await ensureValidToken();

    try {
      const { query, variables } = getEmplacementReplicationPush(
        docs,
        this.idUser
      );
      const { error } = await graphql.request(query, variables);

      // Gestion de l'erreur sur le retour de la requête
      if (error) {
        SentryService.captureException(error, this.idInfo);
        this.AT.toastError(
          $localize`Erreur lors de la sauvegarde de l'emplacement sur le serveur`
        );
        return [];
      }

      this.AT.toastError($localize`Emplacement sauvegardé sur le serveur`);
      return [];
    } catch (error) {
      // Gestion de l'erreur sur la requête
      SentryService.captureException(error, this.idInfo);
      this.AT.toastError(
        $localize`Erreur lors de la sauvegarde de l'emplacement sur le serveur`
      );
      return [];
    }
  }

  async getPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<emplacementDocType, Checkpoint>> {
    await ensureValidToken();

    // Tentative de récupération des données avec une gestion des erreurs et reprise
    const fetchData = async (retries: number = 2): Promise<any> => {
      try {
        const variables = {
          where: {
            _or: this.getOrForQuery(lastCheckpoint),
          },
          where1: {
            deleted_at: {
              _is_null: true,
            },
          },
          order_by: this.getOrderByForQuery(),
          limit: this.BATCH_SIZE,
        };

        const { error, data } = await graphql.request(
          QUERY_GET_EMPLACEMENTS,
          variables
        );

        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        if (error) throw error;

        return data;
      } catch (error) {
        if (retries > 0) {
          console.log('Failed to fetch emplacements, retrying...', error);
          await sleep(5000); // Attente de 5 secondes avant de réessayer
          return await fetchData(retries - 1); // Réessayer
        }
        throw error; // Toutes les tentatives ont échoué, remonter l'erreur
      }
    };

    try {
      const data = await fetchData(); // Appel de la fonction de récupération des données
      const documentsFromRemote: any[] = data.emplacement;
      this.totalCount$.next(data.emplacement_aggregate.aggregate.count);

      return {
        documents: documentsFromRemote,
        checkpoint:
          documentsFromRemote.length === 0
            ? lastCheckpoint
            : {
                id: lastOfArray(documentsFromRemote).id,
                updatedAt: lastOfArray(documentsFromRemote).updated_at,
              },
      };
    } catch (error) {
      console.log(
        `Erreur lors de la récupération des emplacements sur le serveur`
      );
      SentryService.captureException(error, this.idInfo);
      this.AT.toastError(
        $localize`Erreur lors de la récupération des emplacements sur le serveur`
      );
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }
  }
}
