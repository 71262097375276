import { Map } from 'mapbox-gl';

/**
 * Removes a specified source from the Mapbox map if it exists.
 *
 * @param map - The Mapbox map instance.
 * @param source - The ID of the source to be removed.
 */
export const removeSource = (map: Map, source: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (map.getSource(source)) {
    map.removeSource(source);
  }
};
