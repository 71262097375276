import { gql } from "graphql-request";


export const MUTATION_INSERT_STOCK = gql`
  mutation InsertStock(
    $objects: [stock_insert_input!]!
    $on_conflict: stock_on_conflict!
  ) {
    insert_stock(objects: $objects, on_conflict: $on_conflict) {
      returning {
        id
      }
    }
  }
`;
