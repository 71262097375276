import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

interface DialogData {
  title: string;
  emailPlaceholder: string;
  passwordPlaceholder: string;
}

@Component({
    selector: 'app-dialog-prompt-register-new-user',
    styles: [
        `
      :host {
        mat-form-field {
          width: 100%;
        }
      }
    `,
    ],
    template: `
    <form [formGroup]="form" (ngSubmit)="onOkClick()">
      <h1 mat-dialog-title>{{ title }}</h1>
      <mat-form-field mat-dialog-content>
        <mat-label i18n>Adresse email</mat-label>
        <input matInput type="email" formControlName="email" />
        <mat-error
          *ngIf="
            form.controls['email'].invalid && form.controls['email'].touched
          "
          i18n
          >Le format de l'adresse email est invalide
        </mat-error>
      </mat-form-field>
      <div mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()" i18n>Annuler</button>
        <button mat-button cdkFocusInitial [disabled]="form.invalid" i18n>
          Ok
        </button>
      </div>
    </form>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        MatButtonModule,
    ],
})
export class DialogPromptRegisterNewUserComponent {
  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  title: string = $localize`Nouvel utilisateur`;

  constructor(
    public dialogRef: MatDialogRef<DialogPromptRegisterNewUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder
  ) {
    if (data.title) this.title = data.title;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
