import {
  ImagesProduct,
  ProductFamiliesType,
  ProductType,
  ProductTypeOrUndefined,
} from '@types_custom/ProductType';
import { get, uniqBy } from 'lodash-es';
import { composantDocType } from '../db/schemas/emplacement.schema';
import { PHOTO_DEFAULT } from '../enum/photo-default';
import { getProductPathFromCache } from './product/getProductPathFromCache';
import { getFamilyPath } from './productfamily/getFamilyPathFromCache';
import { transformUnitShortcut } from './unit-choices-utils';
import { storage } from '../services/nhost';

// Gestion d'un cache custom pour éviter de faire du récursif a gogo
const CACHE_STORE_PRODUCT_FROM_FAMILIES = new Map<
  string,
  ProductTypeOrUndefined
>();
const CACHE_FAMILIES_PATH = new Map<string, ProductFamiliesType[]>();
const CACHE_STORE_PRODUCT_IMAGE = new Map<string, string>();
const CACHE_STORE_FAMILY_IMAGE = new Map<string, ImagesProduct>();

export function flatFamiliesFromFamilyTree(families: ProductFamiliesType[]) {
  const acc: any[] = [];
  for (const family of families) {
    if (family.children_productfamilies?.length) {
      acc.push(...flatFamiliesFromFamilyTree(family.children_productfamilies));
    }
    acc.push({
      id: family.id,
      name: family.name,
    });
  }
  return acc;
}

export function flatProductsFromFamilyTree(families: ProductFamiliesType[]) {
  const acc: ProductType[] = [];
  for (const family of families) {
    if (family.children_productfamilies?.length) {
      acc.push(...flatProductsFromFamilyTree(family.children_productfamilies));
    }
    acc.push(
      ...family.products.map((val) => {
        return val.product;
      })
    );
  }
  return acc;
}

export function getProposeAsFirstChoiceFamilies(
  acc: ProductFamiliesType[],
  families: ProductFamiliesType[] | null
) {
  if (!families) return [];

  for (const family of families) {
    if (family.children_productfamilies?.length) {
      acc = [
        ...acc,
        ...getProposeAsFirstChoiceFamilies(
          acc,
          family.children_productfamilies
        ),
      ];
    }
    if (family.proposeAsFirstChoice) {
      acc.push(family);
    }
  }
  return uniqBy(acc, 'id');
}

export function getDirectParentFamilyForProductId(
  productId: string,
  families: ProductFamiliesType[]
): ProductFamiliesType | undefined {
  let goodFamily: ProductFamiliesType | undefined = undefined;
  for (const family of families) {
    const existProduct = family.products.find(
      (value) => value.product.id === productId
    );
    // Le produit est dans cette famille
    if (existProduct) {
      goodFamily = family;
      break;
    }
    // Sinon, on regarde dans les enfants de la famille (récursif)
    if (family.children_productfamilies?.length) {
      const recFamily = getDirectParentFamilyForProductId(
        productId,
        family.children_productfamilies
      );
      if (recFamily) {
        goodFamily = recFamily;
        break;
      }
    }
  }
  return goodFamily;
}

export function recursiveFindFamilyFromFamiliesId(
  familyId: string,
  families: ProductFamiliesType[]
): ProductFamiliesType | undefined {
  const path = getFamilyPath(familyId, families);

  if (!path.length) return undefined;

  const firstPath = path[0];
  if (path.length === 1) {
    return families[parseInt(firstPath)];
  }
  if (firstPath) {
    const object = families[parseInt(firstPath)];
    const joinedPath = path.slice(1).join('.');
    return get(object, joinedPath);
  }
}

export function getAssociatedFamiliesFromProduct(
  productId: string,
  families: ProductFamiliesType[],
  onlyGenerated: boolean = true,
  onlyMandatory: boolean = false
) {
  const associatedFamilies: Partial<ProductFamiliesType>[] = [];
  const productPath = getProductPathFromCache(productId, families);

  let indexFirst: number;
  let acc: string = '';

  productPath.forEach((subPath, index) => {
    if (subPath.toString() === 'children_productfamilies') {
      if (acc !== '') acc += '.' + subPath.toString();
      else acc += subPath.toString();
      return;
    }

    if (index === 0) {
      indexFirst = parseInt(subPath);
      if (isNaN(indexFirst) || indexFirst < 0 || indexFirst >= families.length)
        return;

      const family: ProductFamiliesType | undefined = families[indexFirst];
      if (family.productfamilies_frequently_associated_with.length) {
        associatedFamilies.push(
          ...extractAssociatedFamilies(family, onlyGenerated, onlyMandatory)
        );
      }
    } else {
      acc += '.' + subPath.toString();
      const family: ProductFamiliesType | undefined = get(
        families[indexFirst],
        acc
      );

      if (!family) return;

      if (family.productfamilies_frequently_associated_with.length) {
        associatedFamilies.push(
          ...extractAssociatedFamilies(family, onlyGenerated, onlyMandatory)
        );
      }
    }
  });

  return associatedFamilies;
}

function extractAssociatedFamilies(
  family: ProductFamiliesType,
  onlyGenerated: boolean,
  onlyMandatory: boolean
) {
  return family.productfamilies_frequently_associated_with.reduce(
    (acc: any[], el) => {
      if (onlyGenerated && onlyMandatory) {
        if (el.automatically_generated && el.mandatory) {
          for (let i = 0; i < el.quantity; i++) {
            acc.push(el.productfamily_to);
          }
        }
      } else if (onlyGenerated) {
        if (el.automatically_generated) {
          for (let i = 0; i < el.quantity; i++) {
            acc.push(el.productfamily_to);
          }
        }
      } else if (onlyMandatory) {
        if (el.mandatory) {
          for (let i = 0; i < el.quantity; i++) {
            acc.push(el.productfamily_to);
          }
        }
      } else {
        for (let i = 0; i < el.quantity; i++) {
          acc.push(el.productfamily_to);
        }
      }
      return acc;
    },
    []
  );
}

export function recursiveFindFamilyAssociatedFamilies(
  familyId: string,
  families: ProductFamiliesType[],
  onlyGenerated: boolean = true,
  onlyMandatory: boolean = false
) {
  const associatedFamilies: Partial<ProductFamiliesType>[] = [];
  const familyPath = getFamilyPath(familyId, families);

  let indexFirst: number;
  let acc: string = '';

  familyPath.forEach((subPath, index) => {
    if (subPath.toString() === 'children_productfamilies') {
      if (acc !== '') acc += '.' + subPath.toString();
      else acc += subPath.toString();
      return;
    }

    if (index === 0) {
      indexFirst = parseInt(subPath);
      if (isNaN(indexFirst) || indexFirst < 0 || indexFirst >= families.length)
        return;

      const family: ProductFamiliesType | undefined = families[indexFirst];
      if (family.productfamilies_frequently_associated_with.length) {
        associatedFamilies.push(
          ...extractAssociatedFamilies(family, onlyGenerated, onlyMandatory)
        );
      }
    } else {
      acc += '.' + subPath.toString();
      const family: ProductFamiliesType | undefined = get(
        families[indexFirst],
        acc
      );

      if (!family) return;

      if (family.productfamilies_frequently_associated_with.length) {
        associatedFamilies.push(
          ...extractAssociatedFamilies(family, onlyGenerated, onlyMandatory)
        );
      }
    }
  });

  return associatedFamilies;
}

export function getProductFromFamilies(
  productId: string,
  families: ProductFamiliesType[]
) {
  const key = families.map((el) => el.id).join('.') + '.' + productId;
  const cacheEntry = CACHE_STORE_PRODUCT_FROM_FAMILIES.get(key);
  if (cacheEntry !== undefined) {
    return cacheEntry;
  }

  const family = getDirectParentFamilyForProductId(productId, families);
  const product = family?.products.find(
    (el) => el.product.id === productId
  )?.product;
  CACHE_STORE_PRODUCT_FROM_FAMILIES.set(key, product);
  return product;
}

export function getProductImageFromFamilies(
  productId: string,
  families: ProductFamiliesType[],
  hasNhostPrefix = true
) {
  if (!families.length) return '';
  const key = families.map((el) => el.id).join('.') + '.' + productId;
  const cacheEntry = CACHE_STORE_PRODUCT_IMAGE.get(key);
  if (cacheEntry !== undefined) {
    return cacheEntry;
  }

  const family = getDirectParentFamilyForProductId(productId, families);
  if (!family) return '';
  const image = getImageFromFamiliesRecursive(
    family.id,
    families,
    'default',
    hasNhostPrefix
  );
  if (image !== undefined) CACHE_STORE_PRODUCT_IMAGE.set(key, image);
  return image;
}

export function getProductFirstFamilyFromFamilies(
  productId: string,
  families: ProductFamiliesType[]
) {
  const path = getProductPathFromCache(productId, families);
  if (path.length === 0) return null;

  // Il faut trouver le niveau où on a une famille proposeAsFirstChoice
  // Ex pour les panneaux : Panneaux > Panneau de signalisation (Enfant proposeAsFirstChoice)
  // Ex pour les balises : Balises (Direct proposeAsFirstChoice)

  const index = parseInt(path[0]);
  const startFam = families[index];
  if (startFam.proposeAsFirstChoice) {
    return startFam;
  }

  const pathWithoutIndex = path.slice(1);

  let nextChildrenHasProposeAsFirstChoice = false;
  const result = pathWithoutIndex.reduce(
    (acc: { pathParts: string[]; family: any }, subPath: string) => {
      acc.pathParts.push(subPath);
      const tmpPath = acc.pathParts.join('.');
      const tmpResult = get(startFam, tmpPath);
      if (Array.isArray(tmpResult)) {
        nextChildrenHasProposeAsFirstChoice = tmpResult.some(
          (e) => e.proposeAsFirstChoice
        );
      } else {
        if (nextChildrenHasProposeAsFirstChoice) {
          acc.family = tmpResult;
          nextChildrenHasProposeAsFirstChoice = false;
        }
      }
      return acc;
    },
    { pathParts: [], family: startFam }
  );

  return result.family;
}

// Generate NHOST url FROM File_id
export function addNhostPrefix(image: string | undefined) {
  if (!image) return '';
  return `${storage.url}/files/${image}`;
}

export function getFamiliesFromProduct(
  productId: string,
  families: ProductFamiliesType[]
) {
  const path = getProductPathFromCache(productId, families);
  return getFamiliesByPath(path, families);
}

function createCacheKey(path: string[], families: ProductFamiliesType[]) {
  return `${path.join('-')}__${families.map((el) => el.id).join('-')}`;
}

export function getFamiliesByPath(
  path: string[],
  families: ProductFamiliesType[]
) {
  if (!path.length) return [];
  const cacheKey = createCacheKey(path, families);
  const cacheEntry = CACHE_FAMILIES_PATH.get(cacheKey);
  if (cacheEntry !== undefined) {
    return cacheEntry;
  }

  const indexFirst = parseInt(path[0]);
  if (isNaN(indexFirst) || indexFirst < 0 || indexFirst >= families.length)
    return [];

  const selectedFamilies = families[indexFirst];

  if (path.length === 1) return [selectedFamilies];

  const multipleFamilies = [selectedFamilies];
  const result = path.slice(1).reduce(
    (acc: { pathParts: string[]; families: any[] }, subPath: string) => {
      acc.pathParts.push(subPath);
      const family = get(selectedFamilies, acc.pathParts.join('.'));
      if (family && subPath !== 'children_productfamilies') {
        acc.families.push(family);
      }
      return acc;
    },
    { pathParts: [], families: multipleFamilies }
  );

  if (result.families.length === 0) return [];
  CACHE_FAMILIES_PATH.set(cacheKey, result.families);
  return result.families;
}

export function getAssociatedFamiliesFromComposants(
  composants: composantDocType[],
  families: ProductFamiliesType[]
) {
  const assocFamilies: any[] = [];
  for (const composant of composants) {
    if (composant.product?.id) {
      const familiesAss = getAssociatedFamiliesFromProduct(
        composant.product.id,
        families,
        false
      );
      assocFamilies.push(...familiesAss);
    }
    if (composant.type) {
      const familiesAss = recursiveFindFamilyAssociatedFamilies(
        composant.type,
        families,
        false
      );
      if (familiesAss.length) assocFamilies.push(...familiesAss);
    }
  }
  return assocFamilies;
}

export function getImagesFromFamiliesRecursive(
  familyId: string,
  families: ProductFamiliesType[]
) {
  const key = families.map((el) => el.id).join('.') + '.' + familyId;
  const cacheEntry = CACHE_STORE_FAMILY_IMAGE.get(key);
  if (cacheEntry !== undefined) {
    return cacheEntry;
  }

  const path = getFamilyPath(familyId, families);
  if (!path.length) return { default: PHOTO_DEFAULT.DEFAULT };

  let images: undefined | any;

  let startIndex: number;
  let pathAccumulator = '';

  path.forEach((key, index) => {
    if (index === 0 && key !== 'children_productfamilies') {
      startIndex = parseInt(key);
      images = families[startIndex].images;
      return;
    }

    pathAccumulator =
      pathAccumulator === '' ? key : pathAccumulator + '.' + key;
    if (key === 'children_productfamilies') return;

    const familyImage = get(families[startIndex], pathAccumulator);
    if (familyImage?.images) {
      images = familyImage.images;
    }
  });

  if (images) {
    CACHE_STORE_FAMILY_IMAGE.set(key, images);
    return images;
  }

  return { default: PHOTO_DEFAULT.DEFAULT };
}

export function getImageFromFamiliesRecursive(
  familyId: string,
  families: ProductFamiliesType[],
  colorString: keyof ImagesProduct = 'default',
  hasNhostPrefix = true
) {
  const images: any = getImagesFromFamiliesRecursive(familyId, families);
  const image = images[colorString];

  if (image && image !== PHOTO_DEFAULT.DEFAULT && hasNhostPrefix)
    return addNhostPrefix(image);
  if (image && image !== PHOTO_DEFAULT.DEFAULT) return image;

  return PHOTO_DEFAULT.DEFAULT;
}

export function getUnitFromFamilies(
  familyId: string,
  families: ProductFamiliesType[]
) {
  const path = getFamilyPath(familyId, families);
  return extractUnitFromPath(path, families);
}

export function extractUnitFromPath(
  path: string[],
  families: ProductFamiliesType[]
) {
  let unit: undefined | string;

  let startIndex: number;
  let pathAccumulator = '';

  path.forEach((key, index) => {
    if (index === 0 && key !== 'children_productfamilies') {
      startIndex = parseInt(key);
      unit = families[startIndex].unit;
      return;
    }

    pathAccumulator =
      pathAccumulator === '' ? key : pathAccumulator + '.' + key;
    if (key === 'children_productfamilies') return;

    const familyImage = get(families[startIndex], pathAccumulator);
    if (familyImage?.unit) {
      unit = familyImage.unit;
    }
  });

  if (unit) return transformUnitShortcut(unit);

  return '';
}
