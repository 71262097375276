<mat-card *ngIf="user">
  <mat-card-header class="m1em">
    <mat-card-title>
      <div class="d-flex-center">
        <span>User : {{ user.displayName }}</span>
      </div>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div>
      <app-ugau-input
        label="Nom"
        [value]="user.displayName"
        i18n-label
        (valueChange)="onUpdateUser('displayName', $event)"
      ></app-ugau-input>

      <app-ugau-input
        label="Email"
        [value]="user.email"
        i18n-label
        (valueChange)="onUpdateUser('email', $event)"
        [readOnly]="true"
      ></app-ugau-input>

      <mat-list>
        <mat-list-item
          *ngFor="let gr of user.groupes; trackBy: trackByUserGroupeId"
        >
          <span matListItemTitle>{{ gr.groupe }}</span>
          <span>{{ gr.role | roleDisplay }}</span>

          <div matListItemMeta class="icons-container">
            <app-user-role-menu [user]="user" [gr]="gr"></app-user-role-menu>
            <app-ugau-button
              i18n-label
              label=""
              [hasBorder]="false"
              classColor="danger"
              iconColor="danger"
              iconName="delete"
              (click)="deleteUser(gr.user_groupe_id, user.id, gr.groupe_id)"
            >
            </app-ugau-button>
          </div>
        </mat-list-item>
      </mat-list>

      <app-useralt-linked-emplacements
        [useralt]="user"
      ></app-useralt-linked-emplacements>
    </div>
  </mat-card-content>

  <mat-card-actions class="d-flex-center m1em">
    <div class="fg-1">
      <app-ugau-button-annuler-valider
        [onCancel]="onCancel"
        [onValid]="onValid"
      ></app-ugau-button-annuler-valider>
    </div>
  </mat-card-actions>
</mat-card>
