import { GeoJSONSource, Map } from 'mapbox-gl';
import { mapboxSourceOptions } from './mapbox.interface';

/**
 * Adds or updates a GeoJSON source on a Mapbox map.
 *
 * @param {Map} map - The Mapbox map instance.
 * @param {GeoJSON.FeatureCollection} geoJson - The GeoJSON data to be added or updated.
 * @param {mapboxSourceOptions} opt - Options for the source, including:
 *   - `id`: The unique identifier for the source.
 *   - `filterFunc`: A function to filter the features.
 *   - `mapFunc`: An optional function to map the features.
 *   - `flatAfterMapFunc`: A boolean indicating whether to flatten the result of the map function.
 *   - `cluster`: A boolean indicating whether to cluster the features.
 *   - `clusterMinPoints`: The minimum number of points to form a cluster.
 *   - `clusterMaxZoom`: The maximum zoom level for clustering.
 *   - `clusterRadius`: The radius of each cluster when clustering points.
 *   - `tolerance`: The tolerance for simplification.
 *
 * If the source already exists, it updates the data of the source. Otherwise, it adds a new source with the provided options.
 */
export function sourceDoGenericAddOrUpdate(
  map: Map,
  geoJson: GeoJSON.FeatureCollection,
  opt: mapboxSourceOptions
) {
  const source: GeoJSONSource = map.getSource(opt.id) as GeoJSONSource;

  let newFeatures = [];

  if (typeof opt.mapFunc !== 'undefined' && opt.flatAfterMapFunc) {
    newFeatures = geoJson.features
      .filter(opt.filterFunc)
      .map(opt.mapFunc)
      .flatMap((e: any) => e);
  } else if (typeof opt.mapFunc !== 'undefined') {
    newFeatures = geoJson.features.filter(opt.filterFunc).map(opt.mapFunc);
  } else {
    newFeatures = geoJson.features.filter(opt.filterFunc);
  }

  const geoFiltered: GeoJSON.GeoJSON = {
    ...geoJson,
    features: newFeatures,
  };

  if (typeof source !== 'undefined') {
    source.setData(geoFiltered);
    return;
  }

  map.addSource(opt.id, {
    type: 'geojson',
    data: geoFiltered,
    ...(opt.clusterMinPoints !== undefined
      ? { clusterMinPoints: opt.clusterMinPoints }
      : {}),
    ...(opt.cluster ? { cluster: true } : {}),
    ...(opt.clusterMaxZoom ? { clusterMaxZoom: opt.clusterMaxZoom } : {}),
    ...(opt.clusterRadius ? { clusterRadius: opt.clusterRadius } : {}),
    ...(opt.tolerance ? { tolerance: opt.tolerance } : {}),
  });
}
