import { NgIf, AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatFormFieldModule,
  MatFormFieldAppearance,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UgauUploadInputComponent } from './ugau-upload-input.component';
import { storage } from '../services/nhost';
import { NhostFilenamePipe } from '../pipes/get-filename.pipe';

@Component({
  selector: 'app-ugau-upload-input-direct',
  template: `
    <mat-form-field
      class="upload-input"
      [appearance]="appearance"
      floatLabel="always"
    >
      <mat-label>{{ label }}</mat-label>
      <input
        matInput
        readonly
        [value]="value | nhostFilename | async"
        (click)="triggerClick()"
      />

      <button *ngIf="value" mat-icon-button matPrefix>
        <mat-icon>download</mat-icon>
      </button>
      <button
        mat-icon-button
        matSuffix
        (click)="resetValue()"
        *ngIf="value && value !== ''"
      >
        <mat-icon>close</mat-icon>
      </button>
      <button mat-icon-button matSuffix (click)="triggerClick()">
        <mat-icon>attach_file</mat-icon>
      </button>
      <app-ugau-upload-input
        (outputFile)="setUploadData($event)"
        [hidden]="true"
        type="file"
      ></app-ugau-upload-input>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: flex;
        .upload-input {
          padding-left: 16px;
          padding-right: 16px;
          width: 100%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    MatIconModule,
    UgauUploadInputComponent,
    AsyncPipe,
    NhostFilenamePipe,
  ],
})
export class UgauUploadInputDirectComponent {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() label!: string;
  @Input() value!: string | null;
  @Output() valueChange: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();

  @ViewChild(UgauUploadInputComponent)
  uploadInput!: UgauUploadInputComponent;

  async setUploadData(b64String: any) {
    const result = await storage.upload({
      name: `${b64String.filename}`,
      file: b64String.original,
    });
    if (result.fileMetadata) {
      this.valueChange.emit(result.fileMetadata.id);
    }
  }

  triggerClick() {
    this.uploadInput.triggerPromptFile();
  }

  downloadFile() {
    if (this.value) {
      const fileUrl = storage.getPublicUrl({ fileId: this.value });
      window.open(fileUrl, '_blank');
    }
  }

  resetValue() {
    this.valueChange.emit(null);
  }
}
