import { Injectable } from '@angular/core';
import { lastOfArray, ReplicationPullHandlerResult } from 'rxdb';
import { AbstractProvider } from './abstract-provider';
import { QUERY_GET_FABRICANTS } from '../queries/fabricant.queries';
import {
  fabricantDocType,
  fabricantSchema,
  fabricantSchemaLiteral,
} from '../schemas/fabricant.schema';
import { graphql } from './../../services/nhost';
import { Checkpoint } from '../interfaces/Checkpoint.type';

@Injectable({
  providedIn: 'root',
})
export class FabricantProvider extends AbstractProvider<fabricantDocType> {
  public schema = fabricantSchema;
  public schemaLiteral = fabricantSchemaLiteral;
  public enablePush = false;

  async getPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<fabricantDocType, Checkpoint>> {
    const variables: any = {
      where: {
        _or: this.getOrForQuery(lastCheckpoint),
      },
      order_by: this.getOrderByForQuery(),
      limit: this.BATCH_SIZE,
    };

    const { data, error } = await graphql.request(
      QUERY_GET_FABRICANTS,
      variables
    );

    if (error || !data.fabricant) {
      console.log(
        `Erreur lors de la récupération des fabricants sur le serveur`
      );
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.fabricant;

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }
}
