import { REGLEMENTATION_TYPE } from '../../enum/REGLEMENTATION_TYPE';
import { reglementationDocType } from './../../db/schemas/reglementation.schema';

export function isConformiteRecurrence(
  conformite: {
    id_reglementation?: number;
  },
  reglementations: reglementationDocType[]
) {
  const typeValeur = reglementations.find(
    (reglementation) =>
      reglementation.id === conformite.id_reglementation?.toString()
  )?.type_valeur;

  return typeValeur === REGLEMENTATION_TYPE.RECURRENCE;
}
