import { Injectable } from '@angular/core';
import { SentryService } from './sentry.service';
import { AuthenticationService } from './authentication.service';
import { EMPLACEMENT_STATUS } from '../../common-projects/emplacement_status';
import { stringify } from '../utils/json-utils';
import {
  composantDocType,
  emplacementDocType,
  emplacementPhotoDocType,
} from '../db/schemas/emplacement.schema';
import { Position } from 'geojson';
import { map } from 'rxjs';
import { DatabaseService } from '../db/database.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class EmplacementService {
  private emplacementProvider = this.db.getEmplacementProvider();
  private info = this.emplacementProvider.idInfo;

  constructor(
    private db: DatabaseService,
    private auth: AuthenticationService
  ) {}

  initEmplacement(
    id: string,
    emplacementStatus: EMPLACEMENT_STATUS = EMPLACEMENT_STATUS.POSE,
    familyIds: string[] = []
  ) {
    let composants: composantDocType[] = [];
    if (familyIds.length > 0) {
      composants = familyIds.map((id) => {
        return {
          id: uuidv4(),
          type: id,
          quantity: 1,
          status: 'POSE',
          created_at: new Date().toISOString(),
          created_by: this.auth.getUserId(),
          updated_at: new Date().toISOString(),
          updated_by: this.auth.getUserId(),
          deleted_at: null,
          deleted_by: null,
          conformites: [],
          composant_productfields: [],
          product: null,
        };
      });
    }
    return {
      id,
      geometry: {} as GeoJSON.Geometry,
      address: {},
      pr: {},
      composants,
      commentaire: '',
      emplacement_photos: [],
      emplacement_tags: [],
      emplacement_productfields: [],
      created_at: new Date().toISOString(),
      created_by: this.auth.getUserId(),
      updated_at: new Date().toISOString(),
      updated_by: this.auth.getUserId(),
      deleted_at: null,
      deleted_by: null,
      status: emplacementStatus,
    } as emplacementDocType;
  }

  getEmplacementsById(idsEmplacement: string[] = []) {
    return this.emplacementProvider.collection
      .find()
      .where('id')
      .in(idsEmplacement)
      .exec()
      .then((a) =>
        a.map((e) => e.toJSON(true) as unknown as emplacementDocType)
      );
  }

  getEmplacementsByIdObs(idsEmplacement: string[] = []) {
    return this.emplacementProvider.collection
      .find()
      .where('id')
      .in(idsEmplacement)
      .$.pipe(
        map((a) =>
          a.map((e) => e.toJSON(true) as unknown as emplacementDocType)
        )
      );
  }

  getEmplacementById(idEmplacement: string = '') {
    return this.emplacementProvider.collection
      .findOne()
      .where('id')
      .eq(idEmplacement)
      .exec()
      .then((e) =>
        e ? (e.toJSON(true) as unknown as emplacementDocType) : undefined
      );
  }

  getEmplacementById$(idEmplacement: string = '') {
    return this.emplacementProvider.collection
      .findOne()
      .where('id')
      .eq(idEmplacement)
      .$.pipe(
        map((e) => {
          return e
            ? (e.toJSON(true) as unknown as emplacementDocType)
            : undefined;
        })
      );
  }

  async upsert(emplacement: emplacementDocType) {
    // 25 Juin 2024 : On autorise les composants sans produit
    const e = {
      ...emplacement,
      updated_at: new Date().toISOString(),
    };

    forceNumberOnEmplacementGeometry(e);

    try {
      console.log('Emplacement upsert');
      // Sauvegarde de l'emplacement dans la base de données RXDB locale
      const result =
        await this.emplacementProvider.collection.incrementalUpsert(e);

      // Si la réplication est arrêtée, on la redémarre
      if (this.emplacementProvider.replicateState?.isStopped())
        this.emplacementProvider.replicateState.start();

      // On relance un cycle de synchronisation après 10 secondes (pour que les actions serverless aient le temps de s'exécuter)
      setTimeout(() => {
        this.emplacementProvider.replicateState?.reSync();
      }, 10000);

      return result;
    } catch (err) {
      console.log('EMPLACEMENT UPSERT ERROR', err);
      SentryService.captureMessage('ERROR Upsert Emplacement', {
        extra: this.deleteB64fromEmplacement(e),
        ...this.info,
      });
    }
  }

  async bulkUpsert(emplacements: emplacementDocType[]) {
    const emps = emplacements.map((emplacement) => {
      const e = {
        ...emplacement,
        updated_at: new Date().toISOString(),
        composants: emplacement.composants.filter(
          (composant: composantDocType) => {
            return !!composant.product?.id;
          }
        ),
      };

      forceNumberOnEmplacementGeometry(e);
      return e;
    });

    try {
      console.log('Emplacements bulk upsert');
      const result = await this.emplacementProvider.collection.bulkUpsert(emps);
      if (this.emplacementProvider.replicateState?.isStopped())
        this.emplacementProvider.replicateState.start();
      return result;
    } catch (err) {
      console.log('EMPLACEMENT BULK UPSERT ERROR');
      SentryService.captureMessage('ERROR Bulk Upsert Emplacement', {
        extra: emps.map((e) => this.deleteB64fromEmplacement(e)),
        ...this.info,
      });
    }
  }

  deleteB64fromEmplacement(e: emplacementDocType) {
    // Pour éviter de stocker des données inutiles dans le log
    const copy = JSON.parse(stringify(e, 4, (k, v) => v));
    copy.emplacement_photos = copy.emplacement_photos.map(
      (el: emplacementPhotoDocType) => {
        delete el.data;
        return el;
      }
    );
    return copy;
  }

  bulkUpdateStatus(
    status: keyof typeof EMPLACEMENT_STATUS,
    emplacementsIds: string[]
  ) {
    const queryEmplacement = this.emplacementProvider.collection.find({
      selector: {
        id: {
          $in: emplacementsIds,
        },
      },
    });
    return queryEmplacement.update({
      $set: {
        status,
        updated_at: new Date().toISOString(),
        updated_by: this.auth.getUserId(),
      },
    });
  }
}

function forceNumberOnEmplacementGeometry(e: emplacementDocType) {
  if (e.geometry.type === 'Point') {
    e.geometry = {
      ...e.geometry,
      coordinates: [+e.geometry.coordinates[0], +e.geometry.coordinates[1]],
    };
  }
  if (e.geometry.type === 'LineString') {
    e.geometry = {
      type: 'LineString',
      coordinates: e.geometry.coordinates.map((e: Position) =>
        e.map((f: number) => +f)
      ),
    };
  }
  if (e.geometry.type === 'Polygon') {
    e.geometry = {
      type: 'Polygon',
      coordinates: e.geometry.coordinates.map((e: Position[]) =>
        e.map((f: Position) => f.map((g: number) => +g))
      ),
    };
  }
}
