import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { usersDocType } from '../db/schemas/users.schema';
import { GroupesStateService } from './groupes-state.service';
import { userAltType } from '../db/schemas/groupe.schema';
import { DatabaseService } from '../db/database.service';
import { userTransformedType } from '../db/schemas/UserGroupRole';

@Injectable({
  providedIn: 'root',
})
export class UsersStateService {
  usersProvider = inject(DatabaseService).getUserProvider();
  groupesState = inject(GroupesStateService);

  users$: Observable<usersDocType[]> = this.usersProvider.getAll$;
  usersFromGroupes$ = this.groupesState.groupes$.pipe(
    map((e) => {
      return e.reduce<userAltType[]>((acc, e) => {
        if (e.user_groupes.length > 0) {
          e.user_groupes.forEach((f) => {
            const exist = acc.findIndex((g) => g.id === f.user?.id);
            if (f.user && exist === -1) acc.push(f.user);
          });
        }
        return acc;
      }, []);
    })
  );
  usersTransformed$: Observable<userTransformedType[]> =
    this.groupesState.groupes$.pipe(
      map((groupes) =>
        groupes.reduce((acc: any[], groupe) => {
          // Map through each groupe's user_groupes
          groupe.user_groupes.forEach((userGroupe) => {
            if (!userGroupe.user) return;
            // Extract user information
            const { id, email, displayName } = userGroupe.user;
            // Find if the user already exists in the accumulated list
            let user = acc.find((u) => u.id === id);

            if (!user) {
              user = {
                id,
                displayName,
                email,
                groupes: [],
              };
              user.groupes.push({
                groupe_id: groupe.id,
                groupe: groupe.libelle,
                role: userGroupe.role,
                user_groupe_id: userGroupe.id,
              });
              acc.push(user);
            }

            const group = user.groupes.find(
              (g: any) => g.groupe_id === groupe.id
            );
            if (!group)
              user.groupes.push({
                groupe_id: groupe.id,
                groupe: groupe.libelle,
                role: userGroupe.role,
                user_groupe_id: userGroupe.id,
              });
          });

          return acc;
        }, [])
      )
    );

  getUserById(userId: string) {
    return this.getUsers().find((el) => el.id === userId);
  }

  getUser$(userId: string): Observable<usersDocType | undefined> {
    return this.users$.pipe(
      map((users) => users.find((el) => el.id === userId))
    );
  }

  getUsers() {
    return this.usersProvider.getAllValue();
  }

  getUsersFromGroupes(): userAltType[] {
    return this.groupesState.getGroupes().reduce<userAltType[]>((acc, e) => {
      if (e.user_groupes.length > 0) {
        e.user_groupes.forEach((f) => {
          const exist = acc.findIndex((g) => g.id === f.user?.id);
          if (f.user && exist === -1) acc.push(f.user);
        });
      }
      return acc;
    }, []);
  }

  getUserFromGroupes$(userId: string) {
    return this.groupesState.groupes$.pipe(
      map((e) => {
        for (const groupe of e) {
          for (const userGroupe of groupe.user_groupes) {
            if (userGroupe.user && userGroupe.user.id === userId) {
              return userGroupe.user;
            }
          }
        }
        return null;
      })
    );
  }

  getUserTransformedFromGroupe(userId: string): userTransformedType | null {
    const groupes = this.groupesState.getGroupes();
    for (const groupe of groupes) {
      for (const userGroupe of groupe.user_groupes) {
        if (userGroupe.user && userGroupe.user.id === userId) {
          return {
            id: userGroupe.user.id,
            displayName: userGroupe.user.displayName,
            email: userGroupe.user.email,
            groupes: [
              {
                user_groupe_id: userGroupe.id ?? '',
                groupe_id: groupe.id,
                groupe: groupe.libelle,
                role: userGroupe.role ?? '',
              },
            ],
          };
        }
      }
    }
    return null;
  }

  getGroupes() {
    return this.groupesState.getGroupes();
  }

  getGroupesFromUser(userId: string) {
    return this.groupesState.getGroupes().filter((e) => {
      return e.user_groupes.some((f) => f.user?.id === userId);
    });
  }
}
