import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SpriteManager {
  private hash: string | null = null;

  async init() {
    await this.retrieveHashSprite();
    this.addLinkForSpriteCss();
  }

  async retrieveHashSprite() {
    // Il faut aller récupérer le hash du sprite
    const url = environment.SPRITE_URL_HASH;
    const hashResult = await fetch(url).then((response) => response.text());
    if (hashResult) {
      this.hash = hashResult;
      console.log('Hash du sprite récupéré :', this.hash);
      return;
    }

    throw new Error('Impossible de récupérer le hash du sprite');
  }

  getSpriteForMapbox() {
    // Il faut aller récupérer le sprite pour mapbox
    return environment.SPRITE_URL + '?' + this.hash;
  }

  addLinkForSpriteCss() {
    // Il faut ajouter le lien pour le sprite css
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.crossOrigin = 'anonymous';
    link.href =
      environment.SPRITE_URL.replace('sprite/sprite', 'sprite/styles') +
      '.css?' +
      this.hash;
    document.head.appendChild(link);
  }
}
