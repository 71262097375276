import { getUserID } from "../services/nhost";
import { getMapFromIndexedDB } from "../utils/worker/getMapFromIndexedDB";
import { storeMapInIndexedDB } from "../utils/worker/storeMapInIndexedDB";
import { DB_NAME, STORE_NAME, CACHE_NAME } from "../utils/worker/worker.store";


export async function invalidateEmplacementFilterCache(
  emplacementIds: string[]
) {
  const map = await getMapFromIndexedDB(
    DB_NAME,
    STORE_NAME + '_' + getUserID(),
    CACHE_NAME
  );

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!map) return;

  emplacementIds.forEach((id) => {
    map.forEach((value, key) => {
      if (key.includes(id)) {
        map.delete(key);
      }
    });
  });

  await storeMapInIndexedDB(
    DB_NAME,
    STORE_NAME + '_' + getUserID(),
    CACHE_NAME,
    map
  );
}
