import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const groupeSchemaLiteral = jsonSchema({
  name: 'groupe',
  autoMigrate: true,
  version: 3,
  type: 'object',
  required: ['id', 'libelle', 'updated_at', 'user_groupes'],
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    libelle: {
      type: 'string',
    },
    abonnement: {
      type: 'string',
    },
    created_at: {
      type: 'string',
    },
    created_by: {
      type: ['string', 'null'],
    },
    updated_at: {
      type: ['string'],
    },
    updated_by: {
      type: ['string', 'null'],
    },
    deleted_at: {
      type: ['string', 'null'],
    },
    deleted_by: {
      type: ['string', 'null'],
    },
    config: {
      type: ['object', 'null'],
    },
    user_groupes: {
      type: 'array',
      uniqueItems: true,
      required: ['id', 'id_user', 'role'],
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          id_user: {
            type: 'string',
          },
          role: {
            type: 'string',
          },
          user: {
            type: 'object',
            required: ['id', 'email', 'displayName'],
            properties: {
              id: {
                type: 'string',
              },
              email: {
                type: 'string',
              },
              displayName: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  },
} as const);

const groupeSchemaTyped = toTypedRxJsonSchema(groupeSchemaLiteral);

export type groupeDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof groupeSchemaTyped
> & {
  config?: any;
};

export const groupeSchema: RxJsonSchema<groupeDocType> = groupeSchemaLiteral;
export type userGroupeType = groupeDocType['user_groupes'][0];
export type userAltTypeWithUndefined = userGroupeType['user'];
export type userAltType = Exclude<userAltTypeWithUndefined, undefined>;
