import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  DestroyRef,
  inject,
} from '@angular/core';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { ISelectItem } from './../../db/interfaces/select-item';
import { COLORS_BTN } from './../../enum/colors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectItemChipPipe } from '../ugau-select-chip/select-item-chip.pipe';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { NgIf, NgFor } from '@angular/common';
import { trackByGetIdentifierFn } from '../../services/trackByFunctions.utils';

@Component({
  selector: 'app-ugau-select',
  template: `
    <mat-form-field
      class="select-input"
      [appearance]="appearance"
      floatLabel="always"
      *ngIf="readonly"
    >
      <mat-label>{{ label }}</mat-label>
      <input matInput [value]="value | selectItemChip: 'libelle'" readonly />
    </mat-form-field>
    <mat-form-field
      class="select-input"
      [appearance]="appearance"
      floatLabel="always"
      *ngIf="!readonly"
    >
      <mat-label>{{ label }}</mat-label>
      <mat-select [value]="value" [compareWith]="compareIdentifier">
        <mat-option *ngIf="allowEmpty" [value]="undefined">{{
          emptyText
        }}</mat-option>
        <mat-option
          [class]="'mat-option-color-' + (option | selectItemChip: 'color')"
          [value]="option"
          *ngFor="let option of options; trackBy: trackByGetIdentifier"
          >{{ option | selectItemChip: 'libelle' }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: flex;

        .select-input {
          padding-left: 16px;
          padding-right: 16px;
          width: 100%;
        }
        &[data-color='valid'] {
          // ::ng-deep .mat-select-value,
          ::ng-deep .mat-mdc-floating-label {
            color: var(--valid-regular);
          }
        }
        &[data-color='warning'] {
          // ::ng-deep .mat-select-value,
          ::ng-deep .mat-mdc-floating-label {
            color: var(--warning-regular);
          }
        }
        &[data-color='danger'] {
          // ::ng-deep .mat-select-value,
          ::ng-deep .mat-mdc-floating-label {
            color: var(--error-regular);
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    NgFor,
    SelectItemChipPipe,
  ],
})
export class UgauSelectContainer implements AfterViewInit {
  trackByGetIdentifier = trackByGetIdentifierFn;

  @Input() value!: ISelectItem | undefined;
  @Input() options!: ISelectItem[];
  @Input() label = '';
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() allowEmpty = false;
  @Input() emptyText = $localize`Aucun`;

  @Input()
  @HostBinding('attr.data-color')
  classColor: COLORS_BTN = 'primary';

  @Output() valueChange = new EventEmitter<ISelectItem>();

  @ViewChild(MatSelect) select!: MatSelect;

  private destroyRef = inject(DestroyRef);

  ngAfterViewInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.select) {
      this.select.selectionChange
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((change: MatSelectChange) => {
          this.valueChange.emit(change.value);
        });
    }
  }

  compareIdentifier(e1: ISelectItem, e2: ISelectItem) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (e1 && e2) {
      return e1.getIdentifier() === e2.getIdentifier();
    }
    return false;
  }
}
