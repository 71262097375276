import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { fromEvent, merge, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { COLORS_BTN } from './../../enum/colors';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-ugau-input-number',
  templateUrl: './ugau-input-number.component.html',
  styleUrls: ['./ugau-input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatFormFieldModule, NgIf, MatInputModule, MatIconModule],
})
export class UgauInputNumberComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() value!: number | null;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() readOnly: boolean = false;
  @Input()
  @HostBinding('attr.data-color')
  classColor: COLORS_BTN = 'primary';
  @Input() timeDebounce: number = 300;
  @Input() min: number = Number.MIN_SAFE_INTEGER;
  @Input() max: number = Number.MAX_SAFE_INTEGER;
  @Input() step: number = 1;

  @Output() valueChange = new EventEmitter<number>();
  @Output() fieldClick = new EventEmitter<string>();

  @ViewChild('input') inputRef!: ElementRef;

  private destroy$ = new Subject<void>();

  ngAfterViewInit(): void {
    const inputEvents$ = fromEvent<any>(
      this.inputRef.nativeElement,
      'input'
    ).pipe(
      map((event) => parseFloat(event.target.value)),
      distinctUntilChanged(),
      debounceTime(this.timeDebounce),
      takeUntil(this.destroy$)
    );

    const focusOutEvents$ = fromEvent(
      this.inputRef.nativeElement,
      'focusout'
    ).pipe(
      map(() => parseFloat(this.inputRef.nativeElement.value)),
      takeUntil(this.destroy$)
    );

    const combinedEvents$ = merge(inputEvents$, focusOutEvents$).pipe(
      takeUntil(this.destroy$)
    );

    combinedEvents$.subscribe((value) => {
      this.updateValue(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (changes.value) {
      this.updateValue(this.value);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateValue(value: number | null) {
    if (value !== null) {
      const clampedValue = Math.min(Math.max(value, this.min), this.max);
      this.value = clampedValue;
      this.valueChange.emit(clampedValue);
    }
  }

  handleClick() {
    this.fieldClick.emit(this.label);
  }
}
