<!-- MAP FIXE -->
<div
  class="location-map-container"
  *ngIf="!isMobile && !forceDisableMapAndMove"
>
  <app-ugau-map
    #ugauMapLocation
    class="location-map"
    idMap="location-container-map"
    [config]="{
      geojson: geojson,
      disableControl: true,
      disablePointOnLine: true,
      center: center
    }"
    [maxZoom]="19"
  ></app-ugau-map>
</div>

<!-- 1ere photo -->
<div class="location-detail">
  <div
    *ngIf="emplacement?.emplacement_photos?.length"
    [class]="'location-image' + classes"
    [style.background-image]="
        'url(' + (emplacement | emplacementFirstPhoto | async) + '), url(assets/imgs/default-large_default.png)'
      "
    style="background-position: center center"
    (click)="showFirstEmplacementPhoto()"
  ></div>

  <div
    *ngIf="!emplacement?.emplacement_photos?.length"
    [class]="'location-image' + classes"
    [style.background-image]="
        'url(assets/imgs/placeholder_upload_vue_de_face.jpg)'
      "
    [style.cursor]="'pointer'"
    (click)="addEmplacementPhoto()"
  ></div>

  <div class="location-info pb1em pt1em">
    <div class="location-info-button">
      <app-ugau-button
        *ngIf="!forceDisableMapAndMove"
        [label]="labelBtnMove"
        iconName="edit_location_alt"
        [capitalize]="true"
        (click)="changeLocation()"
      ></app-ugau-button>
    </div>

    <div>
      <span
        *ngIf="tags.length > 0; else noTag"
        style="white-space: break-spaces"
      >
        <ul
          *ngFor="let tag of tags; trackBy: trackByKey"
          style="list-style-type: none; padding-left: 0px"
        >
          <span style="text-transform: capitalize">{{tag[0]}} :</span>
          <br />
          <li
            *ngFor="let p of tag[1]; trackBy: trackById"
            style="margin-left: 0.25em; min-height: 1.75em"
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: end;
              "
            >
              <span>{{p.libelle}}</span>

              <mat-icon
                (click)="openTagEdit(p)"
                class="increase15"
                style="min-width: 24px; cursor: pointer"
                >folder_open</mat-icon
              >
            </div>
          </li>
        </ul>
        <!-- prettier-ignore -->
        <p style=" color: rgba(0, 0, 0, 0.6); font-size: 14px; line-height: 20px; text-transform: capitalize;" >{{ emplacement | emplacementZones : 'address' }}</p>
      </span>
    </div>

    <app-ugau-list *ngIf="lineLength">
      <app-ugau-listitem
        [enableClick]="false"
        [firstLine]="(lineLength | formatnumber) + ' m'"
        [forceShowDetailledData]="true"
      >
      </app-ugau-listitem>
    </app-ugau-list>

    <app-ugau-list *ngIf="polygonArea">
      <app-ugau-listitem
        [enableClick]="false"
        [firstLine]="(polygonArea | formatnumber) + ' m²'"
        [forceShowDetailledData]="true"
      >
      </app-ugau-listitem>
    </app-ugau-list>

    <div class="location-quick-button">
      <app-ugau-button
        label=""
        *ngIf="!forceDisableMapAndMove && isMobile"
        iconName="directions_car"
        (click)="openInMap()"
        classColor="valid"
        iconColor="valid"
      ></app-ugau-button>
      <app-ugau-button
        label=""
        *ngIf="!forceDisableMapAndMove && !isMobile"
        iconName="streetview"
        (click)="openInStreetView()"
        matTooltip="Streetview"
      ></app-ugau-button>
      <app-ugau-button
        style="width: 100%"
        i18n-label
        label="Localiser"
        *ngIf="!forceDisableMapAndMove"
        iconName="where_to_vote"
        [capitalize]="true"
        (click)="showOnGlobalMap()"
      ></app-ugau-button>
    </div>
  </div>
</div>

<ng-template #noTag>
  <h4 matListItemTitle>Aucun dossier</h4>
</ng-template>
