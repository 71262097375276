/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Map } from 'mapbox-gl';
import { removeLayer } from './removeLayer';
import { removeSource } from './removeSource';
import { MapboxCustomSwitcherControl } from './MapboxCustomSwitcherControl';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../utils/localstorage-utils.service';
import { lon2tile, lat2tile, tile2lon, tile2lat } from './tile.utils';

export const URI_lines_administratives = 'lines-administratives';
export const URI_points_repere_routiers = 'points-repere-routiers';
export const URI_securouteOA = 'points-securoute-oa';
export const URI_buildings = 'buildings-buildings';
export const URI_cadastral = 'lines-cadastre';
export const URI_urbanisme = 'lines-urbanisme';

const IGN_URL = 'https://data.geopf.fr/';
const IGN_GEO_AND_TILE = 'wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile';
const IGN_TILE =
  '&tilematrixset=PM&TileMatrix={z}&TileCol={x}&TileRow={y}&Format=image%2Fpng&style=normal&I=0&J=0&infoformat=application%2Fjson';
const CLICK_IGN_ENABLE = true;

/**
 * @const layersChoices
 * @description An array of objects representing different map layers available for selection.
 * Each object contains a title and a URI for the respective map layer.
 *
 * @property {string} title - The title of the map layer.
 * @property {string} uri - The URI identifier for the map layer.
 */
const layersChoices = [
  {
    title: 'Limite administrative',
    uri: URI_lines_administratives,
  },
  {
    title: 'Cadastre',
    uri: URI_cadastral,
  },
  {
    title: 'Repère routier',
    uri: URI_points_repere_routiers,
  },
  // {
  //   title: 'Franchissements',
  //   uri: URI_securouteOA,
  // },
  {
    title: 'Hydrographie',
    uri: 'lines-hydrographie',
  },
  {
    title: 'Bâtiments',
    uri: URI_buildings,
  },
  {
    title: 'Urbanisme',
    uri: URI_urbanisme,
  },
];

/**
 * Toggles the visibility of various map layers on a Mapbox map based on the provided URI.
 *
 * @param {Map} map - The Mapbox map instance on which to toggle the layers.
 * @param {string} uri - The URI identifier for the specific map layer to toggle.
 * @param {boolean} enabled - A boolean indicating whether to enable (true) or disable (false) the specified layer.
 *
 * Supported URIs:
 * - `URI_lines_administratives`: Toggles administrative lines.
 * - `URI_points_repere_routiers`: Toggles repere routier points.
 * - `lines-cadastre`: Toggles cadastre lines.
 * - `lines-hydrographie`: Toggles hydrography lines.
 * - `buildings`: Toggles buildings.
 */
const toggleLayerIGN = (map: Map, uri: string, enabled: boolean) => {
  if (uri === URI_lines_administratives) {
    addMapAdministrativeLines(map, enabled);
  } else if (uri === URI_points_repere_routiers) {
    addMapRepereRoutier(map, enabled);
  } else if (uri === URI_securouteOA) {
    addMapSecuRouteOA(map, enabled);
  } else if (uri === URI_cadastral) {
    addMapCadastre(map, enabled);
  } else if (uri === 'lines-hydrographie') {
    addMapWaterLines(map, enabled);
  } else if (uri === URI_buildings) {
    addMapBuildings(map, enabled);
  } else if (uri === URI_urbanisme) {
    addMapLayerDUPSMV(map, enabled);
  }
};

/**
 * Adds a Mapbox control to toggle various Sources and Layers (SandL) on the map.
 *
 * @param {Map} map - The Mapbox map instance to which the control will be added.
 *
 * This function initializes a custom control that allows users to toggle different layers on the map.
 * The layers include administrative lines, road markers, cadastre lines, hydrography lines, and buildings.
 * When a layer is toggled, it is either added to or removed from the `layersEnabled` array.
 * The control is added to the top-right corner of the map.
 *
 * The control listens for the `onOpen` event, which is triggered when a user interacts with the control.
 * Depending on the URI of the layer, the corresponding function is called to add or remove the layer from the map.
 *
 * The following layers can be toggled:
 * - Administrative lines
 * - Road markers
 * - Cadastre lines
 * - Hydrography lines
 * - Buildings (disabled by default)
 *
 * The `options` object contains the event listeners for the control.
 * The `layersEnabled` array keeps track of which layers are currently enabled.
 */
export function addMapIgnSandLToggler(map: Map, forcedLayers: string[] = []) {
  const id = map.getContainer().id;
  const savedLayers = getFromLocalStorage('ENABLED_IGN-LAYERS_' + id, true);

  const layersEnabled: any[] = savedLayers ? savedLayers : [];

  if (layersEnabled.length > 0) {
    layersEnabled.forEach((layer) => {
      toggleLayerIGN(map, layer, false);
    });
  }
  if (forcedLayers.length > 0) {
    forcedLayers.forEach((layer) => {
      toggleLayerIGN(map, layer, false);
    });
  }

  const options = {
    activeLayers: layersEnabled,
    eventListeners: {
      onOpen: (event: MouseEvent) => {
        if (event.target instanceof Element) {
          const uri = event.target.getAttribute('data-uri')?.replace(/"/g, '');
          if (!uri) return true;

          const alreadyEnabled = layersEnabled.findIndex(
            (layer) => layer === uri
          );
          if (alreadyEnabled === -1) {
            layersEnabled.push(uri);
          } else {
            layersEnabled.splice(layersEnabled.indexOf(uri), 1);
          }
          setToLocalStorage('ENABLED_IGN-LAYERS_' + id, layersEnabled);

          toggleLayerIGN(map, uri, alreadyEnabled !== -1);
        }
        return true;
      },
    },
  };
  map.addControl(
    new MapboxCustomSwitcherControl(layersChoices, options),
    'top-right'
  );
}

/**
 * Loads an IGN raster source and layer into the provided Mapbox map instance.
 * If the map's style is already loaded, the source and layer are added immediately.
 * Otherwise, the source and layer are added once the map's 'load' event is fired.
 *
 * @param {Map} map - The Mapbox map instance to which the source and layer will be added.
 * @param {string} sourceLib - The identifier for the source library.
 * @param {string} url - The URL of the raster source.
 */
export function loadIgnRasterSourceAndLayer(
  map: Map,
  sourceLib: string,
  url: string
) {
  if (map.isStyleLoaded()) {
    mapAddSandLIgnRaster(map, sourceLib, url);
    return;
  }
  map.on('load', () => {
    mapAddSandLIgnRaster(map, sourceLib, url);
  });
}

/**
 * Adds or removes water lines on the map using Mapbox.
 *
 * @param {Map} map - The Mapbox map instance.
 * @param {boolean} [remove=false] - If true, removes the water lines; otherwise, adds them.
 * @returns {void}
 */
export function addMapWaterLines(map: Map, remove = false) {
  const sourceLib = 'points-hydrographiques';
  if (remove) return mapRemoveSandLIgnRaster(map, sourceLib);
  const url = IGN_URL + IGN_GEO_AND_TILE;
  '&layer=HYDROGRAPHY.HYDROGRAPHY' + IGN_TILE;

  loadIgnRasterSourceAndLayer(map, sourceLib, url);
}

export function addMapLayerDUPSMV(map: Map, remove = false) {
  const sourceLib = URI_urbanisme;
  if (remove) return mapRemoveSandLIgnRaster(map, sourceLib);

  // URL construite spécifiquement pour les couches `du` et `psmv` sans modifier les constantes globales
  const url =
    `${IGN_URL}wms-v/ows?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap` +
    `&FORMAT=image%2Fpng&TRANSPARENT=true` +
    `&LAYERS=du,psmv&WIDTH=256&HEIGHT=256` +
    `&CRS=EPSG%3A3857&STYLES=&BBOX={bbox-epsg-3857}`;

  loadIgnRasterSourceAndLayer(map, sourceLib, url);
}

/**
 * Adds or removes administrative lines on the given Mapbox map.
 *
 * @param {Map} map - The Mapbox map instance to which the administrative lines will be added or from which they will be removed.
 * @param {boolean} [remove=false] - If true, the administrative lines will be removed from the map. If false, the administrative lines will be added to the map.
 * @returns {void}
 */
function addMapAdministrativeLines(map: Map, remove = false) {
  if (remove) return mapRemoveSandLIgnRaster(map, URI_lines_administratives);

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=LIMITES_ADMINISTRATIVES_EXPRESS.LATEST' +
    IGN_TILE;

  loadIgnRasterSourceAndLayer(map, URI_lines_administratives, url);
}

/**
 * Adds or removes the cadastral map layer to/from the provided Mapbox map instance.
 *
 * @param {Map} map - The Mapbox map instance to which the cadastral layer will be added or removed.
 * @param {boolean} [remove=false] - A flag indicating whether to remove the cadastral layer.
 *                                   If true, the layer will be removed; otherwise, it will be added.
 *
 * @returns {void}
 */
let handleClickCadastre: any; // To keep the reference of the click event
export function addMapCadastre(map: Map, remove = false) {
  if (!handleClickCadastre) {
    handleClickCadastre = (e: any) => {
      e.ugau_wait_cadastre = true;
      getInfoParcelleFromIgn(e.lngLat.lat, e.lngLat.lng, map.getZoom()).then(
        (data) => {
          if (data.features.length > 0) {
            e.ugau_custom_data = data;
          }
        }
      );
    };
  }

  if (remove) {
    if (CLICK_IGN_ENABLE) {
      map.off('click', handleClickCadastre);
    }
    return mapRemoveSandLIgnRaster(map, URI_cadastral);
  }

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=CADASTRALPARCELS.PARCELLAIRE_EXPRESS' +
    IGN_TILE;
  loadIgnRasterSourceAndLayer(map, URI_cadastral, url);

  if (CLICK_IGN_ENABLE) {
    map.off('click', handleClickCadastre);
    map.on('click', handleClickCadastre);
  }
}

/**
 * Adds a raster source and layer to the provided Mapbox map instance.
 *
 * @param {Map} map - The Mapbox map instance to which the raster source and layer will be added.
 * @param {string} sourceLib - The identifier for the source library.
 * @param {string} url - The URL of the raster tiles.
 *
 * @example
 * ```typescript
 * mapAddSandLIgnRaster(map, 'mySourceLib', 'https://example.com/tiles/{z}/{x}/{y}.png');
 * ```
 */
export function mapAddSandLIgnRaster(map: Map, sourceLib: string, url: string) {
  map.addSource(sourceLib, {
    type: 'raster',
    tiles: [url],
    tileSize: 256,
    minzoom: 6,
    maxzoom: 18,
  });
  map.addLayer(
    {
      id: sourceLib + '-layer',
      type: 'raster',
      source: sourceLib,
      paint: {
        'raster-opacity': 0.8,
      },
    },
    'z-index-1'
  );
}
/**
 * Removes a specified raster layer and its source from the Mapbox map.
 *
 * @param map - The Mapbox map instance from which the layer and source will be removed.
 * @param libelle - The label used to identify the layer and source to be removed.
 */
export function mapRemoveSandLIgnRaster(map: Map, libelle: string) {
  removeLayer(map, `${libelle}-layer`);
  removeSource(map, `${libelle}`);
}

// TODO : Ne fonctionne qu'avec mapbox light-v10
// export function addBuildings(map: Map, remove = false) {
//   const sourceLib = 'buildings';
//   if (remove) {
//     map.removeLayer(sourceLib);
//     return;
//   }

//   if (map.isStyleLoaded()) {
//     doAddBuildings(map, sourceLib);
//     return;
//   }
//   map.on('load', () => {
//     doAddBuildings(map, sourceLib);
//   });
// }

// export function doAddBuildings(map: Map, sourceLib: string) {
//   const layers = map.getStyle().layers;
//   const labelLayerId = layers.find(
//     (layer) => layer.type === 'symbol' && layer.layout?.['text-field']
//   )?.id;

//   map.addLayer(
//     {
//       id: sourceLib,
//       source: 'composite',
//       'source-layer': 'building',
//       filter: ['==', 'extrude', 'true'],
//       type: 'fill-extrusion',
//       minzoom: 15,
//       paint: {
//         'fill-extrusion-color': '#aaa',
//         'fill-extrusion-height': [
//           'interpolate',
//           ['linear'],
//           ['zoom'],
//           15,
//           0,
//           15.05,
//           ['get', 'height'],
//         ],
//         'fill-extrusion-base': [
//           'interpolate',
//           ['linear'],
//           ['zoom'],
//           15,
//           0,
//           15.05,
//           ['get', 'min_height'],
//         ],
//         'fill-extrusion-opacity': 0.6,
//       },
//     },
//     labelLayerId
//   );
// }

let funcHandleRepereRouter: any;
export function addMapRepereRoutier(map: Map, remove = false) {
  if (!funcHandleRepereRouter) {
    funcHandleRepereRouter = (e: any) => {
      getInfoPrFromIgn(e.lngLat.lat, e.lngLat.lng, map.getZoom()).then(
        (data) => {
          console.log(data);
        }
      );
    };
  }
  const sourceLib = URI_points_repere_routiers;

  if (remove) {
    if (CLICK_IGN_ENABLE) map.off('click', funcHandleRepereRouter);
    return mapRemoveSandLIgnRaster(map, sourceLib);
  }

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=TRANSPORTNETWORK.COMMONTRANSPORTELEMENTS.MARKERPOST' +
    IGN_TILE;
  loadIgnRasterSourceAndLayer(map, sourceLib, url);

  if (CLICK_IGN_ENABLE) {
    map.off('click', funcHandleRepereRouter);
    map.on('click', funcHandleRepereRouter);
  }
}

export function addMapSecuRouteOA(map: Map, remove = false) {
  const sourceLib = URI_securouteOA; // Layer pour les ouvrages d’art

  if (remove) {
    return mapRemoveSandLIgnRaster(map, sourceLib);
  }

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=SECUROUTE.TE.ALL' +
    IGN_TILE.replace('&style=normal', '&style=TOUS LES FRANCHISSEMENTS');
  loadIgnRasterSourceAndLayer(map, sourceLib, url);
}

export function getInfoPrFromIgn(lat: number, lng: number, zoom: number) {
  const url = getUrlPRinfoPopupFromIgn(lat, lng, zoom);
  return fetch(url).then((response) => response.json());
}

export function getUrlPRinfoPopupFromIgn(
  lat: number,
  lng: number,
  zoom: number
): string {
  const z = Math.floor(zoom);

  // Get the tile X and Y (assuming you have these functions)
  const tileX = lon2tile(lng, z);
  const tileY = lat2tile(lat, z);

  // Convert tile indices back to lat/lng of the top-left corner of the tile
  const tileCornerLng = tile2lon(tileX, z);
  const tileCornerLat = tile2lat(tileY, z);

  // Calculate the position within the tile
  const tileSizeInDegreesLng = tile2lon(tileX + 1, z) - tileCornerLng;
  const tileSizeInDegreesLat = tile2lat(tileY + 1, z) - tileCornerLat;

  const i = Math.floor((256 * (lng - tileCornerLng)) / tileSizeInDegreesLng);
  const j = Math.floor((256 * (lat - tileCornerLat)) / tileSizeInDegreesLat);

  return `https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetFeatureInfo&LAYER=TRANSPORTNETWORK.COMMONTRANSPORTELEMENTS.MARKERPOST_VISU&TILECOL=${tileX}&TILEROW=${tileY}&TILEMATRIX=${z}&TILEMATRIXSET=PM&FORMAT=image/png&STYLE=normal&INFOFORMAT=application/json&I=${i}&J=${j}`;
}

export function getInfoParcelleFromIgn(lat: number, lng: number, zoom: number) {
  const url = getUrlCadastrePopupFromIgn(lat, lng, zoom);
  // Attention, si le fetch echoue, alors on reçoit du xml
  return fetch(url).then((response) => response.json());
}

export function getUrlCadastrePopupFromIgn(
  lat: number,
  lng: number,
  zoom: number
): string {
  // Define a small buffer for the bounding box (this will create a small area around the clicked point)
  const buffer = 0.00000001; // Adjust this to change the size of the bounding box

  const minLat = lat - buffer;
  const maxLat = lat + buffer;
  const minLng = lng - buffer;
  const maxLng = lng + buffer;

  return `https://data.geopf.fr/wfs?SERVICE=WFS&VERSION=2.0.0&REQUEST=GetFeature&TYPENAMES=CADASTRALPARCELS.PARCELLAIRE_EXPRESS:parcelle&SRSNAME=urn:ogc:def:crs:EPSG::4326&BBOX=${minLat},${minLng},${maxLat},${maxLng}&OUTPUTFORMAT=application/json`;
}

// Déclaration d'une variable pour conserver la référence du gestionnaire de clic
let handleClickBuilding: any;
export function addMapBuildings(map: Map, remove = false) {
  const sourceLib = URI_buildings; // Nom de la source

  // Si le gestionnaire de clic n'est pas encore défini, on le crée
  if (!handleClickBuilding) {
    handleClickBuilding = (e: any) => {
      e.ugau_wait_building = true;
      getInfoBuildingFromIgn(e.lngLat.lat, e.lngLat.lng, map.getZoom()).then(
        (data) => {
          if (data.features.length > 0) {
            e.ugau_custom_data = data;
          }
        }
      );
    };
  }

  if (remove) {
    if (CLICK_IGN_ENABLE) {
      map.off('click', handleClickBuilding); // Retirer le gestionnaire de clic si on enlève la couche
    }
    return mapRemoveSandLIgnRaster(map, sourceLib); // Retirer la couche
  }

  const url =
    IGN_URL +
    IGN_GEO_AND_TILE +
    '&layer=BUILDINGS.BUILDINGS' + // Spécifie la couche des bâtiments
    IGN_TILE;

  loadIgnRasterSourceAndLayer(map, sourceLib, url); // Ajoute la couche avec la source et l'URL des tuiles

  if (CLICK_IGN_ENABLE) {
    map.off('click', handleClickBuilding); // On s'assure de retirer les anciens gestionnaires
    map.on('click', handleClickBuilding); // Ajoute le gestionnaire de clic
  }
}

export function getInfoBuildingFromIgn(lat: number, lng: number, zoom: number) {
  const url = getUrlBuildingPopupFromIgn(lat, lng, zoom);
  return fetch(url).then((response) => response.json());
}

export function getUrlBuildingPopupFromIgn(
  lat: number,
  lng: number,
  zoom: number
): string {
  // Définir une petite zone autour du point cliqué (une "bounding box")
  const buffer = 0.00000001; // Ajuster la taille selon la précision désirée

  const minLat = lat - buffer;
  const maxLat = lat + buffer;
  const minLng = lng - buffer;
  const maxLng = lng + buffer;

  // Construire l'URL pour le service WFS GetFeature avec la BBOX
  return `https://data.geopf.fr/wfs?SERVICE=WFS&VERSION=2.0.0&REQUEST=GetFeature&TYPENAMES=BDTOPO_V3:batiment&SRSNAME=urn:ogc:def:crs:EPSG::4326&BBOX=${minLat},${minLng},${maxLat},${maxLng}&OUTPUTFORMAT=application/json`;
}
