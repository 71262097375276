import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { usersDocType } from '../db/schemas/users.schema';
import { UsersStateService } from './users-state.service';
import { getUserID } from '../services/nhost';
import { TagsStateService } from './tags-state.service';

@Injectable({
  providedIn: 'root',
})
export class UserStateService {
  private userId = getUserID();
  user$: Observable<usersDocType | undefined> = this.usersState.users$.pipe(
    map((users) => users.find((el) => el.id === this.userId))
  );

  isFullOperator$ = this.user$.pipe(
    map((user) => {
      if (!user) {
        console.log('isFullOperator$: no user');
        return false;
      }
      return this.checkIfOperatorInAllGroups(user);
    })
  );

  canShowPrices$ = this.user$.pipe(
    map((user) => {
      if (!user) {
        console.log('canShowPrices$: no user');
        return false;
      }
      // Les prix sont visible si l'utilisateur n'est pas opérateur
      return !this.checkForOperatorWithoutPricesInAllGroups(user);
    })
  );

  constructor(
    private usersState: UsersStateService,
    private tagsState: TagsStateService
  ) {}

  private checkRoleCurrentUserForGroupe(groupeId: string, role: string) {
    const user = this.usersState.getUserById(this.userId);
    if (!user) return false;
    return user.user_groupes.some(
      (el) => el.groupe.id === groupeId && el.role === role
    );
  }

  // Est ce que l'utilisateur est opérateur (avec ou sans prix) dans tous les groupes
  checkIfOperatorInAllGroups(user: usersDocType) {
    return user.user_groupes.every((el) => el.role.startsWith('user_created'));
  }
  checkIfUserIsOperatorInAllGroups() {
    const user = this.usersState.getUserById(this.userId);
    if (!user) return false;
    return this.checkIfOperatorInAllGroups(user);
  }

  // Est ce que l'utilisateur est opérateur SANS PRIX dans tous les groupes
  checkForOperatorWithoutPricesInAllGroups(user: usersDocType) {
    return user.user_groupes.every((el) => el.role === 'user_created');
  }
  checkIfUserIsOperatorOnlyInAllGroups() {
    const user = this.usersState.getUserById(this.userId);
    if (!user) return false;
    return this.checkForOperatorWithoutPricesInAllGroups(user);
  }

  isUserOperatorForGroup(groupeId: string) {
    const isUserCreated = this.checkRoleCurrentUserForGroupe(
      groupeId,
      'user_created'
    );
    const isUserCreatedWithPrices = this.checkRoleCurrentUserForGroupe(
      groupeId,
      'user_created_with_prices'
    );
    return isUserCreated || isUserCreatedWithPrices;
  }

  isUserManagerForGroup(groupeId: string) {
    return this.checkRoleCurrentUserForGroupe(groupeId, 'user');
  }

  isUserOperatorForTag(tagId: string) {
    const tag = this.tagsState.getTagById(tagId);
    if (!tag) return true;

    if (tag.id_user === this.userId) return false;

    if (tag.id_groupe) {
      return this.isUserOperatorForGroup(tag.id_groupe);
    }

    if (tag.shared_tags && tag.shared_tags.length > 0) {
      return tag.shared_tags.some((shared_tag) => {
        if (shared_tag.target_user_id === this.userId) return false;
        if (shared_tag.target_group_id) {
          return this.isUserOperatorForGroup(shared_tag.target_group_id);
        }
        return true;
      });
    }
    return true;
  }
}
