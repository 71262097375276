import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Network } from '@capacitor/network';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setToLocalStorage,
} from '../utils/localstorage-utils.service';
import { WAIT_DURATION } from '../enum/WAIT_DURATION';
import { auth } from './nhost';
import { HasuraTokenType } from '@types_custom/x-hasura';
import { SentryService } from './sentry.service';
import { environment } from './../../environments/environment';
import { AuthenticationServiceInterface } from './authentication.interface';
import { Router } from '@angular/router';
import { Subject, Observable, BehaviorSubject, takeUntil } from 'rxjs';
import { EventsService } from './events.service';
import { ToolsService } from './tools.service';
import { reloadApp } from './reloadApp.util';

@Injectable({
  providedIn: 'root',
})
export class MobileAuthenticationService
  implements AuthenticationServiceInterface
{
  isOnline: boolean | unknown = true;
  public getIsOnline() {
    return this.isOnline;
  }
  public idUser!: string;
  public defaultRole: string = '';
  public displayName: string = '';
  public email: string = '';

  private destroy$ = new Subject<void>();

  refreshEnCours = false;

  public token$ = new Observable((observer: any) => {
    // 1er coup, on push le token direct
    observer.next(auth.getAccessToken());
    auth.onTokenChanged(() => {
      // Au prochain changement de token, on repush le token
      observer.next(auth.getAccessToken());
    });
  });

  nhostSessionSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public tools: ToolsService,
    public event: EventsService,
    public router: Router
  ) {}

  public hasTraceAuth() {
    // FIXME: C'est pas terrible du tout ça !
    const hasTokenInStorage: boolean =
      !!getFromLocalStorage('nhostRefreshToken');
    return hasTokenInStorage;
  }

  public watchToken() {
    this.token$.pipe(takeUntil(this.destroy$)).subscribe((token) => {
      this.event.publish('auth:tokenChanged', token);
    });
  }

  public async refreshToken() {
    if (!this.refreshEnCours) {
      this.refreshEnCours = true;
      await auth.refreshSession();
      this.refreshEnCours = false;
    }
  }

  public async logout() {
    try {
      await auth.signOut();
      this.router.navigate(['/logout'], { replaceUrl: true });
    } catch (e) {
      SentryService.captureException(e);
    }
  }

  public register(email: string, password: string) {
    return auth.signUp({
      email: email,
      password: password,
    });
  }

  public getToken() {
    try {
      const token = auth.getAccessToken();

      if (token) {
        this.doTokenThing(token);
        return token;
      }
    } catch (e) {
      console.log(e);
    }
  }
  public async init() {
    console.log('Authentication, Mobile mode');
    if (this.tools.isMobile()) {
      // const { connected } = await Network.getStatus();
      this.isOnline = navigator.onLine;

      if (this.getIsOnline() === false) {
        if (this.hasTraceAuth()) {
          this.mockUser();
        } else {
          await this.logout();
        }
      }

      await this.checkOnline();
      return;
    }

    this.isOnline = true;
  }

  async checkOnline() {
    Network.addListener('networkStatusChange', (status) => {
      this.isOnline = status.connected;
      if (status.connected) {
        this.event.publish('online');
      } else {
        this.event.publish('offline');
      }
    });

    const { connected } = await Network.getStatus();
    this.isOnline = connected;
  }

  public mockUser() {
    // Pour que l'authentification hors ligne fonctionne, il faut :
    // - id_current_user présent dans localstorage
    // const token = getFromLocalStorage('nhostRefreshToken');
    // if (token) {
    //   this.doTokenThing(token);
    // }
    const idUser = getFromLocalStorage('id_current_user');
    if (idUser) this.idUser = idUser;
  }

  public getUserId() {
    const authUser = auth.getUser();
    if (authUser) return authUser.id;

    console.log('getUserId empty from nhost', this.idUser);

    if (!this.idUser || this.idUser === '') {
      if (this.hasTraceAuth()) {
        this.mockUser();
      } else {
        // Display error to user
        this.tools.launchErrorAlert(
          $localize`Impossible de récupérer l'identifiant utilisateur !<br><br>L'application va redémarrer ...`
        );

        removeFromLocalStorage('username');
        removeFromLocalStorage('password');

        auth.signOut().then(() => {
          // Reload app after 2s
          setTimeout(() => {
            return reloadApp('AuthenticationService::getUserId');
          }, WAIT_DURATION.TIMEOUT_BEFORE_RELOAD);
        });
      }
    }

    return this.idUser;
  }

  public isLoggedIn() {
    // Si en ligne, on donne la réponse de NHOST
    if (this.getIsOnline()) {
      return auth.isAuthenticated();
    }

    // Si hors ligne, on cherche l'id user dans le localstorage
    const isNotOnline = !this.getIsOnline();
    const hasTraceAuth = this.hasTraceAuth();
    return isNotOnline && hasTraceAuth;
  }

  public isLoggedInAsync() {
    // Si en ligne, on donne la réponse de NHOST
    if (this.getIsOnline()) {
      return auth.isAuthenticatedAsync();
    }

    // Si hors ligne, on cherche l'id user dans le localstorage
    if (!this.getIsOnline() && this.hasTraceAuth()) {
      return Promise.resolve(true);
    }

    return Promise.resolve(false);
  }

  public doTokenThing(token: string) {
    try {
      const tokenStr = token.includes('.') ? token.split('.')[1] : token;
      const infos: HasuraTokenType = JSON.parse(atob(tokenStr));
      const userInfos = infos['https://hasura.io/jwt/claims'];

      this.idUser = userInfos['x-hasura-user-id'];
      this.defaultRole = userInfos['x-hasura-default-role'];
      this.displayName = userInfos['x-hasura-display-name'];

      setToLocalStorage('id_current_user', this.idUser);

      const userSession = auth.getUser();
      if (userSession?.email) {
        this.email = userSession.email;
      }
    } catch (e) {
      console.log(e);
      SentryService.captureException(e, { step: 'Echec doTokenThing' });
    }
  }

  public async login(user: any) {
    if (!this.getIsOnline()) {
      return {
        session: null,
        error: { message: $localize`Aucune connexion internet` },
      };
    }

    this.watchToken();

    const { session, error } = await auth.signIn({
      email: user.username,
      password: user.password,
    });

    if (error) {
      return { session: null, error };
    }

    if (session) {
      this.nhostSessionSubject$.next(session);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (environment.ENABLE_SENTRY) {
        Sentry.setUser({
          id: session.user.id,
          username: session.user.email,
          email: session.user.email,
        });
      }
      return { session, error: null };
    }

    return {
      session: null,
      error: { message: $localize`Erreur lors de l'authentification` },
    };
  }
}
