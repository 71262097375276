import {
  getProductFromFamilies,
  getAssociatedFamiliesFromProduct,
} from '../productfamilies-utils.service';
import { getFamilyPath } from '../productfamily/getFamilyPathFromCache';
import { getProductPathFromCache } from '../product/getProductPathFromCache';
import {
  emplacementDocType,
  composantDocType,
} from '../../db/schemas/emplacement.schema';
import { ProductFamiliesType } from '@types_custom/ProductType';

export function checkComposantAssocIntegrity(
  emplacement: emplacementDocType,
  families: ProductFamiliesType[]
) {
  let messageComposantAssoc: string = '';
  let checkComposantAssoc = false;
  if (emplacement.composants.length === 0)
    return { checkComposantAssoc, messageComposantAssoc };

  const productsPath = emplacement.composants.map(
    (composant: composantDocType) => {
      if (composant.product?.id)
        return getProductPathFromCache(composant.product.id, families).join(
          '_'
        );
      if (composant.type) {
        return getFamilyPath(composant.type, families).join('_');
      }
      return '';
    }
  );

  // Pour chaque composant, on vérifie que les familles associées obligatoire sont présentes
  for (const composant of emplacement.composants) {
    // 25 Juin 2024 : On autorise les composants sans produit, donc composant intègre
    if (!composant.product?.id) continue;

    const product = getProductFromFamilies(composant.product.id, families);
    const assocFamilies = getAssociatedFamiliesFromProduct(
      composant.product.id,
      families,
      false,
      true
    );

    assocFamilies.every((assocFam: any) => {
      const pathFam = getFamilyPath(assocFam.id, families).join('_') + '_';
      const productFound = productsPath.find((path: string) =>
        (path + '_').includes(pathFam)
      );

      if (!productFound) {
        checkComposantAssoc = true;
        messageComposantAssoc = $localize`Il manque le composant "${assocFam.name}" (nécessaire pour ${product?.name}) 
        
        Veuillez faire le nécessaire pour ajouter l'élément manquant afin de débloquer l'enregistrement de l'emplacement`;
        return false;
      }
      return true;
    });
  }

  return { checkComposantAssoc, messageComposantAssoc };
}
