import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { emplacementDocType } from '../../db/schemas/emplacement.schema';
import { RoutingState } from '../../state/routing-state.service';
import { EventsService } from '../../services/events.service';
import { MatCardModule } from '@angular/material/card';
import { LocationContainer } from '../emplacement/location/location.container';
import { UgauButtonComponent } from '../ugau-button/ugau-button.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-emplacement-short',
  template: `
    <mat-card class="emplacement-short-container" *ngIf="emplacement">
      <mat-card-content>
        <app-location
          class="w100"
          [emplacement]="emplacement"
          [forceDisableMapAndMove]="true"
        ></app-location>
      </mat-card-content>
    </mat-card>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCardModule, LocationContainer, UgauButtonComponent, NgIf],
})
export class AppEmplacementShortComponent {
  private routingState = inject(RoutingState);
  private events = inject(EventsService);

  @Input() emplacement: emplacementDocType | null = null;

  showOnMap() {
    this.events.publish('map:center_emplacement', { item: this.emplacement });
  }

  openEmplacement(event: Event) {
    if (!this.emplacement) return;
    this.routingState.navigateEmplacement(this.emplacement.id);
  }
}
