import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';
import { tagDocType } from '../../db/schemas/tag.schema';
import { EmplacementsStateService } from '../../state/emplacements-state.service';

@Pipe({
  name: 'hasEmplacementTag',
  standalone: true,
})
export class HasEmplacementTagPipe implements PipeTransform {
  constructor(private emplacementsState: EmplacementsStateService) {}

  transform(tag: tagDocType): Observable<boolean> {
    return this.emplacementsState.emplacements$.pipe(
      map((emplacements) => {
        const matchs = emplacements.filter((emplacement) =>
          emplacement.emplacement_tags.some(
            (emplacement_tag) =>
              emplacement_tag.id_tag === tag.id && !emplacement_tag.deleted_at
          )
        );
        return matchs.length > 0;
      })
    );
  }
}
