import { dexieDeleteIndexedDbs } from '../db/database-dexie-helpers';
import { clearLocalStorageForLogout } from './clearLocalStorageForLogout';

export const clearDatabasesAndCache = async () => {
  clearLocalStorageForLogout();

  sessionStorage.clear();

  await deleteCacheFiles();
  await dexieDeleteIndexedDbs();
};

export const clearCacheAndIDBForLiveUpdate = async () => {
  await deleteCacheFiles();
  await dexieDeleteIndexedDbs();
};

export const deleteCacheFiles = async () => {
  if (typeof caches !== 'undefined') {
    const cacheNames = await caches.keys();
    await Promise.all(
      cacheNames.map((cacheName) => {
        return caches.delete(cacheName);
      })
    );
  }
};
