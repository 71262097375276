import { IControl, Map } from 'mapbox-gl';
import {
  MapboxStyleDefinition,
  MapboxStyleSwitcherOptions,
} from 'mapbox-gl-style-switcher';

export class MapboxCustomSwitcherControl implements IControl {
  private controlContainer: HTMLElement | undefined;
  private events?: any;
  private map?: Map;
  private mapStyleContainer: HTMLElement | undefined;
  private styleButton: HTMLButtonElement | undefined;
  private styles: MapboxStyleDefinition[];
  private defaultStyle: string;
  private activeLayers = new Set<string>();

  constructor(
    styles?: MapboxStyleDefinition[],
    options?:
      | (MapboxStyleSwitcherOptions & { activeLayers?: string[] })
      | string
  ) {
    this.styles = styles ?? [];
    const defaultStyle =
      typeof options === 'string'
        ? options
        : options
          ? options.defaultStyle
          : undefined;
    this.defaultStyle = defaultStyle ?? '';
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.events =
      typeof options !== 'string' && options
        ? options.eventListeners
        : undefined;
    if (options && typeof options !== 'string' && options.activeLayers) {
      for (const layer of options.activeLayers) {
        this.activeLayers.add(layer);
      }
    }
  }

  public getDefaultPosition(): string {
    return 'top-right';
  }

  public onAdd(map: Map): HTMLElement {
    this.map = map;
    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('mapboxgl-ctrl', 'mapboxgl-ctrl-group');
    this.mapStyleContainer = document.createElement('div');

    this.styleButton = document.createElement('button');
    this.styleButton.type = 'button';
    this.mapStyleContainer.classList.add('mapboxgl-style-list');

    for (const style of this.styles) {
      const styleElement = document.createElement('button');
      styleElement.type = 'button';
      styleElement.innerText = style.title;
      styleElement.classList.add(style.title.replace(/[^a-z0-9-]/gi, '_'));
      styleElement.dataset.uri = JSON.stringify(style.uri);
      styleElement.addEventListener('click', this.handleStyleClick);

      if (style.title === this.defaultStyle) {
        styleElement.classList.add('active');
      }
      if(this.activeLayers.has(style.uri)) {
        styleElement.classList.add('active');
      }

      this.mapStyleContainer.appendChild(styleElement);
    }
    this.styleButton.classList.add(
      'mapboxgl-ctrl-icon',
      'mapboxgl-style-custom-ign'
    );

    this.styleButton.addEventListener('click', (event) => {
      if (this.events?.onSelect?.(event)) {
        return;
      }
      this.openModal();
    });

    document.addEventListener('click', this.handleDocumentClick);

    this.controlContainer.appendChild(this.styleButton);
    this.controlContainer.appendChild(this.mapStyleContainer);

    return this.controlContainer;
  }

  public onRemove(): void {
    if (!this.controlContainer?.parentNode || !this.map || !this.styleButton) {
      return;
    }

    if (this.mapStyleContainer) {
      const buttons = this.mapStyleContainer.querySelectorAll('button');
      buttons.forEach((button) => {
        button.removeEventListener('click', this.handleStyleClick);
      });
    }

    this.styleButton.removeEventListener('click', this.handleDocumentClick);

    this.controlContainer.parentNode.removeChild(this.controlContainer);
    document.removeEventListener('click', this.handleDocumentClick);
    this.map = undefined;
  }

  private closeModal(): void {
    if (this.mapStyleContainer && this.styleButton) {
      this.mapStyleContainer.style.display = 'none';
      this.styleButton.style.display = 'block';
    }
  }

  private openModal(): void {
    if (this.mapStyleContainer && this.styleButton) {
      this.mapStyleContainer.style.display = 'block';
      this.styleButton.style.display = 'none';
    }
  }

  private handleDocumentClick(event: MouseEvent): void {
    if (
      this.controlContainer &&
      !this.controlContainer.contains(event.target as Element)
    ) {
      this.closeModal();
    }
  }

  private handleStyleClick = (event: MouseEvent): void => {
    const srcElement = event.srcElement as HTMLButtonElement;
    const styleUri = JSON.parse(srcElement.dataset.uri!);

    if (this.activeLayers.has(styleUri)) {
      this.activeLayers.delete(styleUri);
      srcElement.classList.remove('active');
    } else {
      this.activeLayers.add(styleUri);
      srcElement.classList.add('active');
    }
    this.closeModal();

    if (this.events?.onOpen?.(event)) {
      return;
    }
  };
}
