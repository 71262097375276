import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

/**
 * Generates a Mapbox geocoder result object.
 *
 * @param bbox - The bounding box of the result in the format [minX, minY, maxX, maxY].
 * @param lng - The longitude of the center point.
 * @param lat - The latitude of the center point.
 * @returns A MapboxGeocoder.Result object containing the geocoded result.
 */
export function generateGeocoderResult(
  bbox: [number, number, number, number],
  lng: number,
  lat: number
): MapboxGeocoder.Result {
  return {
    bbox: bbox,
    center: [lng, lat],
    place_name: `${lng},${lat}`,
    place_type: ['place_local'],
    relevance: 1,
    text: $localize`Coordonnées`,
    address: '',
    context: [],
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [lng, lat],
    },
    properties: {
      place_name: `${lng},${lat}`,
      text: $localize`Coordonnées`,
      place_type: ['place_local'],
      center: [lng, lat],
    },
  };
}
