import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { emplacementPhotoDocType } from './../../../db/schemas/emplacement.schema';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setToLocalStorage,
} from './../../../utils/localstorage-utils.service';

@Injectable({
  providedIn: 'root',
})
export class EmplacementPhotoSelectorState {
  private emplacementPhotosSubject$ = new BehaviorSubject<
    emplacementPhotoDocType[]
  >([]);
  emplacementPhotos$: Observable<emplacementPhotoDocType[]> =
    this.emplacementPhotosSubject$.asObservable();

  setEmplacementPhotos(photos: emplacementPhotoDocType[]) {
    this.emplacementPhotosSubject$.next(photos);
  }

  getEmplacementPhotos() {
    return this.emplacementPhotosSubject$.getValue();
  }

  private uploadDataSubject$ = new Subject<string>();
  uploadData$: Observable<string> = this.uploadDataSubject$.asObservable();

  setUploadData(data: string) {
    this.uploadDataSubject$.next(data);
  }

  private promptedPhoto: emplacementPhotoDocType | undefined = undefined;
  setPromptedPhoto(photo: emplacementPhotoDocType) {
    this.promptedPhoto = photo;
    setToLocalStorage('promptedPhoto', photo);
  }

  getPromptedPhoto() {
    return this.promptedPhoto ?? getFromLocalStorage('promptedPhoto', true);
  }

  hasPromptedPhoto() {
    return !!this.promptedPhoto || !!getFromLocalStorage('promptedPhoto', true);
  }

  reset() {
    this.setEmplacementPhotos([]);
    this.promptedPhoto = undefined;
    removeFromLocalStorage('promptedPhoto');
  }
}
