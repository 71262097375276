import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { eventTopic } from './eventTopic';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private channels: Record<string, Subject<any>> = {};

  /**
   * Subscribe to a topic and provide a single handler/observer.
   * @param topic The name of the topic to subscribe to.
   * @param observer The observer or callback function to listen when changes are published.
   *
   * @returns Subscription from which you can unsubscribe to release memory resources and to prevent memory leak.
   */
  public subscribe(
    topic: eventTopic,
    observer: (_: any) => void
  ): Subscription {
    if (!this.channels.hasOwnProperty(topic)) {
      // You can also use ReplaySubject with one concequence
      this.channels[topic] = new Subject<any>();
    }

    return this.channels[topic].subscribe(observer);
  }

  public getObservable(topic: eventTopic) {
    if (!this.channels.hasOwnProperty(topic)) {
      // You can also use ReplaySubject with one concequence
      this.channels[topic] = new Subject<any>();
    }
    return this.channels[topic].asObservable();
  }

  /**
   * Publish some data to the subscribers of the given topic.
   * @param topic The name of the topic to emit data to.
   * @param data data in any format to pass on.
   */
  public publish(topic: eventTopic, data?: any): void {
    if (this.channels.hasOwnProperty(topic)) {
      const subject = this.channels[topic];
      subject.next(data);
      return;
    }

    this.channels[topic] = new Subject<any>();
    this.channels[topic].next(data);
  }

  /**
   * When you are sure that you are done with the topic and the subscribers no longer needs to listen to a particular topic, you can
   * destroy the observable of the topic using this method.
   * @param topic The name of the topic to destroy.
   */
  public destroy(topic: eventTopic): void {
    if (!this.channels.hasOwnProperty(topic)) {
      // On complète le subject pour que les observers se désabonnent
      const subject = this.channels[topic];
      subject.complete();

      // On supprime le subject en recréant un nouveau channels
      const newChannels: Record<string, Subject<any>> = {};
      for (const key in this.channels) {
        if (key !== topic) {
          newChannels[key] = this.channels[key];
        }
      }
      this.channels = newChannels;
    }
  }
}
