import {
  getProductFirstFamilyFromFamilies,
  recursiveFindFamilyFromFamiliesId
} from '../productfamilies-utils.service';
import { composantDocType } from '../../db/schemas/emplacement.schema';
import { ProductFamiliesType } from '@types_custom/ProductType';


export function extractFirstFamily(
  composant: composantDocType,
  originalTree: ProductFamiliesType[]
) {
  let firstFamily: ProductFamiliesType | null = null;
  try {
    if (composant.product) {
      firstFamily = getProductFirstFamilyFromFamilies(
        composant.product.id,
        originalTree
      );
    } else if (composant.type && composant.type !== '') {
      firstFamily =
        recursiveFindFamilyFromFamiliesId(composant.type, originalTree) ?? null;
    }
  } catch (e) {
    console.log(e);
  }
  return firstFamily;
}
