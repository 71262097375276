import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FabricantProvider } from '../db/providers/fabricant-provider';
import { fabricantDocType } from '../db/schemas/fabricant.schema';
import { FabricantSelectItem } from '../components/ugau-select-fabricant/fabricant-select-item';

@Injectable({
  providedIn: 'root',
})
export class FabricantsStateService {
  fabricants$: Observable<fabricantDocType[]> = this.fabricantProvider.getAll$;
  fabricantsAsSelectItems$: Observable<FabricantSelectItem[]> =
    this.fabricants$.pipe(
      map((fabricants) =>
        fabricants.map((fabricant) => new FabricantSelectItem(fabricant))
      )
    );

  constructor(private fabricantProvider: FabricantProvider) {}

  getFabricant$(fabricantId: string): Observable<fabricantDocType | undefined> {
    return this.fabricants$.pipe(
      map((fabricants) => fabricants.find((el) => el.id === fabricantId))
    );
  }

  getFabricants() {
    return this.fabricantProvider.getAllValue();
  }
}
