import {
  Component,
  Input,
  Output,
  EventEmitter,
  inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { tagMetaDocType } from '../../../db/schemas/tag.schema';
import { storage } from '../../../services/nhost';
import { retrieveUploadFilename } from '../../../pipes/get-filename.pipe';
import { forceDownloadFile } from '../../emplacement/emplacement-photo-full/extractAndForceDownloadFile';
import { PanelStateService } from '../panel-state';
import { TagMetasStateService } from '../../../state/tags-meta-state.service';
import { initMeta } from './initNewTagMeta';
import { ModalService } from '../../../services/modal.service';
import { TagMetaTypeType } from './TagMetaTypeType';
import { MetaFieldMenuComponent } from './meta-header-fieldmenu.component';
import { MetaFieldAccordionComponent } from './meta-accordion-field.component';

@Component({
  selector: 'app-tag-meta',
  templateUrl: './tag-meta.component.html',
  styleUrls: ['./tag-meta.component.scss'],
  standalone: true,
  imports: [MetaFieldAccordionComponent, MetaFieldMenuComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagMetaComponent {
  private modalService = inject(ModalService);
  private panelStateService = inject(PanelStateService);
  private tagMetasStateService = inject(TagMetasStateService);
  private _metas: tagMetaDocType[] = [];
  @Input() set metas(metas: tagMetaDocType[]) {
    this._metas = metas.sort((a, b) => {
      return a.key.localeCompare(b.key);
    });
  }
  get metas() {
    return this._metas;
  }

  @Input() readonly = false;
  @Output() metaChange = new EventEmitter<tagMetaDocType[]>();

  addMetaField(type: TagMetaTypeType) {
    const meta = initMeta(type);
    this.metas.push(meta);
    this.metaChange.emit(this.metas);

    this.metas.sort((a, b) => {
      return a.key.localeCompare(b.key);
    });
    const index = this.metas.findIndex((m) => m.id === meta.id);
    this.togglePanel(index);
  }

  updateMetaField(
    meta: tagMetaDocType,
    field: keyof tagMetaDocType,
    value: string | null
  ) {
    const newMetas = this.metas.map((m) => {
      if (m.id === meta.id)
        return {
          ...m,
          [field]: value,
        };
      return m;
    });

    this.metaChange.emit(newMetas);
  }

  removeMetaField(meta: tagMetaDocType) {
    this.modalService.presentBooleanDialog(
      {
        data: $localize`Êtes vous certain de vouloir supprimer ce champs ?`,
        title: $localize`Suppression information supplémentaire`,
      },
      () => {
        this.doRemoveMetaField(meta);
      }
    );
  }

  doRemoveMetaField(meta: tagMetaDocType) {
    const newMetas = [...this.metas.filter((m) => m.id !== meta.id)];
    this.metaChange.emit(newMetas);
    this.tagMetasStateService.remoteRemoveTagMeta(meta.id);
  }

  async downloadFile(fileId: string | undefined) {
    if (!fileId) return;

    const filename = await retrieveUploadFilename(fileId);
    const completeUrl = fileId.includes('http')
      ? fileId
      : storage.getPublicUrl({ fileId });

    forceDownloadFile(completeUrl, filename);
  }

  togglePanel(index: number) {
    if (!this.readonly) this.panelStateService.togglePanel('tag_metas', index);
  }
}
