import { PRODUCTFIELD_TYPE } from '../../enum/product-fields-type';
import { isDurationStartDateAfterToday } from '../../components/ugau-duration-start/isDurationStartDateAfterToday';
import {
  composantDocType,
  composantProductfieldDocType,
} from '../../db/schemas/emplacement.schema';
import { productfieldDocType } from '../../db/schemas/productfields.schema';

export function isComposantDurationsValid(
  composant: composantDocType,
  fields: productfieldDocType[]
) {
  const durationStartFields = fields
    .filter((field) => field.type === PRODUCTFIELD_TYPE.DURATION_START)
    .map((field) => field.id);

  const durationStart = composant.composant_productfields.reduce(
    (acc: any, cpf: composantProductfieldDocType) => {
      // @TODO: Il peut y avoir plusieurs productfields duration_start dans le composant !
      if (durationStartFields.includes(cpf.productfield.id)) {
        return cpf.value;
      }
      return acc;
    },
    undefined
  );

  if (!durationStart) return true;

  return isDurationStartDateAfterToday(durationStart, composant, fields);
}
