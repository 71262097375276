export enum URL_PANEL_ACTION {
  DETAIL = 'detail/',
  CREATE = 'add/',
  CHOOSE_ADD = 'chooseAdd/',
}
export enum URL_PANEL_DISPLAY_ACTION {
  PHOTOS = 'picture',
  PRISE_PHOTO = 'add_picture',
  PRISE_PHOTO_DIRECT = 'direct_add_picture',
  LOCATION_SELECTOR = 'location',
  DETAIL = 'view',
}

export enum URL_MODAL_PAGE {
  STOCK = 'stock/',
  BATIMENT = 'locations/',
  TAG = 'tag/',
  INTERVENTION = 'intervention/',
  PARAMETRES = 'parameters/',
  MON_COMPTE = 'account/',
  MES_GROUPES = 'team/',
  MATERIEL = "material/",
}

export enum URL_MODAL_DISPLAY_ACTION {
  LIST = 'list',
  DETAIL = 'view',
  CREATE = 'add',
}
