import { Popup, Map } from 'mapbox-gl';

/**
 * Creates and returns a new popup on the map at the specified latitude and longitude.
 *
 * @param latlng - The latitude and longitude where the popup should be anchored.
 * @param content - The HTML content to be displayed inside the popup.
 * @param map - The map instance to which the popup will be added.
 * @param addClassName - An optional additional class name to be added to the popup for custom styling.
 * @returns A new Popup instance configured with the specified parameters.
 */
export const createPopup = (
  latlng: any,
  content: HTMLElement,
  map: Map,
  addClassName: string = ''
) =>
  new Popup({
    closeButton: true,
    closeOnClick: true,
    className: 'ugau-popup ' + addClassName,
  })
    .setLngLat(latlng)
    .setDOMContent(content)
    .addTo(map);
