export const DEFAULT_AGE = {
  CLASSE_1: 7,
  CLASSE_2: 10,
  CLASSE_3: 12,
};

export function getMaxAgeByClasseAlt(
  num: number,
  overrideClasse: Record<number, number>
) {
  if (overrideClasse.hasOwnProperty(num) && overrideClasse[num] !== 0)
    return overrideClasse[num];

  if (num == 1) return DEFAULT_AGE.CLASSE_1;
  if (num == 2) return DEFAULT_AGE.CLASSE_2;
  if (num == 3) return DEFAULT_AGE.CLASSE_3;
  return 0;
}
