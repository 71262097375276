import { sharedTagDocType } from './../../db/schemas/tag.schema';
import { v4 as uuidv4 } from 'uuid';

interface InitNewTagShareParams {
  id?: string;
  active?: boolean;
  readonly?: boolean;
  expires_at?: string | null;
  shared_at?: string;
  source_user_id?: string | null;
  source_group_id?: string | null;
  target_user_id?: string | null;
  target_group_id?: string | null;
}

export function initNewTagShare({
  id = uuidv4(),
  active = true,
  readonly = false,
  expires_at = null,
  shared_at = new Date().toISOString(),
  source_user_id = null,
  source_group_id = null,
  target_user_id = null,
  target_group_id = null,
}: InitNewTagShareParams = {}): sharedTagDocType {
  return {
    id,
    active,
    readonly,
    expires_at,
    shared_at,
    source_user_id,
    source_group_id,
    target_user_id,
    target_group_id,
  } as sharedTagDocType;
}
