import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { AsyncPipe, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { BehaviorSubject, combineLatest, Observable, map } from 'rxjs';
import { AppEmplacementShortComponent } from '../../../components/batiment/emplacement-short-display.component';
import { emplacementDocType } from '../../../db/schemas/emplacement.schema';
import { PRODUCTFIELD_TYPE } from '../../../enum/product-fields-type';
import { trackByIdFn, trackByKeyFn } from '../../../services/trackByFunctions.utils';
import { EmplacementsStateService } from '../../../state/emplacements-state.service';
import { userAltType } from '../../../db/schemas/groupe.schema';
import { ProductfieldsStateService } from '../../../state/productfields-state.service';

@Component({
  selector: 'app-useralt-linked-emplacements',
  template: `
    <ng-container *ngIf="linkedEmplacements$ | async as linkedEmplacements">
      <ng-container
        *ngFor="
          let item of linkedEmplacements | keyvalue;
          trackBy: trackByKey
        "
      >
        <div class="container-title">{{ item.key }}</div>
        <div class="container-emplacements">
          <ng-container
            *ngFor="
              let emplacement of linkedEmplacements[item.key];
              trackBy: trackByItemId
            "
          >
            <app-emplacement-short
              [emplacement]="emplacement"
            ></app-emplacement-short>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: block;
        margin: 0 16px;

        .container-emplacements {
          display: flex;
          app-emplacement-short {
            flex: 0 0 50%;
            margin-bottom: 16px;
          }
        }
      }

      @media (max-width: 992px) {
        :host {
          .container-emplacements {
            display: flex;
            flex-direction: column;
            app-emplacement-short {
              flex: 0 0 100%;
              margin-bottom: 16px;
            }
          }
        }
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgIf, NgFor, AppEmplacementShortComponent, KeyValuePipe],
})
export class AppUseraltLinkedEmplacementsComponent {
  trackByKey = trackByKeyFn
  trackByItemId = trackByIdFn;

  private emplacementsState = inject(EmplacementsStateService);
  private productfieldsState = inject(ProductfieldsStateService);
  private useraltSubject$ = new BehaviorSubject<userAltType | null>(null);

  @Input() set useralt(value: userAltType) {
    this.useraltSubject$.next(value);
  }

  linkedEmplacements$: Observable<Record<string, emplacementDocType[]>> =
    combineLatest([
      this.useraltSubject$.asObservable(),
      this.emplacementsState.emplacements$,
      this.productfieldsState.productfields$,
    ]).pipe(
      map(([useralt, emplacements, productfields]) => {
        if (!useralt) return {};

        // Grouper les emplacements par nom de productfield
        return emplacements.reduce<Record<string, emplacementDocType[]>>(
          (acc, emplacement) => {
            emplacement.composants.forEach((composant) => {
              composant.composant_productfields.forEach((cpf) => {
                const productfield = productfields.find(
                  (pf) => pf.id === cpf.productfield.id
                );
                const name = productfield?.name ?? 'Unknown';

                // Vérifier si le productfield correspond à l'utilisateur ou à un groupe d'utilisateurs
                const isLinkedToUser =
                  (cpf.value === useralt.id &&
                    cpf.productfield.type ===
                      PRODUCTFIELD_TYPE.USER.toString()) ||
                  (cpf.value?.includes(useralt.id) &&
                    cpf.productfield.type ===
                      PRODUCTFIELD_TYPE.USER_MULTIPLE.toString());

                if (isLinkedToUser) {
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  if (!acc[name]) {
                    acc[name] = [];
                  }
                  // Ajouter l'emplacement au tableau associé au nom du productfield
                  acc[name].push(emplacement);
                }
              });
            });
            return acc;
          },
          {}
        );
      })
    );
}
