import {
  openDatabase,
  getTransaction,
  handleTransactionError,
} from './../../state/indexeddb-utils.service';
import { mapToObject } from './mapToObject';

export function storeMapInIndexedDB(
  dbName: string,
  storeName: string,
  key: string,
  map: Map<any, any>
): Promise<void> {
  const encodedData = JSON.stringify(mapToObject(map));

  return new Promise((resolve, reject) => {
    openDatabase(dbName, storeName)
      .then((db) => {
        try {
          const transaction = getTransaction(db, storeName, 'readwrite');
          const store = transaction.objectStore(storeName);
          const putRequest = store.put(encodedData, key);

          putRequest.onsuccess = () => {
            resolve();
          };

          putRequest.onerror = () => {
            console.error(
              'Erreur lors du stockage de la map',
              putRequest.error
            );
            reject(putRequest.error);
          };

          transaction.oncomplete = () => {
            db.close();
          };

          transaction.onerror = () =>
            handleTransactionError(transaction, reject);
        } catch (error) {
          reject(error);
        }
      })
      .catch((error) => {
        console.error('Error opening database', error);
        reject(error);
      });
  });
}
