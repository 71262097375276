import { Map, IControl } from 'mapbox-gl';

export class MapboxGLButtonControl implements IControl {
  private _className: string;
  _title: string;
  _eventHandler: any;
  _btn!: HTMLButtonElement;
  _container!: HTMLDivElement;
  _map: undefined;

  constructor({
    className = '',
    title = '',
    eventHandler = (event: any) => {},
  }) {
    this._className = className;
    this._title = title;
    this._eventHandler = eventHandler;
  }

  onAdd(map: Map) {
    this._btn = document.createElement('button');
    this._btn.className = 'mapboxgl-ctrl-icon' + ' ' + this._className;
    this._btn.type = 'button';
    this._btn.title = this._title;
    this._btn.onclick = this._eventHandler;

    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode?.removeChild(this._container);
    this._map = undefined;
  }
}
