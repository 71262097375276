import { Injectable } from '@angular/core';
import { gql } from 'graphql-request';
import {
  ReplicationPullHandlerResult,
  RxReplicationWriteToMasterRow,
  WithDeleted,
  lastOfArray,
} from 'rxdb';
import { AbstractProvider } from './abstract-provider';
import { ensureValidToken, graphql } from './../../services/nhost';
import {
  userMetaDocType,
  userMetaSchema,
  userMetaSchemaLiteral,
} from '../schemas/user-meta.schema';
import { SentryService } from '../../services/sentry.service';
import { Checkpoint } from '../interfaces/Checkpoint.type';

@Injectable({
  providedIn: 'root',
})
export class UserMetaProvider extends AbstractProvider<userMetaDocType> {
  schema = userMetaSchema;
  schemaLiteral = userMetaSchemaLiteral;

  protected migrationStrategies = {
    1: function (oldDoc: userMetaDocType) {
      return null;
    },
  };

  async getPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<userMetaDocType, Checkpoint>> {
    const variables = {
      where: {
        _or: this.getOrForQuery(lastCheckpoint),
      },
      order_by: { updated_at: 'asc' },
      limit: batchSize,
    };
    const { data, error } = await graphql.request(
      QUERY_GET_USER_META,
      variables
    );

    if (error || !data.user_meta) {
      console.error(
        'Erreur lors de la récupération des métadonnées utilisateur sur le serveur',
        error
      );
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.user_meta.map((doc: any) => {
      return {
        ...doc,
        deleted_bool: false,
      };
    });

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }

  async getPushQuery(
    docs: RxReplicationWriteToMasterRow<userMetaDocType>[]
  ): Promise<WithDeleted<userMetaDocType>[]> {
    await ensureValidToken();

    const datas = docs.reduce(
      (acc: any[], rxDoc: RxReplicationWriteToMasterRow<userMetaDocType>) => {
        const doc = rxDoc.newDocumentState;
        const { id, key, value, created_at, updated_at } = doc;
        const userMeta = {
          id,
          key,
          value,
          created_at,
          updated_at,
        };

        acc.push(userMeta);
        return acc;
      },
      []
    );
    try {
      const variables = {
        objects: datas,
        on_conflict: {
          constraint: 'user_meta_pkey',
          update_columns: ['key', 'value', 'updated_at'],
        },
      };
      const query = MUTATION_INSERT_USER_META;
      const { error } = await graphql.request(query, variables);

      // Gestion de l'erreur sur le retour de la requête
      if (error) {
        SentryService.captureException(error);
        console.log('ERREUR Push user_meta', error);
        this.AT.toastError(
          $localize`Erreur lors de la sauvegarde des préférences`
        );
        return [];
      }

      this.AT.toastError($localize`Préférences sauvegardées`);
      return [];
    } catch (error) {
      // Gestion de l'erreur sur la requête
      SentryService.captureException(error);
      console.log('ERREUR Push user_meta', error);
      this.AT.toastError(
        $localize`Erreur lors de la sauvegarde des préférences`
      );
      return [];
    }
  }
}

export const QUERY_GET_USER_META = gql`
  query GetUserMeta(
    $where: user_meta_bool_exp!
    $order_by: [user_meta_order_by!]!
    $limit: Int!
  ) {
    user_meta(where: $where, order_by: $order_by, limit: $limit) {
      id
      user_id
      key
      value
      created_at
      updated_at
    }
  }
`;

export const MUTATION_INSERT_USER_META = gql`
  mutation InsertUserMeta(
    $objects: [user_meta_insert_input!]!
    $on_conflict: user_meta_on_conflict!
  ) {
    insert_user_meta(objects: $objects, on_conflict: $on_conflict) {
      returning {
        id
      }
    }
  }
`;
