import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';
import { AnalyticService } from '../services/analytics.service';
import { ToolsService } from '../services/tools.service';

export interface homeState {
  hasIntervention: boolean;
  hasDossier: boolean;
  hasFilter: boolean;
  hasList: boolean;
  hasStats: boolean;
  hasBudget: boolean;
  hasHistory: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HomeStateService {
  stateSubject$ = new BehaviorSubject<homeState>({
    hasIntervention: false,
    hasDossier: false,
    hasFilter: false,
    hasList: false,
    hasStats: false,
    hasBudget: false,
    hasHistory: false,
  });
  state$ = this.stateSubject$.asObservable().pipe(distinctUntilChanged());
  isOpenDossier$ = this.state$.pipe(map((e): boolean => e.hasDossier));
  isOpenIntervention$ = this.state$.pipe(
    map((e): boolean => e.hasIntervention)
  );
  isOpenFilter$ = this.state$.pipe(map((e): boolean => e.hasFilter));
  isOpenList$ = this.state$.pipe(map((e): boolean => e.hasList));
  isOpenStats$ = this.state$.pipe(map((e): boolean => e.hasStats));
  isOpenBudget$ = this.state$.pipe(map((e): boolean => e.hasBudget));
  isOpenHistory$ = this.state$.pipe(map((e): boolean => e.hasHistory));
  isOpenModal$ = this.state$.pipe(
    map(
      (e): boolean =>
        e.hasIntervention ||
        e.hasDossier ||
        e.hasFilter ||
        e.hasList ||
        e.hasStats ||
        e.hasBudget ||
        e.hasHistory
    )
  );

  isMobile = this.tools.isMobile();

  constructor(
    private AnS: AnalyticService,
    private tools: ToolsService
  ) {}

  closeInterventions() {
    const value = this.stateSubject$.getValue();
    return this.stateSubject$.next({
      ...value,
      hasIntervention: false,
    });
  }

  openInterventions() {
    this.AnS.trackEvent('volet:intervention:open');
    const value = this.stateSubject$.getValue();
    if (this.isMobile) {
      value.hasList = false;
      value.hasFilter = false;
      value.hasDossier = false;
    }
    this.stateSubject$.next({
      ...value,
      hasIntervention: true,
    });
  }

  toggleIntervention() {
    const value = this.stateSubject$.getValue();
    if (value.hasIntervention) this.closeInterventions();
    else this.openInterventions();
  }

  closeDossier() {
    const value = this.stateSubject$.getValue();
    return this.stateSubject$.next({
      ...value,
      hasDossier: false,
    });
  }

  openDossier() {
    this.AnS.trackEvent('volet:dossiers:open');
    const value = this.stateSubject$.getValue();
    if (this.isMobile) {
      value.hasList = false;
      value.hasFilter = false;
      value.hasIntervention = false;
    }
    this.stateSubject$.next({
      ...value,
      hasDossier: true,
    });
  }

  toggleDossier() {
    const value = this.stateSubject$.getValue();
    if (value.hasDossier) this.closeDossier();
    else this.openDossier();
  }

  closeFilter() {
    const value = this.stateSubject$.getValue();
    return this.stateSubject$.next({
      ...value,
      hasFilter: false,
    });
  }

  openFilter() {
    this.AnS.trackEvent('volet:filtres:open');
    const value = this.stateSubject$.getValue();
    if (this.isMobile) {
      value.hasList = false;
      value.hasDossier = false;
      value.hasIntervention = false;
    }
    this.stateSubject$.next({
      ...value,
      hasFilter: true,
    });
  }

  toggleFilter() {
    const value = this.stateSubject$.getValue();
    if (value.hasFilter) this.closeFilter();
    else this.openFilter();
  }

  closeList() {
    const value = this.stateSubject$.getValue();
    this.stateSubject$.next({
      ...value,
      hasList: false,
    });
  }

  openList() {
    this.AnS.trackEvent('volet:liste:open');
    const value = this.stateSubject$.getValue();
    if (this.isMobile) {
      value.hasFilter = false;
      value.hasDossier = false;
      value.hasIntervention = false;
    }
    this.stateSubject$.next({
      ...value,
      hasList: true,
    });
  }

  toggleList() {
    const value = this.stateSubject$.getValue();
    if (value.hasList) this.closeList();
    else this.openList();
  }

  closeStats() {
    const value = this.stateSubject$.getValue();
    this.stateSubject$.next({
      ...value,
      hasStats: false,
    });
  }

  openStats() {
    const value = this.stateSubject$.getValue();
    this.stateSubject$.next({
      ...value,
      hasStats: true,
      hasBudget: false,
      hasHistory: false,
    });
    this.AnS.trackEvent('volet:statistiques:open');
  }

  toggleStats() {
    const value = this.stateSubject$.getValue();
    if (value.hasStats) this.closeStats();
    else this.openStats();
  }

  closeBudget() {
    const value = this.stateSubject$.getValue();
    this.stateSubject$.next({
      ...value,
      hasBudget: false,
    });
  }

  openBudget() {
    const value = this.stateSubject$.getValue();
    this.stateSubject$.next({
      ...value,
      hasBudget: true,
      hasStats: false,
      hasHistory: false,
    });
    this.AnS.trackEvent('volet:budget:open');
  }

  toggleBudget() {
    const value = this.stateSubject$.getValue();
    if (value.hasBudget) this.closeBudget();
    else this.openBudget();
  }

  closeHistory() {
    const value = this.stateSubject$.getValue();
    this.stateSubject$.next({
      ...value,
      hasHistory: false,
    });
  }

  openHistory() {
    const value = this.stateSubject$.getValue();
    this.stateSubject$.next({
      ...value,
      hasHistory: true,
      hasStats: false,
      hasBudget: false,
    });
    this.AnS.trackEvent('volet:suiviactivite:open');
  }

  toggleHistory() {
    const value = this.stateSubject$.getValue();
    if (value.hasHistory) this.closeHistory();
    else this.openHistory();
  }

  closeAll() {
    const value = this.stateSubject$.getValue();
    this.stateSubject$.next({
      ...value,
      hasIntervention: false,
      hasDossier: false,
      hasFilter: false,
      hasList: false,
      hasStats: false,
      hasBudget: false,
      hasHistory: false,
    });
  }
}
