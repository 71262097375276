import { getComposantLibelleType } from '../composant/getComposantLibelleType';
import {
  conformiteDocType,
  composantDocType
} from '../../db/schemas/emplacement.schema';
import { reglementationDocType } from './../../db/schemas/reglementation.schema';
import { isConformiteMissingReglementationChoix } from './isConformiteMissingReglementationChoix';


export function checkConformiteIntegrity(
  composant: composantDocType,
  reglementations: reglementationDocType[]
) {
  let messageConformite: string = '';

  const checkConformite = composant.conformites.some(
    (conformite: conformiteDocType) => {
      // Si la conformite n'a pas de reglementation => message d'erreur
      if (!conformite.id_reglementation) {
        messageConformite = `${getComposantLibelleType(
          composant
        )} : Conformite(s) invalide(s)`;
        return true;
      }

      // Récupération de la reglementation
      const reglementation = reglementations.find(
        (r: reglementationDocType) => r.id === conformite.id_reglementation.toString()
      );
      if (!reglementation) return false;

      // Si la reglementation doit avoir un choix
      if (isConformiteMissingReglementationChoix(conformite, reglementation)) {
        const libelle = getComposantLibelleType(composant);
        messageConformite = `${libelle} : Conformite "${reglementation.libelle}" invalide`;
        return true;
      }

      return false;
    }
  );

  return { value: checkConformite, message: messageConformite };
}
