<mat-form-field
  class="ugau-input-number"
  [appearance]="appearance"
  floatLabel="always"
>
  <mat-label>{{ label }}</mat-label>
  <input
    #input
    matInput
    type="number"
    [placeholder]="placeholder"
    [value]="value"
    [readonly]="readOnly"
    (click)="handleClick()"
  />
</mat-form-field>
