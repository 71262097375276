import { Pipe, PipeTransform } from '@angular/core';
import {
  composantDocType,
  composantProductfieldDocType,
} from '../../db/schemas/emplacement.schema';
import { isDurationStartDateAfterToday } from '../../components/ugau-duration-start/isDurationStartDateAfterToday';
import { PRODUCTFIELD_TYPE } from '../../enum/product-fields-type';
import { formatDateddMMyyyy } from '../../utils/date-utils.service';
import { ProductfieldsStateService } from '../../state/productfields-state.service';
import { FabricantsStateService } from '../../state/fabricants-state.service';
import { ProductpropertiesStateService } from '../../state/productproperties-state.service';
import { getComposantBadConformiteString } from '../../utils/composant/getComposantBadConformiteString';
import { isConformeComposant } from '../../utils/composant/isConformeComposant';
import { lastValueFrom, take } from 'rxjs';
import { ReglementationsStateService } from './../../state/reglementations-state.service';

@Pipe({
  name: 'getComposantListitem',
  standalone: true,
})
export class GetComposantListitemPipe implements PipeTransform {
  constructor(
    private productfieldsState: ProductfieldsStateService,
    private propertiesState: ProductpropertiesStateService,
    private fabricantState: FabricantsStateService,
    private reglementationsState: ReglementationsStateService
  ) {}

  async transform(composant: composantDocType, type: string): Promise<string> {
    if (type === 'first_line') {
      return await this.doFirstLine(composant);
    }

    if (
      type === 'second_line' &&
      !isConformeComposant(
        composant,
        null,
        this.propertiesState.getProductproperties(),
        this.productfieldsState.getProductfields(),
        this.reglementationsState.getReglementations()
      )
    ) {
      return Promise.resolve(
        getComposantBadConformiteString(
          composant,
          this.propertiesState.getProductproperties(),
          this.productfieldsState.getProductfields(),
          this.reglementationsState.getReglementations()
        )
      );
    }

    return Promise.resolve('');
  }

  private async doFirstLine(composant: composantDocType) {
    const productfields = this.productfieldsState.getProductfields();
    const truc = await composant.composant_productfields.reduce(
      async (accP: Promise<string[]>, el: composantProductfieldDocType) => {
        const acc = await accP;

        const relatedProductfield = productfields.find(
          (pf) => pf.id === el.productfield.id
        );
        if (!relatedProductfield) return acc;
        if (!relatedProductfield.show_in_title) return acc;

        if (relatedProductfield.type === PRODUCTFIELD_TYPE.MAKER) {
          const value = el.value ?? '';
          const fabricant = await lastValueFrom(
            this.fabricantState.getFabricant$(value).pipe(take(1))
          );

          if (!fabricant) {
            return [...acc, $localize`Fabricant manquant`];
          }
          return [...acc, fabricant.libelle];
        }

        if (relatedProductfield.type === PRODUCTFIELD_TYPE.DURATION_START) {
          const value = el.value ?? '';
          if (
            !isDurationStartDateAfterToday(
              value,
              composant,
              this.productfieldsState.getProductfields()
            )
          ) {
            return [
              ...acc,
              $localize`Contrôle du ${formatDateddMMyyyy(value)} en retard`,
            ];
          }
          return [
            ...acc,
            $localize`Prochain contrôle le ${formatDateddMMyyyy(value)}`,
          ];
        }

        if (
          relatedProductfield.type === PRODUCTFIELD_TYPE.TEXT ||
          relatedProductfield.type === PRODUCTFIELD_TYPE.MULTILINE ||
          relatedProductfield.type ===
            PRODUCTFIELD_TYPE.AUTOCOMPLETE_MULTILINE ||
          relatedProductfield.type === PRODUCTFIELD_TYPE.AUTOCOMPLETE
        ) {
          if (!el.value || el.value === '') {
            return [...acc, $localize`Texte manquant`];
          }
          return [...acc, el.value];
        }

        if (relatedProductfield.type === PRODUCTFIELD_TYPE.DATE_FABRICATION) {
          if (!el.value || el.value === '') {
            return [...acc, $localize`Date fabrication manquante`];
          }
          return [
            ...acc,
            new Date(el.value.toString()).getFullYear().toString(),
          ];
        }

        return acc;
      },
      Promise.resolve([])
    );
    return Promise.resolve(truc.join(', '));
  }
}
