import { INTERVENTION_STATUS } from './../../components/intervention-page/intervention-form/INTERVENTION_STATUS';
import {
  emplacementInterventionDocType,
  interventionDocType,
} from './../../db/schemas/intervention.schema';
import { v4 as uuidv4 } from 'uuid';

export function createIntervention(
  name: string,
  tagId?: string,
  userId?: string,
  empls: emplacementInterventionDocType[] = []
): interventionDocType {
  return {
    id: uuidv4(),
    name: name,
    numero: '',
    id_tag: tagId ?? '',
    description: '',
    date_preparation: new Date().toISOString(),
    date_en_cours: undefined,
    date_termine: undefined,
    etat_depart: undefined,
    etat_fin: undefined,
    status: INTERVENTION_STATUS.PREPARATION,
    created_at: new Date().toISOString(),
    created_by: userId,
    updated_at: new Date().toISOString(),
    updated_by: userId,
    deleted_at: null,
    deleted_by: null,
    emplacements: empls,
    documents: [],
  };
}
