import { CirclePaint } from 'mapbox-gl';

export function getDefaultPaintCluster(color: string): CirclePaint {
  return {
    // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
    //   *  20px circles when point count is less than 100
    //   *  30px circles when point count is between 100 and 750
    //   *  40px circles when point count is greater than or equal to 750
    'circle-color': [
      'step',
      ['get', 'point_count'],
      color,
      100,
      color,
      750,
      color,
    ],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
  };
}
