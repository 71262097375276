import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { emplacementDocType } from '../db/schemas/emplacement.schema';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';
import { DatabaseService } from '../db/database.service';

@Injectable({
  providedIn: 'root',
})
export class EmplacementsStateService {
  emplacements$ = this.db
    .getEmplacementProvider()
    .getAllMod$.pipe(shareReplay(1));

  batchEmplacements$ = this.db.getEmplacementProvider().getAllByBatch$;

  isReplicationActive$ = from(
    this.db.getEmplacementProvider().replicateState?.awaitInSync() ?? of(true)
  ).pipe(
    startWith(false),
    map((e) => !e),
    distinctUntilChanged(),
    shareReplay(1)
  );

  totalCount$ = this.db
    .getEmplacementProvider()
    .totalCount$.pipe(distinctUntilChanged(), shareReplay(1));

  constructor(private db: DatabaseService) {}

  getEmplacements() {
    return this.db.getEmplacementProvider().getAllValue();
  }
  getEmplacementById(id: string | undefined) {
    if (!id) return undefined;
    return this.db
      .getEmplacementProvider()
      .getAllValue()
      .find((e) => e.id === id);
  }

  getEmplacementsByTagId(tagId: string) {
    return this.db
      .getEmplacementProvider()
      .getAllValue()
      .filter((e) => {
        return e.emplacement_tags.map((e) => e.id_tag).includes(tagId);
      });
  }

  getEmplacementsByIds$(
    id: string[]
  ): Observable<emplacementDocType[] | undefined> {
    return this.emplacements$.pipe(
      map((emplacements) => {
        return emplacements.filter((e) => id.includes(e.id));
      })
    );
  }

  upsertEmplacement(emplacement: emplacementDocType) {
    return this.db.getEmplacementProvider().collection.upsert(emplacement);
  }
}
