import { stringify } from '../utils/json-utils';

export function trackByIdFn(index: number, item: any) {
  return item.id;
}

export function trackByIdJsonObj(index: number, item: any) {
  return stringify(item, 3, (k, v) => v);
}

export function trackByGetIdentifierFn(index: number, item: any) {
  return item.getIdentifier();
}

export function trackByValueFn(index: number, item: any) {
  return item;
}

export function trackByNameFn(index: number, item: any) {
  return item.name;
}

export function trackByKeyFn(index: number, item: any) {
  return item.key;
}

export function trackByTextFn(index: number, item: any) {
  return item.text;
}