import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { ToolsService } from '../services/tools.service';
import { UploadService } from './../services/upload.service';
import { UploadInputType } from './UploadInputType';

@Component({
  selector: 'app-ugau-upload-input',
  template: `
    <input
      id="file"
      #inputPhoto
      type="file"
      (change)="handleFileInput($event)"
      [accept]="accept"
    />
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UgauUploadInputComponent {
  @Input()
  @HostBinding('class.hidden')
  display: boolean = true;

  @Input() type: UploadInputType = 'camera'; // Par défaut, on prend la photo
  @Input() accept: '' | 'image/*' = '';

  @ViewChild('inputPhoto', { static: true }) public inputPhoto!: any;
  @Output() outputFile = new EventEmitter<any>();

  constructor(
    private tools: ToolsService,
    private upload: UploadService,
    private zone: NgZone
  ) {}

  triggerPromptFile() {
    if (this.tools.isMobile() && this.type === 'camera') {
      this.handleFileCamera();
    } else {
      this.inputPhoto.nativeElement.click();
    }
  }

  handleFileInput($event: any) {
    this.zone.runOutsideAngular(async () => {
      const b64 = await this.upload.handleFileInputV3($event, this.type);
      this.zone.run(() => this.outputFile.emit(b64));
    });
  }

  async handleFileCamera() {
    const b64 = await this.upload.handleCameraV3();
    this.outputFile.emit(b64);
  }
}
