import { Pipe, PipeTransform } from '@angular/core';
import { GroupesStateService } from '../state/groupes-state.service';

@Pipe({
  name: 'disableIfNotPremium',
  standalone: true,
})
export class DisableIfNotPremiumPipe implements PipeTransform {
  constructor(private groupeState: GroupesStateService) {}

  // Return true if the element should be disabled
  transform(value: any): boolean {
    try {
      return !this.groupeState.hasGroupePremium();
    } catch (error) {
      console.error('Error checking premium status', error);
      return true; // Disable by default in case of error
    }
  }
}
