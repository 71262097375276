import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { ToolsService } from './tools.service';
import { SentryService } from './sentry.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    public authenticationService: AuthenticationService,
    private tools: ToolsService
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (
          err.status === 401 &&
          err.error &&
          err.error.message == 'Invalid JWT Token'
        ) {
          // auto logout if 401 response returned from api
          this.tools.launchErrorAlert('Erreur de connexion au serveur');
        }

        const error = err.error?.message ? err.error.message : err.statusText;
        SentryService.throwError(error);
        return throwError(() => error);
      })
    );
  }
}
