import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { usersDocType } from './../../db/schemas/users.schema';
import packageInfo from '../../../../package.json';
import { UgauButtonComponent } from '../../components/ugau-button/ugau-button.component';
import { UgauInputComponent } from '../../components/ugau-input/ugau-input.component';
import { fromEvent, Subscription } from 'rxjs';
import { SentryService } from '../../services/sentry.service';

@Component({
  selector: 'app-my-account-user-info',
  template: `
    <app-ugau-input
      i18n-label
      label="Nom visible"
      [value]="user.displayName"
      [readOnly]="true"
    ></app-ugau-input>
    <app-ugau-input
      i18n-label
      label="Téléphone"
      [value]="user.phoneNumber || ''"
      [readOnly]="true"
    ></app-ugau-input>
    <app-ugau-input
      i18n-label
      label="Email"
      [value]="user.email"
      [readOnly]="true"
    ></app-ugau-input>

    <div class="d-flex-center flex-wrap">
      <app-ugau-button
        style="flex: 0 1 250px"
        class="m1"
        i18n-label
        label="Modifier mes informations"
        (click)="doElementSelected()"
        [fill]="true"
      ></app-ugau-button>
      <app-ugau-button
        style="flex: 0 1 250px"
        class="m1"
        i18n-label
        label="Changer l'adresse email"
        (click)="doChangeEmail()"
        [fill]="true"
      ></app-ugau-button>
      <app-ugau-button
        style="flex: 0 1 250px"
        class="m1"
        i18n-label
        label="Changer le mot de passe"
        (click)="doChangePassword()"
        [fill]="true"
      ></app-ugau-button>
    </div>
    <div #dblClickDiv class="p1 d-flex-center c-black" i18n>
      Version: {{ version }}
    </div>
  `,
  styles: [
    `
      :host {
        margin: 1em;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UgauInputComponent, UgauButtonComponent],
})
export class MyAccountUserInfoComponent implements AfterViewInit, OnDestroy {
  @Input() user!: usersDocType;

  @Output() changeInfo = new EventEmitter<void>();
  @Output() changeEmail = new EventEmitter<void>();
  @Output() changePassword = new EventEmitter<void>();

  version: string = packageInfo.version;

  private subscription!: Subscription;
  @ViewChild('dblClickDiv', { static: true }) dblClickDiv!: ElementRef;

  doElementSelected() {
    this.changeInfo.emit();
  }

  doChangeEmail() {
    this.changeEmail.emit();
  }

  doChangePassword() {
    this.changePassword.emit();
  }

  ngAfterViewInit() {
    this.subscription = fromEvent(
      this.dblClickDiv.nativeElement,
      'dblclick'
    ).subscribe(() => {
      SentryService.captureException(
        new Error('Double-clic détecté sur la div de version!')
      );
    });
  }

  ngOnDestroy() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
