export function transformDurationShortToObj(duration: string) {
  const regexCount = /[0-9]+/g;
  const regexType = /[a-zA-Z]+/g;

  const type = duration.match(regexType)?.[0];
  const count = duration.match(regexCount)?.[0];

  if (!type || !count) return;

  if (type === 'w') {
    return {
      weeks: parseInt(count),
    };
  } else if (type === 'M') {
    return {
      months: parseInt(count),
    };
  } else if (type === 'y') {
    return {
      years: parseInt(count),
    };
  }
  return {};
}

export function transformDurationShortToLibelle(duration: string) {
  const regexCount = /[0-9]+/g;
  const regexType = /[a-zA-Z]+/g;

  const type = duration.match(regexType)?.[0];
  const count = duration.match(regexCount)?.[0];

  if (!type || !count) return '';

  if (type === 'w') {
    return parseInt(count) > 1
      ? `${parseInt(count)} semaine(s)`
      : `${parseInt(count)} semaine`;
  } else if (type === 'M') {
    return `${parseInt(count)} mois`;
  } else if (type === 'y') {
    return parseInt(count) > 1
      ? `${parseInt(count)} années`
      : `${parseInt(count)} année`;
  }
  return '';
}
