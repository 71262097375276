import { Pipe, PipeTransform } from '@angular/core';
import {
  emplacementDocType,
  emplacementTagDocType,
} from '../../db/schemas/emplacement.schema';
import { TagService } from './../../services/tag/tag.service';
import { InterventionsStateService } from '../../state/interventions-state.service';
import { INTERVENTION_STATUS } from '../../components/intervention-page/intervention-form/INTERVENTION_STATUS';
import { tagDocType } from '../../db/schemas/tag.schema';
import { getEmplacementAddressString } from './getEmplacementAddressString';

@Pipe({
  name: 'emplacementZones',
  standalone: true,
})
export class EmplacementZonesPipe implements PipeTransform {
  constructor(
    private tagService: TagService,
    private interventionService: InterventionsStateService
  ) {}

  transform(
    emplacement: emplacementDocType | null,
    type: 'tag' | 'address' | undefined
  ): string {
    const tagString = doTagsParse(
      emplacement,
      this.tagService,
      this.interventionService
    );
    const addressString = getEmplacementAddressString(emplacement);

    if (type === 'tag') return tagString;
    if (type === 'address') return addressString;

    return `${tagString}\n${addressString}`;
  }
}

export function doTagsParseCommon(
  emplacement: emplacementDocType | null,
  tagService: TagService,
  interventionService: InterventionsStateService
): string | [string, tagDocType[]][] {
  const emplacementOrTagEmpty = !emplacement?.emplacement_tags.length;
  if (emplacementOrTagEmpty) {
    return $localize`Aucun dossier`;
  }

  const notDeletedEmplacementTags = emplacement.emplacement_tags.filter(
    (el: emplacementTagDocType) => {
      const tag = tagService.getTagById(el.id_tag);
      return !el.deleted_at && !tag?.deleted_at;
    }
  );

  if (!notDeletedEmplacementTags.length) {
    return $localize`Aucun dossier`;
  }

  // Création d'une Map pour stocker les tags par type, on ignore
  const tagsByType = new Map<string, tagDocType[]>();
  notDeletedEmplacementTags.forEach((emplacementTag: emplacementTagDocType) => {
    const tag = tagService.getTagById(emplacementTag.id_tag);
    if (!tag) return;

    const type = tag.type;
    // Pas de type, on ignore
    if (!type) return;

    if (type === 'intervention') {
      // Il faut vérifier lé statut de l'intervention
      const interventions = interventionService.getEmplacementsFromTagId(
        tag.id
      );
      const hasLinkedInterventionInProgress = interventions.some(
        (intervention) =>
          intervention.status !== INTERVENTION_STATUS.ARCHIVE.toString() &&
          intervention.status !== INTERVENTION_STATUS.TERMINE.toString()
      );
      if (!hasLinkedInterventionInProgress) return;
    }

    if (!tagsByType.has(type)) {
      tagsByType.set(type, []);
    }

    tagsByType.get(type)?.push(tag);
  });

  // Formatez l'objet pour obtenir la sortie désirée
  return Array.from(tagsByType.entries()).sort((a, b) =>
    a[0].localeCompare(b[0])
  ); // Triez par type
}

function doTagsParse(
  emplacement: emplacementDocType | null,
  tagService: TagService,
  interventionService: InterventionsStateService
): string {
  const result = doTagsParseCommon(
    emplacement,
    tagService,
    interventionService
  );

  if (typeof result === 'string') return result;

  return result
    .map(([type, tags]) => {
      const libelles = tags.map((tag) => tag.libelle);
      if (type)
        return `${type[0].toUpperCase() + type.slice(1)} : ${libelles.join(', ')}`;
      return libelles.join(', ');
    })
    .join('\n');
}
