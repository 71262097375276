import {
  AsyncPipe,
  JsonPipe,
  KeyValue,
  KeyValuePipe,
  NgFor,
  NgIf,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChildren,
} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { FormatNumberPipe } from '../../pipes/format-number.pipe';
import { trackByKeyFn } from '../../services/trackByFunctions.utils';
import { UserStateService } from '../../state/user-state.service';
import { CartStore } from '../../../../@types_custom/cart-store';
import { Observable } from 'rxjs';
import { compareOrderTousFirst } from '../../utils/sort-utils';
import { StatistiqueListComponent } from '../statistique/statistique-list.component';
import { UgauStatistiqueChartDonutContainer } from '../statistique/ugau-statistique-chart-donut/ugau-statistique-chart-donut.container';

@Component({
  selector: 'app-stats-tab-group',
  templateUrl: './stats-tab-groups.component.html',
  styles: [
    `
      :host {
        display: block;
        height: 100%;
        width: 100%;

        .statistique-first {
          height: 100%;
          display: flex;

          mat-tab-group {
            width: 100%;
          }
        }

        .diagTemplate {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }

        @media (max-width: 992px) {
          .diagTemplate {
            flex-direction: column;
            min-width: unset;
          }
        }
      }
    `,
  ],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatTabsModule,
    MatListModule,
    NgTemplateOutlet,
    MatExpansionModule,
    StatistiqueListComponent,
    KeyValuePipe,
    UgauStatistiqueChartDonutContainer,
    FormatNumberPipe,
    AsyncPipe,
    JsonPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsTabGroupComponent {
  private userStateService = inject(UserStateService);
  public trackByKey = trackByKeyFn;
  public canShowPrices =
    this.userStateService.checkIfUserIsOperatorOnlyInAllGroups();
  public canShowPrices$ = this.userStateService.canShowPrices$;

  @ViewChildren(MatTabGroup) tabGroups!: MatTabGroup[]; // Needed for parent to control/retrieve tab selection

  @Input() store$!: Observable<CartStore>;
  @Input() applyHeaderStyle = true;
  @Output() showConcernedEmplacement = new EventEmitter<string[]>();
  @Output() showConcernedComposant = new EventEmitter<string[]>();

  showEmplacement(event: string[]) {
    this.showConcernedEmplacement.emit(event);
  }
  showComposants(event: string[]) {
    this.showConcernedComposant.emit(event);
  }

  compareOrderLocal(e1: KeyValue<string, any>, e2: KeyValue<string, any>) {
    return compareOrderTousFirst(e1, e2);
  }
}
