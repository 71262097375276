import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import * as d3Shape from 'd3-shape';
import * as d3Selection from 'd3-selection';
import { v4 as uuidv4 } from 'uuid';
import { D3DatasType } from '@types_custom/d3-datas';

@Component({
    selector: 'app-ugau-chart-donut',
    template: `
    <figure
      [class]="className"
      [id]="'donut_' + id"
      class="donut-chart"
    ></figure>
  `,
    styles: [
        `
      .donut-chart {
        display: flex;
        justify-content: center;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class UgauChartDonutComponent implements OnChanges, AfterViewInit {
  @Input() data: D3DatasType[] | null = null;
  @Input() width = 450;
  @Input() height = 450;
  @Input() className = 'ugau-chart-donut';
  id: string = 'THE_ID_' + uuidv4();

  svg: d3Selection.Selection<SVGGElement, unknown, HTMLElement, any> | null =
    null;
  private margin = 10;
  // The radius of the pie chart is half the smallest side minus margin
  private radius = Math.min(this.width, this.height) / 2 - this.margin;
  // The radius for empty center is 1/5 of radius
  private innerRadius = this.radius / 5;
  // The radius for filling is 3.5/5 of radius
  private outerRadius = this.innerRadius * 3.5;

  itemsValueSum: number = 0;

  ngAfterViewInit(): void {
    // Si la vue est prête
    this.createDonut();
  }

  ngOnChanges(_changes: SimpleChanges) {
    // Si les données changent
    this.createDonut();
  }

  private createDonut() {
    if (!this.data?.length) {
      return;
    }

    this.data.sort((e1, e2) => (e1.key > e2.key ? 1 : -1));
    this.calcItemsValueSum();
    this.createSvg();
    this.drawChart();
  }

  calcItemsValueSum() {
    this.itemsValueSum =
      this.data?.reduce((acc: number, el: any) => (acc += el.value), 0) ?? 0;
  }

  createSvg() {
    this.removeSvg();

    this.svg = d3Selection
      .select('figure#donut_' + this.id)
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('id', this.id)
      .append('g')
      .attr(
        'transform',
        'translate(' + this.width / 2 + ',' + this.height / 2 + ')'
      );
  }

  private removeSvg() {
    if (this.svg) {
      d3.select('figure#donut_' + this.id + ' svg').remove();
    }
  }

  drawChart() {
    if (!this.data?.length || !this.svg) {
      return;
    }

    const pie = d3Shape.pie<any>().value((d: any) => Number(d.value));

    this.svg
      .selectAll('pieces')
      .data(pie(this.data))
      .enter()
      .append('path')
      .attr(
        'd',
        d3Shape
          .arc()
          .innerRadius(this.innerRadius)
          .outerRadius(this.outerRadius) as any
      )
      .attr('fill', (d: any, i: any): any => {
        return this.data?.[i].color;
      });

    // Add labels
    const labelLocation = d3Shape
      .arc()
      .innerRadius(this.innerRadius)
      .outerRadius(this.outerRadius);
    const pieValue = (d: any) =>
      ((d.endAngle - d.startAngle) * 100) / (2 * Math.PI);
    const accuratePieValue = (d: any) => parseInt(pieValue(d).toFixed(0));
    const pieLableArc = (d: any, i: number) =>
      d3Shape
        .arc()
        .innerRadius(i * 20)
        .outerRadius(this.outerRadius + i * 20);

    this.svg
      .selectAll('pieces')
      .data(pie(this.data))
      .enter()
      .append('text')
      .attr('class', 'test')
      .attr('transform', (d: any, i: number) => {
        let centroid_value = labelLocation.centroid(d);
        if (accuratePieValue(d) <= 5) {
          centroid_value = pieLableArc(d, i).centroid(d);
        }
        return 'translate(' + centroid_value + ')';
      })
      .style('text-anchor', 'middle')
      .style('stroke', 'black')
      .style('stroke-width', '.2em')
      .style('stroke-linejoin', 'round')
      .style('fill', 'white')
      .style('paint-order', 'stroke')
      .style('font-size', 15)
      .append('tspan')
      .attr('x', 0)
      .attr('y', 0)
      .append('tspan')
      .text((d: any) => `${d.data.line1} (${d.data.line2})`);

    this.svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .text(this.itemsValueSum)
      .style('fill', 'black')
      .style('font-size', 23);
  }
}
