export function objectToMap(obj: Record<string, any>): Map<string, any> {
  const map = new Map<string, any>();
  Object.entries(obj).forEach(([key, value]) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      // Recurse only for plain object values
      map.set(key, objectToMap(value));
    } else {
      // Directly set the value for arrays or other types
      map.set(key, value);
    }
  });
  return map;
}
