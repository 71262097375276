<div *ngIf="currentTag$ | async as currentTag">
  <!-- Header Section -->
  <div matSubheader class="c-black m1em d-flex flex-ac flex-sb pt1em pb1em">
    <app-ugau-button
      iconName="arrow_back"
      [hasBorder]="false"
      (click)="cancelCall()"
      label="Retour"
    ></app-ugau-button>

    <div class="ml1em d-flex flex-sb flex-ac fg-1">
      <span *ngIf="currentTag.libelle === ''" i18n>Nouveau dossier</span>
      <span *ngIf="currentTag.libelle !== ''" i18n>
        Détail "{{ currentTag.libelle }}"</span
      >

      <span
        *ngIf="(currentTag | hasWriteAccessOnTag | async) === false"
        class="c-red"
        i18n
        >(Lecture seule)</span
      >

      <app-ugau-button
        *ngIf="currentTag | hasWriteAccessOnTag | async"
        (click)="elementRemove(currentTag)"
        color="error"
        matTooltip="Supprimer"
        i18n-matTooltip
        iconName="delete"
        classColor="danger"
        label=""
        [hasBorder]="false"
      ></app-ugau-button>
    </div>
  </div>

  <!-- Common Input -->
  <app-ugau-input
    [readOnly]="(currentTag | tagbool: 'is_owner') === false"
    i18n-label
    label="Nom"
    appearance="outline"
    [value]="currentTag.libelle"
    (inputChange)="updateCurrentTag('libelle', $event)"
  ></app-ugau-input>

  <app-ugau-input-boolean
    i18n-label
    label="Archivé"
    [value]="currentTag.is_archived"
    (valueChange)="updateCurrentTagArchived('is_archived', $event)"
    [readonly]="(currentTag | tagbool: 'is_owner') === false"
  ></app-ugau-input-boolean>

  <!-- Conditional Sections Based on Action and Access Rights -->
  <ng-container
    *ngIf="currentTag | hasWriteAccessOnTag | async; else readonlySection"
  >
    <app-ugau-input
      *ngIf="currentTag.id_user"
      [readOnly]="true"
      i18n-label
      label="Utilisateur propriétaire"
      appearance="outline"
      [value]="currentTag.id_user | user: 'displayName'"
    ></app-ugau-input>

    <app-ugau-select-tag-groupe
      i18n-label
      label="Autoriser l'accès/gestion"
      [currentGroupe]="currentTag.id_groupe"
      (groupeChange)="updateCurrentTag('id_groupe', $event)"
      [allowEmpty]="true"
    ></app-ugau-select-tag-groupe>

    <app-ugau-select-tag-type
      [currentType]="currentTag.type"
      (typeChange)="updateCurrentTag('type', $event)"
    ></app-ugau-select-tag-type>

    <app-tag-meta
      [metas]="currentTag.tag_meta || []"
      (metaChange)="updateCurrentTag('tag_meta', $event)"
      [readonly]="false"
    ></app-tag-meta>

    <div matSubheader class="c-black d-flex flex-ac flex-sb m1em">
      <span i18n>Partagé avec</span>
      <app-ugau-button
        i18n-label
        label=""
        iconName="add"
        (click)="addTagShare()"
        [fill]="true"
      ></app-ugau-button>
    </div>

    <app-tag-form-shared-tags
      [tag]="currentTag"
      [sharedTags]="currentTag.shared_tags"
      (sharedTagsChange)="updateCurrentTag('shared_tags', $event)"
      [readonly]="false"
    ></app-tag-form-shared-tags>
  </ng-container>

  <!-- Read Only Section -->
  <ng-template #readonlySection>
    <app-ugau-input
      *ngIf="currentTag.id_user"
      [readOnly]="true"
      i18n-label
      label="Utilisateur propriétaire"
      appearance="outline"
      [value]="currentTag.id_user | user: 'displayName'"
    ></app-ugau-input>

    <app-ugau-input
      [readOnly]="true"
      [label]="(libelleGroupeCaseUpdate$ | async) || ''"
      appearance="outline"
      [value]="currentTag.id_groupe | tag: 'libelle'"
    ></app-ugau-input>

    <app-tag-meta
      [metas]="currentTag.tag_meta || []"
      (metaChange)="updateCurrentTag('tag_meta', $event)"
      [readonly]="true"
    ></app-tag-meta>

    <div matSubheader class="c-black m1em" i18n>Partagé avec</div>
    <app-tag-form-shared-tags
      [tag]="currentTag"
      [sharedTags]="currentTag.shared_tags"
      (sharedTagsChange)="updateCurrentTag('shared_tags', $event)"
      [readonly]="true"
    ></app-tag-form-shared-tags>
  </ng-template>

  <!-- Action Buttons -->
  <div class="d-flex-center">
    <app-ugau-button-annuler-valider
      [onCancel]="cancelCall"
      [onValid]="elementModifyCall"
      [disableValid]="(currentTag | hasWriteAccessOnTag | async) === false"
    ></app-ugau-button-annuler-valider>
  </div>
</div>
