/// <reference types="@angular/localize" />

import {
  enableProdMode,
  ErrorHandler,
  APP_INITIALIZER,
  importProvidersFrom,
} from '@angular/core';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import packageInfo from '../package.json';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app/app-routing.module';
import { Platform } from '@ionic/angular/standalone';
import { DatePipe, CommonModule } from '@angular/common';
import { CustomUrlSerializer } from './app/custom-url-serializer';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { Router, UrlSerializer, provideRouter } from '@angular/router';
import { JwtInterceptorService } from './app/services/jwt-interceptor.service';
import { ErrorInterceptorService } from './app/services/error-interceptor.service';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import '@angular/compiler';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';

if (environment.ENABLE_SENTRY) {
  console.log('Sentry enable');
  Sentry.init({
    release: 'ugau@' + packageInfo.version,
    environment: environment.environment.libelle,
    dsn: 'https://50f188d543d746cb861be13cf9f7044d@o478666.ingest.sentry.io/5521509',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    normalizeDepth: 5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (environment.environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      AppRoutingModule,
      ReactiveFormsModule,
      FormsModule,
      BrowserModule,
      CommonModule,
      MatSnackBarModule,
      MatDialogModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.environment.production,
      }),
      MatDatepickerModule,
      MatNativeDateModule,
      MatFormFieldModule
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    Deploy,
    DatePipe,
    Platform,
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter([]),
    provideAnimations(),
  ],
}).catch((err) => console.log(err));
