import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { COLORS_BTN } from './../../enum/colors';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { trackByGetIdentifierFn } from '../../services/trackByFunctions.utils';

@Component({
  selector: 'app-ugau-input-autocomplete-objects',
  template: `
    <mat-form-field
      class="ugau-input-autocomplete"
      [appearance]="appearance"
      floatLabel="always"
    >
      <mat-label>{{ label }}</mat-label>
      <input
        *ngIf="type !== 'textarea'"
        [type]="type"
        #input
        matInput
        [placeholder]="placeholder"
        [readonly]="readOnly"
        [matAutocomplete]="auto"
        [formControl]="myControl"
      />
      <textarea
        *ngIf="type === 'textarea'"
        #input
        matInput
        [placeholder]="placeholder"
        [readonly]="readOnly"
        [matAutocomplete]="auto"
        [formControl]="myControl"
      ></textarea>

      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
        [displayWith]="getLibelle"
      >
        <mat-option
          *ngFor="
            let option of filteredOptions$ | async;
            trackBy: trackByGetIdentifier
          "
          [value]="option"
        >
          {{ option.getLibelle() }}
        </mat-option>
      </mat-autocomplete>

      <mat-icon
        matSuffix
        *ngIf="myControl.value && !readOnly"
        (click)="$event.stopPropagation(); clearInput()"
        class="clear-input"
        >close</mat-icon
      >
      <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: flex;

        .ugau-input-autocomplete {
          padding-left: 16px;
          padding-right: 16px;
          width: 100%;
        }
        &[data-color='valid'] {
          ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
            color: var(--valid-regular);
          }
          ::ng-deep .mat-select-value,
          ::ng-deep .mat-form-field-label {
            color: var(--valid-regular);
          }
        }
        &[data-color='warning'] {
          ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
            color: var(--warning-regular);
          }
          ::ng-deep .mat-select-value,
          ::ng-deep .mat-form-field-label {
            color: var(--warning-regular);
          }
        }
        &[data-color='danger'] {
          ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
            color: var(--error-regular);
          }
          ::ng-deep .mat-select-value,
          ::ng-deep .mat-form-field-label {
            color: var(--error-regular);
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgIf,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    MatOptionModule,
    MatIconModule,
    AsyncPipe,
  ],
})
export class UgauInputAutocompleteObjectsComponent implements OnInit {
  trackByGetIdentifier = trackByGetIdentifierFn;

  @Input() type: string = 'text';
  @Input() value!: IAutocompleteItem | null;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() readOnly: boolean = false;
  @Input() options: IAutocompleteItem[] = [];
  filteredOptions$!: Observable<IAutocompleteItem[]>;

  myControl = new UntypedFormControl('inputAutocomplete');

  @Input()
  @HostBinding('attr.data-color')
  classColor: COLORS_BTN = 'primary';

  @Output() inputChange = new EventEmitter<IAutocompleteItem | null>();

  @ViewChild('input') inputRef!: ElementRef;

  @Input() timeDebounceBlur: number = 400;
  @Input() timeDebounceKeyup: number = 2000;

  private _errorMessage: string | null = null;
  @Input() set errorMessage(value: string | null) {
    this._errorMessage = value;
    if (value) this.myControl.setErrors({ incorrect: true });
  }
  get errorMessage() {
    return this._errorMessage;
  }

  getLibelle(option: IAutocompleteItem | null) {
    if (!option || typeof option === 'string') return '';
    return option.getLibelle();
  }

  clearInput() {
    this.myControl.setValue(null);
    this.inputChange.emit(null);
  }

  ngOnInit(): void {
    if (this.value) this.myControl.setValue(this.value);
    this.filteredOptions$ = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  selected($event: MatAutocompleteSelectedEvent) {
    this.inputChange.emit($event.option.value);
    this.inputRef.nativeElement.blur();
  }

  private _filter(value: string | IAutocompleteItem): IAutocompleteItem[] {
    if (!value) return this.options;

    if (typeof value === 'object')
      return this.options.filter((option) =>
        option
          .getLibelle()
          .trim()
          .toLowerCase()
          .includes(value.getLibelle().trim().toLowerCase())
      );

    const filterValue = value.trim().toLowerCase();
    return this.options.filter((option) =>
      option.getLibelle().trim().toLowerCase().includes(filterValue)
    );
  }
}

export interface IAutocompleteItem {
  item: any;
  getLibelle: () => string;
  getIdentifier: () => string;
}
