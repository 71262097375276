<mat-accordion *ngIf="expandedStates$ | async as expandedStates">
  <mat-expansion-panel
    *ngFor="
      let shared_tag of sharedTags$ | async;
      let i = index;
      trackBy: trackById
    "
    [expanded]="expandedStates[i]"
    [disabled]="true"
  >
    <mat-expansion-panel-header
      (click)="togglePanel(i)"
      [style.cursor]="readonly ? 'default' : 'pointer'"
    >
      <mat-panel-title> Partage {{ i + 1 }} </mat-panel-title>
      <mat-panel-description>
        <span *ngIf="shared_tag.target_user_id">
          {{ shared_tag.target_user_id | user: 'displayName' }}
        </span>
        <span *ngIf="shared_tag.target_group_id">
          {{ shared_tag.target_group_id | groupe: 'libelle' }}
        </span>
        <span *ngIf="!shared_tag.target_user_id && !shared_tag.target_group_id">
          -
        </span>
        <span
          [class]="
            'status-indicator ' + (shared_tag | sharedTag: 'status_class_color')
          "
        ></span>
      </mat-panel-description>
      <span *ngIf="!readonly">
        <mat-icon>{{
          expandedStates[i] === true
            ? 'keyboard_arrow_up'
            : 'keyboard_arrow_down'
        }}</mat-icon>
      </span>
    </mat-expansion-panel-header>

    <div class="shared_tag_edit_list">
      <mat-form-field
        appearance="outline"
        class="source-user-field ml1em mr1em"
      >
        <mat-label i18n>Appartient à l'utilisateur</mat-label>
        <input
          matInput
          value="{{ shared_tag.source_user_id | user: 'displayName' }}"
          disabled
        />
      </mat-form-field>

      <app-ugau-select-tag-groupe
        class="target-group-field"
        i18n-label
        label="Partagé au groupe"
        [currentGroupe]="shared_tag.target_group_id"
        (groupeChange)="updateSharedTagByIndex('target_group_id', $event, i)"
        [readOnly]="readonly"
      ></app-ugau-select-tag-groupe>

      <app-ugau-select-user
        class="target-user-field"
        i18n-label
        label="Partagé à l'utilisateur"
        [currentUser]="shared_tag.target_user_id"
        (userChange)="updateSharedTagByIndex('target_user_id', $event, i)"
        [readOnly]="readonly"
      ></app-ugau-select-user>

      <mat-checkbox
        class="active-field"
        (change)="updateSharedTagByIndex('active', $event.checked, i)"
        (click)="$event.stopPropagation()"
        [checked]="shared_tag.active"
        [disabled]="readonly"
        i18n
        >Activé</mat-checkbox
      >
      <mat-checkbox
        class="readonly-field"
        (change)="updateSharedTagByIndex('readonly', $event.checked, i)"
        (click)="$event.stopPropagation()"
        [checked]="shared_tag.readonly"
        [disabled]="readonly"
        i18n
        >Lecture seule</mat-checkbox
      >

      <app-ugau-date
        class="expires-at-field"
        [date]="shared_tag.expires_at"
        i18n-placeholder
        placeholder="Fin de partage (optionnel)"
        (emitSelectedItems)="
          updateSharedTagByIndex('expires_at', $event?.date, i)
        "
        [readonly]="readonly"
      ></app-ugau-date>

      <app-ugau-button
        class="delete-field"
        iconName="delete"
        label=""
        classColor="danger"
        *ngIf="!readonly"
        (click)="removeSharedTag(shared_tag)"
      >
      </app-ugau-button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
