import { Pipe, PipeTransform } from '@angular/core';
import { ProductpropertiesStateService } from './../../state/productproperties-state.service';
import { isConformeEmplacement } from '../../utils/emplacement/isConformeEmplacement';
import { emplacementDocType } from '../../db/schemas/emplacement.schema';
import { ProductfieldsStateService } from '../../state/productfields-state.service';
import { ReglementationsStateService } from './../../state/reglementations-state.service';

@Pipe({
    name: 'isEmplacementConforme',
    standalone: true,
})
export class IsEmplacementConformePipe implements PipeTransform {
  constructor(
    private propertiesState: ProductpropertiesStateService,
    private productfieldsState: ProductfieldsStateService,
    private reglementationsState: ReglementationsStateService
  ) {}

  transform(emplacement: emplacementDocType | null): boolean {
    return isConformeEmplacement(
      emplacement,
      this.propertiesState.getProductproperties(),
      this.productfieldsState.getProductfields(),
      this.reglementationsState.getReglementations()
    );
  }
}
