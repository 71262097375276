import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { COLORS_BTN } from '../../../enum/colors';
import { UgauButtonAnnulerValiderContainer } from '../../ugau-button-annuler-valider/ugau-button-annuler-valider.container';

@Component({
  selector: 'app-dialog-prompt-bool',
  template: `
    <h1 mat-dialog-title>
      {{ title }}
    </h1>

    <mat-dialog-content class="mat-typography">
      <p>{{ data.data }}</p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <app-ugau-button-annuler-valider
        [libelleCancel]="labelBtnAnnuler"
        [libelleValid]="labelBtnValider"
        colorCancel="primary"
        [colorValid]="colorValid"
        [onCancel]="onCancel"
        [onValid]="onValid"
      ></app-ugau-button-annuler-valider>
    </mat-dialog-actions>
  `,
  styles: [
    `
      :host {
        .mat-typography {
          white-space: pre-line;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, UgauButtonAnnulerValiderContainer],
})
export class DialogPromptBoolComponent {
  labelBtnAnnuler = $localize`Annuler`;
  labelBtnValider = $localize`Supprimer`;
  title: string | undefined = $localize`Suppression`;

  colorValid: COLORS_BTN = 'danger';

  constructor(
    public dialogRef: MatDialogRef<DialogPromptBoolComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: string;
      title?: string;
      labelBtnAnnuler?: string;
      labelBtnValider?: string;
      colorValid?: COLORS_BTN;
    }
  ) {
    if (data.labelBtnAnnuler) this.labelBtnAnnuler = data.labelBtnAnnuler;
    if (data.labelBtnValider) this.labelBtnValider = data.labelBtnValider;
    if (data.title) this.title = data.title;
    if (data.colorValid) this.colorValid = data.colorValid;
  }

  onCancel = () => {
    this.dialogRef.close(false);
  };

  onValid = () => {
    this.dialogRef.close(true);
  };
}
