import { ProductFamiliesType } from '@types_custom/ProductType';

const CACHE = new Map<string, string[]>();

const childrenKey = 'children_productfamilies';

const findPath = (
  productId: string,
  families: ProductFamiliesType[]
): string[] => {
  const path: string[] = [];

  for (const [index, family] of families.entries()) {
    if (!family.products.find((p) => p.product.id === productId)) {
      // Le produit ne se trouve pas dans la famille actuelle

      if (family[childrenKey] && family[childrenKey].length > 0) {
        // La famille actuelle a des enfants
        const productPath = findPath(productId, family[childrenKey]);

        if (productPath.length > 0) {
          // Le produit se trouve dans la famille enfant
          path.push(index.toString());
          path.push(childrenKey);
          path.push(...productPath);
          break;
        }
      }
    } else {
      //Le produit est trouvé
      path.push(index.toString());
      break;
    }
  }

  return path;
};

export const getProductPathFromCache = (
  productId: string | undefined,
  families: ProductFamiliesType[]
) => {
  if (!productId) return [];

  const familyIds = families.map((e) => e.id + e.name).join('.');
  const cacheProductId = familyIds + ' ' + productId;

  const cached = CACHE.get(cacheProductId);
  if (cached) return cached;

  const path = findPath(productId, families);
  if (path.length > 0) CACHE.set(cacheProductId, path);

  return path;
};
