import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FocusService {
  private focusedElementId$ = new BehaviorSubject<string | null>(null);

  // Méthode pour mettre à jour l'ID de l'élément focalisé
  setFocusedElement(elementId: string) {
    this.focusedElementId$.next(elementId);
  }

  // Observable pour suivre l'élément focalisé
  getFocusedElement() {
    return this.focusedElementId$.asObservable();
  }
}