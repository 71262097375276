<div
  class="d-flex-tag-filters"
  [ngClass]="{ 'is-mobile': isMobile, 'is-hidden': !showFilters }"
>
  <app-ugau-button
    *ngIf="isMobile"
    [label]="
      showFilters
        ? 'Masquer les filtres' +
          (filterDictionary.size !== 0
            ? ' (' + filterDictionary.size + ')'
            : '')
        : 'Afficher les filtres' +
          (filterDictionary.size !== 0
            ? ' (' + filterDictionary.size + ')'
            : '')
    "
    (click)="showFilters = !showFilters"
    [freeHeight]="true"
    class="mr1em ml1em"
  >
  </app-ugau-button>

  <div *ngFor="let filter of filterSelectObj; trackBy: trackByName">
    <app-ugau-input-autocomplete
      [value]="filter.modelValue"
      [label]="columnDefs[filter.columnProp].title"
      [options]="filter.options"
      (inputChange)="filterChange(filter, $event)"
      [disableSubWrapper]="true"
      [timeDebounce]="0"
    >
    </app-ugau-input-autocomplete>
  </div>

  <app-ugau-button
    class="ml1em"
    label=""
    classColor="warning"
    iconName="refresh"
    (click)="resetFilters()"
    [freeHeight]="true"
  >
  </app-ugau-button>

  <app-ugau-button
    i18n-label
    class="ml1em mr1em"
    label=""
    [matMenuTriggerFor]="menu"
    iconName="more_vert"
    [hasBorder]="false"
    [freeHeight]="true"
  >
  </app-ugau-button>

  <mat-menu #menu="matMenu">
    <div class="itemMenu" mat-menu-item>
      <app-ugau-button
        i18n-label
        label="Export"
        [hasBorder]="false"
        (click)="export()"
      >
      </app-ugau-button>
    </div>
  </mat-menu>
</div>

<table
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8 stock-table"
  matSort
>
  <!-- Name -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Libelle
      <app-ugau-button
        class="m1em"
        label=""
        classColor="valid"
        iconColor="valid"
        iconName="add"
        (click)="$event.stopPropagation(); addNewBatiment()"
      >
      </app-ugau-button>
    </th>
    <td mat-cell *matCellDef="let emplacement">
      <app-ugau-button
        [label]="emplacement | emplacement: 'libelle'"
        [hasBorder]="false"
        (click)="editBatiment(emplacement)"
        textAlign="left"
      ></app-ugau-button>
    </td>
  </ng-container>

  <!-- Created At Column -->
  <ng-container matColumnDef="conforme">
    <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Conforme</th>
    <td mat-cell *matCellDef="let stock">
      {{ (stock | isEmplacementConforme) ? 'Oui' : 'Non' }}
    </td>
  </ng-container>

  <!-- Created At Column -->
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Création</th>
    <td mat-cell *matCellDef="let stock">
      {{ stock.created_at | date: formatFULL }}
    </td>
  </ng-container>

  <!-- Updated At Column -->
  <ng-container matColumnDef="updated_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>MAJ</th>
    <td mat-cell *matCellDef="let stock">
      {{ stock.updated_at | date: formatFULL }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  [pageSizeOptions]="PAGE_SIZE_OPTIONS"
  [pageSize]="PAGE_SIZE"
  showFirstLastButtons
  (page)="PAGE_CHANGED($event)"
/>