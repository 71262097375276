import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  reglementationDocType,
  reglementationChoixDocType,
} from '../db/schemas/reglementation.schema';
import { DatabaseService } from '../db/database.service';

@Injectable({
  providedIn: 'root',
})
export class ReglementationsStateService {
  reglementationProvider = inject(DatabaseService).getReglementationProvider();

  reglementations$: Observable<reglementationDocType[]> =
    this.reglementationProvider.getAll$;

  getReglementations() {
    return this.reglementationProvider.getAllValue();
  }

  getReglementationById(reglementationId: number | string) {
    return this.reglementationProvider
      .getAllValue()
      .find((el) => el.id === reglementationId.toString());
  }

  getReglementationChoixById(
    reglementationChoixId: number | string
  ): reglementationChoixDocType | undefined {
    return this.reglementationProvider
      .getAllValue()
      .reduce((acc: undefined | reglementationChoixDocType, el) => {
        const regChoix = el.reglementation_choixes.find(
          (choix) => choix.id.toString() === reglementationChoixId.toString()
        );
        if (regChoix) acc = regChoix;
        return acc;
      }, undefined);
  }
}
