import { Injectable } from '@angular/core';
import { AbstractProvider } from './abstract-provider';
import { QUERY_GET_REGLEMENTATIONS } from '../queries/reglementation.queries';
import {
  reglementationDocType,
  reglementationSchema,
  reglementationSchemaLiteral,
} from '../schemas/reglementation.schema';
import { lastOfArray, ReplicationPullHandlerResult } from 'rxdb';
import { graphql } from './../../services/nhost';
import { Checkpoint } from '../interfaces/Checkpoint.type';

@Injectable({
  providedIn: 'root',
})
export class ReglementationProvider extends AbstractProvider<reglementationDocType> {
  public schema = reglementationSchema;
  public schemaLiteral = reglementationSchemaLiteral;
  public enablePush = false;
  public BATCH_SIZE = 5000;

  protected migrationStrategies = {
    1: function (oldDoc: reglementationDocType) {
      return null;
    },
    2: function (oldDoc: reglementationDocType) {
      return null;
    },
  };

  static productTree: object[];

  async getPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<reglementationDocType, Checkpoint>> {
    const { data, error } = await graphql.request(QUERY_GET_REGLEMENTATIONS, {
      where: {
        _or: this.getOrForQuery(lastCheckpoint),
      },
      order_by: this.getOrderByForQuery(),
      limit: this.BATCH_SIZE,
    });

    if (error || !data.reglementation) {
      console.log(
        `Erreur lors de la récupération des réglementations sur le serveur`
      );
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.reglementation;

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }

  public pullQueryModifier(doc: any) {
    // @TODO : Faut me passer en uuid, ça va être génial ! <_>
    doc.id = doc.id.toString();
    return doc;
  }
}
