import { ComposantProductType } from '@types_custom/composant';
import { PRODUCT_PROPERTIES_NAME } from '../../enum/product-properties-name';
import { getComposantFieldValue } from '../composant/getComposantFieldValue';
import { getProductPropertyValueByName } from '../productproperties-utils.service';
import { PRODUCTFIELD_TYPE } from '../../enum/product-fields-type';
import { productpropertiesDocType } from '../../db/schemas/productproperties.schema';
import { productfieldDocType } from '../../db/schemas/productfields.schema';
import { productDocType, composantDocType } from '../../db/schemas/emplacement.schema';
import {
  getMaxAgeClasse1,
  getMaxAgeClasse2,
  getMaxAgeClasse3
} from '../composant/getMaxAgeByClasse';


export function isOkConformiteAge(
  composant: composantDocType,
  product: productDocType | ComposantProductType,
  productfields: productfieldDocType[],
  properties: productpropertiesDocType[]
) {
  const dateFabrication = getComposantFieldValue(
    composant,
    PRODUCTFIELD_TYPE.DATE_FABRICATION,
    productfields
  );
  const classe = getProductPropertyValueByName(
    product,
    PRODUCT_PROPERTIES_NAME.CLASSE,
    properties
  );

  if (!dateFabrication || !classe) return true;

  const maxAge = classe.includes('1')
    ? getMaxAgeClasse1()
    : classe.includes('2')
      ? getMaxAgeClasse2()
      : classe.includes('3')
        ? getMaxAgeClasse3()
        : undefined;
  if (!maxAge) return true;

  const dateRenew = new Date(dateFabrication);
  dateRenew.setFullYear(dateRenew.getFullYear() + maxAge);
  return dateRenew > new Date();
}
