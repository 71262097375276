import { MapboxStyleDefinition } from "mapbox-gl-style-switcher";
import { getStylesList } from "./mapboxStylesList";

/**
 * Retrieves a Mapbox style definition by its name.
 *
 * @param {string | null} name - The name of the style to retrieve. If null, no style will be retrieved.
 * @param {boolean} [isMobile=false] - A flag indicating whether to retrieve styles for mobile. Defaults to false.
 * @returns {MapboxStyleDefinition | null} - The style definition if found, otherwise null.
 */
export function retrieveStylebyName(
  name: string | null,
  isMobile = false
): MapboxStyleDefinition | null {
  const styles = getStylesList(isMobile);
  return styles.find((style) => style.title === name) ?? null;
}
