import {
  Component,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  Input,
  inject,
} from '@angular/core';
import { Observable } from 'rxjs';
import { NgFor, NgIf, AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { tagDocType } from '../../../db/schemas/tag.schema';
import { formatFULL } from '../../../pipes/date-formats';
import { CountEmplacementTagPipe } from '../../../pipes/tags/count-emplacement-tag.pipe';
import { HasEmplacementTagPipe } from '../../../pipes/tags/has-emplacement-tag.pipe';
import { HasWriteAccessOnTagPipe } from '../../../pipes/tags/has-write-access-on-tag.pipe';
import { TagPipe } from '../../../pipes/tags/tag.pipe';
import { TagListColumnNamePipe } from '../../../pipes/tags/TagListColumnName.pipe';
import { getUserID } from '../../../services/nhost';
import { initNewTag } from '../../../services/tag/initNewTag';
import { ToolsService } from '../../../services/tools.service';
import { trackByNameFn } from '../../../services/trackByFunctions.utils';
import { UsersStateService } from '../../../state/users-state.service';
import { UgauButtonComponent } from '../../ugau-button/ugau-button.component';
import { UgauInputAutocompleteComponent } from '../../ugau-input-autocomplete/ugau-input-autocomplete.component';
import { getDisplayedColumnsForTagsList } from '../getDisplayedColumnsForTagsList';
import { TagsActionsCommonService } from '../tags-actions-common.service';
import { MatMenuModule } from '@angular/material/menu';
import {
  CalendarType,
  CalendarEvent,
  sendEventToCalendar,
} from './send-event-to-calendar';
import { GroupesStateService } from '../../../state/groupes-state.service';
import { tagGenerateEventDescription } from './tag-generate-event-description';
import { RoutingState } from '../../../state/routing-state.service';
import { TableComponentBase } from '../../table-base.component';
import { InterventionsStateService } from '../../../state/interventions-state.service';
import { getInterventionStatusString } from '../../../pipes/interventions/intervention.pipe';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    CountEmplacementTagPipe,
    DatePipe,
    HasEmplacementTagPipe,
    HasWriteAccessOnTagPipe,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    NgClass,
    NgFor,
    NgIf,
    TagListColumnNamePipe,
    TagPipe,
    UgauButtonComponent,
    UgauInputAutocompleteComponent,
  ],
})
export class TagsListComponent extends TableComponentBase<tagDocType> {
  private usersState = inject(UsersStateService);
  private groupesState = inject(GroupesStateService);
  private tagQuickCommonService = inject(TagsActionsCommonService);
  private routingState = inject(RoutingState);
  private interventionState = inject(InterventionsStateService);

  private users = this.usersState.getUsersFromGroupes();
  private groupes = this.usersState.getGroupes();

  displayedColumns!: string[];
  @Input() noAddButton = false;
  @Input() data$!: Observable<tagDocType[]>;
  _type!: 'tag' | 'intervention';
  @Input() set type(value: 'tag' | 'intervention') {
    this._type = value;
    this.displayedColumns = getDisplayedColumnsForTagsList(
      this._type,
      this.isMobile
    );
    this.loadStoredFilters();
  }
  get type() {
    return this._type;
  }

  trackByName = trackByNameFn;
  isMobile = inject(ToolsService).isMobile();
  showFilters = !this.isMobile;

  formatFULL = formatFULL;
  exceptSearchForColumns: string[] = [
    'actions',
    'emplacements',
    'shared',
    'created_at',
  ];
  additionnalFilters = ['shared_users_or_group'];
  filterSelectObj: any = [];
  filterDictionary = new Map<string, string>();
  columnDefs: Record<string, { title: string }> = {
    libelle: {
      title: $localize`Libellé`,
    },
    type: {
      title: $localize`Type`,
    },
    user_libelle: {
      title: $localize`Utilisateur propriétaire`,
    },
    groupe_libelle: {
      title: $localize`Groupe propriétaire`,
    },
    is_archived: {
      title: $localize`Archivé`,
    },
    shared_users_or_group: {
      title: $localize`Partagé avec`,
    },
    intervention_status: {
      title: $localize`Statut`,
    },
  };

  dataSource = new MatTableDataSource<tagDocType>();

  @Output() tagSelected = new EventEmitter<tagDocType | null>();

  get key() {
    return 'tags_' + this.type;
  }

  elementShowOnMap(element: tagDocType) {
    this.tagQuickCommonService.applyTagFilter(element);
  }

  elementSelected(element: tagDocType) {
    if (this.type === 'intervention')
      this.routingState.navigateIntervention(element.id);

    if (this.type === 'tag') this.routingState.navigateTag(element.id);
  }

  initializeNewElement() {
    // this.updateAction.emit('create');
    const newTag: tagDocType = initNewTag({
      id_user: getUserID(),
      type: 'zone',
      libelle: '',
    });
    this.tagSelected.emit(newTag);
  }

  elementRemove(tag: tagDocType) {
    this.tagQuickCommonService.confirmTagDeletion(tag);
  }

  shareToCalendar(tag: tagDocType, calendarType: CalendarType): void {
    // Start date is the current date
    let startDate = new Date();
    // Or the date_debut tag meta
    const tagMetaStartDate = tag.tag_meta?.find(
      (meta) => meta.type === 'date_debut'
    );
    if (tagMetaStartDate?.value) {
      startDate = new Date(tagMetaStartDate.value);
    }

    // End date is the start date + 1 hour
    let endDate = new Date(startDate.getTime() + 60 * 60 * 1000);
    // Or the date_fin tag meta
    const tagMetaEndDate = tag.tag_meta?.find(
      (meta) => meta.type === 'date_fin'
    );
    if (tagMetaEndDate?.value) {
      endDate = new Date(tagMetaEndDate.value);
    }

    const link = this.routingState.createUrlTreeTag(tag.id);

    const event: CalendarEvent = {
      id: tag.id,
      title: tag.libelle,
      startDate,
      endDate,
      description: tagGenerateEventDescription(
        tag,
        this.usersState.getUsersFromGroupes(),
        this.groupesState.getGroupes(),
        link
      ),
      location: '',
    };
    sendEventToCalendar(calendarType, event);
  }

  customSortingDataAccessor(data: tagDocType, sortHeaderId: string) {
    switch (sortHeaderId) {
      case 'is_archived':
        const isArchived = data.is_archived && !data.deleted_bool;
        return isArchived ? 'Oui' : 'Non';
      case 'intervention_status':
        const intervention = this.interventionState
          .getInterventions()
          .find((el) => el.id_tag === data.id);
        return intervention ? getInterventionStatusString(intervention) : '';
      case 'shared_users_or_group':
        const shared = data.shared_tags ?? [];
        const sharedUsers = shared.map((el: any) => {
          const userTarget = this.users.find(
            (user) => user.id === el.target_user_id
          );
          return userTarget?.displayName;
        });
        const sharedGroups = shared.map((el: any) => {
          const groupTarget = this.groupes.find(
            (group) => group.id === el.target_group_id
          );
          return groupTarget?.libelle;
        });
        return [...sharedUsers, ...sharedGroups].join(', ');
      default:
        return (data as any)[sortHeaderId];
    }
  }

  customCreateFilter(
    data: tagDocType,
    key: string,
    value: string
  ): boolean | undefined {
    if (key === 'is_archived' && value) {
      const isArchived = data.is_archived && !data.deleted_bool;
      return isArchived ? 'Oui' === value : 'Non' === value;
    }

    if (key === 'intervention_status' && value) {
      const intervention = this.interventionState
        .getInterventions()
        .find((el) => el.id_tag === data.id);
      return intervention
        ? getInterventionStatusString(intervention) === value
        : false;
    }

    if (key === 'shared_users_or_group' && value) {
      const shared = data.shared_tags ?? [];
      const sharedUsers = shared.map((el) => {
        const userTarget = this.users.find(
          (user) => user.id === el.target_user_id
        );
        return userTarget?.displayName;
      });
      const sharedGroups = shared.map((el) => {
        const groupTarget = this.groupes.find(
          (group) => group.id === el.target_group_id
        );
        return groupTarget?.libelle;
      });
      return sharedUsers.includes(value) || sharedGroups.includes(value);
    }
    return undefined;
  }

  customGetFilterObject(
    data: tagDocType,
    key: string
  ): string[] | string | undefined {
    if (key === 'is_archived') {
      // Le tag est archivé si la propriété is_archived est à true et que la propriété deleted_bool est à false
      const isArchived = data.is_archived && !data.deleted_bool;
      return isArchived ? 'Oui' : 'Non';
    }
    if (key === 'intervention_status') {
      const intervention = this.interventionState
        .getInterventions()
        .find((el) => el.id_tag === data.id);
      return intervention ? getInterventionStatusString(intervention) : '';
    }
    if (key === 'shared_users_or_group' && data.shared_tags) {
      const sharedUsers = data.shared_tags.map((el) => {
        const userTarget = this.users.find(
          (user) => user.id === el.target_user_id
        );
        return userTarget?.displayName ?? '';
      });
      const sharedGroups = data.shared_tags.map((el) => {
        const groupTarget = this.groupes.find(
          (group) => group.id === el.target_group_id
        );
        return groupTarget?.libelle ?? '';
      });
      return [...sharedUsers, ...sharedGroups];
    }

    return undefined;
  }
}
