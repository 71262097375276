import { PhotoResizerService } from './image-resizer.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Injectable } from '@angular/core';
import { ToolsService } from './tools.service';
import { SentryService } from './sentry.service';
import { UploadInputType } from '../components/UploadInputType';
import { getB64stringFromCapacitorGetPhoto } from './getB64stringFromCapacitorGetPhoto';
import { getBase64Size } from './utils.service';
import { CapacitorException } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  sizeInMbForCapacitor = 1;

  constructor(
    private resizer: PhotoResizerService,
    private tools: ToolsService
  ) {}

  public async handleCameraV3() {
    const image = await this.takePictureCamera();

    if (image?.base64String) {
      const base64String = getB64stringFromCapacitorGetPhoto(
        image.base64String,
        image.format
      );

      const sizeInMB = this.resizer.getB64SizeInMb(base64String);

      console.log(`Original Image Size: ${sizeInMB} MB`);

      if (sizeInMB > this.sizeInMbForCapacitor) {
        return new Promise<{ b64: string; format: string }>(
          (resolve, reject) => {
            this.resizer
              .reduceB64Size(base64String, this.sizeInMbForCapacitor)
              .subscribe(
                (resizedBase64) => {
                  resolve({
                    b64: resizedBase64,
                    format: image.format,
                  });
                },
                (error) => {
                  console.error('Error resizing image:', error);
                  this.tools.launchErrorAlert(
                    $localize`Une erreur est survenue lors de la réduction de la taille de l'image.`
                  );
                  reject(error);
                }
              );
          }
        );
      }
      return {
        b64: base64String,
        format: image.format,
      };
    }
  }

  public takePictureCamera() {
    try {
      return Camera.getPhoto({
        quality: 40,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        saveToGallery: true,
        source: CameraSource.Camera,
        width: 1000,
      });
    } catch (err: any) {
      // Log the error for debugging purposes
      console.error('Error taking picture:', err);

      // Check if the error is a CapacitorException
      if (err instanceof CapacitorException) {
        switch (err.message) {
          case 'User cancelled photos app':
            this.tools.launchErrorAlert("L'utilisateur a annulé l'opération.");
            return; // Pas besoin de continuer si l'utilisateur a annulé
            break;
          case 'Permissions API not available in this browser':
            this.tools.launchErrorAlert(
              'Les permissions ne sont pas disponibles dans ce navigateur.'
            );
            break;
          case 'Camera permissions are not available in this browser':
            this.tools.launchErrorAlert(
              'Les permissions de la caméra ne sont pas disponibles dans ce navigateur.'
            );
            break;
          default:
            this.tools.launchErrorAlert(
              'Une erreur Capacitor est survenue: ' + err.message
            );
            break;
        }
      } else if (err instanceof TypeError) {
        // Handle specific type errors
        this.tools.launchErrorAlert('Erreur de type détectée: ' + err.message);
      } else if (err.message.includes('NetworkError')) {
        // Handle network-related errors
        this.tools.launchErrorAlert('Erreur de réseau: ' + err.message);
      } else {
        // Handle any other generic errors
        this.tools.launchErrorAlert(
          'Une erreur inattendue est survenue: ' + err.message
        );
      }

      // Send error details to Sentry for further analysis
      SentryService.throwError(err);
    }
  }

  public handleFileInputV3(event: any, type: UploadInputType = 'camera') {
    return new Promise((resolve, reject) => {
      const fileF = event.srcElement.files[0];
      if (!fileF) {
        reject('empty file');
        return;
      }

      if (type === 'camera') {
        if (fileF.type.indexOf('image') === -1) {
          reject('not a image');
          return;
        }

        // Vérification de la taille du fichier
        if (fileF.size > 1000000) {
          console.log('Fichier > 1Mo, on réduit');
          this.resizer
            .compressImage(fileF, 2)
            // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
            .subscribe((imgResized: File | void) => {
              if (imgResized) {
                this.resolveb64(imgResized, resolve, reject);
              } else {
                return reject('resize issue');
              }
            });
          return;
        }
      }

      this.resolveb64(fileF, resolve, reject);
    });
  }

  private async resolveb64(
    fileF: any,
    resolve: (value: unknown) => void,
    reject: (reason?: any) => void
  ) {
    const b64 = await this.toBase64(fileF).catch((err) => {
      reject(err);
    });
    console.log('b64', getBase64Size(b64 as string));
    resolve({
      b64: b64,
      format: '.' + fileF.name.split('.').pop(),
      filename: fileF.name,
      type: fileF.type,
      original: fileF,
    });
  }

  public toBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
