import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tagListColumn',
  standalone: true,
})
export class TagListColumnNamePipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'libelle') return 'Nom';
    if (value === 'type') return 'Catégorie';
    if (value === 'ownership') return 'Propriété';
    if (value === 'share_to') return 'Partagé à';
    if (value === 'share_by') return 'Partagé par';
    if (value === 'id_parent') return 'Parent';
    if (value === 'id_user') return 'Utilisateur propriétaire';
    if (value === 'user_libelle') return 'Utilisateur propriétaire';
    if (value === 'is_archived') return 'Archivé';
    if (value === 'id_groupe') return 'Groupe propriétaire';
    if (value === 'groupe_libelle') return 'Groupe propriétaire';
    if (value === 'shared') return 'Partagé';
    if (value === 'intervention_status') return 'Statut intervention';
    if (value === 'shared_users_or_group') return 'Partagé à';
    return '';
  }
}
