import { getFamilyPath } from '../productfamily/getFamilyPathFromCache';
import { getProductPathFromCache } from '../product/getProductPathFromCache';
import { emplacementDocType } from '../../db/schemas/emplacement.schema';
import { simpleEmplacement } from './../../db/schemas/simpleEmplacement';
import { ProductFamiliesType } from '@types_custom/ProductType';

/**
 * Cache the result of the function isEmplacementInChoosenTree
 */
const CACHE = new Map<string, boolean>();

/**
 * Check if an emplacement is in the choosen tree
 * @param emplacement - The emplacement to check
 * @param choosenTree - The tree to check
 * @returns True if the emplacement is in the choosen tree
 */
export function isEmplacementInChoosenTree(
  emplacement: emplacementDocType | simpleEmplacement,
  choosenTree: ProductFamiliesType[]
) {
  const key =
    emplacement.id +
    choosenTree.map((e) => e.id + e.name).join('_') +
    '_' +
    emplacement.updated_at;
  if (CACHE.has(key)) {
    return CACHE.get(key);
  }

  if (emplacement.composants.length === 0) {
    CACHE.set(key, false);
    return false;
  }

  const bool = emplacement.composants.some((composant) => {
    if (composant.product) {
      const productPath = getProductPathFromCache(
        composant.product.id,
        choosenTree
      );
      return productPath.length > 0;
    }
    if (composant.type) {
      const familyPath = getFamilyPath(composant.type, choosenTree);
      return familyPath.length > 0;
    }
    return false;
  });

  CACHE.set(key, bool);
  return bool;
}
