export interface mapboxStyleType {
  title: string;
  uri: string;
}

// Base styles common to both desktop and mobile
const baseMapboxStyles = [
  {
    title: $localize`Carte`,
    uri: 'mapbox://styles/mapbox/streets-v11?optimize=true',
  },
  {
    title: $localize`Extérieur`,
    uri: 'mapbox://styles/mapbox/outdoors-v11?optimize=true',
  },
  {
    title: $localize`Satellite`,
    uri: 'mapbox://styles/mapbox/satellite-v9?optimize=true',
  },
  {
    title: $localize`Satellite streets`,
    uri: 'mapbox://styles/mapbox/satellite-streets-v11?optimize=true',
  },
  {
    title: $localize`Navigation`,
    uri: 'mapbox://styles/mapbox/navigation-day-v1?optimize=true',
  },
];

// Desktop-specific styles
export const mapboxStylesListDesktop = [
  // {
  //   title: $localize`Carte v3`,
  //   uri: 'mapbox://styles/mapbox/standard',
  // },
  ...baseMapboxStyles,
];

// Mobile styles (same as the base in this case)
export const mapboxStylesListMobile = [...baseMapboxStyles];

export const defaultMapboxStyle =
  'mapbox://styles/mapbox/outdoors-v11?optimize=true';

export function getStylesList(isMobile = false) {
  return isMobile ? mapboxStylesListMobile : mapboxStylesListDesktop;
}
