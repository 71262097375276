<app-meta-field-menu
  [readonly]="readonly"
  (addMetaField)="addMetaField($event)"
>
</app-meta-field-menu>

<app-meta-field-accordion
  [metas]="metas"
  [readonly]="readonly"
  (updateMeta)="updateMetaField($event.meta, $event.key, $event.value)"
  (removeMeta)="removeMetaField($event)"
></app-meta-field-accordion>
