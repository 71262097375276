import { gql } from "graphql-request";


export const MUTATION_INSERT_TAGS = gql`
  mutation MyMutation(
    $objects: [tag_insert_input!]!
    $on_conflict: tag_on_conflict!
  ) {
    insert_tag(objects: $objects, on_conflict: $on_conflict) {
      returning {
        id
      }
    }
  }
`;
