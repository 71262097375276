import { ProductFamiliesType } from '../../../../@types_custom/ProductType';
import {
  composantDocType,
  emplacementDocType,
} from '../../db/schemas/emplacement.schema';
import {
  flatProductsFromFamilyTree,
  flatFamiliesFromFamilyTree,
} from '../../utils/productfamilies-utils.service';

export function isEmplacementBatiment(
  emplacement: emplacementDocType,
  batimentFamilies: ProductFamiliesType[]
) {
  const productIds = flatProductsFromFamilyTree(batimentFamilies).map(
    (p) => p.id
  );
  const familiesIds = flatFamiliesFromFamilyTree(batimentFamilies).map(
    (f) => f.id
  );
  return isEmplacementBatimentInside(emplacement, productIds, familiesIds);
}
export function isEmplacementBatimentInside(
  emplacement: emplacementDocType,
  productIds: string[],
  familiesIds: string[]
) {
  // Pour chaque composant de chaque emplacement, on vérifie product.id et type
  return emplacement.composants
    .filter((c) => !c.deleted_at)
    .some(
      (c) =>
        (c.product?.id && productIds.includes(c.product.id)) ||
        familiesIds.includes(c.type!)
    );
}
export function areComposantsBatiment(
  composants: composantDocType[],
  batimentFamilies: ProductFamiliesType[]
) {
  const productIds = flatProductsFromFamilyTree(batimentFamilies).map(
    (p) => p.id
  );
  const familiesIds = flatFamiliesFromFamilyTree(batimentFamilies).map(
    (f) => f.id
  );
  return areComposantsBatimentInside(composants, productIds, familiesIds);
}
export function areComposantsBatimentInside(
  composants: composantDocType[],
  productIds: string[],
  familiesIds: string[]
) {
  return composants
    .filter((c) => !c.deleted_at)
    .some(
      (c) =>
        (c.product?.id && productIds.includes(c.product.id)) ||
        familiesIds.includes(c.type!)
    );
}
