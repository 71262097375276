import { ISelectItem } from './../../db/interfaces/select-item';
import { COLORS_BTN } from './../../enum/colors';


export class TagTypeSelectItem implements ISelectItem {
  item: { id: string; name: string; };

  getLibelle() {
    return this.item.name;
  }
  getIdentifier() {
    return this.item.id;
  }
  getColor(): COLORS_BTN {
    return 'primary';
  }

  constructor(item: { id: string; name: string; }) {
    this.item = item;
  }
}
