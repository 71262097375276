import { getDefaultIdProductByFirstFamily } from './../../../utils/composant/getDefaultIdProductByFirstFamily';
import { recursiveFindFamilyFromFamiliesId } from './../../../utils/productfamilies-utils.service';
import { ProductFamiliesTreeState } from './../../../state/product-families-tree-state.service';
import { ProductFamiliesType } from '@types_custom/ProductType';


export function getDefaultProductByFirstFamily(
  productTreeState: ProductFamiliesTreeState,
  familyId: string | undefined,
  originalTree: ProductFamiliesType[]
) {
  if (!familyId) return undefined;

  let defaultProduct = undefined;
  if (familyId) {
    // CAS 1 : Le localstorage contient le dernier produit sélectionné pour la famille
    const defaultProductIdLocalStorage = getDefaultIdProductByFirstFamily(familyId);
    if (defaultProductIdLocalStorage) {
      defaultProduct = productTreeState.getProductById(
        defaultProductIdLocalStorage
      );
    } else {
      // CAS 2 : Si la famille n'a qu'un seul produit, alors on le prend par défaut
      const familyAssoc = recursiveFindFamilyFromFamiliesId(
        familyId,
        originalTree
      );
      if (familyAssoc?.products.length === 1) {
        defaultProduct = familyAssoc.products[0].product;
      }
    }
  }
  return defaultProduct;
}
