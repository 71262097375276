import { gql } from 'graphql-request';

const FRAGMENT_EMPLACEMENT = `
  fragment fragment_emplacement on emplacement {
      commentaire
      numero_ensemble
      address
      pr
      status
      created_at
      created_by
      geometry
      updated_at
      updated_by
      deleted_at
      deleted_by
      deleted_bool
      id
      emplacement_photos(order_by: { created_at: desc }) {
        id
        created_at
        created_by
        deleted_at
        deleted_by
        chemin
        type_photo
      }
      emplacement_tags(order_by: { created_at: asc }) {
        id
        created_at
        created_by
        deleted_at
        deleted_by
        id_tag
        is_from_shared_tag
      }
      interventions(order_by: { created_at: asc }) {
        id_intervention
        id
      }
      composants(order_by: { created_at: asc }) {
        id
        created_at
        created_by
        order_per_type
        quantity
        product {
          id
          defaultQuantity
          canChangeQuantity
          shortcut
          name
          productfamilies {
            productfamily {
              id
              name
              images
            }
          }
          productpropertyvalues {
            productpropertyvalue {
              id
              productproperty_id
              value
            }
          }
          price {
            price
          }
        }
        type
        deleted_at
        deleted_by
        updated_at
        updated_by
        conformites(order_by: { created_at: asc }) {
          id
          commentaire
          est_conforme
          id_reglementation
          id_reglementation_choix
          valeur
          created_at
          created_by
          deleted_at
          deleted_by
        }
        composant_productfields(order_by: { created_at: asc }) {
          productfield {
            id
            type
          }
          value
          id
          created_at
          deleted_at
          updated_at
        }
      }
      emplacement_productfields(order_by: { created_at: asc }) {
        productfield {
          id
          type
        }
        value
        id
        created_at
        deleted_at
        updated_at
      }
    }`;

export const QUERY_GET_EMPLACEMENTS = gql`
  ${FRAGMENT_EMPLACEMENT}
  query MyQuery(
    $where: emplacement_bool_exp!
    $where1: emplacement_bool_exp!
    $order_by: [emplacement_order_by!]!
    $limit: Int!
  ) {
    emplacement(where: $where, order_by: $order_by, limit: $limit) {
      ...fragment_emplacement
    }
    emplacement_aggregate(where: $where1) {
      aggregate {
        count
      }
    }
  }
`;

// For netlify serverless
export const QUERY_GET_EMPLACEMENT_BY_PK = gql`
  ${FRAGMENT_EMPLACEMENT}
  query getEmplacement($id: uuid = "") {
    emplacement_by_pk(id: $id) {
      ...fragment_emplacement
    }
  }
`;

export const MUTATION_UPSERT_EMPLACEMENT = gql`
  mutation MyMutation(
    $objects_emplacement: [emplacement_insert_input!]!
    $on_conflict_emplacement: emplacement_on_conflict!
    $objects_emplacement_photo: [UploadPhotoInput!]!
    $objects_emplacement_tag: [emplacement_tag_insert_input!]!
    $on_conflict_emplacement_tag: emplacement_tag_on_conflict!
    $objects_composant: [composant_insert_input!]!
    $on_conflict_composant: composant_on_conflict!
  ) {
    insert_emplacement(
      objects: $objects_emplacement
      on_conflict: $on_conflict_emplacement
    ) {
      returning {
        id
      }
    }

    insert_composant(
      objects: $objects_composant
      on_conflict: $on_conflict_composant
    ) {
      returning {
        id
      }
    }

    insert_emplacement_tag(
      objects: $objects_emplacement_tag
      on_conflict: $on_conflict_emplacement_tag
    ) {
      returning {
        id
      }
    }

    UploadPhoto(objects: $objects_emplacement_photo) {
      id
    }
  }
`;

export const MUTATION_SOFT_DELETE_EMPLACEMENT: string = gql`
  mutation softDeleteEmplacement(
    $idEmplacement: uuid = ""
    $deleted_by: uuid = ""
    $deleted_at: timestamptz = "now()"
  ) {
    update_emplacement(
      where: { id: { _eq: $idEmplacement } }
      _set: { deleted_at: $deleted_at, deleted_by: $deleted_by }
    ) {
      returning {
        id
      }
    }
  }
`;

export const MUTATION_UPSERT_EMPLACEMENT_PHOTO = gql`
  mutation MyMutation($objects_emplacement_photo: [UploadPhotoInput!]!) {
    UploadPhoto(objects: $objects_emplacement_photo) {
      id
    }
  }
`;
