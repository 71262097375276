<div
  *ngIf="newUser$ | async as newUser; else listDepot"
  class="mb1em mt1em c-black"
>
  <app-useralt-form [user]="newUser"></app-useralt-form>
</div>

<ng-template #listDepot>
  <app-useralt-list></app-useralt-list>
</ng-template>
