import { MapMouseEvent, EventData, Map } from 'mapbox-gl';
import { addClickEventOnClusterLayer } from './addClickEventOnClusterLayer';
import { enableGenericLayer } from './enableGenericLayer';
import { getDefaultLayersEmplacementSelector } from './getDefaultLayers';
import { getDefaultLayersEmplacement } from './getDefaultLayersEmplacement';
import { getDefaultLayersHome } from './getDefaultLayersHome';
import { setupClickEventListeners } from './layerAddOrUpdate';
import { mapboxLayerOptions } from './mapbox.interface';

/**
 * Adds map layers to a Mapbox map instance based on the specified type.
 *
 * @param {Map} map - The Mapbox map instance to which layers will be added.
 * @param {'home' | 'emplacement_detail' | 'emplacement_selector'} type - The type of map layers to add.
 *   - 'home': Adds layers specific to the home view.
 *   - 'emplacement_detail': Adds layers specific to the emplacement detail view.
 *   - 'emplacement_selector': Adds layers specific to the emplacement selector view.
 * @param {(e: MapMouseEvent & EventData) => void} [clickCallback] - Optional callback function to handle click events on the map layers.
 *
 * @returns {void}
 */
export function addMapLayers(
  map: Map,
  type: 'home' | 'emplacement_detail' | 'emplacement_selector',
  clickCallback:
    | ((e: MapMouseEvent & EventData) => void)
    | undefined = undefined
) {
  let defaultLayers: mapboxLayerOptions[] = [];
  if (type === 'emplacement_detail') {
    // MAP emplacement selector / emplacement detail
    defaultLayers = getDefaultLayersEmplacement();
    defaultLayers.forEach((layer) => {
      enableGenericLayer(map, layer);
    });
  }
  if (type === 'emplacement_selector') {
    // MAP emplacement selector / emplacement detail
    defaultLayers = getDefaultLayersEmplacementSelector();
    defaultLayers.forEach((layer) => {
      enableGenericLayer(map, layer);
    });
  }

  // MAP HOME
  if (type === 'home') {
    defaultLayers = getDefaultLayersHome();
    defaultLayers.forEach((layer) => {
      enableGenericLayer(map, layer);
      // Gestion du clic sur les layers de cluster
      if (layer.id.includes('cluster')) {
        addClickEventOnClusterLayer(map, layer);
      }
    });
  }

  if (clickCallback) {
    // Clic sur les emplacements
    const layersForClick = defaultLayers.map((e) => e.id);
    setupClickEventListeners(map, { clickCallback }, layersForClick);
  }
}
