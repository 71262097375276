import { Map } from 'mapbox-gl';

/**
 * Proxy function to call a specified function based on the Mapbox map's style load status.
 *
 * @param {Object} params - The parameters for the proxy call.
 * @param {Map} params.map - The Mapbox map instance.
 * @param {() => void} params.func - The function to be called.
 * @param {boolean} [params.once=true] - Whether to call the function only once.
 * @param {boolean} [params.forceOn=false] - Whether to force the function to be called on the specified listener.
 * @param {string} [params.listener='style.load'] - The event listener to attach the function to.
 */
export function proxyCallMap({
  map,
  func,
  once = true,
  forceOn = false,
  listener = 'style.load',
}: {
  map: Map;
  func: () => void;
  once?: boolean;
  forceOn?: boolean;
  listener?: string;
}) {
  if (!map.isStyleLoaded()) {
    if (once) {
      map.once(listener, () => {
        func();
      });
      return;
    }
    map.on(listener, () => func());
    return;
  }

  if (forceOn) {
    map.on(listener, () => func());
  }
  func();
}
