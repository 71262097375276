<div
  class="d-flex-tag-filters"
  [ngClass]="{ 'is-mobile': isMobile, 'is-hidden': !showFilters }"
>
  <app-ugau-button
    *ngIf="isMobile"
    [label]="
      showFilters
        ? 'Masquer les filtres' +
          (filterDictionary.size !== 0
            ? ' (' + filterDictionary.size + ')'
            : '')
        : 'Afficher les filtres' +
          (filterDictionary.size !== 0
            ? ' (' + filterDictionary.size + ')'
            : '')
    "
    (click)="showFilters = !showFilters"
    [freeHeight]="true"
    class="mr1em ml1em"
  >
  </app-ugau-button>

  <div *ngFor="let filter of filterSelectObj; trackBy: trackByName">
    <app-ugau-input-autocomplete
      [value]="filter.modelValue"
      [label]="columnDefs[filter.columnProp].title"
      [options]="filter.options"
      (inputChange)="filterChange(filter, $event)"
      [disableSubWrapper]="true"
      [timeDebounce]="0"
    >
    </app-ugau-input-autocomplete>
  </div>

  <app-ugau-button
    class="ml1em"
    label=""
    classColor="warning"
    iconName="refresh"
    (click)="resetFilters()"
    [freeHeight]="true"
  >
  </app-ugau-button>

  <app-ugau-button
    i18n-label
    class="ml1em mr1em"
    label=""
    [matMenuTriggerFor]="menu"
    iconName="more_vert"
    [hasBorder]="false"
    [freeHeight]="true"
  >
  </app-ugau-button>

  <mat-menu #menu="matMenu">
    <div class="itemMenu" mat-menu-item>
      <app-ugau-button
        i18n-label
        label="Export"
        [hasBorder]="false"
        (click)="export()"
      >
      </app-ugau-button>
    </div>
  </mat-menu>
</div>

<table
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8 stock-table"
  matSort
>
  <!-- Name -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Libelle

      <app-ugau-button
        class="m1em"
        label=""
        classColor="valid"
        iconColor="valid"
        iconName="add"
        [matMenuTriggerFor]="menu"
      >
      </app-ugau-button>
      <mat-menu #menu="matMenu">
        <div
          class="c-black"
          style="
            font-size: 16px;
            padding: 10px;
            border-bottom: 1px solid #e0e0e0;
            text-align: center;
          "
        >
          Choisir un bâtiment
        </div>

        <div
          class="itemMenu"
          mat-menu-item
          *ngFor="let bat of batiments$ | async; trackBy: trackById"
        >
          <app-ugau-button
            iconName="chevron_right"
            i18n-label
            [label]="bat | emplacement: 'libelle'"
            [hasBorder]="false"
            [freeHeight]="true"
            (click)="addNewMateriel(bat)"
            [maxWidth]="true"
          >
          </app-ugau-button>
        </div>
        <div class="itemMenu" mat-menu-item>
          <app-ugau-button
            i18n-label
            iconName="add"
            label="Bâtiment"
            [hasBorder]="false"
            (click)="addNewMateriel()"
          >
          </app-ugau-button>
        </div>
      </mat-menu>
    </th>
    <td mat-cell *matCellDef="let composant">
      <app-ugau-button
        [label]="(composant | productName | async) || ''"
        (click)="editMateriel(composant)"
        [hasBorder]="false"
        textAlign="left"
      ></app-ugau-button>
    </td>
  </ng-container>

  <!-- Created At Column -->
  <ng-container matColumnDef="emplacement">
    <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Bâtiment</th>
    <td mat-cell *matCellDef="let composant">
      <app-ugau-button
        [label]="composant.id_emplacement | emplacementId: 'libelle_batiment'"
        [hasBorder]="false"
        textAlign="left"
        (click)="navigateEmplacement(composant.id_emplacement)"
      ></app-ugau-button>
    </td>
  </ng-container>

  <!-- Created At Column -->
  <ng-container matColumnDef="conforme">
    <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Conforme</th>
    <td mat-cell *matCellDef="let composant">
      {{
        (composant | getComposantListitem: 'second_line' | async) === ''
          ? 'Oui'
          : (composant | getComposantListitem: 'second_line' | async)
      }}
    </td>
  </ng-container>

  <!-- Created At Column -->
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Création</th>
    <td mat-cell *matCellDef="let composant">
      {{ composant.created_at | date: formatFULL }}
    </td>
  </ng-container>

  <!-- Updated At Column -->
  <ng-container matColumnDef="updated_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>MAJ</th>
    <td mat-cell *matCellDef="let composant">
      {{ composant.updated_at | date: formatFULL }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  [pageSizeOptions]="PAGE_SIZE_OPTIONS"
  [pageSize]="PAGE_SIZE"
  showFirstLastButtons
  (page)="PAGE_CHANGED($event)"
/>
