import { Injectable } from '@angular/core';
import Pica from 'pica';
import { Observable, Subject } from 'rxjs';

declare let window: any;

@Injectable({
  providedIn: 'root',
})
export class PhotoResizerService {
  private picaResizer: Pica.Pica;

  constructor() {
    this.picaResizer = new Pica({
      tile: 1024,
      features: ['js', 'wasm', 'ww'],
      idle: 2000,
      concurrency: 4,
    });

    if (typeof this.picaResizer.resize !== 'function') {
      this.picaResizer = new window.Pica();
    }
  }

  public compressImage(
    file: File,
    sizeInMB: number,
    options?: any
  ): Observable<File> {
    const compressedImage = new Subject<File>();

    if (this.bytesToMB(file.size) <= sizeInMB) {
      setTimeout(() => {
        compressedImage.next(file);
        compressedImage.complete();
      });
    } else {
      this.resize(file, file.type)
        .then((blob) => {
          if (this.bytesToMB(blob.size) <= sizeInMB) {
            const imgCompressed: File = this.blobToFile(
              blob,
              file.name,
              file.type,
              new Date().getTime()
            );
            compressedImage.next(imgCompressed);
            compressedImage.complete();
          } else {
            compressedImage.error('Not able to compress enough');
          }
        })
        .catch((err) => compressedImage.error(err));
    }

    return compressedImage.asObservable();
  }

  private resize(
    blob: Blob,
    mimeType: string,
    width?: number,
    height?: number
  ): Promise<Blob> {
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    const image = new Image();
    return new Promise((resolve, reject) => {
      image.onload = () => {
        width = width ?? image.width;
        height = height ?? image.height;
        canvas.width = width;
        canvas.height = height;
        this.picaResizer
          .resize(image, canvas, {
            quality: 1, // max
            alpha: false,
          })
          .then((result) => this.picaResizer.toBlob(result, 'image/jpeg', 0.7))
          .then((resizedBlob) => resolve(resizedBlob))
          .catch((error) => reject(error));
      };
      image.src = URL.createObjectURL(blob);
      image.onerror = (error) => reject(error);
    });
  }

  private blobToFile(
    blob: Blob,
    name: string,
    type: string,
    lastModified: number
  ): File {
    return new File([blob], name, { lastModified, type });
  }

  private bytesToMB(bytes: number) {
    return bytes / 1048576;
  }

  public getB64SizeInMb(base64: string): number {
    const sizeInBytes =
      base64.length * (3 / 4) -
      (base64.endsWith('==') ? 2 : base64.endsWith('=') ? 1 : 0);
    return this.bytesToMB(sizeInBytes);
  }

  public reduceB64Size(
    base64: string,
    sizeInMB: number,
    quality: number = 0.5 // Ajustez la qualité de compression ici
  ): Observable<string> {
    const reducedImage = new Subject<string>();
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });

    if (this.getB64SizeInMb(base64) <= sizeInMB) {
      reducedImage.next(base64);
      reducedImage.complete();
    } else {
      this.compressBlob(blob, 'image/jpeg', quality)
        .then((compressedBlob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const compressedBase64 = reader.result as string;
            const compressedSize = this.getB64SizeInMb(compressedBase64);
            console.log('Compressed Size (MB):', compressedSize);

            if (compressedSize <= sizeInMB) {
              reducedImage.next(compressedBase64);
              reducedImage.complete();
            } else {
              reducedImage.error('Not able to compress enough');
            }
          };
          reader.onerror = (error) => {
            reducedImage.error('FileReader error: ' + JSON.stringify(error));
          };
          reader.readAsDataURL(compressedBlob);
        })
        .catch((err) => reducedImage.error(err));
    }

    return reducedImage.asObservable();
  }

  private compressBlob(
    blob: Blob,
    mimeType: string,
    quality: number
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);

        canvas.toBlob(
          (compressedBlob) => {
            if (compressedBlob) {
              resolve(compressedBlob);
            } else {
              reject(new Error('Compression failed'));
            }
          },
          mimeType,
          quality
        );
      };

      img.onerror = (err) => {
        reject(err);
      };

      img.src = URL.createObjectURL(blob);
    });
  }
}
