import { gql } from 'graphql-request';

export const QUERY_GET_CONTACT = gql`
  query GetContact(
    $where: contact_bool_exp!
    $order_by: [contact_order_by!]!
    $limit: Int!
  ) {
    contact(where: $where, order_by: $order_by, limit: $limit) {
      id
      name
      email
      user_id
      groupe_id
      type
      created_at
      updated_at
      created_by
      updated_by
      deleted_at
      deleted_by
      contact_meta {
        id
        key
        value
        type
        created_at
        updated_at
      }
    }
  }
`;
