import {
  openDatabase,
  getTransaction,
  handleTransactionError,
} from './../../state/indexeddb-utils.service';
import { objectToMap } from './objectToMap';
import { WorkerFilterChild } from './worker.types';

export function getMapFromIndexedDB(
  dbName: string,
  storeName: string,
  key: string
): Promise<Map<string, WorkerFilterChild[] | undefined>> {
  return new Promise((resolve, reject) => {
    openDatabase(dbName, storeName)
      .then((db) => {
        const transaction = getTransaction(db, storeName, 'readonly');
        const store = transaction.objectStore(storeName);
        const requestGet = store.get(key);

        requestGet.onsuccess = () => {
          const encodedData = requestGet.result;
          if (encodedData) {
            try {
              const obj = JSON.parse(encodedData);
              const map = objectToMap(obj as Record<string, any>);
              resolve(map);
            } catch (e) {
              console.error('Error while decoding map from indexedDB', e);

              // Start a new transaction to remove the entry
              const deleteTransaction = getTransaction(
                db,
                storeName,
                'readwrite'
              );
              const deleteStore = deleteTransaction.objectStore(storeName);
              const requestDelete = deleteStore.delete(key);

              requestDelete.onsuccess = () => {
                console.log('Entry removed successfully due to decode failure');
                db.close();
              };

              requestDelete.onerror = () => {
                console.error('Failed to remove entry', requestDelete.error);
                db.close();
                reject(requestDelete.error);
              };

              deleteTransaction.oncomplete = () => {
                db.close();
              };

              reject(e);
            }
          } else {
            reject('No data found');
          }
        };

        requestGet.onerror = () => {
          console.error('Request error', requestGet.error);
          db.close();
          reject(requestGet.error);
        };

        transaction.oncomplete = () => {
          db.close();
        };

        transaction.onerror = () => handleTransactionError(transaction, reject);
      })
      .catch((error) => {
        console.error('Error opening database', error);
        reject(error);
      });
  });
}
