import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const productfieldsSchemaLiteral = jsonSchema({
  name: 'productfields',
  autoMigrate: true,
  version: 1,
  type: 'object',
  required: ['id', 'name', 'updated_at'],
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    name: {
      type: 'string',
    },
    libelle_action_historique: {
      type: ['string', 'null'],
    },
    order: {
      type: ['number', 'null'],
    },
    defaultvalue: {
      type: ['string', 'null'],
    },
    type: {
      type: 'string',
    },
    is_for_emplacement: {
      type: 'boolean',
    },
    show_in_title: {
      type: 'boolean',
    },
    filtrable: {
      type: 'boolean',
    },
    mandatory: {
      type: 'boolean',
    },
    updated_at: {
      type: 'string',
    },
    deleted_at: {
      type: ['string', 'null'],
    },
    deleted_bool: {
      type: 'boolean',
    },
  },
} as const);

const productfieldsSchemaTyped = toTypedRxJsonSchema(
  productfieldsSchemaLiteral
);

export type productfieldDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof productfieldsSchemaTyped
> & { order?: number };

export const productfieldsSchema: RxJsonSchema<productfieldDocType> =
  productfieldsSchemaLiteral;
