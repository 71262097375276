import { gql } from "graphql-request";

export const QUERY_GET_FABRICANTS = gql`
    query MyQuery ($where: fabricant_bool_exp!, $order_by: [fabricant_order_by!]!, $limit: Int!) {
    fabricant (
        where: $where,
        order_by: $order_by,
        limit: $limit
    ) {
        id
        libelle
        adresse
        ville {
            id
            code_postal
            nom
        }
        certification
        created_at
        created_by
        updated_at
        updated_by
        deleted_at
        deleted_by
        deleted_bool
        }
    }
`;

export const MUTATION_INSERT_FABRICANTS = gql`
    mutation MyMutation (
        $objects: [fabricant_insert_input!]!,
        $on_conflict: fabricant_on_conflict!
    ) {
        insert_fabricant (
        objects: $objects,
        on_conflict: $on_conflict,
        ) {
        returning {
            id
        }
        }
    }
`