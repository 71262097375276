import { checkConformiteIntegrity } from '../conformite/checkConformiteIntegrity';
import { productfieldDocType } from '../../db/schemas/productfields.schema';
import { emplacementDocType, composantDocType } from '../../db/schemas/emplacement.schema';
import { reglementationDocType } from '../../db/schemas/reglementation.schema';
import { ProductFamiliesType } from '@types_custom/ProductType';
import { composantHasEmptyMandatoryField } from './composantHasEmptyMandatoryField';

export function checkComposantIntegrity(
  emplacement: emplacementDocType,
  families: ProductFamiliesType[],
  productfields: productfieldDocType[],
  reglementations: reglementationDocType[]
) {
  let messageComposant: string = '';
  const checkComposant = emplacement.composants
    .filter((el: composantDocType) => !el.deleted_at)
    .some((composant: composantDocType) => {
      // 25 Juin 2024 : On autorise les composants sans produit

      const checkHasEmptyMandatoryFields = composantHasEmptyMandatoryField(
        composant,
        families,
        productfields
      );
      if (checkHasEmptyMandatoryFields.value) {
        messageComposant = checkHasEmptyMandatoryFields.message;
        return true;
      }

      const checkConformite = checkConformiteIntegrity(
        composant,
        reglementations
      );
      if (checkConformite.value) {
        messageComposant = checkConformite.message;
        return true;
      }

      return false;
    }) || false;

  return { checkComposant, messageComposant };
}