import { LOCALSTORAGE } from '../enum/local-storage';

export function getFromLocalStorage(
  key: string,
  doJsonParse = false
): null | string | any {
  const value = localStorage.getItem(key);
  if (value && !doJsonParse) {
    return value;
  }
  if (value && doJsonParse) {
    try {
      return JSON.parse(value);
    } catch (error) {
      console.log('JSON parse error : ' + key, error);
      return null;
    }
  }
  return '';
}

export function setToLocalStorage(key: string | LOCALSTORAGE, value: any) {
  switch (typeof value) {
    case 'string':
    case 'boolean':
    case 'number':
      localStorage.setItem(key, value.toString());
      break;
    case 'object':
      localStorage.setItem(key, JSON.stringify(value));
      break;
  }
}

export function removeFromLocalStorage(key: string | string[]) {
  if (Array.isArray(key)) {
    key.forEach((k) => {
      localStorage.removeItem(k);
    });
    return;
  }
  localStorage.removeItem(key);
}

export function getBooleanFromLocalStorage(key: string): boolean {
  return getFromLocalStorage(key) === 'true';
}

export function setMaxAgeClasse(num: number, value: string | number) {
  if (typeof value == 'number') {
    value = value.toString();
  }
  setToLocalStorage('age_limit_' + num, value);
}
