import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { groupeDocType } from '../../db/schemas/groupe.schema';
import { IAutocompleteItem, UgauInputAutocompleteObjectsComponent } from '../ugau-input-autocomplete/ugau-input-autocomplete-objects.component';
import { GroupesStateService } from '../../state/groupes-state.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-ugau-select-tag-groupe',
    template: `
    <ng-container *ngIf="groupeChoices">
      <app-ugau-input-autocomplete-objects
        [value]="currentGroupeSelectItem || undefined"
        [label]="label"
        [options]="groupeChoices"
        (inputChange)="valueChange($event)"
        [readOnly]="readOnly"
      ></app-ugau-input-autocomplete-objects>
    </ng-container>
  `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, UgauInputAutocompleteObjectsComponent],
})
export class UgauSelectGroupeContainer implements OnChanges {
  @Input() label: string = 'Groupe';
  @Input() allowEmpty = false;
  @Input() readOnly = false;
  @Input() currentGroupe!: string | null | undefined;
  currentGroupeSelectItem!: groupeSelectItem;
  groupeChoices: groupeSelectItem[] = [];

  @Output() groupeChange = new EventEmitter<string | null>();

  constructor(private groupeState: GroupesStateService) {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.groupeChoices = this.getGroupes();

    if (this.currentGroupe) {
      const choice = this.groupeState
        .getGroupes()
        .find((e) => e.id === this.currentGroupe);
      if (!choice) throw new Error('Groupe inconnu');
      this.currentGroupeSelectItem = new groupeSelectItem(choice);
    }
  }

  valueChange($event: groupeSelectItem | null) {
    if ($event?.item.id) return this.groupeChange.emit($event.item.id);
    this.groupeChange.emit(null);
  }

  getGroupes() {
    const groupes = this.groupeState.getGroupes();
    if (!this.allowEmpty) return groupes.map((e) => new groupeSelectItem(e));
    return [
      new groupeSelectItem({
        id: '',
        libelle: '',
        updated_at: '',
        user_groupes: [],
      }),
      ...groupes.map((e) => new groupeSelectItem(e)),
    ];
  }
}

export class groupeSelectItem implements IAutocompleteItem {
  item: groupeDocType;

  getLibelle() {
    return this.item.libelle;
  }
  getIdentifier() {
    return this.item.id;
  }

  constructor(item: groupeDocType) {
    this.item = item;
  }
}
