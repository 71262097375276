<div
  class="d-flex-tag-filters mb1em"
  [ngClass]="{ 'is-mobile': isMobile, 'is-hidden': !showFilters }"
>
  <app-ugau-button
    *ngIf="isMobile"
    [label]="
      showFilters
        ? 'Masquer les filtres' +
          (filterDictionary.size !== 0
            ? ' (' + filterDictionary.size + ')'
            : '')
        : 'Afficher les filtres' +
          (filterDictionary.size !== 0
            ? ' (' + filterDictionary.size + ')'
            : '')
    "
    (click)="showFilters = !showFilters"
    [freeHeight]="true"
    class="mr1em ml1em"
  >
  </app-ugau-button>

  <div *ngFor="let filter of filterSelectObj; trackBy: trackByName">
    <app-ugau-input-autocomplete
      [value]="filter.modelValue"
      [label]="columnDefs[filter.columnProp].title"
      [options]="filter.options"
      (inputChange)="filterChange(filter, $event)"
      [disableSubWrapper]="true"
    >
    </app-ugau-input-autocomplete>
  </div>

  <app-ugau-button
    class="ml1em"
    label=""
    classColor="warning"
    iconName="refresh"
    (click)="resetFilters()"
    [freeHeight]="true"
  >
  </app-ugau-button>
</div>

<table
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8 stock-table"
  matSort
>
  <!-- DisplayName -->
  <ng-container matColumnDef="displayName">
    <th mat-header-cell *matHeaderCellDef i18n mat-sort-header>
      <div class="d-flex flex-ac">
        Collaborateurs
        <app-ugau-button
          class="m1em"
          [matMenuTriggerFor]="menu"
          label=""
          classColor="valid"
          iconColor="valid"
          iconName="add"
          (click)="$event.stopImmediatePropagation()"
        >
        </app-ugau-button>
        <mat-menu #menu="matMenu">
          <div
            *ngFor="let groupe of groupes$ | async; trackBy: trackByIdFn"
            class="itemMenu"
            mat-menu-item
          >
            <app-ugau-button
              i18n-label
              [label]="'Pour ' + groupe.libelle"
              [hasBorder]="false"
              (click)="addUser(groupe)"
            >
            </app-ugau-button>
          </div>
        </mat-menu>
      </div>
    </th>
    <td mat-cell *matCellDef="let user" class="stock-libelle">
      <div class="d-flex flex-ac flex-sb">
        <span>
          {{ user.displayName }}
        </span>
      </div>
    </td>
  </ng-container>

  <!-- Email -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
    <td mat-cell *matCellDef="let user">
      {{ user.email }}
    </td>
  </ng-container>

  <!-- Groupe -->
  <ng-container matColumnDef="grouperole">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Groupe - Role</th>
    <td mat-cell *matCellDef="let user">
      <div class="d-flex-column">
        <!-- // eslint-disable-next-line @angular-eslint/template/use-track-by-function -->
        <div
          *ngFor="let groupe of user.groupes; trackBy: trackByValue"
          class="d-flex flex-sb flex-ac"
        >
          <span>{{ groupe.groupe }}</span>
          <div class="d-flex flex-sb flex-ac">
            <span>{{ groupe.role | roleDisplay }}</span>
            <div matListItemMeta *ngIf="!isMobile">
              <app-user-role-menu
                [user]="user"
                [gr]="groupe"
              ></app-user-role-menu>
            </div>
          </div>
        </div>
      </div>
    </td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef="let user">
      <div class="d-flex-right">Actions</div>
    </th>
    <td mat-cell *matCellDef="let user">
      <div class="d-flex-right">
        <app-ugau-button
          label=""
          [hasBorder]="false"
          classColor="valid"
          iconColor="valid"
          iconName="edit"
          (click)="editCollaborator(user)"
        ></app-ugau-button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  [pageSizeOptions]="[10, 20, 50, 100, 200]"
  showFirstLastButtons
/>
