import { inject, Pipe, PipeTransform } from '@angular/core';
import { ProductFamiliesTreeState } from '../../state/product-families-tree-state.service';
import { productDocType } from '../../db/schemas/emplacement.schema';
import { getProductFromFamilies } from '../../utils/productfamilies-utils.service';
import { getProductImageByProductId } from '../../utils/product-utils';
import { firstValueFrom } from 'rxjs';

@Pipe({
  name: 'productId',
  standalone: true,
})
export class ProductIdPipe implements PipeTransform {
  private productTreeState = inject(ProductFamiliesTreeState);

  async transform(
    idProduct: string | undefined | null,
    property:
      | keyof Omit<
          productDocType,
          'productpropertyvalues' | 'productproperties' | 'price'
        >
      | 'image'
      | undefined = 'name'
  ): Promise<string> {
    if (!idProduct) return '';

    const families = await firstValueFrom(this.productTreeState.vanillaTree$);
    const product = getProductFromFamilies(idProduct, families);
    if (!product) return '';

    if (property === 'image') {
      return getProductImageByProductId(idProduct, families, 'default', false);
    }

    if (typeof product[property] !== 'string') return '';

    return product[property].toString();
  }
}
