import { tagMetaDocType } from '../../../db/schemas/tag.schema';
import { v4 as uuidv4 } from 'uuid';

export function initMeta(type: string): tagMetaDocType {
  let key = '';
  if (type === 'date_debut') {
    key = 'Date de début';
  }
  if (type === 'date_fin') {
    key = 'Date de fin';
  }

  return {
    id: uuidv4(),
    type,
    key: key,
    value: '',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };
}
