import { Map } from 'mapbox-gl';
import { mapboxLayerOptions } from './mapbox.interface';
import { addLayerZindex } from './addLayerZindex';

/**
 * Enables a generic layer on the provided Mapbox map instance.
 * 
 * @param map - The Mapbox map instance where the layer will be added.
 * @param layer - An object containing the layer options.
 * @param layer.id - The unique identifier for the layer.
 * @param layer.layer - The actual layer object to be added to the map.
 * @param layer.zIndex - Optional. The z-index position where the layer should be added.
 * 
 * @remarks
 * If the layer with the specified id already exists on the map, the function does nothing.
 * If a zIndex is provided, the layer is added at the specified z-index position.
 * Otherwise, the layer is added without specifying a z-index.
 */
export function enableGenericLayer(map: Map, layer: mapboxLayerOptions) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!map.getLayer(layer.id)) {
    if (layer.zIndex) {
      addLayerZindex(map);
      map.addLayer(layer.layer, layer.zIndex);
      return;
    }
    map.addLayer(layer.layer);
  }
}
