import { v4 as uuidv4 } from 'uuid';
import { documentInterventionDocType } from '../../db/schemas/intervention.schema';

export function createInterventionDocument(
  document: {
    filename: string;
    type: string | null | undefined;
    chemin: string;
  },
  userId: string
): documentInterventionDocType {
  return {
    id: uuidv4(),
    document_name: document.filename,
    document_type: document.type ?? '',
    chemin: document.chemin,
    created_by: userId,
    created_at: new Date().toISOString(),
    updated_by: userId,
    updated_at: new Date().toISOString(),
    deleted_at: undefined,
    deleted_by: undefined,
  };
}
