import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { tagDocType } from '../db/schemas/tag.schema';
import { SentryService } from '../services/sentry.service';
import { DatabaseService } from '../db/database.service';

@Injectable({
  providedIn: 'root',
})
export class TagsStateService {
  tagProvider = inject(DatabaseService).getTagProvider();

  tags$: Observable<tagDocType[]> = this.tagProvider.getAll$.pipe(
    map((e) => e.filter((t) => t.is_archived === false))
  );

  getTags() {
    return this.tagProvider.getAllValue();
  }

  getTagById(tagId: number | string) {
    return this.tagProvider
      .getAllValue()
      .find((el) => el.id === tagId.toString());
  }

  upsert(tag: tagDocType) {
    try {
      if (tag.id_user || tag.id_groupe) {
        tag.updated_at = new Date().toISOString();
        return this.tagProvider.collection.incrementalUpsert(tag);
      }
      SentryService.captureMessage('Error Upsert Tag : User and group empty', {
        extra: tag,
      });
    } catch (err) {
      SentryService.captureMessage('Error Upsert Tag : catch', {
        extra: tag,
      });
      console.log('Error Upsert Tag : catch', err);
    }
  }
}

