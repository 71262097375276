import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const reglementationSchemaLiteral = jsonSchema({
  name: 'reglementation',
  autoMigrate: true,
  version: 2,
  type: 'object',
  primaryKey: 'id',
  required: [
    'id',
    'libelle',
    'updated_at',
    'reglementation_choixes',
    'productfields',
  ],
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    libelle: {
      type: 'string',
    },
    btn_name_bad: {
      type: 'string',
    },
    actif: {
      type: 'boolean',
    },
    controle: {
      type: 'boolean',
    },
    order: {
      type: ['number', 'null'],
    },
    type_valeur: {
      type: ['string', 'null'],
    },
    valeur_min: {
      type: ['string', 'null'],
    },
    valeur_max: {
      type: ['string', 'null'],
    },
    deleted_bool: {
      type: 'boolean',
    },
    updated_at: {
      type: 'string',
    },
    reglementation_choixes: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'object',
        required: ['id', 'est_conforme', 'libelle'],
        properties: {
          id: {
            type: 'number',
          },
          order: {
            type: ['number', 'null'],
          },
          libelle: {
            type: ['string', 'null'],
          },
          est_conforme: {
            type: 'boolean',
          },
          actif: {
            type: 'boolean',
          },
        },
      },
    },
    productfields: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          productfield_id: {
            type: 'string',
          },
        },
      },
    },
  },
} as const);

const reglementationSchemaTyped = toTypedRxJsonSchema(
  reglementationSchemaLiteral
);

export type reglementationDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof reglementationSchemaTyped
>;
export type reglementationChoixDocType = NonNullable<
  reglementationDocType['reglementation_choixes']
>[number];

export const reglementationSchema: RxJsonSchema<reglementationDocType> =
  reglementationSchemaLiteral;
