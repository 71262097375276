import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  lastValueFrom,
  Observable,
} from 'rxjs';
import {
  debounceTime,
  map,
  shareReplay,
  take,
  tap,
  distinctUntilChanged,
} from 'rxjs/operators';
import { LOCALSTORAGE } from '../enum/local-storage';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setToLocalStorage,
} from '../utils/localstorage-utils.service';
import { flatProductsFromFamilyTree } from '../utils/productfamilies-utils.service';
import { skipNull } from '../utils/types-rxjs-utils';
import { uniqBy } from 'lodash-es';
import { ProductFamiliesType, ProductType } from '@types_custom/ProductType';
import { WAIT_DURATION } from '../enum/WAIT_DURATION';
import { DatabaseService } from '../db/database.service';

@Injectable({
  providedIn: 'root',
})
export class ProductFamiliesTreeState {
  private provider = inject(DatabaseService).getProductTreeProvider();

  private shownModuleTypeSubject$ = new BehaviorSubject<'module' | 'materiel'>(
    'module'
  );
  shownModuleType$ = this.shownModuleTypeSubject$.asObservable();
  setShownModuleType(type: 'module' | 'materiel') {
    this.shownModuleTypeSubject$.next(type);
  }

  vanillaTree: ProductFamiliesType[] | null = null;
  originalTree: ProductFamiliesType[] | null = null;
  productsList: ProductType[] | null = null;

  // Doit servir pour les images
  vanillaTree$ = this.provider.getAll$.pipe(
    distinctUntilChanged((prev, curr) => {
      return prev.every((p, index) => p.updated_at === curr[index].updated_at);
    }),
    map((trees) => {
      return uniqBy(
        trees.map((e) => JSON.parse(e.json)),
        'id'
      );
    }),
    tap((e) => {
      this.vanillaTree = e;
    })
  );

  // Doit servir pour l'affichage à l'utilisateur
  originalTree$: Observable<ProductFamiliesType[] | null> = combineLatest([
    this.provider.getAll$,
    this.shownModuleType$,
  ]).pipe(
    distinctUntilChanged((prev, curr) => {
      const [prevTree, prevType] = prev;
      const [currTree, currType] = curr;
      return (
        prevTree.every(
          (p, index) => p.updated_at === currTree[index].updated_at
        ) && prevType === currType
      );
    }),
    map(([trees, type]) => {
      // Si materiel, on accepte MATERIEL
      return uniqBy(
        trees.map((e) => JSON.parse(e.json)),
        'id'
      );
    }),
    tap((e) => {
      this.originalTree = e;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      this.productsList = e ? flatProductsFromFamilyTree(e) : null;
    }),
    shareReplay(1)
  );

  private choosenFirstFamilySubject$ = new BehaviorSubject<number | null>(
    getFromLocalStorage(LOCALSTORAGE.CHOOSEN_FIRSTFAMILY) !== null
      ? parseInt(getFromLocalStorage(LOCALSTORAGE.CHOOSEN_FIRSTFAMILY) || '')
      : null
  );
  choosenFirstFamily$ = this.choosenFirstFamilySubject$.asObservable();

  choosenTree$: Observable<ProductFamiliesType[]> = combineLatest([
    this.originalTree$,
    this.choosenFirstFamily$,
  ]).pipe(
    map(([originalTree, choosenFirstFamily]) => {
      if (!originalTree) return [];
      if (choosenFirstFamily === null || isNaN(choosenFirstFamily)) {
        return originalTree;
      }
      return [originalTree[choosenFirstFamily]];
    }),
    shareReplay(1)
  );

  getOriginalTree() {
    const value = this.originalTree;
    if (!value) return [];
    return value;
  }

  getVanillaTree() {
    const value = this.vanillaTree;
    if (!value) return [];
    return value;
  }

  getTreesByType(type: string | string[]) {
    const value = this.provider.getAllValue();
    if (!Array.isArray(type)) {
      return value
        .filter((e) => e.type === type)
        .map((e) => JSON.parse(e.json));
    }

    return value
      .filter((e) => e.type && type.includes(e.type))
      .map((e) => JSON.parse(e.json));
  }

  getBatimentFirstFamilyId(): Promise<string | undefined> {
    const value = this.provider.getAllValue();
    const module = value.find((e) => e.type === 'batiment');
    if (!module?.json) return Promise.resolve(undefined);

    // Il faut retourner id
    try {
      const json = JSON.parse(module.json) as ProductFamiliesType;
      const id = json.id;
      if (!id) return Promise.resolve(undefined);

      return this.getOriginalTreePromise().then((tree) => {
        if (!tree) return undefined;
        const index = tree.findIndex((e) => e.id === id);
        if (index === -1) return undefined;
        // On retourne le premier enfant (qui doit être le type de batiment)
        return tree[index].children_productfamilies?.[0].id;
      });
    } catch (e) {
      return Promise.resolve(undefined);
    }
  }

  getMaterielFirstFamily(): Promise<ProductFamiliesType | undefined> {
    const value = this.provider.getAllValue();
    const module = value.find((e) => e.type === 'materiel');
    if (!module?.json) return Promise.resolve(undefined);

    // Il faut retourner id
    try {
      const json = JSON.parse(module.json) as ProductFamiliesType;
      const id = json.id;
      if (!id) return Promise.resolve(undefined);

      return this.getOriginalTreePromise().then((tree) => {
        if (!tree) return undefined;
        const index = tree.findIndex((e) => e.id === id);
        if (index === -1) return undefined;
        return tree[index];
      });
    } catch (e) {
      return Promise.resolve(undefined);
    }
  }

  getOriginalTreePromise(): Promise<ProductFamiliesType[] | null> {
    return lastValueFrom(
      this.originalTree$.pipe(
        skipNull(),
        debounceTime(WAIT_DURATION.SHORT),
        take(1)
      )
    );
  }

  getProductsList() {
    return this.productsList ?? [];
  }

  getProductById(productId: string) {
    return this.getProductsList().find((product) => product.id === productId);
  }

  getChoosenFirstFamily() {
    return this.choosenFirstFamilySubject$.getValue();
  }

  setChoosenFirstFamily(indexFirstFamily: number | null) {
    if (indexFirstFamily !== null) {
      setToLocalStorage(
        LOCALSTORAGE.CHOOSEN_FIRSTFAMILY,
        indexFirstFamily.toString() || ''
      );
    } else {
      removeFromLocalStorage(LOCALSTORAGE.CHOOSEN_FIRSTFAMILY);
    }
    this.choosenFirstFamilySubject$.next(indexFirstFamily);
  }
}
