import { Pipe, PipeTransform } from '@angular/core';
import { sharedTagDocType } from '../../db/schemas/tag.schema';

@Pipe({
  name: 'sharedTag',
  standalone: true,
  pure: true,
})
export class SharedTagPipe implements PipeTransform {
  transform(
    sharedTag: sharedTagDocType | undefined | null,
    property: 'status_class_color' | string | undefined = undefined
  ): string {
    if (!sharedTag) return '';

    if (
      property === 'status_class_color' &&
      (sharedTag.target_group_id || sharedTag.target_user_id)
    ) {
      const isActive = isActivedSharedTag(sharedTag);
      if (!isActive) {
        return 'tag-shared-disabled';
      }

      if (sharedTag.readonly) {
        return 'tag-shared-active-readonly';
      }

      return 'tag-shared-active';
    }
    return '';
  }
}

export function isActivedSharedTag(sharedTag: sharedTagDocType): boolean {
  const isActive = sharedTag.active ?? false;
  const isNotExpiredOrEmpty =
    !sharedTag.expires_at || new Date(sharedTag.expires_at) > new Date();

  return isActive && isNotExpiredOrEmpty;
}
