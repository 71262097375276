/* eslint-disable sonarjs/no-identical-functions */
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { AppBatimentListComponent } from './batiment-list.component.ts/batiment-list.component';
import { map, Observable } from 'rxjs';
import { emplacementDocType } from '../../db/schemas/emplacement.schema';
import { StockState } from '../stock/stock.state';
import { CartService } from '../../services/cart.service';
import { ProductFamiliesTreeState } from '../../state/product-families-tree-state.service';
import { CartStore } from '../../../../@types_custom/cart-store';
import { StatsTabGroupComponent } from '../stats-tab-group/stats-tab-groups.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RoutingState } from '../../state/routing-state.service';
import { HomeStateService } from '../../state/home-state.service';
import { MapboxSelectionsStateService } from '../../services/mapbox/mapbox-selection-state.service';
import { AsyncPipe } from '@angular/common';
import { TabBatimentIndexState } from './TabBatimentIndexState';

@Component({
  selector: 'app-batiment',
  templateUrl: './batiment.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    AppBatimentListComponent,
    StatsTabGroupComponent,
    MatTabsModule,
  ],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AppBatimentComponent implements OnInit {
  private stockState = inject(StockState);
  private cartState = inject(CartService);
  private treeState = inject(ProductFamiliesTreeState);
  private routingState = inject(RoutingState);
  private mapboxSelectionState = inject(MapboxSelectionsStateService);
  private homeState = inject(HomeStateService);
  private tabIndexState = inject(TabBatimentIndexState);

  data$: Observable<emplacementDocType[]> = this.stockState.depots$;
  tabIndex$ = this.tabIndexState.tabIndex$;
  store$!: Observable<CartStore>;

  ngOnInit(): void {
    const tree = this.treeState.getTreesByType('batiment');
    const id = 'list-batiments';
    this.data$.subscribe((data) => {
      this.cartState.calculate(id, data, tree);
    });
    this.store$ = this.cartState.getStore$(id).pipe(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      map((store) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (store?.Batiments?.childrens) {
          return store.Batiments.childrens;
        }
        return store;
      })
    );
  }

  showConcernedEmplacement(ids: string[]): void {
    if (ids.length === 0) return;
    if (ids.length === 1) {
      this.routingState.navigateEmplacement(ids[0], true);
      return;
    }
    if (ids.length > 1) {
      this.mapboxSelectionState.setMapboxSelections({
        emplacementIds: ids,
        startPos: undefined,
        endPos: undefined,
      });

      this.homeState.openDossier();
    }
  }

  changeIndex(index: number): void {
    this.tabIndexState.changeIndex(index);
  }
}
