import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MatSlideToggle,
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { COLORS_BTN } from './../../enum/colors';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-ugau-input-boolean',
  template: `
    <div class="mat-mdc-form-field">
      <div
        class="mat-mdc-text-field-wrapper mdc-text-field mdc-text-field--outlined"
      >
        <div class="mat-mdc-form-field-flex">
          <div
            class="mdc-notched-outline mdc-notched-outline--notched mdc-notched-outline--upgraded"
          >
            <div class="mdc-notched-outline__leading"></div>
            <div class="mdc-notched-outline__notch" *ngIf="!forceUseLabel">
              <label
                class="mdc-floating-label mat-mdc-floating-label mdc-floating-label--float-above"
              >
                {{ label }}
              </label>
            </div>
            <div class="mdc-notched-outline__trailing"></div>
          </div>
          <div class="mat-mdc-form-field-infix">
            <mat-slide-toggle
              [checked]="value"
              (change)="onToggleChange($event)"
              [color]="classColor"
              class="mat-slide-toggle-wrapper"
              [disabled]="readonly"
            >
              <span class="slide-label" *ngIf="!forceUseLabel; else labelTpl">{{
                value ? 'Oui' : 'Non'
              }}</span>
              <ng-template #labelTpl>
                <span class="slide-label">{{ label }}</span>
              </ng-template>
            </mat-slide-toggle>
          </div>
        </div>
      </div>
      <div
        class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align"
      >
        <div class="mat-mdc-form-field-hint-wrapper"></div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;

        .slide-label {
          margin-left: 16px;
        }

        .mat-mdc-form-field {
          padding-left: 16px;
          padding-right: 16px;
          width: 100%;
        }

        .toggle-input {
          padding-left: 16px;
          padding-right: 16px;
          width: 100%;
        }

        .mat-slide-toggle-wrapper {
          display: flex;
          align-items: center;
          height: 100%;
        }

        &[data-color='valid'] ::ng-deep .mat-slide-toggle-thumb,
        &[data-color='valid'] ::ng-deep .mat-slide-toggle-bar {
          background-color: var(--valid-regular);
        }

        &[data-color='warning'] ::ng-deep .mat-slide-toggle-thumb,
        &[data-color='warning'] ::ng-deep .mat-slide-toggle-bar {
          background-color: var(--warning-regular);
        }

        &[data-color='danger'] ::ng-deep .mat-slide-toggle-thumb,
        &[data-color='danger'] ::ng-deep .mat-slide-toggle-bar {
          background-color: var(--error-regular);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatFormFieldModule, MatSlideToggleModule],
})
export class UgauInputBooleanContainer {
  @Input() value: boolean | undefined = false;
  @Input() label = '';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() forceUseLabel = false;
  @Input() readonly = false;

  @Input()
  @HostBinding('attr.data-color')
  classColor: COLORS_BTN = 'primary';

  @Output() valueChange = new EventEmitter<boolean>();

  @ViewChild(MatSlideToggle) toggle!: MatSlideToggle;

  onToggleChange(event: MatSlideToggleChange) {
    this.valueChange.emit(event.checked);
  }
}
