import { LngLatBounds } from 'mapbox-gl';
import { getBoundsCenter } from './getBoundsCenter';

/**
 * Retrieves the central latitude and longitude from a given GeoJSON Polygon.
 *
 * @param {GeoJSON.Polygon} geometry - The GeoJSON Polygon from which to extract the central coordinates.
 * @returns {LngLatLike} The central latitude and longitude of the polygon.
 */
export function getLatLngFromPolygon(
  geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon
) {
  const bounds = new LngLatBounds();
  if (geometry.type === 'Polygon') {
    geometry.coordinates.forEach((coords) => {
      coords.forEach((subCoords) => {
        bounds.extend([subCoords[0], subCoords[1]]);
      });
    });
  }
  if (geometry.type === 'MultiPolygon') {
    geometry.coordinates.forEach((coords) => {
      coords.forEach((subCoords) => {
        subCoords.forEach((subSubCoords) => {
          bounds.extend([subSubCoords[0], subSubCoords[1]]);
        });
      });
    });
  }

  return getBoundsCenter(bounds);
}
