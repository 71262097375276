<div
  class="d-flex-tag-filters"
  [ngClass]="{ 'is-mobile': isMobile, 'is-hidden': !showFilters }"
>
  <app-ugau-button
    *ngIf="isMobile"
    [label]="
      showFilters
        ? 'Masquer les filtres' +
          (filterDictionary.size !== 0
            ? ' (' + filterDictionary.size + ')'
            : '')
        : 'Afficher les filtres' +
          (filterDictionary.size !== 0
            ? ' (' + filterDictionary.size + ')'
            : '')
    "
    (click)="showFilters = !showFilters"
    [freeHeight]="true"
    class="mr1em ml1em"
  >
  </app-ugau-button>

  <div *ngFor="let filter of filterSelectObj; trackBy: trackByName">
    <app-ugau-input-autocomplete
      [value]="filter.modelValue"
      [label]="columnDefs[filter.columnProp].title"
      [options]="filter.options"
      (inputChange)="filterChange(filter, $event)"
      [disableSubWrapper]="true"
      [timeDebounce]="0"
    >
    </app-ugau-input-autocomplete>
  </div>
  <app-ugau-button
    class="ml1em mr1em"
    label=""
    classColor="warning"
    iconName="refresh"
    (click)="resetFilters()"
    [freeHeight]="true"
  >
  </app-ugau-button>
</div>

<div class="table-container">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="libelle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'libelle' | tagListColumn }}
        <app-ugau-button
          *ngIf="!noAddButton"
          label=""
          classColor="valid"
          iconName="add"
          (click)="$event.stopImmediatePropagation(); initializeNewElement()"
          [freeHeight]="true"
          class="mr1em ml1em"
        >
        </app-ugau-button>
      </th>
      <td mat-cell *matCellDef="let el">
        <app-ugau-button
          [label]="el.libelle"
          [hasBorder]="false"
          [freeHeight]="true"
          (click)="elementSelected(el)"
          textAlign="left"
        ></app-ugau-button>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'type' | tagListColumn }}
      </th>
      <td mat-cell *matCellDef="let el">{{ el.type }}</td>
    </ng-container>

    <ng-container matColumnDef="intervention_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
      <td mat-cell *matCellDef="let el">
        {{ el.id | tag: 'intervention_status' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="user_libelle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
        Utilisateur propriétaire
      </th>
      <td mat-cell *matCellDef="let el">
        {{ el.user_libelle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="groupe_libelle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
        Groupe propriétaire
      </th>
      <td mat-cell *matCellDef="let el">
        {{ el.groupe_libelle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="emplacements">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Ensembles</th>
      <td mat-cell *matCellDef="let el">
        {{ el | countEmplacementTag | async }}
      </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Création</th>
      <td mat-cell *matCellDef="let el">
        {{ el.created_at | date: formatFULL }}
      </td>
    </ng-container>

    <ng-container matColumnDef="shared">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Partagé</th>
      <td mat-cell *matCellDef="let el">
        <mat-icon
          *ngIf="el.shared_tags?.length > 0; else notShared"
          matTooltip="Oui"
          i18n-matTooltip
          >check</mat-icon
        >
        <ng-template #notShared>
          <mat-icon matTooltip="Non" i18n-matTooltip>close</mat-icon>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_archived">
      <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>Archivé</th>
      <td mat-cell *matCellDef="let el">
        <mat-icon
          *ngIf="el.is_archived; else notArchived"
          matTooltip="Archivé"
          i18n-matTooltip
          >check</mat-icon
        >
        <ng-template #notArchived>
          <mat-icon matTooltip="Archivé" i18n-matTooltip>close</mat-icon>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <div class="d-flex-right flex-ac" i18n>Actions</div>
      </th>
      <td mat-cell *matCellDef="let el">
        <div
          class="d-flex-right flex-ac"
          *ngIf="el | hasWriteAccessOnTag | async; else locked"
        >
          <mat-icon
            class="p05em"
            *ngIf="el | hasEmplacementTag | async"
            (click)="elementShowOnMap(el)"
            matTooltip="Voir sur la carte"
            color="primary"
            i18n-matTooltip
            >zoom_out_map</mat-icon
          >

          <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
          <mat-menu #menu="matMenu">
            <div mat-menu-item>
              <app-ugau-button
                label="Outlook"
                iconName="schedule_send"
                (click)="shareToCalendar(el, 'outlook')"
                [hasBorder]="false"
              >
              </app-ugau-button>
            </div>
            <div mat-menu-item>
              <app-ugau-button
                label="Google"
                iconName="schedule_send"
                (click)="shareToCalendar(el, 'google')"
                [hasBorder]="false"
              >
              </app-ugau-button>
            </div>
            <div mat-menu-item>
              <app-ugau-button
                label="Supprimer"
                classColor="danger"
                iconName="delete"
                (click)="elementRemove(el)"
                [hasBorder]="false"
              >
              </app-ugau-button>
            </div>
          </mat-menu>
        </div>
        <ng-template #locked>
          <div class="d-flex-right flex-ac">
            <mat-icon
              class="p05em"
              *ngIf="el | hasEmplacementTag | async"
              (click)="elementShowOnMap(el)"
              color="primary"
              matTooltip="Voir sur la carte"
              i18n-matTooltip
              >zoom_out_map</mat-icon
            >
          </div>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<mat-paginator
  [pageSizeOptions]="PAGE_SIZE_OPTIONS"
  [pageSize]="PAGE_SIZE"
  showFirstLastButtons
  (page)="PAGE_CHANGED($event)"
/>
