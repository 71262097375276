<!-- Attention, Prettier met des espace en auto et c'est pas beau (title, firstLine, secondLine) -->
<mat-list-item (click)="toggleDetailledData()">
  <div
    *ngIf="image && isUsingSprite"
    class="ugau-image-listitem"
    matListItemAvatar
    [class]="'img-' + image"
  ></div>
  <img
    class="ugau-image-listitem"
    matListItemAvatar
    [src]="image"
    *ngIf="image && !isUsingSprite"
  />
  <mat-icon
    class="ugau-icon-listitem"
    *ngIf="iconImage && !image"
    matListItemAvatar
    >{{ iconImage }}</mat-icon
  >
  <!-- prettier-ignore -->
  <h4
    *ngIf="title"
    matListItemTitle
    class="ugau-title-listitem"
    [style.color]="titleColor"
  ><mat-chip
      *ngIf="titleBadge && badgePosition === 'left'"
      [color]="titleBadgeColor"
      class="ugau-title-badge-left"
      >{{ titleBadge }}</mat-chip
    >{{ title }}<mat-chip
      *ngIf="titleBadge && badgePosition === 'right'"
      [color]="titleBadgeColor"
      class="ugau-title-badge-right"
      >{{ titleBadge }}</mat-chip
    >
  </h4>
  <!-- prettier-ignore -->
  <p
    *ngIf="firstLine"
    matListItemLine
    class="ugau-line1-listitem"
    [style.color]="firstLineColor"
  >{{ firstLine }}</p>
  <!-- prettier-ignore -->
  <p
    *ngIf="secondLine"
    matListItemLine
    class="ugau-line2-listitem"
    [style.color]="secondLineColor"
  >{{ secondLine }}</p>

  <app-ugau-button
    [iconName]="iconActionButton"
    *ngIf="showActionButton"
    (click)="clickActionBtn($event)"
    label=""
    [hasBorder]="false"
    [classColor]="colorActionButton"
    matListItemMeta
  ></app-ugau-button>

  <app-ugau-button
    matListItemMeta
    [iconName]="iconDeleteButton"
    *ngIf="showDeleteButton"
    (click)="clickDeleteBtn($event)"
    label=""
    [hasBorder]="false"
    [classColor]="colorDeleteButton"
  ></app-ugau-button>

  <div matListItemMeta *ngIf="enableClick || !forceShowDetailledData">
    <mat-icon>
      {{ showDetailledData ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </div>
</mat-list-item>

<ng-container *ngIf="showDetailledData || forceShowDetailledData">
  <ng-content></ng-content>
</ng-container>
