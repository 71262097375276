import {
  composantDocType,
  emplacementDocType,
} from './../../db/schemas/emplacement.schema';
import { PHOTO_DEFAULT } from './../../enum/photo-default';
import { getProductImages } from '../product-utils';
import { getImagesFromFamiliesRecursive } from '../productfamilies-utils.service';
import { productfieldDocType } from './../../db/schemas/productfields.schema';
import { productpropertiesDocType } from './../../db/schemas/productproperties.schema';
import { reglementationDocType } from './../../db/schemas/reglementation.schema';
import { isConformeEmplacementV2 } from './../../services/data-accessor.service';
import { EMPLACEMENT_STATUS } from './../../../common-projects/emplacement_status';
import { keyValueProductOrder } from '../product/getProductsOrder';
import { ProductFamiliesType, ImagesProduct } from '@types_custom/ProductType';
import { ICON_MAP_BLUE } from './ICON_MAP_BLUE';

export function getInfoEmplacement(
  emplacement: emplacementDocType,
  productsOrder: any
) {
  const initialAcc = {
    firstProductId: null as string | null,
    firstFamilyId: null as string | null,
    indexComposant: null as number | null,
  };
  return emplacement.composants.reduce((acc, composant, index) => {
    if (composant.deleted_at) return acc;

    const productId = composant.product?.id ?? 0;
    const hasProductId = productId !== 0;
    const hasProductOrder = productsOrder[productId] !== undefined;

    if (
      hasProductId &&
      hasProductOrder &&
      (acc.firstProductId === null ||
        productsOrder[productId] < productsOrder[acc.firstProductId])
    ) {
      acc.firstProductId = productId;
      acc.indexComposant = index;
    }

    if (productId === 0 && !acc.firstFamilyId) {
      acc.firstFamilyId = composant.type ?? null;
    }

    return acc;
  }, initialAcc);
}

export function getImgProductEmplacement(
  emplacement: emplacementDocType,
  originalTree: ProductFamiliesType[],
  productsOrder: any
): ImagesProduct {
  if (emplacement.composants.length === 0)
    return { default: PHOTO_DEFAULT.DEFAULT };

  const result = getInfoEmplacement(emplacement, productsOrder);

  if (result.firstProductId) {
    return getProductImages({ id: result.firstProductId }, originalTree);
  }

  if (result.indexComposant !== null) {
    const type = emplacement.composants[result.indexComposant].type;
    if (type) {
      return getImagesFromFamiliesRecursive(type, originalTree);
    }
  }

  if (result.firstFamilyId) {
    return getImagesFromFamiliesRecursive(result.firstFamilyId, originalTree);
  }

  return { default: PHOTO_DEFAULT.DEFAULT };
}

const cacheIconMarker = new Map<string, string>();
export function getIconMarkerForEmplacement(
  emplacement: emplacementDocType,
  familyTree: ProductFamiliesType[],
  properties: productpropertiesDocType[],
  fields: productfieldDocType[],
  reglementations: reglementationDocType[],
  productsOrder: keyValueProductOrder
) {
  const cacheKey = `${emplacement.id}-${emplacement.updated_at}-${familyTree.map((e) => e.id).join('-')}`;
  if (cacheIconMarker.has(cacheKey)) {
    return cacheIconMarker.get(cacheKey);
  }

  const images = getImgProductEmplacement(
    emplacement,
    familyTree,
    productsOrder
  );

  const isMapillary = calculateIsMapillary(emplacement);
  const isConforme = calculateIsConforme(
    emplacement,
    familyTree,
    properties,
    fields,
    reglementations
  );

  const iconResult =
    images.default !== PHOTO_DEFAULT.DEFAULT.toString()
      ? determineIconBasedOnStatus(emplacement, images, isMapillary, isConforme)
      : ICON_MAP_BLUE;

  cacheIconMarker.set(cacheKey, iconResult);
  return iconResult;
}

function calculateIsMapillary(emplacement: emplacementDocType): boolean {
  return emplacement.composants.every(
    (composant: composantDocType) => !composant.product
  );
}

function calculateIsConforme(
  emplacement: emplacementDocType,
  familyTree: ProductFamiliesType[],
  properties: productpropertiesDocType[],
  fields: productfieldDocType[],
  reglementations: reglementationDocType[]
): boolean {
  return isConformeEmplacementV2(
    emplacement,
    familyTree,
    properties,
    fields,
    reglementations
  );
}

function determineIconBasedOnStatus(
  emplacement: emplacementDocType,
  images: any,
  isMapillary: boolean,
  isConforme: boolean
): string {
  if (
    isMapillary ||
    emplacement.status === EMPLACEMENT_STATUS.IMPORTED.toString() ||
    emplacement.status === EMPLACEMENT_STATUS.NON_POSE.toString() ||
    emplacement.status === EMPLACEMENT_STATUS.NEED_ENTRETIEN.toString() ||
    emplacement.status === EMPLACEMENT_STATUS.NEED_INTERVENTION.toString()
  ) {
    return images.orange;
  } else if (
    emplacement.emplacement_photos.length === 0 ||
    emplacement.status === EMPLACEMENT_STATUS.MUST_CHECK.toString()
  ) {
    return images.grey;
  } else if (isConforme) {
    return images.green;
  }
  return images.red;
}

export const getFillColor = (
  emplacement: emplacementDocType
): string | undefined => {
  // Parcourt les composants et retourne la couleur correspondante si trouvée
  for (const composant of emplacement.composants) {
    for (const cpf of composant.composant_productfields) {
      // CASE : SELECT_AND_COLOR productfield (Contains '@#xxxxx' in value)
      if (cpf.value?.includes('@#')) {
        return cpf.value.split('@')[1]; // Retourne la couleur dès qu'une correspondance est trouvée
      }
    }
  }

  // La couleur peut aussi être une propriété du produit du composant
  for (const composant of emplacement.composants) {
    if (composant.product) {
      if (
        !composant.product.productpropertyvalues ||
        composant.product.productpropertyvalues.length === 0
      )
        continue;

      for (const prop of composant.product.productpropertyvalues) {
        if (prop.productpropertyvalue?.value?.includes('@#')) {
          return prop.productpropertyvalue.value.split('@')[1];
        }
      }
    }
  }

  return undefined; // Retourne undefined si aucune correspondance n'est trouvée
};
