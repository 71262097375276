<div
  class="d-flex-tag-filters"
  [ngClass]="{ 'is-mobile': isMobile, 'is-hidden': !showFilters }"
>
  <app-ugau-button
    *ngIf="isMobile"
    [label]="
      showFilters
        ? 'Masquer les filtres' +
          (filterDictionary.size !== 0
            ? ' (' + filterDictionary.size + ')'
            : '')
        : 'Afficher les filtres' +
          (filterDictionary.size !== 0
            ? ' (' + filterDictionary.size + ')'
            : '')
    "
    (click)="showFilters = !showFilters"
    [freeHeight]="true"
    class="mr1em ml1em"
  >
  </app-ugau-button>

  <div *ngFor="let filter of filterSelectObj; trackBy: trackByName">
    <app-ugau-input-autocomplete
      [value]="filter.modelValue"
      [label]="columnDefs[filter.columnProp].title"
      [options]="filter.options"
      (inputChange)="filterChange(filter, $event)"
      [disableSubWrapper]="true"
      [timeDebounce]="0"
    >
    </app-ugau-input-autocomplete>
  </div>

  <app-ugau-button
    class="ml1em"
    label=""
    classColor="warning"
    iconName="refresh"
    (click)="resetFilters()"
    [freeHeight]="true"
  >
  </app-ugau-button>

  <app-ugau-button
    i18n-label
    class="ml1em mr1em"
    label=""
    [matMenuTriggerFor]="menu"
    iconName="more_vert"
    [hasBorder]="false"
    [freeHeight]="true"
    *ngIf="!isMobile"
  >
  </app-ugau-button>

  <mat-menu #menu="matMenu">
    <div class="itemMenu" mat-menu-item>
      <app-ugau-button
        i18n-label
        label="Export"
        [hasBorder]="false"
        (click)="export()"
      >
      </app-ugau-button>
    </div>
  </mat-menu>
</div>

<table
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8 stock-table"
  matSort
>
  <!-- Product ID Column -->
  <ng-container matColumnDef="meta">
    <th
      mat-header-cell
      *matHeaderCellDef
      i18n
      class="d-flex flex-ac"
      mat-sort-header
    >
      Produit
      <div class="d-flex flex-ac">
        <ng-container *ngIf="groupes$ | async as groupes">
          <app-ugau-button
            *ngIf="groupes.length === 1; else menuButton"
            class="m1em"
            label=""
            classColor="valid"
            iconColor="valid"
            iconName="add"
            (click)="$event.stopPropagation(); addNewStock(groupes[0].id)"
          >
          </app-ugau-button>

          <ng-template #menuButton>
            <app-ugau-button
              class="m1em"
              label=""
              classColor="valid"
              iconColor="valid"
              iconName="add"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
            </app-ugau-button>
            <mat-menu #menu="matMenu">
              <div
                class="itemMenu"
                mat-menu-item
                *ngFor="let groupe of groupes$ | async; trackBy: trackById"
              >
                <app-ugau-button
                  i18n-label
                  [label]="groupe.libelle"
                  [hasBorder]="false"
                  (click)="addNewStock(groupe.id)"
                >
                </app-ugau-button>
              </div>
            </mat-menu>
          </ng-template>
        </ng-container>

        <app-history-button
          (click)="$event.stopPropagation()"
          [type]="_type"
        ></app-history-button>
      </div>
    </th>
    <td mat-cell *matCellDef="let stock" class="stock-libelle">
      <div class="d-flex flex-ac flex-sb">
        <app-ugau-button
          [label]="
            stock.meta?.designation
              ? stock.meta?.designation
              : (stock.product_id | productId | async)
          "
          [hasBorder]="false"
          (click)="editStock(stock)"
          textAlign="left"
          class="fg-1"
        ></app-ugau-button>
        <div
          [class]="
            'stock-img img-' + (stock.product_id | productId: 'image' | async)
          "
        ></div>
      </div>
    </td>
  </ng-container>

  <!-- Supplier -->
  <ng-container matColumnDef="supplier">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fournisseur</th>
    <td mat-cell *matCellDef="let stock">
      {{ stock.supplier }}
    </td>
  </ng-container>

  <!-- Quantity Column -->
  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef i18n mat-sort-header>Quantité</th>
    <td mat-cell *matCellDef="let stock">{{ stock.quantity }}</td>
  </ng-container>

  <!-- Price Column -->
  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef i18n mat-sort-header>Prix</th>
    <td mat-cell *matCellDef="let stock">{{ stock.price | formatnumber }}</td>
  </ng-container>

  <!-- Price Column -->
  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef i18n mat-sort-header>Total</th>
    <td mat-cell *matCellDef="let stock">{{ stock.total | formatnumber }}</td>
  </ng-container>

  <!-- Groupe Column -->
  <ng-container matColumnDef="groupe_id">
    <th mat-header-cell *matHeaderCellDef i18n mat-sort-header>Groupe</th>
    <td mat-cell *matCellDef="let stock">
      {{ stock.groupe_id | groupeId }}
    </td>
  </ng-container>
  <!-- Depot Column -->
  <ng-container matColumnDef="emplacement">
    <th mat-header-cell *matHeaderCellDef i18n mat-sort-header>Bâtiment</th>
    <td mat-cell *matCellDef="let stock">
      <app-ugau-button
        [label]="stock.emplacement_id | emplacementId: 'libelle'"
        [hasBorder]="false"
        (click)="navigateBatiment(stock.emplacement_id)"
        textAlign="left"
      ></app-ugau-button>
    </td>
  </ng-container>

  <!-- Created At Column -->
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Création</th>
    <td mat-cell *matCellDef="let stock">
      {{ stock.created_at | date: formatFULL }}
    </td>
  </ng-container>

  <!-- Created At Column -->
  <ng-container matColumnDef="updated_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>MAJ</th>
    <td mat-cell *matCellDef="let stock">
      {{ stock.updated_at | date: formatFULL }}
    </td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      <div class="d-flex-right">Actions</div>
    </th>
    <td mat-cell *matCellDef="let stock">
      <div class="d-flex-right">
        <app-ugau-button
          label=""
          [hasBorder]="false"
          iconName="login"
          (click)="doInputStock(stock)"
        ></app-ugau-button>
        <app-ugau-button
          label=""
          [hasBorder]="false"
          iconName="logout"
          (click)="doOutputStock(stock)"
        ></app-ugau-button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  [pageSizeOptions]="PAGE_SIZE_OPTIONS"
  [pageSize]="PAGE_SIZE"
  showFirstLastButtons
  (page)="PAGE_CHANGED($event)"
/>
