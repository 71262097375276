import { gql } from 'graphql-request';

export const QUERY_GET_REGLEMENTATIONS = gql`
  query MyQuery(
    $where: reglementation_bool_exp = {}
    $order_by: [reglementation_order_by!] = {}
    $limit: Int = 10
  ) {
    reglementation(where: $where, order_by: $order_by, limit: $limit) {
      libelle
      btn_name_bad
      actif
      controle
      id
      type_valeur
      valeur_max
      valeur_min
      updated_at
      order
      deleted_bool
      reglementation_choixes(order_by: { id: asc }) {
        libelle
        id
        est_conforme
        actif
        order
      }
      productfields {
        productfield_id
      }
    }
  }
`;
