import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TabBatimentIndexState {
  private tabIndexSubject$ = new BehaviorSubject<number>(0);
  tabIndex$ = this.tabIndexSubject$.asObservable();
  changeIndex(index: number): void {
    this.tabIndexSubject$.next(index);
  }
}
