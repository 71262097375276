import { Geometry } from 'geojson';
import { PHOTO_DEFAULT } from '../../enum/photo-default';

/* eslint-disable sonarjs/no-duplicate-string */
export const getContentPopupEmplacement = (
  image: string,
  imagesComposants: string[],
  navEmplacement: () => void
) => {
  const popupContent = document.createElement('div');
  popupContent.style.display = 'flex';
  popupContent.style.flexDirection = 'column';
  popupContent.style.justifyContent = 'center';
  popupContent.style.alignItems = 'center';

  const imageEmplacement = document.createElement('img');
  imageEmplacement.classList.add('w100');
  imageEmplacement.src = `${image}`;
  imageEmplacement.onerror = () => {
    imageEmplacement.src = PHOTO_DEFAULT.DEFAULT;
  };

  // Create the banner container that holds the img-xxx divs
  const bannerContainer = document.createElement('div');
  bannerContainer.classList.add('image-banner-container'); // Custom class for the container

  // Create and append divs for each component image
  imagesComposants.forEach((imageComposant) => {
    const imageComposantElement = document.createElement('div');
    imageComposantElement.classList.add(`img-${imageComposant}`); // Uses the background image set in CSS
    bannerContainer.appendChild(imageComposantElement);
  });

  const linkList = document.createElement('a');
  linkList.classList.add('w100', 'ugau-button-popup');

  // Créer l'élément mat-icon (span) à l'intérieur du bouton
  const icon = document.createElement('span');
  icon.classList.add('material-icons');
  icon.textContent = 'create'; // Choisir l'icône que vous souhaitez, par exemple 'add'

  // Créer le texte du bouton après l'icône
  const linkText = document.createTextNode('Voir le détail');

  // Ajouter l'icône et le texte au bouton (lien)
  linkList.appendChild(icon);
  linkList.appendChild(linkText);

  linkList.onclick = () => {
    navEmplacement();
  };

  // Ajouter les éléments au contenu de la popup
  popupContent.appendChild(bannerContainer); // Add the banner container before the image
  popupContent.appendChild(imageEmplacement);
  popupContent.appendChild(linkList);

  return popupContent;
};

export const getContentPopupCreateEmplacement = (
  navEmplacement: () => void
) => {
  const popupContent = document.createElement('div');
  popupContent.style.display = 'flex';
  popupContent.style.flexDirection = 'column';
  popupContent.style.justifyContent = 'center';
  popupContent.style.alignItems = 'center';

  const emplacementName = document.createElement('div');
  emplacementName.classList.add(
    'mat-mdc-subheader',
    'mdc-list-group__subheader'
  );
  emplacementName.textContent = `Création`;

  // Créer le lien de création d'emplacement (bouton)
  const linkList = document.createElement('a');
  linkList.classList.add('w100', 'ugau-button-popup');

  // Créer l'élément mat-icon (span) à l'intérieur du bouton
  const icon = document.createElement('span');
  icon.classList.add('material-icons');
  icon.textContent = 'add'; // Choisir l'icône que vous souhaitez, par exemple 'add'

  // Créer le texte du bouton après l'icône
  const linkText = document.createTextNode('Créer un emplacement');

  // Ajouter l'icône et le texte au bouton (lien)
  linkList.appendChild(icon);
  linkList.appendChild(linkText);

  linkList.onclick = () => {
    navEmplacement();
  };

  // Ajouter les éléments au contenu de la popup
  popupContent.appendChild(emplacementName);
  popupContent.appendChild(linkList);

  return popupContent;
};

export const getContentPopupCreateEmplacementFromCadastre = (
  data: any,
  callback: (feature: Geometry, numero: string, commentaire: string[]) => void,
  libelle: string = 'Créer '
) => {
  // Create the main container div
  const popupContent = document.createElement('div');
  popupContent.style.display = 'flex';
  popupContent.style.flexDirection = 'column';
  popupContent.style.justifyContent = 'center';
  popupContent.style.alignItems = 'center';

  // Create the header element for "Création"
  const emplacementName = document.createElement('div');
  emplacementName.classList.add(
    'mat-mdc-subheader',
    'mdc-list-group__subheader'
  );
  emplacementName.innerHTML = `Création`;

  // Add the header once, outside the loop
  popupContent.appendChild(emplacementName);

  // Iterate through each feature and create a button for it
  data.features.forEach((feature: any) => {
    // Create the link (button) element for each feature
    const linkList = document.createElement('a');
    linkList.classList.add('w100', 'ugau-button-popup');

    // Create the icon span element
    const icon = document.createElement('span');
    icon.classList.add('material-icons');
    icon.textContent = 'add'; // Use the material icon 'add'

    // Create the text for the button
    const linkText = document.createTextNode(
      libelle + feature.properties.numero
    );

    // Append the icon and the text to the button
    linkList.appendChild(icon);
    linkList.appendChild(linkText);

    // Set the click event for each button
    linkList.onclick = () => {
      callback(
        feature.geometry,
        feature.properties.numero,
        Object.keys(feature.properties).map(
          (key) => `${key}: ${feature.properties[key]}`
        )
      );
    };

    // Append the button (link) to the popup content
    popupContent.appendChild(linkList);
  });

  return popupContent;
};

export const getContentPopupCreateEmplacementFromBuilding = (
  data: any,
  callback: (feature: Geometry, numero: string, commentaire: string[]) => void,
  libelle: string = 'Créer '
) => {
  // Create the main container div
  const popupContent = generatePopupElement();

  // Create the header element for "Création"
  const emplacementName = document.createElement('div');
  emplacementName.classList.add(
    'mat-mdc-subheader',
    'mdc-list-group__subheader'
  );
  emplacementName.innerHTML = `Création`;

  // Add the header once, outside the loop
  popupContent.appendChild(emplacementName);

  // Iterate through each feature and create a button for it
  data.features.forEach((feature: any) => {
    // Create the link (button) element for each feature
    const linkList = document.createElement('a');
    linkList.classList.add('w100', 'ugau-button-popup');

    // Create the icon span element
    const icon = document.createElement('span');
    icon.classList.add('material-icons');
    icon.textContent = 'add'; // Use the material icon 'add'

    // Create the text for the button
    const linkText = document.createTextNode(
      libelle + feature.properties.cleabs.replace('BATIMENT', '')
    );

    // Append the icon and the text to the button
    linkList.appendChild(icon);
    linkList.appendChild(linkText);

    // Set the click event for each button
    linkList.onclick = () => {
      callback(
        feature.geometry,
        feature.properties.cleabs,
        Object.keys(feature.properties).map(
          (key) => `${key}: ${feature.properties[key]}`
        )
      );
    };

    // Append the button (link) to the popup content
    popupContent.appendChild(linkList);
  });

  return popupContent;
};

function generatePopupElement() {
  const popupContent = document.createElement('div');
  popupContent.style.display = 'flex';
  popupContent.style.flexDirection = 'column';
  popupContent.style.justifyContent = 'center';
  popupContent.style.alignItems = 'center';
  return popupContent;
}

export const getContentPopupCreateEmplacementFromGeocoder = (
  clickedFeature: any,
  callback: (feature: Geometry) => void,
  libelle: string = 'Créer '
) => {
  const popupContent = generatePopupElement();

  // Create the header element for "Création"
  const emplacementName = document.createElement('div');
  emplacementName.classList.add(
    'mat-mdc-subheader',
    'mdc-list-group__subheader'
  );
  emplacementName.innerHTML = `Création <br> Geocoder`;

  // Add the header once, outside the loop
  popupContent.appendChild(emplacementName);

  const linkList = document.createElement('a');
  linkList.classList.add('w100', 'ugau-button-popup');

  const icon = document.createElement('span');
  icon.classList.add('material-icons');
  icon.textContent = 'add';

  const linkText = document.createTextNode(libelle);

  linkList.appendChild(icon);

  linkList.appendChild(linkText);

  linkList.onclick = () => {
    callback(clickedFeature.geometry);
  };

  popupContent.appendChild(linkList);

  return popupContent;
};
