import { inject } from '@angular/core';
import { userMetaDocType } from '../db/schemas/user-meta.schema';
import { v4 as uuidv4 } from 'uuid';
import { ToolsService } from '../services/tools.service';
import { DatabaseService } from '../db/database.service';

export abstract class BaseUserMetaState {
  private userMetaProvider = inject(DatabaseService).getUserMetaProvider();
  private isMobile = inject(ToolsService).isMobile();

  protected getMetadata(key: string) {
    return this.userMetaProvider
      .getAllValue()
      .find((m) => m.key === this.getKey(key));
  }

  protected setMetadata(key: string, value: any) {
    const metas = this.userMetaProvider.getAllValue();
    const meta = metas.find((m) => m.key === this.getKey(key));

    if (meta) {
      meta.value = this.convertValueToString(value);
      meta.updated_at = new Date().toISOString();
      return this.userMetaProvider.collection.upsert(meta);
    }

    const usermeta: userMetaDocType = {
      id: uuidv4(),
      key: this.getKey(key),
      value: this.convertValueToString(value),
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
    return this.userMetaProvider.collection.upsert(usermeta);
  }

  protected getMetadataValue<T>(
    key: string,
    doJsonParse = false,
    expectedType: 'boolean' | 'number' | 'string' | 'object' = 'string'
  ): T | null {
    const metadata = this.getMetadata(key);

    if (!metadata) {
      console.log(`No metadata found for key: ${key}`);
      return null;
    }

    const value = metadata.value;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (value === undefined || value === null) {
      console.log(`Value is undefined or null for key: ${key}`);
      return null;
    }

    if (doJsonParse) {
      try {
        return JSON.parse(value) as T;
      } catch (error) {
        console.log('JSON parse error : ' + key, error);
        return null;
      }
    }

    switch (expectedType) {
      case 'boolean':
        return (value === 'true') as unknown as T;
      case 'number':
        const parsedValue = Number(value);
        return isNaN(parsedValue) ? null : (parsedValue as unknown as T);
      case 'string':
        return value as unknown as T;
      case 'object':
        try {
          return JSON.parse(value) as T;
        } catch {
          console.log(`Error parsing value as object: ${value}`);
          return null;
        }
      default:
        console.log(`Unexpected type: ${expectedType}`);
        return null;
    }
  }

  protected getKey(key: string): string {
    return key + (this.isMobile ? '_mobile' : '_desktop');
  }

  private convertValueToString(value: any): string {
    switch (typeof value) {
      case 'boolean':
      case 'string':
      case 'number':
        return value.toString();
      case 'object':
        return JSON.stringify(value);
      default:
        throw new Error('Unsupported value type');
    }
  }
}
