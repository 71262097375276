import { emplacementDocType } from "../../db/schemas/emplacement.schema";


export function getEmplacementAddressString(
  emplacement: emplacementDocType | null
): string {
  // Adresse gouv
  if (emplacement?.address?.properties?.hasOwnProperty('name')) {
    return `${emplacement.address.properties.name} (${emplacement.address.properties.postcode} ${emplacement.address.properties.city}) `;
  }
  // Mapbox result
  if (emplacement?.address?.hasOwnProperty('place_name')) {
    return `${emplacement.address.place_name}`;
  }
  // Else
  return '';
}
