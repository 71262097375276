import { isConformeComposant } from '../composant/isConformeComposant';
import { productpropertiesDocType } from '../../db/schemas/productproperties.schema';
import {
  emplacementDocType,
  composantDocType,
} from '../../db/schemas/emplacement.schema';
import { productfieldDocType } from './../../db/schemas/productfields.schema';
import { reglementationDocType } from '../../db/schemas/reglementation.schema';

export function isConformeEmplacement(
  emplacement: emplacementDocType | null,
  properties: productpropertiesDocType[],
  fields: productfieldDocType[],
  reglementations: reglementationDocType[]
) {
  if (!emplacement?.composants.length) {
    // Pas de composant, donc c'est conforme :)
    return true;
  }
  return emplacement.composants.every((composant: composantDocType) => {
    return isConformeComposant(
      composant,
      null,
      properties,
      fields,
      reglementations
    );
  });
}
