import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmplacementDetailMapillaryStateService {
  private mapillarySubject$ = new BehaviorSubject<string | null>(null);
  mapillary$ = this.mapillarySubject$.asObservable();

  setId(value: string | null) {
    this.mapillarySubject$.next(value);
  }
  reset() {
    this.mapillarySubject$.next(null);
  }
}
