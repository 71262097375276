import { NavigationControl, Map } from 'mapbox-gl';

/**
 * Adds navigation controls to a Mapbox map instance.
 *
 * @param {Map} map - The Mapbox map instance to which the controls will be added.
 * @param {boolean} [showCompass=true] - Optional. Whether to show the compass control. Defaults to true.
 * @param {boolean} [visualizePitch=true] - Optional. Whether to visualize the pitch control. Defaults to true.
 */
export function addMapControls(
  map: Map,
  showCompass = true,
  visualizePitch = true
) {
  map.addControl(
    new NavigationControl({
      showCompass,
      visualizePitch,
    }),
    'top-left'
  );
}
