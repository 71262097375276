import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';

@Component({
    selector: 'app-ugau-list',
    template: `
    <mat-list>
      <ng-content></ng-content>
    </mat-list>
  `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatListModule],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UgauListComponent {}
