import { getFromLocalStorage } from '../localstorage-utils.service';
import { DEFAULT_AGE } from './getMaxAgeByClasseAlt';

export function getMaxAgeByClasse(num: number) {
  const value: number = parseInt(
    getFromLocalStorage('age_limit_' + num) || '0',
    10
  );
  if (value == 0 || isNaN(value)) {
    if (num == 1) return DEFAULT_AGE.CLASSE_1;
    if (num == 2) return DEFAULT_AGE.CLASSE_2;
    if (num == 3) return DEFAULT_AGE.CLASSE_3;
  }
  return value;
}

export function getMaxAgeClasse1() {
  return getMaxAgeByClasse(1);
}

export function getMaxAgeClasse2() {
  return getMaxAgeByClasse(2);
}

export function getMaxAgeClasse3() {
  return getMaxAgeByClasse(3);
}
