import { Pipe, PipeTransform } from "@angular/core";
import { groupeDocType } from "../../db/schemas/groupe.schema";
import { GroupesStateService } from "../../state/groupes-state.service";


@Pipe({
  name: 'groupeId',
  standalone: true,
})
export class GroupeIdPipe implements PipeTransform {
  constructor(private groupeState: GroupesStateService) { }
  transform(
    idGroupe: string | undefined | null,
    property: keyof groupeDocType | undefined = 'libelle'
  ): string {
    if (!idGroupe) return '';

    const groupe = this.groupeState
      .getGroupes()
      .find((el) => el.id === idGroupe);
    if (!groupe) return '';

    return groupe[property] || '';
  }
}
