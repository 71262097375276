import { Pipe, PipeTransform } from '@angular/core';
import { auth } from './../../services/nhost';
import { UsersStateService } from './../../state/users-state.service';
import { tagDocType } from './../../db/schemas/tag.schema';

@Pipe({
    name: 'tagbool',
    standalone: true,
})
export class TagBoolPipe implements PipeTransform {
  constructor(private usersState: UsersStateService) {}

  transform(
    tag: tagDocType | undefined | null,
    property: 'is_owner' | undefined = undefined
  ): boolean {
    if (!tag) return false;

    if (property === 'is_owner') {
      const idUser = auth.getUser()?.id;
      if (!idUser) return false;

      if (tag.id_user === idUser) return true;

      const userGroupes = this.usersState.getGroupesFromUser(idUser);
      if (userGroupes.length < 1) return false;

      return userGroupes.some((groupe) => groupe.id === tag.id_groupe);
    }

    return false;
  }
}
