import { SymbolLayout } from 'mapbox-gl';

export function getDefaultLayoutUnclustered(
  idCurrentEmplacement: string
): SymbolLayout {
  return {
    'icon-image': ['get', 'image'],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'icon-size': ['case', ['in', idCurrentEmplacement, ['get', 'id']], 1, 0.65],
  };
}

export function getDefaultLayoutIconLineString(
  idCurrentEmplacement: string
): SymbolLayout {
  return {
    'symbol-placement': 'line',
    'icon-image': ['get', 'image'],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'icon-size': ['case', ['in', idCurrentEmplacement, ['get', 'id']], 1, 0.65],
  };
}
