import { gql } from "graphql-request";

export const QUERY_GET_PRODUCTPROPERTIES = gql`
    query MyQuery ($where: productproperties_bool_exp!, $order_by: [productproperties_order_by!]!, $limit: Int!) {
        productproperties(
            where: $where,
            order_by: $order_by,
            limit: $limit
        ) {
            id
            name
            order
            filtrable
            updated_at
            deleted_at
            deleted_bool
        }
    }
`