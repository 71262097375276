import { emplacementDocType } from "../../db/schemas/emplacement.schema";
import { getDesignationComposant } from "../../pipes/products/getDesignationComposant";
import { getInfoEmplacement } from "../../utils/emplacement/getImgEmplacement";


export function getEmplacementLibelle(
  emplacement: emplacementDocType | undefined,
  productsOrder: any
): string {
  if (!emplacement) return '';

  // Le libelle doit être composé du nom/designation du produit du 1er composant
  const infos = getInfoEmplacement(emplacement, productsOrder);
  if (infos.firstProductId) {
    const composant = emplacement.composants[infos.indexComposant!];
    return getDesignationComposant(composant) ?? composant.product?.name ?? '';
  }

  return '';
}
