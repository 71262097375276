import { inject, Injectable } from '@angular/core';
import { tagDocType } from '../../db/schemas/tag.schema';
import { EmplacementFilterState } from '../../state/emplacement-filter-state.service';
import { DrawerControlService } from '../left-menu/left-menu-drawer-control.service';
import { HomeStateService } from '../../state/home-state.service';
import { TagService } from '../../services/tag/tag.service';
import { ToolsService } from '../../services/tools.service';
import { EventsService } from '../../services/events.service';
import { ModalService } from '../../services/modal.service';
import { RoutingState } from '../../state/routing-state.service';

@Injectable({
  providedIn: 'root',
})
export class TagsActionsCommonService {
  private emplacementFilterState = inject(EmplacementFilterState);
  private homeState = inject(HomeStateService);
  private drawerControlService = inject(DrawerControlService);
  private tagService = inject(TagService);
  private modalService = inject(ModalService);
  private tools = inject(ToolsService);
  private events = inject(EventsService);
  private routingState = inject(RoutingState);

  applyTagFilter(element: tagDocType) {
    const type = element.type === 'intervention' ? 'intervention' : 'dossier';

    // Mise à jour du filtre
    this.emplacementFilterState.simulateClickOnFilter(
      element.libelle,
      type,
      true
    );

    // Ouverture des onglets
    this.homeState.openFilter();
    if (type === 'intervention') {
      this.homeState.openInterventions();
    } else {
      this.homeState.openDossier();
    }
    this.homeState.openList();

    this.routingState.navigateClearModal();

    // Fermeture du menu
    this.drawerControlService.closeDrawer();

    // Centrage de la carte sur les éléments
    setTimeout(() => {
      this.events.publish('map:center_all');
    }, 1500);
  }

  confirmTagDeletion(tag: tagDocType) {
    this.modalService.presentBooleanDialog(
      {
        title: $localize`Suppression du dossier`,
        data: $localize`Êtes vous certain de vouloir supprimer ce dossier ?`,
      },
      () => {
        // Suppression du dossier
        this.removeTag(tag).then(() => {
          if (tag.type === 'intervention') {
            this.routingState.navigateIntervention();
            return;
          }
          this.routingState.navigateTag();
        });
      }
    );
  }

  private async removeTag(tag: tagDocType) {
    const result = await this.tagService.remove(tag);
    if (result) {
      this.tools.toastError('Le dossier a été supprimé');
      return;
    }
    this.tools.toastError('Erreur lors de la suppression du dossier');
  }
}
