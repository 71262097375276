import { Pipe, PipeTransform } from '@angular/core';
import { GroupesStateService } from './../../state/groupes-state.service';

@Pipe({
  name: 'groupe',
  standalone: true,
})
export class GroupePipe implements PipeTransform {
  constructor(private groupeState: GroupesStateService) {}
  transform(
    idGroupe: string | undefined | null,
    property: string | undefined = undefined
  ): string {
    if (!idGroupe) return '';

    const groupe = this.groupeState
      .getGroupes()
      .find((el) => el.id === idGroupe);
    if (!groupe) return '';

    if (property === 'libelle') return groupe.libelle || '';
    if (property === 'abonnement') return groupe.abonnement ?? '';

    return '';
  }
}