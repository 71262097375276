import { Map } from 'mapbox-gl';

/**
 * Removes a specified layer from the Mapbox map if it exists.
 *
 * @param map - The Mapbox map instance from which the layer will be removed.
 * @param layer - The ID of the layer to be removed.
 */
export const removeLayer = (map: Map, layer: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (map.getLayer(layer)) {
    map.removeLayer(layer);
  }
};
