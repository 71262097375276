<mat-card *ngIf="stock">
  <mat-card-header>
    <div class="w100">
      <div class="c-black d-flex flex-ac flex-sb">
        <app-ugau-button
          iconName="arrow_back"
          [hasBorder]="false"
          (click)="onCancel()"
          label="Retour"
        ></app-ugau-button>

        <div class="d-flex flex-sb flex-ac fg-1">
          <div class="d-flex-center">
            <div
              [class]="
                'm1em img-' + (stock.product_id | productId: 'image' | async)
              "
            ></div>
            <span>
              {{
                stock.meta?.designation
                  ? stock.meta?.designation
                  : (stock.product_id | productId | async)
              }}</span
            >
          </div>

          <app-ugau-button
            i18n-label
            label=""
            [hasBorder]="false"
            classColor="danger"
            iconColor="danger"
            iconName="delete"
            (click)="deleteStock(stock)"
          >
          </app-ugau-button>
        </div>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content style="padding: 0">
    <app-ugau-input-autocomplete
      label="Produit"
      [value]="
        stock.meta?.designation
          ? stock.meta?.designation
          : (stock.product_id | productId | async)
      "
      [readOnly]="
        stock.meta?.isDesignationUpdatable
          ? stock.meta?.isDesignationUpdatable === false
          : true
      "
      [options]="(optionsDesignation$ | async) || []"
      (inputChange)="onUpdateDesignationStock($event)"
      i18n-label
    ></app-ugau-input-autocomplete>

    <app-ugau-select-emplacement-stock
      label="Bâtiment"
      [value]="stock.emplacement_id"
      (depotChange)="onUpdateStock('emplacement_id', $event)"
      i18n-label
    >
    </app-ugau-select-emplacement-stock>

    <app-ugau-input-number
      label="Quantité"
      [value]="stock.quantity"
      i18n-label
      (valueChange)="onUpdateStock('quantity', $event)"
    ></app-ugau-input-number>

    <app-ugau-input-number
      label="Prix"
      [value]="stock.price"
      i18n-label
      (valueChange)="onUpdateStock('price', $event)"
    ></app-ugau-input-number>

    <app-ugau-input-number
      label="Seuil d'alerte"
      [value]="stock.alert_threshold || 0"
      i18n-label
      (valueChange)="onUpdateStock('alert_threshold', $event)"
    ></app-ugau-input-number>

    <app-ugau-input-autocomplete
      label="Fournisseur"
      [value]="stock.supplier || ''"
      [options]="(optionsSupplier$ | async) || []"
      (inputChange)="onUpdateStock('supplier', $event)"
      i18n-label
    ></app-ugau-input-autocomplete>

    <app-meta-field-menu (addMetaField)="addMetaField($event)">
    </app-meta-field-menu>

    <app-meta-field-accordion
      [metas]="stock.meta.metas"
      (updateMeta)="updateMetaField($event.meta, $event.key, $event.value)"
      (removeMeta)="removeMetaField($event)"
    ></app-meta-field-accordion>
  </mat-card-content>

  <mat-card-actions class="d-flex-center">
    <div class="fg-1">
      <app-ugau-button-annuler-valider
        [onCancel]="onCancel"
        [onValid]="onValid"
      ></app-ugau-button-annuler-valider>
    </div>
  </mat-card-actions>
</mat-card>
