const unitChoices = [
  { id: 'unit', name: $localize`(unité)` },
  { id: '%', name: $localize`(pourcentage)` },
  { id: 'm2', name: '(m²)' },
  { id: 'ml', name: '(m)' },
  { id: 'h', name: '(heure)' },
];

export function transformUnitShortcut(
  str: string,
  withParentheses: boolean = true
) {
  if (withParentheses) {
    return unitChoices.find((e) => e.id === str)?.name ?? '';
  }
  return (
    unitChoices.find((e) => e.id === str)?.name.replace(/[\(\)]/g, '') ?? ''
  );
}
