import { Injectable } from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  Subscription,
  lastValueFrom,
  combineLatest,
} from 'rxjs';
import { EmplacementService } from '../services/emplacement.service';
import { map, shareReplay, startWith, take } from 'rxjs/operators';
import { diff } from 'deep-object-diff';
import { SentryService } from '../services/sentry.service';
import { ToolsService } from '../services/tools.service';
import {
  URL_PANEL_ACTION,
  URL_PANEL_DISPLAY_ACTION,
} from '../enum/url-emplacement';
import { getDefaultIdProductByFirstFamily } from '../utils/composant/getDefaultIdProductByFirstFamily';
import { checkComposantAssocIntegrity } from '../utils/composant/checkComposantAssocIntegrity';
import { checkComposantIntegrity } from '../utils/composant/checkComposantIntegrity';
import { generateComposantFromFamily } from '../utils/composant/generateComposantFromFamily';
import { sortComposant } from '../utils/composant/sortComposant';
import { ConformiteType } from '@types_custom/conformite';
import { EMPLACEMENT_STATUS } from '../../common-projects/emplacement_status';
import { ProductFamiliesTreeState } from './product-families-tree-state.service';
import { ProductSearchStateService } from './product-search-state.service';
import { ProductFamiliesType, ProductType } from '@types_custom/ProductType';
import {
  recursiveFindFamilyAssociatedFamilies,
  getAssociatedFamiliesFromProduct,
  recursiveFindFamilyFromFamiliesId,
} from '../utils/productfamilies-utils.service';
import { ReglementationsStateService } from './reglementations-state.service';
import {
  composantDocType,
  emplacementDocType,
  emplacementPhotoDocType,
  anyProductfieldDocType,
} from '../db/schemas/emplacement.schema';
import { UsersStateService } from './users-state.service';
import { ComponentItemsOpenedState } from './emplacement-composant-list-state.service';
import { RoutingState } from './routing-state.service';
import { EmplacementDetailWidthStateService } from './emplacement-detail-width-state';
import { skipNull } from '../utils/types-rxjs-utils';
import { EmplacementDetailMapillaryStateService } from './emplacement-detail-mapilllary-state';
import { v4 as uuidv4 } from 'uuid';
import { ProductfieldsStateService } from './productfields-state.service';
import { sleep } from './../../common-projects/sleep';
import { generateAnyProductfields } from '../components/emplacement/composant-listitem/generateComposantProductfield';
import { doTreatmentAfterProductSearch } from '../components/emplacement/composant-listitem/doTreatmentAfterProductSearch';
import { WAIT_DURATION } from '../enum/WAIT_DURATION';
import { uniqBy } from 'lodash-es';
import { TagService } from '../services/tag/tag.service';
import { hasWriteOnEmplacement } from '../utils/hasWriteOnEmplacement';
import { EmplacementPhotoWidthStateService } from './emplacement-photo-width-state';
import { getUserID } from '../services/nhost';
import { invalidateEmplacementFilterCache } from './invalidateEmplacementFilterCache';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from '../utils/localstorage-utils.service';

@Injectable({
  providedIn: 'root',
})
export class EmplacementDetailStateService {
  // Changement en attente, Initialisé à false
  private hasPendingChangesSubject$ = new BehaviorSubject<boolean>(false);
  hasPendingChanges$: Observable<boolean> = this.hasPendingChangesSubject$
    .asObservable()
    .pipe(shareReplay(1));

  // Emplacement sélectionné, Initialisé à null
  private currentEmplacementSubject$ =
    new BehaviorSubject<emplacementDocType | null>(null);
  currentEmplacement$: Observable<emplacementDocType | null> =
    this.currentEmplacementSubject$.asObservable().pipe(shareReplay(1));

  // Emplacement photo selectionnée, Initialisé à null
  private currentEmplacementPhotoSubject$ =
    new BehaviorSubject<emplacementPhotoDocType | null>(null);
  currentEmplacementPhoto$ = this.currentEmplacementPhotoSubject$
    .asObservable()
    .pipe(shareReplay(1));

  subscriptionEmplacementDetail: Subscription | null = null;

  isReadOnly$: Observable<boolean> = combineLatest([
    this.currentEmplacement$,
    this.userState.getUser$(getUserID()),
  ]).pipe(
    map(([emplacement, user]) => {
      if (!emplacement || !user) return true;
      const tags = this.tagState.getTags();
      return !hasWriteOnEmplacement(emplacement, tags, user);
    }),
    startWith(true),
    shareReplay(1)
  );

  constructor(
    public routingState: RoutingState,
    private emplacementService: EmplacementService,
    private toolService: ToolsService,
    private productTreeState: ProductFamiliesTreeState,
    private productSearchState: ProductSearchStateService,
    private userState: UsersStateService,
    private tagState: TagService,
    private reglementationState: ReglementationsStateService,
    private composantsOpenedState: ComponentItemsOpenedState,
    public width: EmplacementDetailWidthStateService,
    public widthFullPhoto: EmplacementPhotoWidthStateService,
    public mapillary: EmplacementDetailMapillaryStateService,
    private productfieldsState: ProductfieldsStateService
  ) {
    this.routingState.routing$.subscribe((routing) => {
      const page = routing.page;
      if (!page) return;

      if (!page.hasPage() || !page.hasAction()) {
        this.currentEmplacementPhotoSubject$.next(null);
        this.currentEmplacementSubject$.next(null);
        this.hasPendingChangesSubject$.next(false);
        return;
      }
      if (!page.hasIdEmplacementPhoto()) {
        this.currentEmplacementPhotoSubject$.next(null);
      }
      if (!page.hasIdEmplacement()) {
        this.currentEmplacementSubject$.next(null);
      }

      const emplacement = this.getCurrentEmplacement();
      const isPageCreate = page.page === URL_PANEL_ACTION.CREATE;
      const isPageDetail = page.page === URL_PANEL_ACTION.DETAIL;
      const isNotSameEmplacement = emplacement?.id !== page.id_emplacement;
      const isActionPhoto = page.action === URL_PANEL_DISPLAY_ACTION.PHOTOS;

      if (isPageCreate && isNotSameEmplacement) {
        const familyIds = page.familyIds;

        const cadastreData = getFromLocalStorage('cadastreData', true);
        const buildingData = getFromLocalStorage('buildingData', true);
        removeFromLocalStorage('cadastreData');
        removeFromLocalStorage('buildingData');
        const hasCadastreData = cadastreData?.feature;
        const hasBuildingData = buildingData?.numero && buildingData?.feature;

        const newEmplacement = {
          ...this.emplacementService.initEmplacement(
            page.id_emplacement ?? uuidv4(),
            page.emplacement_status ?? EMPLACEMENT_STATUS.POSE,
            familyIds
          ),
          ...(page.latitude &&
            page.longitude &&
            !hasCadastreData &&
            !hasBuildingData && {
              geometry: {
                type: 'Point',
                coordinates: [+page.longitude, +page.latitude],
              } as GeoJSON.Geometry,
            }),
          ...(hasCadastreData && {
            geometry: cadastreData.feature,
            numero_ensemble: cadastreData.numero,
            commentaire: cadastreData.commentaire.join(`\n`),
          }),
          ...(hasBuildingData && {
            geometry: buildingData.feature,
            numero_ensemble: buildingData.numero,
            commentaire: buildingData.commentaire.join(`\n`),
          }),
        };

        this.currentEmplacementSubject$.next(newEmplacement);
        this.hasPendingChangesSubject$.next(false);
      }

      if (isPageDetail && isNotSameEmplacement && page.id_emplacement) {
        this.setCurrentEmplacementDetail(page.id_emplacement);
      }

      if (isActionPhoto && page.id_emplacement_photo && page.id_emplacement) {
        this.setEmplacementPhotoDetail(
          page.id_emplacement,
          page.id_emplacement_photo
        );
      }

      if (page.forceFullWidth === true) {
        this.width.setFullwidthWithoutRegisterToMeta(true);
      }
      if (page.forceFullWidth === false) {
        this.width.setFullwidthWithoutRegisterToMeta(false);
      }
    });
  }

  private setEmplacementPhotoDetail(
    idEmplacement: string,
    idEmplacementPhoto: string
  ) {
    const findPhotoAndSendToPhotoSubject = (
      emplacement: emplacementDocType
    ) => {
      const emplacementProxy = emplacement;
      const emplacementPhoto = emplacementProxy.emplacement_photos.find(
        (photo: emplacementPhotoDocType) =>
          photo.id === idEmplacementPhoto && !photo.deleted_at
      );

      if (emplacementPhoto) {
        this.currentEmplacementPhotoSubject$.next(emplacementPhoto);
      }
    };

    // Est ce que l'emplacement est déjà chargé ?
    const currentEmplacement = this.getCurrentEmplacement();
    if (currentEmplacement?.id === idEmplacement) {
      return findPhotoAndSendToPhotoSubject(currentEmplacement);
    }

    // Si non, on le charge depuis la base
    this.emplacementService
      .getEmplacementById$(idEmplacement)
      .pipe(take(1))
      .subscribe((emplacement): void => {
        if (!emplacement) {
          // L'emplacement n'existe pas encore en base lors de la création
          const emplacementCreated = this.currentEmplacementSubject$.getValue();
          if (emplacementCreated?.id === idEmplacement) {
            return findPhotoAndSendToPhotoSubject(emplacementCreated);
          }

          SentryService.throwError(
            `emplacementService.getEmplacementById$: emplacement is EMPTY (id: ${idEmplacement})`
          );
          return;
        }
        return findPhotoAndSendToPhotoSubject(emplacement);
      });
  }

  clearEmplacement() {
    if (this.subscriptionEmplacementDetail) {
      this.subscriptionEmplacementDetail.unsubscribe();
    }
    // Reset des observables
    this.currentEmplacementSubject$.next(null);
    this.currentEmplacementPhotoSubject$.next(null);
    this.hasPendingChangesSubject$.next(false);
    this.composantsOpenedState.closeComposant();
    // Reset du routing emplacement
    this.routingState.clearEmplacement();
  }

  clearEmplacementPhoto() {
    this.currentEmplacementPhotoSubject$.next(null);
    this.routingState.navigateCurrentEmplacement();
  }

  setCurrentEmplacementDetail(idEmplacement: string) {
    if (this.subscriptionEmplacementDetail) {
      this.subscriptionEmplacementDetail.unsubscribe();
    }

    this.subscriptionEmplacementDetail = this.emplacementService
      .getEmplacementById$(idEmplacement)
      .subscribe((emplacement): void => {
        if (emplacement) {
          this.currentEmplacementSubject$.next(emplacement);
        } else {
          this.clearEmplacement();
        }
        this.hasPendingChangesSubject$.next(false);
      });
  }

  cancelPendingUpdate() {
    const idEmplacement = this.getCurrentEmplacement()?.id;
    if (!idEmplacement) {
      this.throwErrorCurrentEmplacementEmpty();
      return;
    }
    this.hasPendingChangesSubject$.next(false);

    const state = this.routingState.getRouting().page;
    if (state?.page === URL_PANEL_ACTION.CREATE) {
      this.currentEmplacementSubject$.next(
        this.emplacementService.initEmplacement(idEmplacement)
      );
    }

    this.setCurrentEmplacementDetail(idEmplacement);
  }

  async hasPendingPhotoUpdate() {
    if (this.hasPendingChangesSubject$.getValue()) {
      const emplacement = this.getCurrentEmplacement();
      if (!emplacement?.id) return false;

      const oldEmplacementProxy =
        await this.emplacementService.getEmplacementById(emplacement.id);
      if (!oldEmplacementProxy) return false;

      const oldEmplacement: emplacementDocType = oldEmplacementProxy;
      if (!oldEmplacement.id) return false;

      const diffUpdated: any = diff(emplacement, oldEmplacement);
      return !!diffUpdated?.emplacement_photos?.[0];
    }
    return false;
  }

  updateEmplacement(newEmplacement: emplacementDocType) {
    const currentEmplacement = this.getCurrentEmplacement();

    if (!currentEmplacement) {
      this.throwErrorCurrentEmplacementEmpty();
      return;
    }

    this.userState
      .getUser$(getUserID())
      .pipe(take(1))
      .subscribe((user) => {
        if (!user) return;

        const hasWrite = hasWriteOnEmplacement(
          currentEmplacement,
          this.tagState.getTags(),
          user
        );

        if (!hasWrite) {
          this.hasPendingChangesSubject$.next(false);
          this.toolService.toastError(
            $localize`Lecture seule, modification impossible`
          );
          return;
        }

        this.applyUpdateEmplacement(currentEmplacement, newEmplacement);
      });
  }

  private applyUpdateEmplacement(
    currentEmplacement: emplacementDocType,
    newEmplacement: emplacementDocType
  ) {
    const diffUpdated = diff(currentEmplacement, newEmplacement);

    if (Object.keys(diffUpdated).length > 0) {
      this.hasPendingChangesSubject$.next(true);
      this.currentEmplacementSubject$.next(newEmplacement);
    }
  }

  updateComposant(
    partial: Partial<composantDocType>,
    composantId: string,
    additionnalEmp: Partial<emplacementDocType> = {}
  ) {
    const emplacement = this.getCurrentEmplacement();
    if (!emplacement) {
      // Could be possible if the user is too fast and close the emplacement
      console.log('updateComposant: emplacement does not exist anymore');
      return;
    }

    let shouldUpdate = false;

    const newEmplacement = {
      ...emplacement,
      composants: emplacement.composants.map((comp: composantDocType) => {
        if (
          comp.id === composantId &&
          Object.keys(diff(comp, partial)).length > 0
        ) {
          shouldUpdate = true;
          return {
            ...comp,
            ...partial,
            updated_at: new Date().toISOString(),
          };
        }

        return comp;
      }),
      ...additionnalEmp,
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (shouldUpdate) {
      this.updateEmplacement(newEmplacement);
    }
  }

  updateEmplacementPhoto(emplacementPhoto: emplacementPhotoDocType) {
    this.currentEmplacementPhotoSubject$.next(emplacementPhoto);
  }

  async saveEmplacement() {
    // Permet de laisser le temps au champs focus de se mettre à jour
    await sleep(500);

    const newEmplacement = this.getCurrentEmplacement();
    if (!newEmplacement) {
      this.throwErrorCurrentEmplacementEmpty();
      return false;
    }

    const isValidForSave = await this.isEmplacementValidForSave(newEmplacement);
    if (!isValidForSave) return;

    const oldEmplacement =
      (await this.emplacementService.getEmplacementById(newEmplacement.id)) ??
      {};

    const diffUpdated: any = diff(oldEmplacement, newEmplacement);

    if (diffUpdated) {
      let mustTakePhoto = false;

      if (diffUpdated?.composants) {
        mustTakePhoto = Object.keys(diffUpdated.composants).some(
          (key: string) => {
            const composant = diffUpdated.composants[key];
            if (composant?.date_fabrication) {
              return true;
            }

            if (composant?.conformites?.length) {
              return composant.conformites.some(
                (conformite: Partial<ConformiteType>) => {
                  // Il y a un reglementation_choix modifié donc photo nécessaire
                  // Donc on ne prend pas en compte AGE et TEXTE
                  return (
                    !!conformite.reglementation_choix ||
                    !!conformite.reglementation
                  );
                }
              );
            }
            return false;
          }
        );
      }

      let hasPhoto = false;
      if (diffUpdated?.emplacement_photos) {
        hasPhoto = Object.keys(diffUpdated.emplacement_photos).length !== 0;
      }

      if (mustTakePhoto && !hasPhoto && this.toolService.isMobile()) {
        // Redirection vers la prise de photo
        this.routingState.navigateCurrentEmplacementPhotoCreate();
        this.toolService.launchErrorAlert(
          $localize`Suite à des modifications, la prise de photo est obligatoire`
        );
        return;
      }

      await this.emplacementService.upsert({
        ...newEmplacement,
      });

      // Il faut invalider cet emplacement du cache de filtre
      await invalidateEmplacementFilterCache([newEmplacement.id]);

      // On attend 2 secondes sur mobile pour laisser le temps à la photo de se télécharger
      if (this.toolService.isMobile()) {
        await sleep(2500);
      }

      this.hasPendingChangesSubject$.next(false);
      this.currentEmplacementPhotoSubject$.next(null);
      if (this.toolService.isMobile()) {
        // Sur mobile, on retourne sur la home
        this.clearEmplacement();
      } else {
        this.routingState.navigateCurrentEmplacement();
        this.toolService.toastError(
          $localize`Vos modifications ont été enregistrées`
        );
      }
    }
  }

  async isEmplacementValidForSave(emp: emplacementDocType): Promise<boolean> {
    const productfields = await lastValueFrom(
      this.productfieldsState.productfields$.pipe(take(1))
    );
    const families =
      (await this.productTreeState.getOriginalTreePromise()) ?? [];
    const reglementations = this.reglementationState.getReglementations();

    // Géometry obligatoire
    const isGeometryValid = (geometry: any) => {
      return geometry.type && geometry.coordinates;
    };

    if (!isGeometryValid(emp.geometry)) {
      this.toolService.launchErrorAlert(
        $localize`L'emplacement n'a pas de position GPS, veuillez cliquer sur le bouton "Déplacer" et sélectionner une position sur la carte`
      );
      return false;
    }

    // Il faut minimum un emplacement_tag
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!emp.emplacement_tags || emp.emplacement_tags.length === 0) {
      this.toolService.launchErrorAlert(
        $localize`L'emplacement n'a pas de dossier, veuillez cliquer sur le bouton "Déplacer" et affecter au minimum un dossier`
      );
      return false;
    }

    const { checkComposant, messageComposant } = checkComposantIntegrity(
      emp,
      families,
      productfields,
      reglementations
    );
    if (checkComposant) {
      this.toolService.launchErrorAlert(messageComposant);
      return false;
    }

    const { checkComposantAssoc, messageComposantAssoc } =
      checkComposantAssocIntegrity(
        emp,
        this.productTreeState.getOriginalTree()
      );
    if (checkComposantAssoc) {
      this.toolService.launchErrorAlert(messageComposantAssoc);
      return false;
    }

    return true;
  }

  async upsertEmplacement(newEmplacement: emplacementDocType) {
    const isValidForSave = await this.isEmplacementValidForSave(newEmplacement);
    if (!isValidForSave) return;

    const oldEmplacement =
      (await this.emplacementService.getEmplacementById(newEmplacement.id)) ??
      {};

    const diffUpdated: any = diff(oldEmplacement, newEmplacement);

    if (diffUpdated) {
      let mustTakePhoto = false;

      if (diffUpdated?.composants) {
        mustTakePhoto = Object.keys(diffUpdated.composants).some(
          (key: string) => {
            const composant = diffUpdated.composants[key];
            if (composant?.date_fabrication) {
              return true;
            }

            if (composant?.conformites?.length) {
              return composant.conformites.some(
                (conformite: Partial<ConformiteType>) => {
                  // Il y a un reglementation_choix modifié donc photo nécessaire
                  // Donc on ne prend pas en compte AGE et TEXTE
                  return (
                    !!conformite.reglementation_choix ||
                    !!conformite.reglementation
                  );
                }
              );
            }
            return false;
          }
        );
      }

      let hasPhoto = false;
      if (diffUpdated?.emplacement_photos) {
        hasPhoto = Object.keys(diffUpdated.emplacement_photos).length !== 0;
      }

      if (mustTakePhoto && !hasPhoto && this.toolService.isMobile()) {
        // Redirection vers la prise de photo
        this.routingState.navigateCurrentEmplacementPhotoCreate();
        this.toolService.launchErrorAlert(
          $localize`Suite à des modifications, la prise de photo est obligatoire`
        );
        return;
      }

      await this.emplacementService.upsert(newEmplacement);
      // On attend 2 secondes sur mobile pour laisser le temps à la photo de se télécharger
      if (this.toolService.isMobile()) {
        await sleep(5000);
      }
    }
  }

  getCurrentEmplacement() {
    return this.currentEmplacementSubject$.getValue();
  }

  async getOriginalEmplacement() {
    const emplacement = this.getCurrentEmplacement();
    if (!emplacement) return;
    const oldEmplacement = await this.emplacementService.getEmplacementById(
      emplacement.id
    );
    if (!oldEmplacement) return;
    return oldEmplacement;
  }

  isCreateCurrentAction() {
    return this.routingState.isCreateCurrentAction();
  }

  throwErrorCurrentEmplacementEmpty() {
    SentryService.throwError(
      'EmplacementDetailStateService: currentEmplacement is EMPTY'
    );
  }

  addComposantsFromProduct(
    selectedProduct: ProductType | undefined,
    startFamily: ProductFamiliesType | null,
    doAssociation: boolean = true
  ) {
    const reglementations = this.reglementationState.getReglementations();
    const originalTree = this.productSearchState.getOriginalTree();
    if (originalTree.length < 1 || !startFamily) return;

    const emplacement = this.getCurrentEmplacement();
    if (!emplacement) return;

    const composant = generateComposantFromFamily(
      selectedProduct,
      startFamily.id,
      reglementations,
      this.productfieldsState.getProductfieldsComposant(),
      getUserID()
    );

    const composants = [...emplacement.composants, composant];
    let associatedFamilies = [];
    let emplacementProductfields: anyProductfieldDocType[] = [];

    if (selectedProduct?.id) {
      associatedFamilies = getAssociatedFamiliesFromProduct(
        selectedProduct.id,
        originalTree
      );

      emplacementProductfields = generateAnyProductfields(
        emplacement.emplacement_productfields,
        selectedProduct,
        this.productfieldsState.getProductfieldsEmplacement()
      );
    } else {
      associatedFamilies = recursiveFindFamilyAssociatedFamilies(
        startFamily.id,
        originalTree
      );
    }

    if (associatedFamilies.length && doAssociation) {
      // Pour chaque famille associée, on génère un composant
      const assocComposants = associatedFamilies.map((family) => {
        let defaultProduct = undefined;
        if (family.id) {
          // CAS 1 : Le localstorage contient le dernier produit sélectionné pour la famille
          const defaultProductIdLocalStorage = getDefaultIdProductByFirstFamily(
            family.id
          );
          if (defaultProductIdLocalStorage) {
            defaultProduct = this.productTreeState.getProductById(
              defaultProductIdLocalStorage
            );
          } else {
            // CAS 2 : Si la famille n'a qu'un seul produit, alors on le prend par défaut
            const familyAssoc = recursiveFindFamilyFromFamiliesId(
              family.id,
              originalTree
            );
            if (familyAssoc?.products.length === 1) {
              defaultProduct = familyAssoc.products[0].product;
            }
          }
        }

        return generateComposantFromFamily(
          defaultProduct,
          family.id,
          reglementations,
          this.productfieldsState.getProductfieldsComposant(),
          getUserID()
        );
      });
      if (assocComposants.length) {
        composants.push(...assocComposants);
      }
    }

    // Attention, il ne faut pas de doublons dans les productfields de l'emplacement
    const emplacementProductfieldsUniq = uniqBy(
      [...emplacement.emplacement_productfields, ...emplacementProductfields],
      'productfield.id'
    );

    this.updateEmplacement({
      ...emplacement,
      composants,
      emplacement_productfields: emplacementProductfieldsUniq,
    });
    this.productSearchState.clearSelectedProduct();

    setTimeout(() => {
      this.composantsOpenedState.openComposants(composant.id);
    }, 200);
  }

  async creationOpenNextComposant(composantId: string) {
    const emplacement = this.getCurrentEmplacement();
    if (!emplacement?.composants) return;

    const composants = emplacement.composants;

    // Il faut de l'ordre
    const choosenTree = await this.productTreeState.choosenTree$
      .pipe(take(1))
      .toPromise();
    if (!choosenTree) return;

    composants.sort(sortComposant(choosenTree));
    let indexBefore = composants.findIndex(
      (e: composantDocType) => e.id === composantId
    );
    if (indexBefore < 1) {
      indexBefore = 0;
    }

    if (composants[indexBefore + 1]) {
      this.composantsOpenedState.openComposants(composants[indexBefore + 1].id);

      // Si le composant suivant n'a pas de produit, alors on ouvre la recherche de produit
      if (!composants[indexBefore + 1].product?.id) {
        setTimeout(() => {
          doTreatmentAfterProductSearch(
            composants[indexBefore + 1],
            this.productTreeState,
            this.productSearchState,
            this.reglementationState,
            this,
            this.productfieldsState
          );
        }, WAIT_DURATION.SHORT);
      }
    } else {
      this.composantsOpenedState.closeComposant();
    }
  }

  getCurrentEmplacementPromise() {
    return lastValueFrom(this.currentEmplacement$.pipe(skipNull(), take(1)));
  }
}
