import Dexie from 'dexie';

export async function dexieDeleteIndexedDbs(stringFilter = '') {
  const names = await Dexie.getDatabaseNames();
  // Si la liste des noms de bases de données est vide, on ne fait rien
  if (!names.length) {
    return;
  }

  // On filtre les noms des bases de données pour ne garder que celles qui contiennent stringFilter, si stringFilter est non vide
  const filteredNames =
    stringFilter !== ''
      ? names.filter((name) => name.includes(stringFilter))
      : names;

  // Si la liste des noms filtrés est vide, on ne fait rien
  if (!filteredNames.length) {
    return;
  }

  // Delete each database sequentially with retry logic
  await filteredNames.reduce((promise, name) => {
    return promise.then(() => retryDeleteDb(name, 3));
  }, Promise.resolve());
}

function retryDeleteDb(name: string, retries: number): Promise<void> {
  return dexieDeleteDb(name).catch((err) => {
    if (retries > 1) {
      console.error(`Could not delete database: ${name}, retrying...`);
      return new Promise((resolve) => {
        setTimeout(resolve, 1000);
      }).then(() => retryDeleteDb(name, retries - 1));
    }
    console.error(`Failed to delete database after retries: ${name}`);
    throw err;
  });
}

function dexieDeleteDb(name: string): Promise<void> {
  const db = new Dexie(name);
  return db
    .delete()
    .then(() => {
      console.log(`Successfully deleted database: ${name}`);
    })
    .catch((err) => {
      console.error(`Could not delete database: ${name}`, err);
      throw err;
    });
}
