import { DataComposantType } from '@types_custom/composant';
import {
  DataEmplacementType,
  MutationEmplacementType,
} from '@types_custom/emplacement';
import { EmplacementPhotoType } from '@types_custom/emplacement-photo';
import { DataEmplacementTagType } from '@types_custom/emplacement-tag';
import { MUTATION_UPSERT_EMPLACEMENT } from '../../db/queries/emplacement.queries';
import { SentryService } from '../../services/sentry.service';
import {
  emplacementPhotoDocType,
  emplacementTagDocType,
  emplacementDocType,
  composantDocType,
  emplacementProductfieldDocType,
} from '../../db/schemas/emplacement.schema';
import { RxReplicationWriteToMasterRow } from 'rxdb/dist/types/types/replication-protocol';
import { getDataComposantFromComposant } from './getDataComposantFromComposant';

export function getEmplacementReplicationPush(
  docs: RxReplicationWriteToMasterRow<emplacementDocType>[],
  idUser: string
) {
  const datas = docs.reduce(
    (
      acc: any = {
        dataEmplacement: [],
        dataEmplacementPhotos: [],
        dataEmplacementTags: [],
        dataComposants: [],
      },
      rxDoc: RxReplicationWriteToMasterRow<emplacementDocType>
    ) => {
      const dataEmplacement: DataEmplacementType = {} as DataEmplacementType;
      const doc = rxDoc.newDocumentState;

      dataEmplacement.id = doc.id;
      dataEmplacement.status = doc.status;
      dataEmplacement.commentaire = doc.commentaire;
      dataEmplacement.numero_ensemble = doc.numero_ensemble;
      dataEmplacement.address = doc.address;
      dataEmplacement.pr = doc.pr;
      dataEmplacement.created_at = doc.created_at;
      dataEmplacement.created_by = doc.created_by ?? undefined;
      dataEmplacement.updated_at = new Date().toISOString();
      dataEmplacement.updated_by = idUser;
      dataEmplacement.deleted_at = doc.deleted_at;
      dataEmplacement.deleted_by = doc.deleted_by;
      dataEmplacement.geometry = doc.geometry;

      if (doc.interventions && doc.interventions.length > 0) {
        dataEmplacement.interventions = {
          data: doc.interventions.map((el) => {
            return {
              id_intervention: el.id_intervention,
              created_by: idUser,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              updated_by: idUser,
            };
          }),
          on_conflict: {
            constraint:
              'intervention_emplacement_id_emplacement_id_intervention_key',
            update_columns: ['updated_at'],
          },
        };
      }

      if (doc.emplacement_productfields.length > 0) {
        const dataEmplacementProductfield = doc.emplacement_productfields.map(
          (el: emplacementProductfieldDocType) => {
            return {
              id: el.id,
              value: el.value,
              created_at: el.created_at,
              updated_at: el.updated_at,
              deleted_at: el.deleted_at,
              productfield_id: el.productfield.id,
            };
          }
        );
        dataEmplacement.emplacement_productfields = {
          data: dataEmplacementProductfield,
          on_conflict: {
            constraint: 'emplacement_productfields_pkey',
            update_columns: ['value', 'updated_at', 'deleted_at'],
          },
        };
      }
      acc.dataEmplacement.push(dataEmplacement);

      // Photos
      let dataEmplacementPhotos: EmplacementPhotoType[] = [];
      if (doc.emplacement_photos.length > 0) {
        dataEmplacementPhotos = doc.emplacement_photos.map(
          (photo: emplacementPhotoDocType) => {
            return {
              ...photo,
              id_emplacement: doc.id,
            };
          }
        );
        acc.dataEmplacementPhotos = [
          ...acc.dataEmplacementPhotos,
          ...dataEmplacementPhotos,
        ];
      }

      // Tags
      let dataEmplacementTags: DataEmplacementTagType[] = [];

      if (doc.emplacement_tags.length > 0) {
        dataEmplacementTags = doc.emplacement_tags.map(
          (elTag: emplacementTagDocType) => {
            const { id_tag, id, ...elTagWithoutTag } = elTag;
            return {
              id_emplacement: doc.id,
              id_tag: id_tag,
              ...elTagWithoutTag,
            };
          }
        );
        acc.dataEmplacementTags = [
          ...acc.dataEmplacementTags,
          ...dataEmplacementTags,
        ];
      } else {
        SentryService.throwError(
          'Error (Emplacement PUSH QUERY) : Emplacement without tags',
          doc
        );
      }

      let dataComposants: DataComposantType[] = [];
      if (doc.composants.length > 0) {
        dataComposants = doc.composants.map(
          (elComp: composantDocType): DataComposantType => {
            return getDataComposantFromComposant(elComp, doc);
          }
        );
        acc.dataComposants = [...acc.dataComposants, ...dataComposants];
      }
      return acc;
    },
    {
      dataEmplacement: [],
      dataEmplacementPhotos: [],
      dataEmplacementTags: [],
      dataComposants: [],
    }
  );

  const variables: MutationEmplacementType = {
    objects_emplacement: datas.dataEmplacement,
    on_conflict_emplacement: {
      constraint: 'emplacement_pkey',
      update_columns: [
        'geometry',
        'address',
        'pr',
        'status',
        'commentaire',
        'numero_ensemble',
        'updated_at',
        'updated_by',
        'created_at',
        'created_by',
        'deleted_at',
        'deleted_by',
      ],
    },
    objects_emplacement_photo: datas.dataEmplacementPhotos,
    objects_emplacement_tag: deduplicateTags(datas.dataEmplacementTags),
    on_conflict_emplacement_tag: {
      constraint: 'emplacement_tag_id_emplacement_id_tag_key',
      update_columns: ['deleted_at', 'deleted_by'],
    },
    objects_composant: datas.dataComposants,
    on_conflict_composant: {
      constraint: 'emplacement_produit_pkey',
      update_columns: [
        'id_product',
        'updated_at',
        'updated_by',
        'created_at',
        'created_by',
        'deleted_at',
        'deleted_by',
        'quantity',
        'order_per_type',
        'type'
      ],
    },
  };

  return {
    query: MUTATION_UPSERT_EMPLACEMENT,
    variables,
  };
}

// Function to deduplicate and prioritize entries in datas.dataEmplacementTags
function deduplicateTags(data: any[]) {
  // First, sort by 'deleted_at', nulls first
  data.sort((a, b) =>
    a.deleted_at === null ? -1 : b.deleted_at === null ? 1 : 0
  );

  // Use a Map to deduplicate, preserving the first entry of each unique key
  const deduplicated = new Map();

  data.forEach((item) => {
    const key = `${item.id_tag}_${item.id_emplacement}`;
    if (
      !deduplicated.has(key) ||
      (deduplicated.get(key).deleted_at !== null && item.deleted_at === null)
    ) {
      deduplicated.set(key, item);
    }
  });

  // Convert Map values back to an array
  return Array.from(deduplicated.values());
}
