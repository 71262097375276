import { NgIf, DatePipe, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ProductIdPipe } from '../../../pipes/products/productId.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableComponentBase } from '../../../components/table-base.component';
import { Observable } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { UgauButtonComponent } from '../../../components/ugau-button/ugau-button.component';
import { UgauInputAutocompleteComponent } from '../../../components/ugau-input-autocomplete/ugau-input-autocomplete.component';
import { UsersStateService } from '../../../state/users-state.service';
import { MyAccountGroupState } from '../my-account-groups.state';
import { userTransformedType } from '../../../db/schemas/UserGroupRole';
import { GroupePipe } from '../../../pipes/groupes/groupe.pipe';
import {
  deleteUserGroupeAndTags,
  registerNewUser,
} from '../../my-account/registerNewUser';
import { DialogPromptBoolComponent } from '../../../components/dialogs/dialog-prompt-bool/dialog-prompt-bool.component';
import { MatDialog } from '@angular/material/dialog';
import { SentryService } from '../../../services/sentry.service';
import { DatabaseService } from '../../../db/database.service';
import { GroupesStateService } from '../../../state/groupes-state.service';
import {
  trackByIdFn,
  trackByNameFn,
  trackByValueFn,
} from '../../../services/trackByFunctions.utils';
import { groupeDocType } from '../../../db/schemas/groupe.schema';
import { isValidEmail } from '@nhost/nhost-js';
import { DialogPromptRegisterNewUserComponent } from '../../../components/dialogs/dialog-prompt-register-new-user/dialog-prompt-register-new-user.component';
import { RoleDisplayPipe } from './role-display.pipe';
import { transformRoleToRoleDisplay } from './transformRoleToRoleDisplay';
import { UserRoleMenuComponent } from '../user-role-menu.component';

@Component({
  selector: 'app-useralt-list',
  templateUrl: './useralt-list.component.html',
  styleUrls: ['./useralt-list.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DatePipe,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    NgClass,
    NgFor,
    NgIf,
    ProductIdPipe,
    UgauButtonComponent,
    UgauInputAutocompleteComponent,
    MatMenuModule,
    GroupePipe,
    AsyncPipe,
    RoleDisplayPipe,
    UserRoleMenuComponent,
  ],
})
export class UserAltListComponent extends TableComponentBase<userTransformedType> {
  private db = inject(DatabaseService);
  private GroupeP = inject(GroupesStateService);
  private usersState = inject(UsersStateService);
  private userAltState = inject(MyAccountGroupState);
  private dialog = inject(MatDialog);
  trackByValue = trackByValueFn;
  trackByName = trackByNameFn;
  trackByIdFn = trackByIdFn;
  key = 'useralt';

  groupes$ = this.GroupeP.groupes$;
  data$: Observable<userTransformedType[]> = this.usersState.usersTransformed$;
  columnDefs: Record<string, { title: string }> = {
    displayName: { title: 'Nom' },
    grouperole: { title: 'Groupe - Roles' },
    email: { title: 'Email' },
  };
  displayedColumns: string[] = this.isMobile
    ? ['displayName', 'actions']
    : ['displayName', 'email', 'grouperole', 'actions'];
  exceptSearchForColumns: string[] = ['actions'];

  customSortingDataAccessor(data: userTransformedType, sortHeaderId: string) {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (sortHeaderId) {
      case 'grouperole':
        return data.groupes.map((g) => g.groupe + ' ' + g.role).join(', ');
      default:
        return (data as any)[sortHeaderId];
    }
  }

  customCreateFilter(
    data: userTransformedType,
    key: string,
    value: string
  ): boolean | undefined {
    if (key === 'grouperole' && value) {
      return (
        data.groupes.some((g) => g.groupe === value) ||
        data.groupes.some((g) => transformRoleToRoleDisplay(g.role) === value)
      );
    }
    return undefined;
  }

  customGetFilterObject(
    data: userTransformedType,
    key: string
  ): string[] | string | undefined {
    if (key === 'grouperole') {
      return [
        ...data.groupes.map((g) => g.groupe),
        ...data.groupes.map((g) => transformRoleToRoleDisplay(g.role)),
      ];
    }
    return undefined;
  }

  editCollaborator(user: userTransformedType) {
    this.userAltState.navigateUserAlt(user.id);
  }

  changeUserType(
    userId: string,
    groupeId: string,
    role: 'user' | 'user_created' | 'user_created_with_prices'
  ) {
    this.userAltState.changeUserType(userId, groupeId, role);
  }

  deleteUser(
    userGroupeId: string | undefined,
    userId: string,
    groupeId: string
  ) {
    const dialog = this.dialog.open(DialogPromptBoolComponent, {
      width: '350px',
      data: {
        data: confirmMsgDeleteUser,
      },
    });

    dialog.afterClosed().subscribe((bool: Boolean) => {
      if (bool === false) return;
      if (!userGroupeId) return;

      deleteUserGroupeAndTags(userGroupeId, userId, groupeId)
        .then((result) => {
          if (result.error) {
            const hasSpecificError: string | undefined | null =
              result.error[0].extensions.internal.error.message;
            if (
              hasSpecificError?.includes(
                'partages de tag actifs impliquant ce groupe'
              )
            ) {
              this.tools.launchErrorAlert(errorMsgHasShares);
              return;
            }

            this.tools.launchErrorAlert(errorMsgDefault);
            return;
          }

          this.tools.toastError(successMsgDeleteUser);
          this.db.getGroupeProvider().replicateState?.reSync();
          this.db.getTagProvider().replicateState?.reSync();
        })
        .catch((e) => {
          SentryService.captureException(e, 'FAILED_DELETE_USER_FROM_GROUPE');
          this.tools.launchErrorAlert(errorMsgDefault);
        });
    });
  }

  addUser(groupe: groupeDocType) {
    this.promptNewUser(groupe);
  }

  promptNewUser(groupe: groupeDocType) {
    const dialogRef = this.dialog.open(DialogPromptRegisterNewUserComponent, {
      data: {
        title: $localize`Ajouter un utilisateur`,
        emailPlaceholder: $localize`Adresse email`,
        passwordPlaceholder: $localize`Mot de passe`,
      },
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((data: { email: string } | null) => {
      if (!data) return;

      const { email } = data;

      if (email) {
        const isEmailValid = isValidEmail(email);
        if (!isEmailValid) {
          this.tools.launchErrorAlert(
            $localize`Le format de l'adresse email est invalide`
          );
          return;
        }

        const errorMsg = $localize`Echec lors de la création de l'utilisateur, veuillez réessayer plus tard ou contacter le support`;

        // Création de l'utilisateur
        registerNewUser(email, groupe.id)
          .then((register) => {
            if (register?.error) {
              this.tools.launchErrorAlert(errorMsg);
              return;
            }

            this.db.getGroupeProvider().replicateState?.reSync();
            this.db.getUserProvider().replicateState?.reSync();
          })
          .catch((e) => {
            SentryService.captureException(
              e,
              'FAILED_REGISTER_NEW_USER_IN_GROUPE'
            );
            this.tools.launchErrorAlert(errorMsg);
          });
      }
    });
  }
}

const confirmMsgDeleteUser = $localize`Êtes vous certain de vouloir supprimer cet utilisateur de ce groupe ?`;
const successMsgDeleteUser = $localize`Suppression effectuée avec succès`;
const errorMsgDefault = $localize`Echec lors de la suppression de l'utilisateur, veuillez réessayer plus tard ou contacter le support`;
const errorMsgHasShares = $localize`Echec lors de la suppression de l'utilisateur, il existe des partages de tag actifs impliquant cet utilisateur et ce groupe.

            Veuillez d'abord supprimer les partages de tag actifs avant de supprimer l'utilisateur.`;
