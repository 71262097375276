import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { tagDocType } from '../../db/schemas/tag.schema';
import { initNewTag } from '../../services/tag/initNewTag';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../utils/localstorage-utils.service';
import { v4 as uuidv4 } from 'uuid';

interface TagsPageStateType {
  element: tagDocType | null;
  action: 'create' | 'update' | null;
  type: 'tag' | 'intervention' | null;
}

@Injectable({
  providedIn: 'root',
})
export class TagsPageState {
  private tagsPageSubject$ = new BehaviorSubject<TagsPageStateType>({
    element: getFromLocalStorage('tagSelected', true),
    action: getFromLocalStorage('tagACtion'),
    type: null,
  });

  tagsPage$ = this.tagsPageSubject$.asObservable();
  element$ = this.tagsPage$.pipe(map((state) => state.element));
  action$ = this.tagsPage$.pipe(map((state) => state.action));

  setElement(element: tagDocType | null) {
    setToLocalStorage('tagSelected', element);
    setToLocalStorage('tagACtion', 'update');
    this.tagsPageSubject$.next({
      ...this.tagsPageSubject$.getValue(),
      element,
      action: 'update',
    });
  }

  setAction(action: 'create' | 'update' | null) {
    this.tagsPageSubject$.next({
      ...this.tagsPageSubject$.getValue(),
      action,
    });
  }

  setNewElement(id: string = uuidv4(), type: string = 'zone') {
    const element = initNewTag({ id, type });
    setToLocalStorage('tagACtion', 'create');
    this.tagsPageSubject$.next({
      element,
      action: 'create',
      type: null,
    });
  }

  displayList() {
    setToLocalStorage('tagSelected', null);
    setToLocalStorage('tagACtion', null);
    this.tagsPageSubject$.next({
      element: null,
      action: null,
      type: null,
    });
  }

  quickOpenTag(tag: tagDocType) {
    this.tagsPageSubject$.next({
      element: tag,
      action: 'update',
      type: tag.type === 'intervention' ? 'intervention' : 'tag',
    });
  }
}
