import {
  Component,
  ChangeDetectionStrategy,
  inject,
  Input,
} from '@angular/core';
import { MyAccountGroupState } from '../my-account-groups.state';
import { NgFor, NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { UgauButtonAnnulerValiderContainer } from '../../../components/ugau-button-annuler-valider/ugau-button-annuler-valider.container';
import { UgauButtonComponent } from '../../../components/ugau-button/ugau-button.component';
import { UgauInputComponent } from '../../../components/ugau-input/ugau-input.component';
import {
  userGroupRole,
  userTransformedType,
} from '../../../db/schemas/UserGroupRole';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RoleDisplayPipe } from "../useralt-list/role-display.pipe";
import { UserRoleMenuComponent } from "../user-role-menu.component";
import { AppUseraltLinkedEmplacementsComponent } from "./user-linked-emplacements.component";

@Component({
  selector: 'app-useralt-form',
  templateUrl: './useralt-form.component.html',
  styles: [
    `
      :host {
        .icons-container {
          display: flex !important;
          justify-content: center;
          align-items: center;
        }
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    MatCardModule,
    UgauButtonAnnulerValiderContainer,
    UgauButtonComponent,
    UgauInputComponent,
    MatListModule,
    NgFor,
    MatMenuModule,
    RoleDisplayPipe,
    UserRoleMenuComponent,
    AppUseraltLinkedEmplacementsComponent
],
})
export class UserAltFormComponent {
  private myAccountGroupState = inject(MyAccountGroupState);

  trackByUserGroupeId = (index: number, item: userGroupRole) =>
    item.user_groupe_id;

  @Input() user: userTransformedType | null = null;

  onUpdateUser = (key: keyof userTransformedType, value: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.user) {
      return;
    }
    this.user[key] = value;
  };

  onValid = () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.user) {
      return;
    }
    this.goToList();
  };

  onCancel = () => {
    this.goToList();
  };

  private goToList() {
    this.myAccountGroupState.navigateUserAlt();
  }

  async deleteUser(
    userGroupeId: string | undefined,
    userId: string,
    groupeId: string
  ) {
    const ret = await this.myAccountGroupState.deleteUser(
      userGroupeId,
      userId,
      groupeId
    );
    if (ret) {
      this.goToList();
    }
  }

  changeUserType(
    userId: string,
    groupeId: string,
    role: 'user' | 'user_created' | 'user_created_with_prices'
  ) {
    this.myAccountGroupState.changeUserType(userId, groupeId, role);
  }
}
