import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-dialog-prompt-input-number',
  template: `
    <form [formGroup]="form" (ngSubmit)="onOkClick()">
      <h1 mat-dialog-title>{{ data.title }}</h1>
      <mat-form-field mat-dialog-content>
        <mat-label>{{ data.placeholder }}</mat-label>
        <input matInput type="number" formControlName="value" />
      </mat-form-field>
      <mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()" i18n>Annuler</button>
        <button mat-button cdkFocusInitial [disabled]="form.invalid" i18n>
          Valider
        </button>
      </mat-dialog-actions>
    </form>
  `,
  styles: [
    `
      :host {
        mat-form-field {
          width: 100%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
})
export class DialogPromptInputNumberComponent {
  form = this.fb.group({
    value: ['', [Validators.required]],
  });

  constructor(
    public dialogRef: MatDialogRef<DialogPromptInputNumberComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      placeholder: string;
      title: string;
      validators?: Validators[];
    }
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onOkClick(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.value);
    }
  }
}
