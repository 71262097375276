export const GET_PRODUCT_TREE_CACHE = `
  query getProductTreeCache($where: product_tree_cache_bool_exp = {}, $order_by: [product_tree_cache_order_by!]!, $limit: Int!) {
    product_tree_cache(
      where: $where,
      order_by: $order_by,
      limit: $limit
    ) {
      hash
      id
      json
      updated_at
      deleted_bool
      order
      type
    }
  }
`;

export const GET_PRODUCT_TREE_CACHE_WITHOUT_JSON = `
  query getProductTreeCache($where: product_tree_cache_bool_exp = {}, $order_by: [product_tree_cache_order_by!]!, $limit: Int!) {
    product_tree_cache(
      where: $where,
      order_by: $order_by,
      limit: $limit
    ) {
      hash
      id
      updated_at
      order
    }
  }
`;
