import { DatePipe, NgFor } from '@angular/common';
import {
  Component,
  Inject,
  ChangeDetectionStrategy,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { StockState } from './stock.state';
import { MatIconModule } from '@angular/material/icon';
import { UserPipe } from '../../pipes/users/user.pipe';
import { UsersStateService } from '../../state/users-state.service';
import {
  downloadArrayAsCSV,
  generateTimeName,
} from '../../services/utils.service';
import { StockHistoryItem } from './StockHistoryItem';
import { UgauButtonComponent } from '../ugau-button/ugau-button.component';

@Component({
  selector: 'app-stock-history-dialog',
  template: `
    <h2 mat-dialog-title>
      <div class="d-flex flex-sb">
        <span i18n>Historique du stock</span>
        <div class="d-flex-center">
          <app-ugau-button
            label=""
            classColor="black"
            [hasBorder]="false"
            iconName="download"
            (click)="download()"
          ></app-ugau-button>

          <app-ugau-button
            label=""
            classColor="black"
            [hasBorder]="false"
            iconName="close"
            (click)="close()"
          ></app-ugau-button>
        </div>
      </div>
    </h2>
    <mat-dialog-content>
      <mat-list>
        <mat-list-item *ngFor="let item of dataParsed; trackBy: trackById">
          <div mat-line>
            [{{ item.created_at | date: 'medium' }}]
            <strong>{{ item.designation }}</strong> -
            {{ item.change_type === 'increase' ? 'Entrée' : 'Sortie' }}
            {{ item.change_quantity }}
            ({{ item.created_by | user: 'displayName' }})
          </div>
        </mat-list-item>
      </mat-list>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="close()" i18n>Fermer</button>
    </mat-dialog-actions>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatButtonModule,
    NgFor,
    DatePipe,
    UserPipe,
    UgauButtonComponent,
  ],
})
export class HistoryDialogComponent {
  private stockState = inject(StockState);
  private users = inject(UsersStateService).getUsersFromGroupes();

  dataParsed: any[] = [];

  trackById(index: number, item: StockHistoryItem): string {
    return item.id;
  }

  constructor(
    public dialogRef: MatDialogRef<HistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StockHistoryItem[]
  ) {
    this.dataParsed = data.map((item) => {
      return {
        ...item,
        designation: this.stockState.getStockDesignation(item.stock),
      };
    });
  }

  download() {
    const keysToi18n = {
      date: $localize`Date`,
      designation: $localize`Désignation`,
      type: $localize`Type`,
      quantity: $localize`Quantité`,
      created_by: $localize`Par`,
    };

    const csvArray = this.dataParsed.map((item) => {
      return {
        [keysToi18n.date]: item.created_at,
        [keysToi18n.designation]: item.designation,
        [keysToi18n.type]:
          item.change_type === 'increase' ? 'Entrée' : 'Sortie',
        [keysToi18n.quantity]: item.change_quantity,
        [keysToi18n.created_by]: this.users.find(
          (user) => user.id === item.created_by
        )?.displayName,
      };
    });
    const filename = generateTimeName('export_history');
    downloadArrayAsCSV(csvArray, filename);
  }

  close(): void {
    this.dialogRef.close();
  }
}
