import {
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { COLORS, COLORS_BTN } from './../../enum/colors';
import { UgauButtonComponent } from '../ugau-button/ugau-button.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatListModule } from '@angular/material/list';

@Component({
    selector: 'app-ugau-listitem',
    templateUrl: './ugau-listitem.component.html',
    styleUrls: ['./ugau-listitem.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatListModule,
        NgIf,
        MatIconModule,
        MatChipsModule,
        UgauButtonComponent,
    ],
})
export class UgauListitemComponent {

  @Input() title: string = '';
  @Input() titleColor: string | undefined = COLORS.LISTITEM_TITLE;
  @Input() titleBadge: string | undefined = undefined;
  @Input() titleBadgeColor: string | undefined = undefined;
  @Input() badgePosition: 'left' | 'right' = 'right';
  @Input() image: string | undefined;
  @Input() iconImage: string | undefined;

  @Input() firstLine: string | undefined | null;
  @Input() firstLineColor: string | undefined = COLORS.LISTITEM_FIRST_LINE;

  @Input() secondLine: string | undefined | null;
  @Input() secondLineColor: string | undefined = COLORS.LISTITEM_SECOND_LINE;

  @Input() enableClick = true;
  @Input() showDetailledData: boolean = false;
  @Input() forceShowDetailledData: boolean = false;

  @Input() showActionButton: boolean = false;
  @Input() iconActionButton: string = '';
  @Input() colorActionButton: COLORS_BTN = 'primary';
  @Input() clickActionButtonCallback: any;
  @Output() clickActionButton = new EventEmitter<any>();

  @Input() showDeleteButton: boolean = false;
  @Input() iconDeleteButton: string = '';
  @Input() colorDeleteButton: COLORS_BTN = 'primary';
  @Input() clickDeleteButtonCallback: any;
  @Output() clickDeleteButton = new EventEmitter<any>();

  @Input() isUsingSprite: boolean = false;

  @Input()
  @HostBinding('class.sub-mat-item')
  isSubList: boolean = false;

  @Input()
  @HostBinding('class.has-prewrap')
  hasPreWrap: boolean = false;

  @Input()
  @HostBinding('class.has-free-height')
  hasFreeHeight: boolean = false;

  @Output() openChanged = new EventEmitter<boolean>();

  toggleDetailledData() {
    if (this.enableClick) {
      this.showDetailledData = !this.showDetailledData;
      this.openChanged.emit(this.showDetailledData);
    }
  }

  clickActionBtn(e: Event) {
    e.stopPropagation();
    if (this.clickActionButtonCallback) this.clickActionButtonCallback();
    this.clickActionButton.emit();
  }

  clickDeleteBtn(e: Event) {
    e.stopPropagation();
    if (this.clickDeleteButtonCallback) this.clickDeleteButtonCallback();
    this.clickDeleteButton.emit();
  }
}
