import { ProductTypeOrUndefined } from '@types_custom/ProductType';
import { v4 as uuidv4 } from 'uuid';
import {
  composantDocType,
  composantProductfieldDocType,
  productDocType,
} from '../../db/schemas/emplacement.schema';
import { reglementationDocType } from '../../db/schemas/reglementation.schema';
import { productfieldDocType } from '../../db/schemas/productfields.schema';
import { generateConformites } from './../conformite/createConformiteFromReglementationId';
import { generateAnyProductfields } from './../../components/emplacement/composant-listitem/generateComposantProductfield';

export function generateComposantFromFamily(
  selectedProduct: ProductTypeOrUndefined,
  startFamilyId: string | undefined,
  reglementations: reglementationDocType[],
  fields: productfieldDocType[],
  userId: string
) {
  const composant: composantDocType = {
    id: uuidv4(),
    product: selectedProduct as productDocType,
    type: startFamilyId ?? '',
    order_per_type: 1,
    quantity: selectedProduct?.defaultQuantity ?? 1,
    created_at: new Date().toISOString(),
    created_by: userId,
    updated_at: new Date().toISOString(),
    updated_by: userId,
    deleted_at: null,
    deleted_by: null,
    conformites: [],
    composant_productfields: [],
  };

  if (selectedProduct?.id) {
    // Création des conformites pour le composant en fonction du produit
    composant.conformites = generateConformites(
      composant,
      selectedProduct,
      reglementations,
      userId
    );
    composant.composant_productfields = generateAnyProductfields(
      composant.composant_productfields,
      selectedProduct,
      fields
    ) as composantProductfieldDocType[];
  }
  return composant;
}
