import { Pipe, PipeTransform } from '@angular/core';
import { transformUnitShortcut } from '../utils/unit-choices-utils';

@Pipe({
    name: 'transformUnit',
    standalone: true,
})
export class TransformUnitPipe implements PipeTransform {
  transform(value: string): string {
    return transformUnitShortcut(value, false);
  }
}
