import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DateArgType } from '@types_custom/date';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldAppearance, MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-ugau-date',
  template: `
    <mat-form-field
      class="ugau-input-date"
      [appearance]="appearance"
      floatLabel="always"
    >
      <mat-label>{{ placeholder }}</mat-label>
      <input
        (dateChange)="doDateJob()"
        [(ngModel)]="date"
        matInput
        [matDatepicker]="picker"
        [readonly]="readonly"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker
        (opened)="reset()"
        #picker
        [disabled]="readonly"
      ></mat-datepicker>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: flex;
        .ugau-input-date {
          padding-left: 16px;
          padding-right: 16px;
          width: 100%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    ReactiveFormsModule,
  ],
})
export class UgauDateComponent {
  _date: Date | undefined | null;
  @Input() set date(value: Date | string | undefined | null) {
    if (typeof value === 'string') {
      const parsedDate = new Date(value);
      this._date = isNaN(parsedDate.getTime()) ? null : parsedDate;
    } else {
      this._date = value;
    }
  }
  get date(): Date | string | undefined | null {
    return this._date;
  }
  @Input() placeholder: string | undefined = $localize`Date`;
  @Input() readonly = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Output() emitSelectedItems = new EventEmitter<any>();
  picker: any;

  doDateJob() {
    if (this.date) {
      if (typeof this.date === 'string') this.date = new Date(this.date);
      const date: DateArgType = {
        date: this.date,
      };
      this.emitSelectedItems.emit(date);
    }
  }

  reset() {
    this.date = undefined;
    this.doDateJob();
  }
}
