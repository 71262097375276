import { DurationType } from '@types_custom/duration';
import { ISelectItem } from './../../db/interfaces/select-item';
import { COLORS_BTN } from './../../enum/colors';
import { getFromLocalStorage } from '../../utils/localstorage-utils.service';
import { LOCALSTORAGE } from './../../enum/local-storage';
import { durationsChoices } from './../../../common-projects/durationsChoices';

export class DurationSelectItem implements ISelectItem {
  item: DurationType;
  getLibelle() {
    return `${this.item.libelle}`;
  }
  getIdentifier() {
    return this.item.id;
  }
  getColor(): COLORS_BTN {
    return 'primary';
  }

  constructor(item: DurationType) {
    this.item = item;
  }
}

export function getDurationChoice(
  id: string | undefined,
  productfield_id: string | undefined = undefined
) {
  if (!id) return undefined;

  const overrideDurations = getFromLocalStorage(
    LOCALSTORAGE.OVERRIDE_DURATIONS,
    true
  );
  if (
    overrideDurations &&
    productfield_id &&
    overrideDurations[productfield_id]
  ) {
    const duration = durationsChoices.find(
      (e) =>
        e.id.toLowerCase() === overrideDurations[productfield_id].toLowerCase()
    );
    if (duration) {
      return duration;
    }
  }

  return durationsChoices.find((e) => e.id.toLowerCase() === id.toLowerCase());
}
