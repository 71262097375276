import { productfieldDocType } from '../db/schemas/productfields.schema';
import { productpropertiesDocType } from '../db/schemas/productproperties.schema';
import { isComposantDurationsValid } from '../utils/composant/isComposantDurationsValid';
import {
  emplacementDocType,
  composantDocType,
  conformiteDocType,
} from '../db/schemas/emplacement.schema';
import { isOkConformiteAge } from '../utils/conformite/isOkConformiteAge';
import { isConformiteDateFabrication } from '../utils/conformite/isConformiteDateFabrication';
import { isConformiteRecurrence } from '../utils/conformite/isConformiteRecurrence';
import { reglementationDocType } from '../db/schemas/reglementation.schema';
import { isConformiteTexte } from '../utils/conformite/isConformiteTexte';
import { ProductFamiliesType } from '@types_custom/ProductType';

const cacheComposantConf = new Map<string, boolean>();

export function isConformeEmplacementV2(
  emplacement: emplacementDocType,
  families: ProductFamiliesType[],
  properties: productpropertiesDocType[],
  productfields: productfieldDocType[],
  reglementations: reglementationDocType[],
  debug = false
): boolean {
  return emplacement.composants.every((composant: composantDocType) => {
    return isConformeComposantV2(
      composant,
      null,
      families,
      properties,
      productfields,
      reglementations,
      debug
    );
  });
}

export function isNotConformeComposantV2(
  composant: composantDocType,
  conformite: any = null,
  families: ProductFamiliesType[],
  properties: productpropertiesDocType[],
  productfields: productfieldDocType[],
  reglementations: reglementationDocType[]
) {
  return !isConformeComposantV2(
    composant,
    conformite,
    families,
    properties,
    productfields,
    reglementations
  );
}

function isConformeComposantV2(
  composant: composantDocType,
  conformite: any = null,
  families: ProductFamiliesType[],
  properties: productpropertiesDocType[],
  productfields: productfieldDocType[],
  reglementations: reglementationDocType[],
  debug = false
) {
  const cacheKey = `${composant.id}-${composant.updated_at}-${families.map((e) => e.id).join('-')}`;
  const cachedResult = cacheComposantConf.get(cacheKey);
  if (cachedResult) {
    return cachedResult;
  }

  if (
    composant.conformites.length < 1 ||
    !composant.product?.id ||
    composant.deleted_at
  ) {
    cacheComposantConf.set(cacheKey, true);
    return true;
  }

  if (conformite) {
    // Est ce que la conformité est précisée ?
    // Est ce qu'il s'agit de la conformité pour l'age ?
    if (isConformiteDateFabrication(conformite, reglementations)) {
      const conformeAge = isOkConformiteAge(
        composant,
        composant.product,
        productfields,
        properties
      );
      cacheComposantConf.set(cacheKey, conformeAge);
      return conformeAge;
    }

    if (isConformiteRecurrence(conformite, reglementations)) {
      const result = isComposantDurationsValid(composant, productfields);
      cacheComposantConf.set(cacheKey, result);
      return result;
    }

    if (isConformiteTexte(conformite, reglementations)) {
      const result = !!conformite.valeur && conformite.valeur !== '';
      cacheComposantConf.set(cacheKey, result);
      return result;
    }

    // On retourne le boolean de la conformité
    const result = conformite.est_conforme === true;
    cacheComposantConf.set(cacheKey, result);
    return result;
  }

  // Pas de conformité de préciser, on check tout le composant
  const conforme = composant.conformites
    .filter((el: conformiteDocType) => !el.deleted_at)
    .every((el: conformiteDocType) => {
      // On ne compte pas le boolean de la conformite de l'age
      if (
        isConformiteDateFabrication(el, reglementations) &&
        composant.product
      ) {
        return isOkConformiteAge(
          composant,
          composant.product,
          productfields,
          properties
        );
      }
      if (isConformiteRecurrence(el, reglementations)) {
        return isComposantDurationsValid(composant, productfields);
      }

      if (isConformiteTexte(el, reglementations)) {
        return !!el.valeur && el.valeur !== '';
      }
      return el.est_conforme;
    });

  cacheComposantConf.set(cacheKey, conforme);
  return conforme;
}
