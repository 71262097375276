import * as Sentry from '@sentry/angular-ivy';
import { ScopeContext } from '@sentry/types';
import { environment } from './../../environments/environment';

export const SentryService = {
  throwError(
    error: Error | string,
    extra?: Record<string, any>,
    options?: Partial<ScopeContext>
  ) {
    if (!this.isSentryEnabled()) {
      this.logToConsole(error, options?.level, extra);
      return;
    }

    if (typeof error === 'string') {
      this.captureMessage(error, extra, options);
      throw new Error(error); // Maintain consistency by converting string to Error
    } else {
      this.captureException(error, extra, options);
      throw error;
    }
  },

  captureException(
    error: Error | unknown,
    extra?: Record<string, any> | string,
    options?: Partial<ScopeContext>
  ) {
    if (!this.isSentryEnabled()) {
      this.logToConsole(error, options?.level, extra);
      return;
    }

    const extraData = typeof extra === 'string' ? { message: extra } : extra;

    Sentry.captureException(error, {
      user: options?.user, // Ensure user is of type User or undefined
      level: options?.level ?? 'error',
      extra: extraData,
      contexts: options?.contexts,
      tags: options?.tags,
      fingerprint: options?.fingerprint,
      requestSession: options?.requestSession,
    });
  },

  captureMessage(
    message: string,
    extra?: Record<string, any>,
    options?: Partial<ScopeContext>
  ) {
    if (!this.isSentryEnabled()) {
      this.logToConsole(message, options?.level, extra);
      return;
    }

    Sentry.captureMessage(message, {
      user: options?.user,
      level: options?.level ?? 'error',
      extra,
      contexts: options?.contexts,
      tags: options?.tags,
      fingerprint: options?.fingerprint,
      requestSession: options?.requestSession,
    });

    this.logToConsole(message, options?.level, extra); // Always log to console after capture
  },

  flush() {
    if (!this.isSentryEnabled()) return Promise.resolve();

    return Sentry.flush(2000).catch((err) => {
      console.error('Error flushing Sentry:', err);
      return Promise.resolve(); // Return resolved promise to avoid breaking chains
    });
  },

  logToConsole(
    message: string | Error | unknown,
    level: 'fatal' | 'error' | 'warning' | 'info' | 'log' | 'debug' = 'log',
    extra?: any
  ) {
    const logMethods = {
      fatal: console.error,
      error: console.error,
      warning: console.warn,
      info: console.log,
      log: console.log,
      debug: console.debug,
    };

    const log = logMethods[level];
    log(message, extra);
  },

  isSentryEnabled() {
    return environment.ENABLE_SENTRY;
  },
};
