import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Platform } from '@ionic/angular/standalone';
import { DialogMessageComponent } from './DialogDataExampleDialog';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  private platform = inject(Platform);
  private snackBar = inject(MatSnackBar);
  private dialog = inject(MatDialog);

  isMobile() {
    return this.platform.is('android') || this.platform.is('ios');
  }

  toastError(
    content: string,
    duration: number = 2000,
    className: string = 'ugau-toast'
  ) {
    this.snackBar.open(content, 'Ok', {
      duration: duration,
      panelClass: [className],
    });
  }

  launchErrorAlert(
    content: string,
    title = 'Erreur !',
    useHtmlContent = false
  ) {
    this.dialog.open(DialogMessageComponent, {
      data: { title, message: content, buttons: 'Fermer', useHtmlContent },
    });
  }

  launchAlert(
    header: string,
    message: string,
    buttons: any,
    columnForButton: boolean = false,
    useHtmlContent = false
  ) {
    this.dialog.open(DialogMessageComponent, {
      data: {
        title: header,
        message: message,
        buttons: buttons,
        columnForButton: columnForButton,
        useHtmlContent,
      },
    });
  }

  launchInfoAlert(
    content: string,
    title = 'Informations',
    useHtmlContent = false
  ) {
    this.dialog.open(DialogMessageComponent, {
      data: { title, message: content, buttons: 'Ok', useHtmlContent },
    });
  }
}
