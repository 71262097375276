import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { MyAccountGroupState } from './my-account-groups.state';
import { NgIf } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { UgauButtonComponent } from '../../components/ugau-button/ugau-button.component';

@Component({
  selector: 'app-user-role-menu',
  template: `
    <app-ugau-button
      label=""
      [hasBorder]="false"
      iconName="more_vert"
      [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation()"
    ></app-ugau-button>

    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngIf="gr.role !== 'user'"
        (click)="changeUserType(user.id, gr.groupe_id, 'user')"
        i18n
      >
        Passer en gestionnaire
      </button>
      <button
        mat-menu-item
        *ngIf="gr.role !== 'user_created'"
        (click)="changeUserType(user.id, gr.groupe_id, 'user_created')"
        i18n
      >
        Passer en opérateur sans prix
      </button>
      <button
        mat-menu-item
        *ngIf="gr.role !== 'user_created_with_prices'"
        (click)="
          changeUserType(user.id, gr.groupe_id, 'user_created_with_prices')
        "
        i18n
      >
        Passer en opérateur avec prix
      </button>
    </mat-menu>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatMenuModule, UgauButtonComponent],
})
export class UserRoleMenuComponent {
  private myAccountGroupState = inject(MyAccountGroupState);
  // Input properties for user and group roles
  @Input() user!: { id: string }; // User object with user ID
  @Input() gr!: { role: string; groupe_id: string }; // Group role with role type and group ID

  // Function to change user type based on selected role
  changeUserType(
    userId: string,
    groupId: string,
    role: 'user' | 'user_created' | 'user_created_with_prices'
  ): void {
    this.myAccountGroupState.changeUserType(userId, groupId, role);
  }
}
