import mapboxgl, { AnySourceData } from 'mapbox-gl';

/**
 * Adds layers to the Mapbox map with a specific z-index order.
 *
 * This function initializes a GeoJSON source and adds two layers ('z-index-2' and 'z-index-1')
 * to the map if they do not already exist. The layers are added in such a way that 'z-index-1'
 * is rendered below 'z-index-2', mimicking the behavior of z-index in CSS.
 *
 * @param {mapboxgl.Map} map - The Mapbox map instance to which the layers will be added.
 */
export function addLayerZindex(map: mapboxgl.Map) {
  const initSource: AnySourceData = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  };

  // Pour faire de l'equivalent de z-index avec les layers
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!map.getLayer('z-index-2')) {
    map.addLayer({
      id: 'z-index-2',
      type: 'symbol',
      source: initSource,
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!map.getLayer('z-index-1')) {
    map.addLayer(
      {
        id: 'z-index-1',
        type: 'symbol',
        source: initSource,
      },
      'z-index-2'
    );
  }
}
