import { emplacementDocType } from '../../schemas/emplacement.schema';

export const getEmplacementMigrationStrategies = {
  1: function (oldDoc: emplacementDocType) {
    return null;
  },
  2: function (oldDoc: emplacementDocType) {
    return null;
  },
  3: function (oldDoc: emplacementDocType) {
    return null;
  },
  4: function (oldDoc: emplacementDocType) {
    return null;
  },
  5: function (oldDoc: emplacementDocType) {
    return null;
  },
  6: function (oldDoc: emplacementDocType) {
    return null;
  },
  7: function (oldDoc: emplacementDocType) {
    return null;
  },
  8: function (oldDoc: emplacementDocType) {
    return null;
  },
};
