import { LngLat, LngLatBounds } from 'mapbox-gl';

/**
 * Calculates the center point of the given bounding box.
 *
 * @param bounds - The bounding box for which the center point is calculated.
 * @returns An object containing the latitude, longitude, and a `LngLat` object representing the center point.
 */
export function getBoundsCenter(bounds: LngLatBounds) {
  return {
    lat: bounds.getCenter().lat,
    lng: bounds.getCenter().lng,
    latlng: new LngLat(bounds.getCenter().lng, bounds.getCenter().lat),
  };
}
