import { WorkerFilterChild } from './worker.types';

export const CACHE_INDEX_PARENT_NODE = new Map<string, number>();
export const DB_NAME = 'ugau_cache_filter_worker';
export const STORE_NAME = 'cache_store';
export const CACHE_NAME = 'EMPLACEMENTS_FILTERS';
export const cachedProcessedEmplacements = new Map<
  string,
  WorkerFilterChild[] | undefined
>();
