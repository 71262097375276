import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { DatabaseService } from '../db/database.service';
import { AuthenticationService } from './authentication.service';
import { Platform } from '@ionic/angular/standalone';
import { isRxDatabase } from 'rxdb';
import { auth } from './nhost';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  hasCheckedUpdate: boolean = false;

  constructor(
    public router: Router,
    public auth: AuthenticationService,
    public db: DatabaseService,
    public platform: Platform
  ) {}

  /**
   * Permet de vérifier le droit d'accès a une page
   * Vérification effectuées :
   * - Vérification de la version avec le LIVEUPDATE (Une seule fois)
   * - Vérification de l'authentification de l'utilisateur
   * - Vérification de l'existance de la base de données IDB
   * @param {ActivatedRouteSnapshot} _next -
   * @param {RouterStateSnapshot} state - 
   * @return {| Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree} 
   */
  public async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const queryParams = next.queryParams;
    // console.log('queryParams', queryParams);
    // console.log('state.url', state.url);

    if (this.handleTicketVerification(queryParams)) {
      // Si la vérification du ticket entraîne une redirection, on termine l'exécution ici.
      return false;
    }

    if (this.needToUpdate(state.url, queryParams)) {
      // console.log('AUTH Redirect to updating');
      return this.redirectTo(['/updating'], state.url, queryParams);
    }

    if (!(await this.isAuthenticated(next.queryParams))) {
      // console.log('AUTH Redirect to login');
      return this.redirectTo(['/login'], state.url, queryParams);
    }

    if (!this.isDatabaseInitialized()) {
      // console.log('AUTH Redirect to loading');
      return this.redirectTo(['/loading'], state.url, queryParams);
    }

    return true;
  }

  private handleTicketVerification(queryParams: any): boolean {
    const { ticket, redirectTo, type } = queryParams;
    if (ticket && redirectTo && type) {
      const authUrl = auth.url; // Assurez-vous que cette méthode existe et retourne l'URL appropriée
      window.location.href = `${authUrl}/verify?ticket=${ticket}&type=${type}&redirectTo=${redirectTo}`;
      return true;
    }
    return false;
  }

  private needToUpdate(requestedUrl: string, queryParams: any): boolean {
    if (
      (this.platform.is('android') || this.platform.is('ios')) &&
      !this.hasCheckedUpdate &&
      !queryParams.skipUpdate
    ) {
      this.hasCheckedUpdate = true;
      return true;
    }
    return false;
  }

  private async isAuthenticated(queryParams: any): Promise<boolean> {
    const isLoggedIn = await this.auth.isLoggedInAsync();
    if (!isLoggedIn && this.canRefreshSession(queryParams)) {
      const { error } = await auth.refreshSession(queryParams.refreshToken);
      return !error;
    }
    return isLoggedIn;
  }

  private canRefreshSession(queryParams: any): boolean {
    return (
      queryParams.refreshToken &&
      ['signinPasswordless', 'emailVerify'].includes(queryParams.type)
    );
  }

  private isDatabaseInitialized(): boolean {
    return isRxDatabase(this.db.dbInstance);
  }

  private redirectTo(
    route: any[],
    requestedUrl: string,
    queryParams: any
  ): UrlTree {
    return this.router.createUrlTree(route, {
      queryParams: { ...queryParams, returnUrl: requestedUrl },
    });
  }
}
