import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  HostBinding,
  Injector,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogComponent } from './dialog-component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FocusStateDirective } from './focus-state.directive';
import { RoutingState } from '../../state/routing-state.service';

@Component({
  selector: 'app-modale',
  template: `
    <div class="modale-content" #modaleContainer appFocusState="modale">
      <ng-container #modaleContent></ng-container>
    </div>
  `,
  styles: [
    `
      :host {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-top: env(safe-area-inset-top);

        .modale-content {
          background: #fff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          position: relative;
          z-index: 1001;
          width: 100%;
          height: 100%;
          overflow-y: auto;
          animation: slide-down 0.3s ease-out;
        }

        &.hidden {
          display: none;
        }
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, FocusStateDirective],
})
export class AppModaleComponent implements AfterViewInit {
  @ViewChild('modaleContainer', { read: ViewContainerRef })
  modaleContainer!: ViewContainerRef;

  @ViewChild('modaleContent', { read: ViewContainerRef })
  modaleContent!: ViewContainerRef;

  componentRef!: ComponentRef<DialogComponent>;

  private isVisibleSubject$ = new BehaviorSubject<boolean>(false);
  isVisible = this.isVisibleSubject$.asObservable();

  @HostBinding('class.hidden') get hidden() {
    return !this.isVisibleSubject$.value;
  }

  private isInitialized = false;

  constructor(
    private elRef: ElementRef,
    private routingState: RoutingState
  ) {}

  private queue: {
    component: any;
    inputs?: any;
    title: string;
    icon?: string;
    iconImg?: string;
    width?: string;
    height?: string;
    maxHeight?: string;
    maxWidth?: string;
  }[] = [];

  ngAfterViewInit() {
    this.isInitialized = true;
    this.queue.forEach((item) => {
      this.executeOpenModale(
        item.component,
        item.title,
        item.icon,
        item.inputs,
        item.width,
        item.height,
        item.maxHeight,
        item.maxWidth
      );
    });
    this.queue = [];
  }

  openModale(
    component: any,
    params: {
      title: string;
      icon?: string;
      iconImg?: string;
      inputs?: any;
      width?: string;
      height?: string;
      maxHeight?: string;
      maxWidth?: string;
    }
  ) {
    if (this.isInitialized) {
      this.executeOpenModale(
        component,
        params.title,
        params.icon,
        params.iconImg,
        params.inputs,
        params.width,
        params.height,
        params.maxHeight,
        params.maxWidth
      );
    } else {
      this.queue.push({
        component,
        inputs: params.inputs,
        title: params.title,
        icon: params.icon,
        iconImg: params.iconImg,
        width: params.width,
        height: params.height,
        maxHeight: params.maxHeight,
        maxWidth: params.maxWidth,
      });
    }
  }

  private executeOpenModale(
    component: any,
    title: string,
    icon: string|undefined,
    iconImg?: string,
    inputs?: any,
    width?: string,
    height?: string,
    maxHeight?: string,
    maxWidth?: string
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.modaleContent) {
      console.error('modaleContent is not initialized properly.');
      return;
    }

    this.isVisibleSubject$.next(true);
    this.modaleContent.clear();

    const injector = Injector.create({
      providers: [
        { provide: MatDialogRef, useClass: FakeMatDialogRef },
        {
          provide: MAT_DIALOG_DATA,
          useValue: {
            title,
            icon,
            bodyComponent: component,
            inputs,
          },
        },
      ],
    });

    this.componentRef = this.modaleContent.createComponent(DialogComponent, {
      injector,
    });
    this.elRef.nativeElement.style.width = width ?? '100%';
    this.elRef.nativeElement.style.height = height ?? '100%';
    this.elRef.nativeElement.style.maxHeight = maxHeight ?? '100%';
    this.elRef.nativeElement.style.maxWidth = maxWidth ?? '100%';

    // Passe les données nécessaires à DialogComponent
    this.componentRef.instance.updateData({
      title,
      icon,
      iconImg,
      bodyComponent: component,
      inputs,
    });

    this.componentRef.instance.closeSubject$.subscribe(() =>
      this.closeModale()
    );
  }

  closeModale() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.componentRef) this.componentRef.destroy();
    this.isVisibleSubject$.next(false);
    this.modaleContent.clear();
    this.routingState.navigateClearModal();
  }
}

// Créez un faux MatDialogRef qui ne fait rien, juste pour éviter l'erreur d'injection
class FakeMatDialogRef {
  close(): void {
    console.log('FakeMatDialogRef close called');
  }
}
