export const durationsChoices = [
  {
    id: '1w',
    libelle: 'Une semaine',
  },
  {
    id: '2w',
    libelle: '2 semaines',
  },
  {
    id: '3w',
    libelle: '3 semaines',
  },
  {
    id: '4w',
    libelle: '4 semaines',
  },
  {
    id: '1M',
    libelle: '1 mois',
  },
  {
    id: '2M',
    libelle: '2 mois',
  },
  {
    id: '3M',
    libelle: '3 mois',
  },
  {
    id: '4M',
    libelle: '4 mois',
  },
  {
    id: '5M',
    libelle: '5 mois',
  },
  {
    id: '6M',
    libelle: '6 mois',
  },
  {
    id: '7M',
    libelle: '7 mois',
  },
  {
    id: '8M',
    libelle: '8 mois',
  },
  {
    id: '9M',
    libelle: '9 mois',
  },
  {
    id: '10M',
    libelle: '10 mois',
  },
  {
    id: '11M',
    libelle: '11 mois',
  },
  {
    id: '12M',
    libelle: '12 mois',
  },
  {
    id: '13M',
    libelle: '13 mois',
  },
  {
    id: '14M',
    libelle: '14 mois',
  },
  {
    id: '15M',
    libelle: '15 mois',
  },
  {
    id: '16M',
    libelle: '16 mois',
  },
  {
    id: '17M',
    libelle: '17 mois',
  },
  {
    id: '18M',
    libelle: '18 mois',
  },
  {
    id: '19M',
    libelle: '19 mois',
  },
  {
    id: '20M',
    libelle: '20 mois',
  },
  {
    id: '21M',
    libelle: '21 mois',
  },
  {
    id: '22M',
    libelle: '22 mois',
  },
  {
    id: '23M',
    libelle: '23 mois',
  },
  {
    id: '24M',
    libelle: '24 mois',
  },
  {
    id: '36M',
    libelle: '2 ans et demi',
  },
  {
    id: '3y',
    libelle: '3 ans',
  },
  {
    id: '42M',
    libelle: '3 ans et demi',
  },
  {
    id: '4y',
    libelle: '4 ans',
  },
  {
    id: '54M',
    libelle: '4 ans et demi',
  },
  {
    id: '5y',
    libelle: '5 ans',
  },
  {
    id: '66M',
    libelle: '5 ans et demi',
  },
  {
    id: '6y',
    libelle: '6 ans',
  },
  {
    id: '78M',
    libelle: '6 ans et demi',
  },
  {
    id: '7y',
    libelle: '7 ans',
  },
  {
    id: '90M',
    libelle: '7 ans et demi',
  },
  {
    id: '8y',
    libelle: '8 ans',
  },
  {
    id: '102M',
    libelle: '8 ans et demi',
  },
  {
    id: '9y',
    libelle: '9 ans',
  },
  {
    id: '114M',
    libelle: '9 ans et demi',
  },
  {
    id: '10y',
    libelle: '10 ans',
  },
];
