import { Injectable } from '@angular/core';
import {
  lastOfArray,
  ReplicationPullHandlerResult,
  RxReplicationWriteToMasterRow,
  WithDeleted,
} from 'rxdb';
import { AbstractProvider } from './abstract-provider';
import { Checkpoint } from '../interfaces/Checkpoint.type';
import { QUERY_GET_GROUPES } from '../queries/groupe.queries';
import {
  groupeDocType,
  groupeSchema,
  groupeSchemaLiteral,
} from '../schemas/groupe.schema';
import { ensureValidToken, graphql } from './../../services/nhost';
import { SentryService } from '../../services/sentry.service';
import { gql } from 'graphql-request';

// REFACTO : Le fonctionnement de RXDB doit être abstrait de la classe
@Injectable({
  providedIn: 'root',
})
export class GroupeProvider extends AbstractProvider<groupeDocType> {
  schema = groupeSchema;
  schemaLiteral = groupeSchemaLiteral;
  enablePush = true;

  protected migrationStrategies = {
    1: function (oldDoc: groupeDocType) {
      return null;
    },
    2: function (oldDoc: groupeDocType) {
      return null;
    },
    3: function (oldDoc: groupeDocType) {
      return null;
    },
  };

  async getPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<groupeDocType, Checkpoint>> {
    const variables: any = {
      where: {
        _or: this.getOrForQuery(lastCheckpoint),
      },
      order_by: this.getOrderByForQuery(),
      limit: this.BATCH_SIZE,
    };

    if (!this.isSuperAdmin) {
      variables.where.user_groupes = {
        id_user: {
          _eq: this.idUser,
        },
      };
    }
    const { data, error } = await graphql.request(QUERY_GET_GROUPES, variables);
    if (error || !data.groupe) {
      console.log(`Erreur lors de la récupération des groupes sur le serveur`);
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.groupe;

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }

  async getPushQuery(
    docs: RxReplicationWriteToMasterRow<groupeDocType>[]
  ): Promise<WithDeleted<groupeDocType>[]> {
    await ensureValidToken();

    try {
      await docs.reduce(async (acc, rxDoc) => {
        await acc; // Wait for the previous update to complete
        const doc = rxDoc.newDocumentState;
        const variables = {
          id: doc.id,
          config: doc.config,
          updated_at: new Date().toISOString(),
        };

        const response = await graphql.request(
          MUTATION_UPDATE_GROUP_CONFIG,
          variables
        );
        const { error } = response;

        if (error) {
          SentryService.captureException(error);
          console.log('ERREUR Push user_meta', error);
          this.AT.toastError(
            $localize`Erreur lors de la sauvegarde des préférences`
          );
          return Promise.resolve(); // Resolve the promise for the next iteration
        }

        return Promise.resolve(); // Resolve the promise for the next iteration
      }, Promise.resolve());

      this.AT.toastError($localize`Préférences sauvegardées`);
      return [];
    } catch (error) {
      SentryService.captureException(error);
      console.log('ERREUR Push user_meta', error);
      this.AT.toastError(
        $localize`Erreur lors de la sauvegarde des préférences`
      );
      return [];
    }
  }
}

export const MUTATION_UPDATE_GROUP_CONFIG = gql`
  mutation UpdateGroupConfig(
    $id: uuid!
    $config: jsonb!
    $updated_at: timestamptz!
  ) {
    update_groupe(
      where: { id: { _eq: $id } }
      _set: { config: $config, updated_at: $updated_at }
    ) {
      affected_rows
    }
  }
`;
