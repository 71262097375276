import { Pipe, PipeTransform } from '@angular/core';
import { graphql } from '../services/nhost';

@Pipe({
  name: 'nhostFilename',
  standalone: true,
})
export class NhostFilenamePipe implements PipeTransform {
  transform(fileId: string | null | undefined): Promise<string> {
    if (!fileId) {
      return Promise.resolve('');
    }
    return retrieveUploadFilename(fileId);
  }
}

export async function retrieveUploadFilename(fileId: string): Promise<string> {
  const query = `
    query {
      file(id: "${fileId}") {
        name
      }
    }
  `;

  const { data, error } = await graphql.request(query);

  if (error) {
    console.error('Error while retrieving upload filename:', error);
    return '';
  }

  return data.file.name;
}
