import { FabricantSelectItem } from '../../components/ugau-select-fabricant/fabricant-select-item';
import { fabricantDocType } from '../../db/schemas/fabricant.schema';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../localstorage-utils.service';

export function getDefaultFabricant() {
  return getFromLocalStorage('fabricant_default', true);
}

export function hasDefaultFabricant(): boolean {
  const fabricant = getFromLocalStorage('fabricant_default', true);
  return fabricant?.libelle ? true : false;
}

export function setDefaultFabricant(fab: fabricantDocType) {
  setToLocalStorage('fabricant_default', JSON.stringify(fab));
}

export function getDefaultFabricantAsSelectItem() {
  const fabricant = getDefaultFabricant();
  return new FabricantSelectItem(fabricant);
}
