import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { InterventionsStateType } from './InterventionsStateType';

@Injectable({
  providedIn: 'root',
})
export class InterventionsStateStore {
  // Pour stocker le noeud cliqué
  private stateSubject = new BehaviorSubject<InterventionsStateType>({
    currentTabDetailView: 0,
    currentTabFormView: 0,
    emplacementIds: [],

    intervention: null,
    interventionId: undefined,

    isDetailEmplacementOpened: false,
    isDetailIntervenantOpened: false,

    listFilterValue: '',

    node: undefined, // Selected NODE in filters tree
    tagId: undefined, // Si

    view: 'preview-list',

    sortOrder: 'asc',
    sortType: 'created_at',
  });
  state$ = this.stateSubject.asObservable().pipe(shareReplay(1));

  currentTabDetailView$ = this.state$.pipe(
    map((e) => {
      return e.currentTabDetailView;
    })
  );
  currentTabFormView$ = this.state$.pipe(
    map((e) => {
      return e.currentTabFormView;
    })
  );
  isDetailEmploacementOpened$ = this.state$.pipe(
    map((e) => {
      return e.isDetailEmplacementOpened;
    })
  );

  isDetailIntervenantOpened$ = this.state$.pipe(
    map((e) => {
      return e.isDetailIntervenantOpened;
    })
  );
  // Intervention courante
  currentIntervention$ = this.state$.pipe(
    map((e) => {
      return e.intervention;
    })
  );

  setState = (state: Partial<InterventionsStateType>) => {
    const currentState = this.stateSubject.getValue();
    this.stateSubject.next({
      ...currentState,
      ...state,
    });
  };
  getState(): InterventionsStateType {
    return this.stateSubject.getValue();
  }
}
