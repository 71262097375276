import { v4 as uuidv4 } from 'uuid';
import { ProductFieldType } from '@types_custom/ProductFieldType';
import { ProductType } from '@types_custom/ProductType';
import { PRODUCTFIELD_TYPE } from './../../../enum/product-fields-type';
import {
  getDefaultFabricant,
  hasDefaultFabricant,
} from './../../../utils/fabricant/getDefaultFabricant';
import { productfieldDocType } from './../../../db/schemas/productfields.schema';
import { anyProductfieldDocType } from '../../../db/schemas/emplacement.schema';

function newCpf(
  el: {
    productfield: ProductFieldType;
  },
  value: string = ''
): anyProductfieldDocType {
  return {
    id: uuidv4(),
    productfield: el.productfield,
    value: value,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    deleted_at: null,
  } as anyProductfieldDocType;
}

export function generateAnyProductfields(
  anyProductfields: anyProductfieldDocType[],
  selectedProduct: ProductType,
  fields: productfieldDocType[]
) {
  return selectedProduct.productfields.reduce(
    (acc: anyProductfieldDocType[] = [], el) => {
      const existInComposant = anyProductfields.find(
        (e: anyProductfieldDocType) => e.productfield.id === el.productfield.id
      );

      const field = fields.find((e) => e.id === el.productfield.id);
      if (!field) return acc;

      if (existInComposant) {
        if (field.type === PRODUCTFIELD_TYPE.DATE_FABRICATION) {
          acc.push({ ...existInComposant, value: new Date().toISOString() });
          return acc;
        }

        if (
          (field.type === PRODUCTFIELD_TYPE.DATE ||
            field.type === PRODUCTFIELD_TYPE.DURATION_START) &&
          field.defaultvalue?.toLowerCase() === 'now'
        ) {
          acc.push({ ...existInComposant, value: new Date().toISOString() });
          return acc;
        }

        if (field.type === PRODUCTFIELD_TYPE.DURATION) {
          acc.push({
            ...existInComposant,
            value: field.defaultvalue ?? '',
          });
          return acc;
        }

        if (field.type === PRODUCTFIELD_TYPE.MAKER && hasDefaultFabricant()) {
          const fabricant = getDefaultFabricant();
          if (fabricant) {
            acc.push({
              ...existInComposant,
              value: fabricant.id,
            });
            return acc;
          }
        }

        acc.push(existInComposant);
        return acc;
      }

      if (field.type === PRODUCTFIELD_TYPE.DATE_FABRICATION) {
        acc.push(newCpf(el, new Date().toISOString()));
        return acc;
      }

      if (
        (field.type === PRODUCTFIELD_TYPE.DATE ||
          field.type === PRODUCTFIELD_TYPE.DURATION_START) &&
        field.defaultvalue?.toLowerCase() === 'now'
      ) {
        acc.push(newCpf(el, new Date().toISOString()));
        return acc;
      }

      if (field.type === PRODUCTFIELD_TYPE.DURATION) {
        acc.push(newCpf(el, field.defaultvalue ?? ''));
        return acc;
      }

      if (field.type === PRODUCTFIELD_TYPE.MAKER && hasDefaultFabricant()) {
        const fabricant = getDefaultFabricant();
        if (fabricant) {
          acc.push(newCpf(el, fabricant.id));
          return acc;
        }
      }

      acc.push(newCpf(el));
      return acc;
    },
    []
  );
}
