import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const fabricantSchemaLiteral = jsonSchema({
  name: 'fabricant',
  autoMigrate: true,
  title: 'Fabricant schema',
  type: 'object',
  required: ['id', 'libelle', 'updated_at'],
  primaryKey: 'id',
  version: 0,
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    libelle: {
      type: 'string',
    },
    adresse: {
      type: 'string',
    },
    ville: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        code_postal: {
          type: 'string',
        },
        nom: {
          type: 'string',
        },
      },
    },
    certification: {
      type: ['string', 'null'],
    },
    created_at: {
      type: 'string',
    },
    created_by: {
      type: ['string', 'null'],
    },
    updated_at: {
      type: ['string'],
    },
    updated_by: {
      type: ['string', 'null'],
    },
    deleted_at: {
      type: ['string', 'null'],
    },
    deleted_by: {
      type: ['string', 'null'],
    },
  },
} as const);

const fabricantSchemaTyped = toTypedRxJsonSchema(fabricantSchemaLiteral);

export type fabricantDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof fabricantSchemaTyped
>;

export const fabricantSchema: RxJsonSchema<fabricantDocType> =
  fabricantSchemaLiteral;
