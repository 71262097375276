import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

/**
 * @fileoverview VisibilityService
 * @description This service provides observables to track the visibility state of the document.
 * It listens to the 'visibilitychange' event and emits the current visibility state.
 *
 * @class VisibilityService
 * @example
 * // Inject the service in your component
 * constructor(private visibilityService: VisibilityService) {}
 *
 * // Subscribe to visibility changes
 * this.visibilityService.visible$.subscribe(isVisible => {
 *   if (isVisible) {
 *     console.log('Document is visible');
 *   }
 * });
 *
 * this.visibilityService.hidden$.subscribe(isHidden => {
 *   if (isHidden) {
 *     console.log('Document is hidden');
 *   }
 * });
 */
@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  /**
   * Observable that emits the current visibility state of the document whenever it changes.
   *
   * This observable listens to the 'visibilitychange' event on the document and maps it to the
   * document's visibility state. It also emits the initial visibility state when subscribed to.
   *
   * @private
   * @type {Observable<DocumentVisibilityState>}
   */
  private visibilityChange$ = fromEvent(document, 'visibilitychange').pipe(
    map(() => document.visibilityState),
    startWith(document.visibilityState)
  );

  /**
   * An observable that emits `true` when the visibility state changes to 'visible'.
   *
   * This observable is derived from `visibilityChange$` and filters the state to only
   * emit when the state is 'visible'. It then maps the state to a boolean value `true`.
   *
   * @type {Observable<boolean>}
   */
  public visible$: Observable<boolean> = this.visibilityChange$.pipe(
    filter((state) => state === 'visible'),
    map(() => true)
  );

  /**
   * Observable that emits `false` when the visibility state changes to 'hidden'.
   *
   * This observable listens to the `visibilityChange$` observable, filters for
   * the 'hidden' state, and maps the result to `false`.
   *
   * @type {Observable<boolean>}
   */
  public hidden$: Observable<boolean> = this.visibilityChange$.pipe(
    filter((state) => state === 'hidden'),
    map(() => false)
  );

  /**
   * Returns an observable that emits the visibility state of the application.
   * The observable emits either 'visible' or 'hidden' based on the visibility state.
   *
   * @returns {Observable<'visible' | 'hidden'>} An observable that emits the visibility state.
   */
  public getVisibilityState$(): Observable<'visible' | 'hidden'> {
    return this.visibilityChange$ as Observable<'visible' | 'hidden'>;
  }
}
