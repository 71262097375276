import {
  MapboxStyleDefinition,
  MapboxStyleSwitcherOptions,
} from 'mapbox-gl-style-switcher';
import { getStylesList } from './mapboxStylesList';

/**
 * Retrieves the Mapbox style definition based on the provided parameters.
 *
 * @param {boolean} [isMobile=false] - Indicates if the styles should be tailored for mobile devices.
 * @param {string | null} [defaultValue=null] - The default style to use if none is provided.
 * @returns {{ styles: MapboxStyleDefinition[], options: MapboxStyleSwitcherOptions }} An object containing the list of Mapbox styles and the options including the default style.
 */
export function getMapboxStyleDefinition(
  isMobile = false,
  defaultValue: string | null = null
): {
  styles: MapboxStyleDefinition[];
  options: MapboxStyleSwitcherOptions;
} {
  const defaultStyle: string =
    defaultValue === null || defaultValue === '' ? 'Carte' : defaultValue;

  const mapboxStylesList = getStylesList(isMobile);

  return {
    styles: mapboxStylesList,
    options: {
      defaultStyle,
    },
  };
}
