import { NgIf, AsyncPipe } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  inject,
  HostBinding,
} from '@angular/core';
import { ToolsService } from '../../services/tools.service';
import { StockState } from './stock.state';
import { StockFormComponent } from './stock-form/stock-form.component';
import { StockListComponent } from './stock-list/stock-list.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-stock',
  template: `
    <div
      *ngIf="newStock$ | async as newStock; else listDepot"
      class="mb1em mt1em c-black"
    >
      <app-stock-form [stock]="newStock" type="stock"></app-stock-form>
    </div>

    <ng-template #listDepot>
      <app-stock-list
        type="stock"
        [data$]="stock$"
        (addNew)="addNewStock($event)"
        (edit)="editStock($event)"
      ></app-stock-list>
    </ng-template>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe, StockFormComponent, StockListComponent],
})
export class AppStockComponent {
  private stockState = inject(StockState);
  private tools = inject(ToolsService);

  stock$ = this.stockState.stocks$;

  @HostBinding('class.is-mobile') isMobile = this.tools.isMobile();

  newStock$ = this.stockState.newStock$;
  addNewStock(groupeId: string) {
    this.stockState.routingState.navigateStock(uuidv4(), true, groupeId);
  }

  editStock(id: string) {
    this.stockState.routingState.navigateStock(id);
  }
}