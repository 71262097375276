export enum LOCALSTORAGE {
  BALISE_TREE = 'choix_categorie_balise_tree',
  PANNEAUPOLICE_TREE = 'choix_categorie_produit_tree',
  SUPPORT_TREE = 'choix_categorie_support_tree',
  FIXATION_TREE = 'liste_support_fixation',
  COMPLEMENT_TREE = 'liste_support_complement',
  MOST_USED_BALISES = 'most_used_balises',
  MOST_USED_PANNEAUPOLICE = 'most_used_products',
  MOST_USED_SUPPORT = 'most_used_supports',
  DEFAULT_ZONE = 'zones_default',
  DEFAUT_PRODUCT_PREFIX = 'default_product_for_start_family_',
  CHOOSEN_FIRSTFAMILY = 'choosen_first_family',
  OVERRIDE_DURATIONS = 'override_default_durations',
  MAPBOX_DEFAULT_CENTER = 'mapbox_default_center',
  HIDDEN_FILTERS = 'hidden_filters',
  DEFAULT_COMPOSANT_OPENED = 'default_composant_opened',
}
