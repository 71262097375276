import { Pipe, PipeTransform } from '@angular/core';
import { tagDocType } from './../../db/schemas/tag.schema';
import { auth } from './../../services/nhost';
import { UserService } from './../../services/user.service';

@Pipe({
  name: 'hasWriteAccessOnTag',
  standalone: true,
})
export class HasWriteAccessOnTagPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  async transform(tag: tagDocType): Promise<boolean> {
    const userId = auth.getUser()?.id;
    if (!userId) return false;

    // Est ce que le tag est à moi ?
    const user = await this.userService.getUserById(userId);
    if (!user) return false;

    if (tag.id_user === userId) return true;

    // Est ce que le tag est dans un groupe dont je suis gestionnaire ?
    const userGroupes = user.user_groupes
      .filter((ug) => ug.role === 'user')
      .map((ug) => ug.groupe.id);

    return tag.id_groupe && userGroupes.includes(tag.id_groupe) ? true : false;
  }
}
