<div
  *ngIf="(display$ | async) === 'listing' && (user$ | async) as user"
  class="account-container-listing"
>
  <app-my-account-user-info
    [user]="user"
    (changeEmail)="changeEmail()"
    (changePassword)="changePassword()"
    (changeInfo)="elementSelected()"
  ></app-my-account-user-info>
  <div class="d-flex-center">
    <app-ugau-button
      iconName="logout"
      [fill]="true"
      iconColor="danger"
      classColor="danger"
      label="Déconnexion"
      [hasBorder]="false"
      (click)="logout()"
      class="m1em"
    ></app-ugau-button>
  </div>
</div>

<div *ngIf="(display$ | async) === 'form_user' && userProxy" class="form">
  <div matSubheader class="c-black" i18n>Informations utilisateur</div>
  <app-ugau-input
    i18n-label
    label="Nom visible"
    [value]="userProxy.displayName"
    (inputChange)="userProxy.displayName = $event"
  ></app-ugau-input>
  <app-ugau-input
    i18n-label
    label="Téléphone"
    [value]="userProxy.phoneNumber || ''"
    (inputChange)="userProxy.phoneNumber = $event"
  ></app-ugau-input>
  <div class="d-flex-center">
    <app-ugau-button
      i18n-label
      label="Annuler"
      (click)="displayList()"
      [fill]="true"
    >
    </app-ugau-button>
    <div>&nbsp;</div>
    <app-ugau-button
      i18n-label
      label="Valider"
      (click)="validateUserModification()"
      [fill]="true"
    >
    </app-ugau-button>
  </div>
</div>
