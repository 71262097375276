export const typeTagsChoices = [
  { id: 'zone', name: $localize`Zone` },
  { id: 'ville', name: $localize`Ville` },
  { id: 'chantier', name: $localize`Chantier` },
  { id: 'dossier', name: $localize`Dossier` },
  { id: 'entreprise', name: $localize`Entreprise` },
  { id: 'marche', name: $localize`Marché` },
  { id: 'ligne', name: $localize`Ligne` },
];

export const typeTagsChoicesIntervention = [
  { id: 'intervention', name: $localize`Intervention` },
];

export function beautifyTypeTag(type: string) {
  const choice = typeTagsChoices.find((element) => element.id === type);
  if (!choice) {
    const choiceIntervention = typeTagsChoicesIntervention.find(
      (element) => element.id === type
    );
    if (!choiceIntervention) return type;
    return choiceIntervention.name;
  }
  return choice.name;
}
