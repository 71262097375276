export const getDisplayedColumnsForTagsList = (
  type: 'tag' | 'intervention' | undefined,
  isMobile = false
) => {
  if (type === 'tag')
    return isMobile
      ? ['libelle', 'type', 'actions']
      : [
          'libelle',
          'type',
          'user_libelle',
          'groupe_libelle',
          'emplacements',
          'shared',
          'is_archived',
          'created_at',
          'actions',
        ];

  if (type === 'intervention')
    return isMobile
      ? ['libelle', 'intervention_status', 'actions']
      : [
          'libelle',
          'type',
          'intervention_status',
          'user_libelle',
          'groupe_libelle',
          'emplacements',
          'shared',
          'is_archived',
          'created_at',
          'actions',
        ];

  return [];
};
