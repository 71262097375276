import { Injectable } from '@angular/core';
import { addRxPlugin, createRxDatabase, RxDatabase, isRxDatabase } from 'rxdb';
import { EventsService } from '../services/events.service';
import { AuthenticationService } from '../services/authentication.service';
import { SentryService } from '../services/sentry.service';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { getDatabaseName } from './DATABASE_NAME';
import { dexieDeleteIndexedDbs } from './database-dexie-helpers';
import { sleep } from './../../common-projects/sleep';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration-schema';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { ToolsService } from '../services/tools.service';
import { reloadApp } from '../services/reloadApp.util';
import { getRxStorageWorker } from 'rxdb-premium/plugins/storage-worker';
import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb';
import { VisibilityService } from '../services/visibility.service';
import { initMobileProviders, initProviders } from './database-providers';
import { Providers } from './providers-interface';
import { resetCollections, setupReplication } from './database-replication';

addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBUpdatePlugin); // Utilisé pour faire des updates sur les documents (Bulk update emplacements)

/**
 * Service pour la gestion de la base de données.
 */
@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  public dbInstance!: RxDatabase;
  public idUser!: string;
  public t0: number = 0;

  private isMobile = this.tools.isMobile();

  private providers!: Providers;

  /**
   * Constructeur du service DatabaseService.
   * @param auth Service d'authentification.
   * @param event Service d'événements.
   * @param tools Service d'outils.
   * @param visibilityService Service de gestion de la visibilité.
   */
  constructor(
    private auth: AuthenticationService,
    private event: EventsService,
    private tools: ToolsService,
    private visibilityService: VisibilityService
  ) {
    if (this.tools.isMobile()) {
      console.log('Mobile, init mobile providers');
      this.providers = initMobileProviders();
    } else {
      console.log('Desktop, init desktop providers');
      this.providers = initProviders();
    }
  }

  /**
   * Initialise la base de données.
   * @param {boolean} [isMobile=false] - Indique si l'application s'exécute sur un appareil mobile.
   */
  public async initDatabase(isMobile = false) {
    this.t0 = performance.now();
    this.idUser = this.auth.getUserId();

    try {
      // On crée la base de données si elle n'existe pas
      if (!isRxDatabase(this.dbInstance)) {
        this.dbInstance = await this.createDatabase();
      }
    } catch (e) {
      console.log('Issue on database init');
      console.log(e);
      await dexieDeleteIndexedDbs('');
    }

    try {
      await resetCollections(this.dbInstance, this.providers);
      console.log(
        'Initialisation de la base front en ' +
          (performance.now() - this.t0) +
          'ms'
      );
    } catch (e) {
      console.log('Issue on collections init');
      console.log(e);
      await dexieDeleteIndexedDbs('');
    }

    try {
      await setupReplication(
        this.dbInstance,
        this.providers,
        isMobile,
        this.event,
        this.visibilityService
      );
    } catch (e) {
      console.log('Issue on replication setup');
      console.log(e);
    }
  }

  public getDb() {
    return this.dbInstance;
  }

  /**
   * Crée une nouvelle instance de base de données.
   * @returns {Promise<RxDatabase>} Instance de la base de données créée.
   */
  public async createDatabase() {
    const db = await this.createDatabaseForCurrentDevice();

    // Si la base n'est pas une instance de RxDatabase, on reload la page
    if (!isRxDatabase(db)) {
      await sleep(2000);
      reloadApp('DatabaseService::createDatabase');
      throw new Error('Database is not a RxDatabase instance');
    }

    return db;
  }

  /**
   * Crée une base de données spécifique à l'appareil courant.
   * @returns {Promise<RxDatabase>} Instance de la base de données créée.
   */
  createDatabaseForCurrentDevice() {
    if (this.isMobile) {
      console.log('Mobile, direct indexeddb');
      return createRxDatabase({
        name: getDatabaseName(this.idUser),
        multiInstance: false,
        storage: getRxStorageIndexedDB({
          transactionDurability: 'strict',
        }),
      });
    }

    console.log('Desktop, worker indexeddb');
    return createRxDatabase({
      name: getDatabaseName(this.idUser),
      multiInstance: false,
      storage: getRxStorageWorker({
        workerInput: 'indexeddb.worker.js',
        workerOptions: {
          type: 'module', // Assurez-vous que le type est 'module' si le worker utilise des imports ES6.
          credentials: 'same-origin',
        },
      }),
    });
  }

  /**
   * Resynchronise toutes les collections de la base de données.
   */
  public resync() {
    let k: keyof Providers;
    for (k in this.providers) {
      const provider = this.providers[k];
      if (provider.replicateState?.reSync) {
        provider.replicateState.reSync();
      }
    }
  }

  public async purgeNotAllDatasAndReload() {
    await this.stopReplication();
    this.purgeReplication();

    await this.providers.user.collection.remove();
    await this.providers.usermeta.collection.remove();
    await this.providers.groupe.collection.remove();
    await this.providers.tag.collection.remove();
    await this.providers.emplacement.collection.remove();
    await this.providers.intervention.collection.remove();
    await this.providers.stock.collection.remove();
    await this.providers.contact.collection.remove();

    await SentryService.flush();
    return reloadApp('DatabaseService::purgeNotAllDatasAndReload');
  }

  /**
   * Purge les données RXDB et recharge l'application.
   * @returns {Promise<void>}
   */
  public async purgeDatasAndReload() {
    await this.remove();
    await SentryService.flush();
    return reloadApp('DatabaseService::purgeDatasAndReload');
  }

  /**
   * Arrête la réplication pour toutes les collections.
   * @returns {Promise<void>}
   */
  async stopReplication() {
    let k: keyof Providers;
    const promises: Promise<void>[] = [];
    for (k in this.providers) {
      const provider = this.providers[k];
      promises.push(provider.stop());
    }
    await Promise.all(promises);
  }

  /**
   * Supprime les répliques pour toutes les collections.
   */
  purgeReplication() {
    let k: keyof Providers;
    for (k in this.providers) {
      const provider = this.providers[k];
      provider.removeReplication();
    }
  }

  /**
   * Supprime la base de données RXDB et les données avec Dexie.
   * @returns {Promise<void>}
   */
  async remove() {
    try {
      // Stop all replications
      await this.stopReplication();
      this.purgeReplication();

      // Properly remove RxDB instance
      if (isRxDatabase(this.dbInstance)) {
        await this.dbInstance.remove();
        await this.dbInstance.destroy();
      }
    } catch (error) {
      console.error('Error during RxDB removal:', error);
    } finally {
      // Ensure Dexie operations run regardless of RxDB errors
      try {
        await dexieDeleteIndexedDbs();
      } catch (dexieError) {
        console.error('Error during Dexie IndexedDB deletion:', dexieError);
      }
    }
  }

  getUserProvider() {
    return this.providers.user;
  }
  getUserMetaProvider() {
    return this.providers.usermeta;
  }
  getGroupeProvider() {
    return this.providers.groupe;
  }
  getTagProvider() {
    return this.providers.tag;
  }
  getProductpropertiesProvider() {
    return this.providers.productproperties;
  }
  getReglementationProvider() {
    return this.providers.reglementation;
  }
  getProductfieldsProvider() {
    return this.providers.productfields;
  }
  getFabricantProvider() {
    return this.providers.fabricant;
  }
  getProductTreeProvider() {
    return this.providers.producttree;
  }
  getEmplacementProvider() {
    return this.providers.emplacement;
  }
  getInterventionProvider() {
    return this.providers.intervention;
  }
  getStockProvider() {
    return this.providers.stock;
  }
  getContactProvider() {
    return this.providers.contact;
  }
}
