import { ISelectItem } from "../../db/interfaces/select-item";
import { emplacementDocType } from "../../db/schemas/emplacement.schema";
import { COLORS_BTN } from "../../enum/colors";
import { getEmplacementLibelle } from "../../utils/emplacement/getEmplacementLibelle";


export class EmplacementSelectItem implements ISelectItem {
  item: emplacementDocType;
  productsOrder?: any;

  constructor(item: emplacementDocType, productsOrder: any) {
    this.item = item;
    this.productsOrder = productsOrder;
  }
  getColor(): COLORS_BTN {
    return 'primary';
  }

  getLibelle() {
    return getEmplacementLibelle(this.item, this.productsOrder);
  }
  getIdentifier() {
    return this.item.id;
  }
}
