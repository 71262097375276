/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export enum COLORS {
  // Composant LISTITEM
  LISTITEM_TITLE = 'var(--black)',
  LISTITEM_FIRST_LINE = 'var(--shades--grey)',
  LISTITEM_SECOND_LINE = 'var(--shades--grey)',
  // EmplacementHistory-utils
  HISTORY_RED = 'var(--error-regular)',
  HISTORY_GREEN = 'var(--valid-regular)',
  HISTORY_BLACK = 'var(--black)',
  HISTORY_BLUE = 'var(--primary)',
  // UgauHistoryChartDonut
  UGAU_CHART_AJOUT = 'var(--primary)',
  UGAU_CHART_NETTOYAGE = 'var(--valid-light)',
  UGAU_CHART_SUPPRESSION = 'var(--error-light)',
  UGAU_CHART_REMPLACEMENT = 'var(--valid-dark)',
  UGAU_CHART_MODIFICATION_DIVERS = 'var(--valid-regular)',
  UGAU_CHART_DECLARATION_SINISTRE = 'var(--error-regular)',
  UGAU_CHART_CORRECTION_ACCESSIBILITE = 'var(--valid-regular)',
  UGAU_CHART_POSE = 'var(--warning-regular)',
  // UgauInterventionChartDonut
  UGAU_CHART_PREPARATION = 'var(--primary)',
}

export type COLORS_BTN =
  | 'primary'
  | 'valid'
  | 'warning'
  | 'danger'
  | 'grey'
  | 'black'
  | 'validdanger';
