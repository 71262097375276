import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
  toTypedRxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const userMetaSchemaLiteral = jsonSchema({
  name: 'user_meta',
  autoMigrate: true,
  title: 'User Meta schema',
  version: 1,
  type: 'object',
  required: ['id', 'key', 'value', 'created_at', 'updated_at'],
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    key: {
      type: 'string',
    },
    value: {
      type: 'string',
    },
    created_at: {
      type: 'string',
      format: 'date-time',
    },
    updated_at: {
      type: 'string',
      format: 'date-time',
    },
  },
} as const);

const userMetaSchemaTyped = toTypedRxJsonSchema(userMetaSchemaLiteral);

export type userMetaDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof userMetaSchemaTyped
>;

export const userMetaSchema: RxJsonSchema<userMetaDocType> = userMetaSchemaLiteral;
