import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const productpropertiesSchemaLiteral = jsonSchema({
  name: 'productproperties',
  autoMigrate: true,
  version: 0,
  type: 'object',
  required: ['id', 'name', 'updated_at'],
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    name: {
      type: 'string',
    },
    order: {
      type: ['number', 'null'],
    },
    filtrable: {
      type: 'boolean',
    },
    updated_at: {
      type: 'string',
    },
    deleted_at: {
      type: ['string', 'null'],
    },
    deleted_bool: {
      type: 'boolean',
    },
  },
} as const);

const productpropertiesSchemaTyped = toTypedRxJsonSchema(
  productpropertiesSchemaLiteral
);

export type productpropertiesDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof productpropertiesSchemaTyped
>;

export const productpropertiesSchema: RxJsonSchema<productpropertiesDocType> =
  productpropertiesSchemaLiteral;
