import { LngLatBounds } from 'mapbox-gl';
import { getBoundsCenter } from './getBoundsCenter';

/**
 * Extracts the latitude and longitude bounds from a GeoJSON MultiLineString geometry.
 *
 * @param {GeoJSON.MultiLineString} geometry - The GeoJSON MultiLineString geometry object.
 * @returns {LngLat} The center of the bounding box that encompasses all the coordinates in the MultiLineString.
 */
export function getLatLngFromMultilineString(
  geometry: GeoJSON.MultiLineString
) {
  const bounds = new LngLatBounds();
  geometry.coordinates.forEach((coords) => {
    coords.forEach((subCoords) => {
      bounds.extend([subCoords[0], subCoords[1]]);
    });
  });

  return getBoundsCenter(bounds);
}
