import { openInBlank } from '../../emplacement/geo-qrcode-emplacement/openInBlank';

export type CalendarType = 'google' | 'outlook' | 'yahoo';
export interface CalendarEvent {
  id?: string;
  title: string;
  startDate: Date;
  endDate: Date;
  description?: string;
  location?: string;
}
function formatDate(date: Date): string {
  // Format the date as YYYYMMDDTHHMMSSZ for Google and Yahoo
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
}
function formatDateOutlook(date: Date): string {
  // Format the date as YYYY-MM-DDTHH:MM:SSZ for Outlook
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}
export function sendEventToCalendar(
  calendarType: CalendarType,
  event: CalendarEvent
): void {
  const title = encodeURIComponent(event.title);
  const startDate = formatDate(event.startDate);
  const endDate = formatDate(event.endDate);
  const description = event.description
    ? encodeURIComponent(event.description)
    : '';
  const location = event.location ? encodeURIComponent(event.location) : '';

  let url = '';
  switch (calendarType) {
    case 'google':
      const googleId = event.id ? `&uid=${event.id}` : '';
      url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDate}/${endDate}&details=${description}&location=${location}${googleId}`;
      openInBlank(url);
      break;

    case 'outlook':
      const startDateOutlook = formatDateOutlook(event.startDate);
      const endDateOutlook = formatDateOutlook(event.endDate);
      const outlookId = event.id ? `\n\nID: ${event.id}` : '';
      // Outlook ne supporte pas directement un ID dans l'URL, mais vous pouvez l'ajouter en tant que partie de la description ou du sujet si nécessaire

      const newdescription = description + encodeURIComponent(outlookId);
      url = `https://outlook.live.com/owa/?path=/calendar/action/compose&subject=${title}&startdt=${startDateOutlook}&enddt=${endDateOutlook}&body=${newdescription}&location=${location}`;

      openInBlank(url);
      break;
  }
}
