import { gql } from 'graphql-request';

export const QUERY_GET_USERS = gql`
  query MyQuery(
    $where: users_bool_exp!
    $order_by: [users_order_by!]!
    $limit: Int!
  ) {
    users(where: $where, order_by: $order_by, limit: $limit) {
      id
      displayName
      phoneNumber
      email
      defaultRole
      user_groupes {
        id
        role
        groupe {
          id
          libelle
          abonnement
        }
      }
      createdAt
      updatedAt
      deleted_bool: disabled
    }
  }
`;

export const MUTATION_INSERT_USERS = gql`
  mutation MyMutation(
    $objects: [users_insert_input!]!
    $on_conflict: users_on_conflict!
  ) {
    insertUsers(objects: $objects, on_conflict: $on_conflict) {
      returning {
        id
      }
    }
  }
`;
