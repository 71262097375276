import { gql } from 'graphql-request';

export const QUERY_GET_INTERVENTIONS = gql`
  query MyQuery(
    $where: interventions_bool_exp!
    $order_by: [interventions_order_by!]!
    $limit: Int!
  ) {
    interventions(where: $where, order_by: $order_by, limit: $limit) {
      id
      numero
      name
      id_tag
      description
      date_preparation
      date_valide
      date_en_cours
      date_termine
      etat_depart
      etat_fin
      status
      created_at
      created_by
      updated_at
      updated_by
      deleted_at
      deleted_by
      emplacements(
        order_by: { created_at: desc }
        where: { deleted_at: { _is_null: true } }
      ) {
        id
        id_emplacement
        created_at
        created_by
        updated_at
        updated_by
        date_start_intervention
        date_end_intervention
      }
      documents(
        order_by: { created_at: desc }
        where: { deleted_at: { _is_null: true } }
      ) {
        id
        document_name
        document_type
        chemin
        created_at
        created_by
        updated_at
        updated_by
        deleted_at
        deleted_by
      }
    }
  }
`;

export const QUERY_GET_INTERVENTIONS_MESSAGES = gql`
  query MyQuery($where: intervention_messages_bool_exp!, $limit: Int!) {
    intervention_messages(
      where: $where
      order_by: { created_at: desc }
      limit: $limit
    ) {
      id
      id_intervention
      type
      id_user
      message
      history
      created_at
      created_by
    }
  }
`;

export const QUERY_GET_COUNT_INTERVENTIONS_MESSAGES = gql`
  query MyQuery($where: intervention_messages_bool_exp!) {
    intervention_messages_aggregate(
      where: $where
      order_by: { created_at: desc }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const QUERY_INSERT_INTERVENTIONS_MESSAGES = gql`
  mutation MyMutation(
    $objects: [intervention_messages_insert_input!]!
    $id_intervention: uuid!
    $updated_at: timestamptz!
    $updated_by: uuid!
  ) {
    insert_intervention_messages(
      objects: $objects
      on_conflict: {
        constraint: intervention_messages_pkey
        update_columns: [
          message
          updated_at
          updated_by
          deleted_at
          deleted_by
        ]
      }
    ) {
      affected_rows
    }
    update_interventions(
      where: { id: { _eq: $id_intervention } }
      _set: { updated_at: $updated_at, updated_by: $updated_by }
    ) {
      affected_rows
    }
  }
`;
