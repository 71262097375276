import { add, isPast } from 'date-fns';
import { PRODUCTFIELD_TYPE } from './../../enum/product-fields-type';
import { transformDurationShortToObj } from './../../../common-projects/duration-utils.service';
import { getDurationChoice } from '../ugau-duration-select/duration-select-item';
import {
  composantDocType,
  composantProductfieldDocType,
} from '../../db/schemas/emplacement.schema';
import { productfieldDocType } from './../../db/schemas/productfields.schema';

export function isDurationStartDateAfterToday(
  startDateString: string,
  composant: composantDocType,
  fields: productfieldDocType[]
) {
  const startDate = new Date(startDateString);
  const fieldsDuration = fields
    .filter((field) => field.type === PRODUCTFIELD_TYPE.DURATION)
    .map((field) => field.id);

  const duration = composant.composant_productfields.reduce(
    (acc: any, cpf: composantProductfieldDocType) => {
      if (fieldsDuration.includes(cpf.productfield.id) && cpf.value) {
        acc = getDurationChoice(cpf.value, cpf.productfield.id)?.id;
      }
      return acc;
    },
    undefined
  );
  if (!duration) return false;

  const optionDuration = transformDurationShortToObj(duration);
  if (!optionDuration) return false;

  const nextCheckDate = add(startDate, optionDuration);
  return !isPast(nextCheckDate);
}
