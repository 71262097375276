import { Pipe, PipeTransform } from '@angular/core';
import { transformRoleToRoleDisplay } from './transformRoleToRoleDisplay';

@Pipe({
  name: 'roleDisplay',
  standalone: true,
  pure: true,
})
export class RoleDisplayPipe implements PipeTransform {
  transform(role: string): string {
    return transformRoleToRoleDisplay(role);
  }
}


