import { inject, Pipe, PipeTransform } from '@angular/core';
import { ProductFamiliesTreeState } from '../state/product-families-tree-state.service';
import { getImageFromFamiliesRecursive } from '../utils/productfamilies-utils.service';

const cache = new Map<string, string>();

@Pipe({
  name: 'customicon',
  standalone: true,
})
export class CustomIconPipe implements PipeTransform {
  productTreeState = inject(ProductFamiliesTreeState);

  transform(type: 'batiment' | 'materiel'): string {
    return getCustomIcon(type, this.productTreeState);
  }
}

export const getCustomIcon = (
  type: 'batiment' | 'materiel',
  productTreeState: ProductFamiliesTreeState
) => {
  const cached = cache.get(type);
  if (cached) return cached;

  const tree = productTreeState.getTreesByType(type);
  if (!tree.length || tree.length === 0) return '';

  const image = getImageFromFamiliesRecursive(tree[0].id, tree);
  cache.set(type, image);
  return image;
};
