import { ProductFamiliesType } from '@types_custom/ProductType';

export type keyValueProductOrder = Record<string, any>;

const CACHE = new Map<string, any>();

export function getProductsOrder(
  families: ProductFamiliesType[]
): keyValueProductOrder {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const validFamilies = families.filter((family) => family?.id);
  if (!validFamilies.length || validFamilies.length === 0) {
    return {};
  }

  const key = validFamilies.map((el) => el.id).join('.');
  const cached = CACHE.get(key);
  if (cached) {
    return cached;
  }

  let acc: Record<string, string> = {};
  for (const family of validFamilies) {
    if (family.children_productfamilies?.length) {
      const result = getProductsOrder(family.children_productfamilies);
      Object.keys(result).forEach((key) => {
        result[key] = lpad(family.order, 4) + result[key];
      });
      acc = { ...acc, ...result };
    }
    if (family.products.length) {
      family.products.forEach((product) => {
        if (product.product.id) {
          acc[product.product.id] = lpad(family.order, 4);
        }
      });
    }
    acc[family.id] = lpad(family.order, 4);
  }

  CACHE.set(key, acc);
  return acc;
}

// Permet de rajouter des 0 devant un nombre
const lpad = (value: number | string, padding: number) => {
  const zeroes = new Array(padding + 1).join('0');
  return (zeroes + value).slice(-padding);
};
