import { emplacementDocType } from '../../../db/schemas/emplacement.schema';
import { getCenterFromGeometry } from '../../../services/mapbox/getCenterFromGeometry';
import { CenterAbbr } from './CenterAbbr';
import { openInBlank } from './openInBlank';

export const openInMap = (
  emplacement: emplacementDocType | undefined | null,
  useIos: boolean
) => {
  if (!emplacement?.geometry) return;

  const center = getCenterFromGeometry(emplacement.geometry);
  if (!center?.lat || !center.lng) return;

  if (useIos) {
    openInBlank(getAppleMapsHref(center));
  }
  openInBlank(getGoogleMapsHref(center));
};
const getAppleMapsHref = (center: CenterAbbr) => {
  return `https://maps.apple.com/?saddr=Current%20Location&daddr=${center.lat},${center.lng}&dirflg=d`;
};
const getGoogleMapsHref = (center: CenterAbbr) => {
  return `https://www.google.com/maps/dir/?api=1&origin=My+Location&destination=${center.lat},${center.lng}&travelmode=driving`;
};
