import { LngLatBounds } from 'mapbox-gl';
import { getBoundsCenter } from './getBoundsCenter';

/**
 * Extracts the latitude and longitude from a GeoJSON LineString and calculates the center of the bounding box.
 *
 * @param {GeoJSON.LineString} geometry - The GeoJSON LineString object containing the coordinates.
 * @returns {LngLat} The center point of the bounding box that encompasses all coordinates in the LineString.
 */
export function getLatLngFromLineString(geometry: GeoJSON.LineString) {
  const bounds = new LngLatBounds();
  geometry.coordinates.forEach((coords) => {
    bounds.extend([coords[0], coords[1]]);
  });

  return getBoundsCenter(bounds);
}
