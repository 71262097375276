import { Map } from 'mapbox-gl';
import { ICON_MAP_BLUE } from '../../utils/emplacement/ICON_MAP_BLUE';

const missingImages = new Set();

export const handleMissingImage = (map: Map, e: any, id: string) => {
  // @TODO: C'est pas beau, https://github.com/mapbox/mapbox-gl-js/issues/11565
  if (e.id === 'rectangle-yellow-5') return;
  if (e.id === 'rectangle-yellow-6') return;
  if (e.id === ICON_MAP_BLUE) {
    return map.loadImage(e.id, (error: any, imageLoaded: any) => {
      if (error) {
        console.log(error);
        return;
      }

      if (!map.hasImage(e.id)) {
        map.addImage(e.id, imageLoaded);
      }
    });
  }

  if (missingImages.has(e.id)) return;
  missingImages.add(e.id);
  console.log('missing image', id, e.id);
};
