import { INTERVENTION_STATUS } from './../../components/intervention-page/intervention-form/INTERVENTION_STATUS';
import { emplacementDocType } from './../../db/schemas/emplacement.schema';
import { interventionDocType } from './../../db/schemas/intervention.schema';
import { auth } from './../../services/nhost';
import { v4 as uuidv4 } from 'uuid';

export function getInterventionLabel(intervention: interventionDocType | null) {
  switch (intervention?.status) {
    case INTERVENTION_STATUS.PREPARATION:
      return $localize`Valider l'étude`;
    case INTERVENTION_STATUS.VALIDE:
      return $localize`Démarrer l'intervention`;
    case INTERVENTION_STATUS.EN_COURS:
      return $localize`Clôturer l'intervention`;
    case INTERVENTION_STATUS.TERMINE:
      return $localize`Archiver l'intervention`;
    case INTERVENTION_STATUS.ARCHIVE:
      return '';
    default:
      return '';
  }
}
export function transformEmplacementsForIntervention(
  emplacements: { id: string }[]
) {
  return emplacements.map((e) => {
    return {
      id: uuidv4(),
      id_emplacement: e.id,
      created_at: new Date().toISOString(),
      created_by: auth.getUser()?.id,
      updated_at: new Date().toISOString(),
      updated_by: auth.getUser()?.id,
    };
  });
}
export function getEmplacementFomInterventions(
  emplacements: emplacementDocType[],
  intervention: interventionDocType
) {
  const emplacementsIds = intervention.emplacements.map(
    (e) => e.id_emplacement
  );
  return emplacements.filter((e) => emplacementsIds.includes(e.id));
}
