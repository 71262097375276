import { composantDocType } from '../../db/schemas/emplacement.schema';


export function getComposantLibelleType(
  composant: composantDocType,
  _display: string = ''
): string {
  const capitalize = (str: string = ''): string => str.toUpperCase().slice(0, 1) +
    str.toLowerCase().slice(1).replace('_', ' ');

  let composantOrderType = composant.order_per_type?.toString();
  if (_display === 'no_number') {
    composantOrderType = '';
  }

  return `${capitalize(composant.type)} ${composantOrderType}`;
}
