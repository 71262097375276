import { mapboxLayerOptions } from './mapbox.interface';
import {
  POINT_SYMBOL_HOME,
  POINT_CLUSTER_HOME,
  POINT_CLUSTER_COUNT_HOME,
} from './default-layers-point';
import {
  LINE_SYMBOL_HOME,
  LINE_LINE_HOME,
  LINE_CLUSTER_HOME,
  LINE_CLUSTER_COUNT_HOME,
} from './default-layers-line';
import {
  POLYGON_LINE_HOME,
  POLYGON_FILL_HOME,
  POLYGON_CLUSTER_HOME,
  POLYGON_CLUSTER_COUNT_HOME,
} from './default-layers-polygon';

export function getDefaultLayersHome(): mapboxLayerOptions[] {
  return [
    // Points
    POINT_SYMBOL_HOME,
    POINT_CLUSTER_HOME,
    POINT_CLUSTER_COUNT_HOME,
    // Lines
    LINE_SYMBOL_HOME,
    LINE_LINE_HOME,
    LINE_CLUSTER_HOME,
    LINE_CLUSTER_COUNT_HOME,
    // Polygons
    POLYGON_LINE_HOME,
    POLYGON_FILL_HOME,
    POLYGON_CLUSTER_HOME,
    POLYGON_CLUSTER_COUNT_HOME,
  ];
}
