import { gql } from 'graphql-request';

export const MUTATION_INSERT_CONTACT = gql`
  mutation InsertContact(
    $objects: [contact_insert_input!]!
    $on_conflict: contact_on_conflict!
  ) {
    insert_contact(objects: $objects, on_conflict: $on_conflict) {
      returning {
        id
      }
    }
  }
`;
