import { ISelectItem } from './../../db/interfaces/select-item';
import { fabricantDocType } from './../../db/schemas/fabricant.schema';
import { COLORS_BTN } from './../../enum/colors';

export class FabricantSelectItem implements ISelectItem {
  item: fabricantDocType;
  getLibelle() {
    return `${this.item.libelle} (${this.item.ville?.nom})`;
  }
  getIdentifier() {
    return this.item.id;
  }
  getColor(): COLORS_BTN {
    return 'primary';
  }

  constructor(item: fabricantDocType) {
    this.item = item;
  }
}
