import { getDirectParentFamilyForProductId } from '../productfamilies-utils.service';
import { productfieldDocType } from '../../db/schemas/productfields.schema';
import {
  composantProductfieldDocType,
  composantDocType
} from '../../db/schemas/emplacement.schema';
import { ProductFamiliesType } from '@types_custom/ProductType';

export function composantHasEmptyMandatoryField(
  composant: composantDocType,
  families: ProductFamiliesType[],
  productfields: productfieldDocType[]
) {
  let messageComposant = '';
  const checkComposant = composant.composant_productfields.some(
    (cpf: composantProductfieldDocType) => {
      const productfield = productfields.find(
        (pf: productfieldDocType) => pf.id === cpf.productfield.id
      );

      if (productfield?.mandatory === true &&
        (cpf.value === '' || !cpf.value)) {
        if (!composant.product?.id) return true;
        const family = getDirectParentFamilyForProductId(
          composant.product.id,
          families
        );

        if (!family) return true;
        messageComposant = `${family.name} : ${productfield.name} invalide.

            Le champ ${productfield.name} est obligatoire.`;
        return true;
      }
      return false;
    }
  );

  return { value: checkComposant, message: messageComposant };
}
