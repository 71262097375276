import { format, formatDistance } from 'date-fns';
import { environment } from './../../environments/environment';

const DATE_FORMAT = 'dd/MM/yyyy';
const DATE_FORMAT_REVERSE = 'yyyy-MM-dd_HH-mm';
const LOCALE = environment.LOCALE;

export function durationSinceToday(date: Date | string | null | undefined) {
  if (!date) date = new Date();
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const locale = {
    ...LOCALE,
    addSuffix: true,
  };
  return formatDistance(date, new Date(), locale);
}

export function formatDateddMMyyyy(date: Date | string) {
  if (!date || date === '') {
    date = new Date();
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  return format(date, DATE_FORMAT, LOCALE);
}

export function formatDateddMMyyyyHHmm(date: Date | string | null | undefined) {
  if (!date) date = new Date();
  if (typeof date === 'string') {
    date = new Date(date);
  }

  return format(date, `${DATE_FORMAT} HH:mm`, LOCALE);
}

export function formatDateYyyyMMdd(date: Date | string | null | undefined) {
  if (!date) date = new Date();
  if (typeof date === 'string') {
    date = new Date(date);
  }

  return format(date, `${DATE_FORMAT_REVERSE}`, LOCALE);
}

export function formatLocaleStr(value: string | Date | undefined | null) {
  if (!value) return '';
  if (typeof value === 'string') {
    value = new Date(value);
  }
  return format(value, "EEEE dd MMMM yyyy, à HH'h'mm", LOCALE);
}
