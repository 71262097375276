import { Injectable, inject } from '@angular/core';
import { RoutingState } from '../../state/routing-state.service';
import { UsersStateService } from '../../state/users-state.service';
import { BehaviorSubject } from 'rxjs';
import { userTransformedType } from '../../db/schemas/UserGroupRole';
import { UserService } from '../../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogPromptBoolComponent } from '../../components/dialogs/dialog-prompt-bool/dialog-prompt-bool.component';
import { ToolsService } from '../../services/tools.service';
import { DatabaseService } from '../../db/database.service';
import { SentryService } from '../../services/sentry.service';
import { deleteUserGroupeAndTags } from '../my-account/registerNewUser';

@Injectable({
  providedIn: 'root',
})
export class MyAccountGroupState {
  private UserP = inject(UserService);
  private usersStates = inject(UsersStateService);
  private routingState = inject(RoutingState);
  private dialog = inject(MatDialog);
  private db = inject(DatabaseService);
  private tools = inject(ToolsService);

  users$ = this.usersStates.getUserFromGroupes$;
  newUserSubject$ = new BehaviorSubject<userTransformedType | undefined>(
    undefined
  );
  newUser$ = this.newUserSubject$.asObservable();

  setNewUser(newUser: userTransformedType) {
    this.newUserSubject$.next(newUser);
  }

  clearNewUser() {
    this.newUserSubject$.next(undefined);
  }

  navigateUserAlt(itemId: string | undefined = undefined) {
    this.routingState.navigateUser(itemId);
  }
  getUsers() {
    return this.usersStates.getUsersFromGroupes();
  }
  getUser(id: string) {
    return this.usersStates.getUserTransformedFromGroupe(id);
  }

  changeUserType(
    userId: string,
    groupeId: string,
    role: 'user' | 'user_created' | 'user_created_with_prices'
  ) {
    let msg = '';
    switch (role) {
      case 'user':
        msg = confirmMsgToGestionnaire;
        break;
      case 'user_created':
        msg = confirmMsgToOperator;
        break;
      case 'user_created_with_prices':
        msg = confirmMsgToOperatorWithPrices;
        break;
    }

    const dialog = this.dialog.open(DialogPromptBoolComponent, {
      width: '350px',
      data: {
        data: msg,
        title: $localize`Modification`,
        labelBtnValider: $localize`Valider`,
      },
    });

    dialog.afterClosed().subscribe((bool: Boolean) => {
      if (bool === false) return;

      this.UserP.changeUserType(userId, groupeId, role);
    });
  }

  deleteUser(
    userGroupeId: string | undefined,
    userId: string,
    groupeId: string
  ) {
    return new Promise((resolve) => {
      const dialog = this.dialog.open(DialogPromptBoolComponent, {
        width: '350px',
        data: {
          data: confirmMsgDeleteUser,
        },
      });

      dialog.afterClosed().subscribe((bool: Boolean) => {
        if (bool === false || !userGroupeId) {
          resolve(false);
          return;
        }

        deleteUserGroupeAndTags(userGroupeId, userId, groupeId)
          .then((result) => {
            if (result.error) {
              const hasSpecificError: string | undefined | null =
                result.error[0].extensions.internal.error.message;
              if (
                hasSpecificError?.includes(
                  'partages de tag actifs impliquant ce groupe'
                )
              ) {
                this.tools.launchErrorAlert(errorMsgHasShares);
                resolve(false);
                return;
              }

              this.tools.launchErrorAlert(errorMsgDefault);
              resolve(false);
              return;
            }

            this.tools.toastError(successMsgDeleteUser);
            this.db.getGroupeProvider().replicateState?.reSync();
            this.db.getTagProvider().replicateState?.reSync();
            resolve(true);
          })
          .catch((e) => {
            SentryService.captureException(e, 'FAILED_DELETE_USER_FROM_GROUPE');
            this.tools.launchErrorAlert(errorMsgDefault);
            resolve(false);
          });
      });
    });
  }
}

const confirmMsgToGestionnaire = $localize`Attention, en passant cet utilisateur en "Gestionnaire" celui-ci aura accès à tous les dossiers et emplacement du groupe.
  Êtes vous certain de vouloir passer cet utilisateur en "Gestionnaire" ?`;
const confirmMsgToOperator = $localize`Êtes vous certain de vouloir passer cet utilisateur en "Opérateur" ?
  (Pas d'accès aux dossiers et aux emplacement du groupe, prix masqués)`;
const confirmMsgToOperatorWithPrices = $localize`Êtes vous certain de vouloir passer cet utilisateur en "Opérateur avec prix" ?
  (Pas d'accès aux dossiers et aux emplacement du groupe, prix affichés)`;

const confirmMsgDeleteUser = $localize`Êtes vous certain de vouloir supprimer cet utilisateur de ce groupe ?`;
const successMsgDeleteUser = $localize`Suppression effectuée avec succès`;
const errorMsgDefault = $localize`Echec lors de la suppression de l'utilisateur, veuillez réessayer plus tard ou contacter le support`;
const errorMsgHasShares = $localize`Echec lors de la suppression de l'utilisateur, il existe des partages de tag actifs impliquant cet utilisateur et ce groupe.

            Veuillez d'abord supprimer les partages de tag actifs avant de supprimer l'utilisateur.`;
