import { ConformiteType } from '@types_custom/conformite';
import { isConformiteDateFabrication } from '../conformite/isConformiteDateFabrication';
import { isConformiteRecurrence } from '../conformite/isConformiteRecurrence';
import { productpropertiesDocType } from '../../db/schemas/productproperties.schema';
import { composantDocType } from '../../db/schemas/emplacement.schema';
import { isComposantDurationsValid } from './isComposantDurationsValid';
import { getComposantDateRenouvellement } from './getComposantDateRenouvellement';
import { productfieldDocType } from '../../db/schemas/productfields.schema';
import { reglementationDocType } from './../../db/schemas/reglementation.schema';

export function isConformeComposant(
  composant: composantDocType,
  conformite: ConformiteType | null = null,
  properties: productpropertiesDocType[],
  fields: productfieldDocType[],
  reglementations: reglementationDocType[]
) {
  // Si pas de composant ou pas de conformites dans le composant, on considère que c'est conforme
  if (composant.conformites.length > 0) {
    return true;
  }

  // Si on a une conformite en paramètre, on traite uniquement cette conformite
  if (conformite) {
    if (isConformiteDateFabrication(conformite, reglementations)) {
      return (
        getComposantDateRenouvellement(composant, properties, fields) >
        new Date()
      );
    }
    return conformite.est_conforme;
  }

  // Sinon on traite toutes les conformites du composant
  return composant.conformites
    .filter((el: any) => !el.deleted_at)
    .every((el: any) => {
      if (isConformiteDateFabrication(el, reglementations)) {
        return (
          getComposantDateRenouvellement(composant, properties, fields) >
          new Date()
        );
      }
      if (isConformiteRecurrence(el, reglementations)) {
        return isComposantDurationsValid(composant, fields);
      }
      return el.est_conforme;
    });
}
