import { Injectable } from '@angular/core';
import { AbstractProvider } from './abstract-provider';
import { QUERY_GET_PRODUCTFIELDS_W_ACTION_LIB } from '../queries/productfields.queries';
import {
  productfieldDocType,
  productfieldsSchema,
  productfieldsSchemaLiteral,
} from '../schemas/productfields.schema';
import { lastOfArray, ReplicationPullHandlerResult } from 'rxdb';
import { graphql } from './../../services/nhost';
import { Checkpoint } from '../interfaces/Checkpoint.type';

// REFACTO : Le fonctionnement de RXDB doit être abstrait de la classe
@Injectable({
  providedIn: 'root',
})
export class ProductfieldsProvider extends AbstractProvider<productfieldDocType> {
  public schema = productfieldsSchema;
  public schemaLiteral = productfieldsSchemaLiteral;
  public enablePush = false;
  public BATCH_SIZE = 5000;

  protected migrationStrategies = {
    1: function (oldDoc: productfieldDocType) {
      return null;
    },
  };

  async getPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<productfieldDocType, Checkpoint>> {
    const { data, error } = await graphql.request(
      QUERY_GET_PRODUCTFIELDS_W_ACTION_LIB,
      {
        where: {
          _or: this.getOrForQuery(lastCheckpoint),
        },
        order_by: this.getOrderByForQuery(),
        limit: this.BATCH_SIZE,
      }
    );

    if (error || !data.productfields) {
      console.log(
        `Erreur lors de la récupération des productfields sur le serveur`
      );
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.productfields;

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }
}
