import { getLatLngFromMultilineString } from './getLatLngFromMultilineString';
import { getLatLngFromPolygon } from './getLatLngFromPolygon';
import { getLatLngFromLineString } from './getLatLngFromLineString';
import { MAX_ZOOM_CLUSTER } from './mapbox-constants';

export const getDefaultSources = () => [
  {
    id: 'markers_source',
    filterFunc: (e: any) => e.geometry.type === 'Point',
    cluster: true,
    clusterMaxZoom: MAX_ZOOM_CLUSTER,
    clusterRadius: 50,
    tolerance: 2,
  },
  {
    id: 'lines_source',
    filterFunc: (e: any) =>
      e.geometry.type === 'LineString' || e.geometry.type === 'MultiLineString',
  },
  {
    id: 'lines_points_source',
    filterFunc: (e: any) =>
      e.geometry.type === 'LineString' || e.geometry.type === 'MultiLineString',
    mapFunc: (e: any) => {
      if (e.geometry.type === 'LineString')
        return {
          ...e,
          geometry: {
            type: 'Point',
            coordinates: getLatLngFromLineString(e.geometry).latlng.toArray(),
          },
        };
      if (e.geometry.type === 'MultiLineString')
        return {
          ...e,
          geometry: {
            type: 'Point',
            coordinates: getLatLngFromMultilineString(
              e.geometry
            ).latlng.toArray(),
          },
        };
      return e;
    },
    clusterMinPoints: 1,
    cluster: true,
    clusterMaxZoom: MAX_ZOOM_CLUSTER,
    clusterRadius: 50,
    tolerance: 2,
  },
  {
    id: 'lines_all_points_source',
    filterFunc: (e: any) =>
      e.geometry.type === 'LineString' || e.geometry.type === 'MultiLineString',
    mapFunc: (e: any) => {
      let points = [];
      if (e.geometry.type === 'LineString') {
        points = e.geometry.coordinates.map((coordinate: any) => ({
          ...e,
          geometry: {
            type: 'Point',
            coordinates: coordinate,
          },
        }));
      } else if (e.geometry.type === 'MultiLineString') {
        points = e.geometry.coordinates.flatMap((line: any) =>
          line.map((coordinate: any) => ({
            ...e,
            geometry: {
              type: 'Point',
              coordinates: coordinate,
            },
          }))
        );
      }
      return points;
    },
    flatAfterMapFunc: true,
    tolerance: 2,
  },
  {
    id: 'polygons_source',
    filterFunc: (e: any) =>
      e.geometry.type === 'Polygon' || e.geometry.type === 'MultiPolygon',
    tolerance: 2,
  },
  {
    id: 'polygons_points_source',
    filterFunc: (e: any) =>
      e.geometry.type === 'Polygon' || e.geometry.type === 'MultiPolygon',
    mapFunc: (e: any) => {
      if (e.geometry.type === 'Polygon') {
        return {
          ...e,
          geometry: {
            type: 'Point',
            coordinates: getLatLngFromPolygon(e.geometry).latlng.toArray(),
          },
        };
      }
      if (e.geometry.type === 'MultiPolygon') {
        return {
          ...e,
          geometry: {
            type: 'Point',
            coordinates: getLatLngFromPolygon(e.geometry).latlng.toArray(),
          },
        };
      }
      return e;
    },
    clusterMinPoints: 1,
    cluster: true,
    clusterMaxZoom: MAX_ZOOM_CLUSTER,
    clusterRadius: 50,
    tolerance: 2,
  },
  {
    id: 'polygons_all_points_source',
    filterFunc: (e: any) =>
      e.geometry.type === 'Polygon' || e.geometry.type === 'MultiPolygon',
    mapFunc: (e: any) => {
      if (e.geometry.type === 'Polygon') {
        return e.geometry.coordinates.flatMap((line: any) =>
          line.map((coordinate: any) => ({
            ...e,
            geometry: {
              type: 'Point',
              coordinates: coordinate,
            },
          }))
        );
      }
      if (e.geometry.type === 'MultiPolygon') {
        return e.geometry.coordinates.flatMap((polygon: any) =>
          polygon.flatMap((line: any) =>
            line.map((coordinate: any) => ({
              ...e,
              geometry: {
                type: 'Point',
                coordinates: coordinate,
              },
            }))
          )
        );
      }
    },
    flatAfterMapFunc: true,
    tolerance: 2,
  },
];
