import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PanelStateService {
  // Utilisation d'un objet pour stocker plusieurs états, chaque clé représente un ensemble d'états
  private expandedStatesMap$: BehaviorSubject<Record<string, boolean[]>> =
    new BehaviorSubject<Record<string, boolean[]>>({});
  expandedStates$ = this.expandedStatesMap$.asObservable();

  getExpandedStates$(key: string) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return this.expandedStates$.pipe(map((states) => states[key] || []));
  }

  // Ouvre ou ferme un panneau par index pour un ensemble d'états donné (identifié par la clé)
  togglePanel(key: string, index: number) {
    const currentMap = this.expandedStatesMap$.getValue();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const currentStates = currentMap[key] || [];
    const length = currentStates.length;

    if (index >= length) {
      // Si l'index est supérieur à la taille actuelle du tableau, on ajoute des valeurs à false avant de basculer l'état de l'index
      for (let i = length; i <= index; i++) {
        currentStates[i] = false;
      }
    }

    // Bascule l'état du panneau spécifié par l'index
    currentStates[index] = !currentStates[index];

    // Réinitialise tous les autres panneaux de cet ensemble à false
    for (let i = 0; i < currentStates.length; i++) {
      if (i !== index) {
        currentStates[i] = false;
      }
    }

    // Mise à jour de l'état pour la clé donnée
    this.expandedStatesMap$.next({
      ...currentMap,
      [key]: currentStates,
    });
  }
}
