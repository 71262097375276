import { tagDocType } from './../../db/schemas/tag.schema';
import { getUserID } from './../nhost';
import { v4 as uuidv4 } from 'uuid';

interface TagParams {
  id?: string;
  libelle?: string;
  type?: string;
  id_user?: string | null;
  id_groupe?: string | null;
  _by?: string;
}

export function initNewTag(
  {
    id = uuidv4(),
    libelle = '',
    type = 'zone',
    id_user = getUserID(),
    id_groupe = null,
    _by = getUserID(),
  }: TagParams = {} as TagParams
): tagDocType {
  const currentDate = new Date().toISOString();

  return {
    id: id,
    libelle,
    type,
    id_user,
    id_groupe,
    id_parent: undefined,
    created_at: currentDate,
    created_by: _by,
    updated_at: currentDate,
    updated_by: _by,
    deleted_at: null,
    deleted_by: null,
    shared_tags: [],
    is_archived: false,
  };
}
