import { Injectable } from '@angular/core';
import {
  ReplicationPullHandlerResult,
  lastOfArray,
  RxReplicationWriteToMasterRow,
  WithDeleted,
} from 'rxdb';
import { AuthenticationService } from '../../../services/authentication.service';
import { EventsService } from '../../../services/events.service';
import { GraphQlService } from '../../../services/graph-ql.service';
import { SentryService } from '../../../services/sentry.service';
import { ToolsService } from '../../../services/tools.service';
import { Checkpoint } from '../../interfaces/Checkpoint.type';
import { QUERY_GET_INTERVENTIONS } from '../../queries/intervention.queries';
import {
  interventionDocType,
  interventionSchema,
  interventionSchemaLiteral,
} from '../../schemas/intervention.schema';
import { AbstractProvider } from '../abstract-provider';
import { interventionReplicationPush } from './interventionReplicationPush';
import { graphql } from '../../../services/nhost';

// REFACTO : Le fonctionnement de RXDB doit être abstrait de la classe
@Injectable({
  providedIn: 'root',
})
export class InterventionProvider extends AbstractProvider<interventionDocType> {
  public schema = interventionSchema;
  public schemaLiteral = interventionSchemaLiteral;
  public BATCH_SIZE = 5000;

  public staticsCollectionFunctions = {};

  constructor(
    public AT: ToolsService,
    public auth: AuthenticationService,
    public gql: GraphQlService,
    public events: EventsService
  ) {
    super();

    this.events.subscribe('intervention:reset', () => {
      this.replicateState?.reSync();
    });
  }

  async getPullQuery(
    lastCheckpoint: Checkpoint,
    batchSize: number
  ): Promise<ReplicationPullHandlerResult<interventionDocType, Checkpoint>> {
    const variables = {
      where: {
        _or: this.getOrForQuery(lastCheckpoint, 'id', 'updated_at_computed'),
      },
      order_by: this.getOrderByForQuery('id', 'updated_at_computed'),
      limit: this.BATCH_SIZE,
    };

    const { data, error } = await graphql.request(
      QUERY_GET_INTERVENTIONS,
      variables
    );

    if (error || !data.interventions) {
      console.log(
        `Erreur lors de la récupération des interventions sur le serveur`
      );
      return {
        documents: [],
        checkpoint: lastCheckpoint,
      };
    }

    const documentsFromRemote: any[] = data.interventions;

    return {
      documents: documentsFromRemote,
      checkpoint:
        documentsFromRemote.length === 0
          ? lastCheckpoint
          : {
              id: lastOfArray(documentsFromRemote).id,
              updatedAt: lastOfArray(documentsFromRemote).updated_at,
            },
    };
  }

  async getPushQuery(
    docs: RxReplicationWriteToMasterRow<interventionDocType>[]
  ): Promise<WithDeleted<interventionDocType>[]> {
    try {
      const { query, variables } = interventionReplicationPush(docs);
      const { error } = await graphql.request(query, variables);

      if (error) {
        console.log(error);
        SentryService.captureException(error);
        this.AT.toastError(
          $localize`Erreur lors de la sauvegarde de l'intervention sur le serveur`
        );
        return [];
      }

      this.AT.toastError($localize`Sauvegarde de l'intervention réussie`);
      return [];
    } catch (error) {
      // Gestion de l'erreur sur la requête
      console.log(error);
      SentryService.captureException(error);
      this.AT.toastError(
        $localize`Erreur lors de la sauvegarde de l'intervention sur le serveur`
      );
      return [];
    }
  }
}
