import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../utils/localstorage-utils.service';

/**
 * Interface representing the mode type for Mapbox implantation.
 *
 * @interface MapboxImplantationModeType
 * @property {'implantation' | 'releve'} mode - The mode of the Mapbox implantation, which can be either 'implantation' or 'releve'.
 */
export interface MapboxImplantationModeType {
  mode: 'implantation' | 'releve';
}

/**
 * The default mode to fall back to when no specific mode is provided.
 *
 * @constant {string} defaultModeFallback - The fallback mode, defaulting to 'releve'.
 */
const defaultModeFallback = 'releve';

/**
 * Service to manage the state of Mapbox implantation mode.
 *
 * @remarks
 * This service uses a `BehaviorSubject` to hold the current state of the Mapbox implantation mode.
 * The state is shared as an observable and can be subscribed to by other components or services.
 * The state is also persisted in local storage to maintain the mode across sessions.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class MapboxImplantationModeState {
  private implantationModeSubject$: BehaviorSubject<MapboxImplantationModeType> =
    new BehaviorSubject<MapboxImplantationModeType>({
      mode:
        getFromLocalStorage('mapboxImplantationMode') || defaultModeFallback,
    });

  implantationMode$: Observable<MapboxImplantationModeType> =
    this.implantationModeSubject$.asObservable().pipe(shareReplay());

  setImplantationMode(mode: MapboxImplantationModeType) {
    this.implantationModeSubject$.next(mode);
    setToLocalStorage('mapboxImplantationMode', mode.mode);
  }

  getImplantationMode(): MapboxImplantationModeType {
    return this.implantationModeSubject$.getValue();
  }
}
