import { NgIf, AsyncPipe } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { BehaviorSubject, map } from 'rxjs';
import { tagDocType } from '../../../db/schemas/tag.schema';
import { HasWriteAccessOnTagPipe } from '../../../pipes/tags/has-write-access-on-tag.pipe';
import { TagBoolPipe } from '../../../pipes/tags/tag-bool.pipe';
import { TagPipe } from '../../../pipes/tags/tag.pipe';
import { UserPipe } from '../../../pipes/users/user.pipe';
import { initNewTagShare } from '../../../services/tag/initNewTagShare';
import { TagService } from '../../../services/tag/tag.service';
import { ToolsService } from '../../../services/tools.service';
import { UgauButtonAnnulerValiderContainer } from '../../ugau-button-annuler-valider/ugau-button-annuler-valider.container';
import { UgauButtonComponent } from '../../ugau-button/ugau-button.component';
import { UgauInputBooleanContainer } from '../../ugau-input-bool/ugau-input-boolean.container';
import { UgauInputComponent } from '../../ugau-input/ugau-input.component';
import { UgauSelectTagTypeContainer } from '../../ugau-select-tag-type/ugau-select-tag-type.container';
import { PanelStateService } from '../panel-state';
import { TagMetaComponent } from '../tag-meta/tag-meta.component';
import { TagSharedTagsComponent } from '../tag-shared-tag-form/tag-form-shared-tag.component';
import { TagsActionsCommonService } from '../tags-actions-common.service';
import { UgauSelectGroupeContainer } from '../ugau-select-tag-groupe.container';
import { v4 as uuidv4 } from 'uuid';
import { getUserID } from '../../../services/nhost';
import { DialogPromptBoolComponent } from '../../dialogs/dialog-prompt-bool/dialog-prompt-bool.component';

@Component({
  selector: 'app-tags-form',
  templateUrl: './tag-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatListModule,
    UgauInputComponent,
    UgauSelectGroupeContainer,
    UgauSelectTagTypeContainer,
    UgauButtonComponent,
    TagSharedTagsComponent,
    UgauButtonAnnulerValiderContainer,
    AsyncPipe,
    UserPipe,
    TagPipe,
    HasWriteAccessOnTagPipe,
    TagBoolPipe,
    UgauInputBooleanContainer,
    TagMetaComponent,
    MatDividerModule,
  ],
})
export class TagFormComponent {
  @Input()
  public set element(value: tagDocType | null) {
    if (value)
      this.currentTagSubject$.next({
        ...value,
        shared_tags: [...(value.shared_tags ?? [])],
      });
    else this.currentTagSubject$.next(null);
  }
  public get element(): tagDocType | null {
    return this.currentTagSubject$.getValue();
  }

  currentTagSubject$ = new BehaviorSubject<tagDocType | null>(null);
  currentTag$ = this.currentTagSubject$.asObservable();

  libelleGroupeCaseUpdate$ = this.currentTag$.pipe(
    map((tag) =>
      tag?.id_user
        ? $localize`Groupe autorisés`
        : $localize`Groupe propriétaire`
    )
  );

  @Output() elementModify = new EventEmitter<tagDocType>();
  @Output() displayList = new EventEmitter<void>();

  idUser = getUserID();

  constructor(
    private tagState: TagService,
    private AT: ToolsService,
    private dialog: MatDialog,
    private tagQuickCommonService: TagsActionsCommonService,
    private sharedTagPanelState: PanelStateService
  ) {}

  updateCurrentTag(prop: keyof tagDocType, value: any) {
    const currentTag = this.currentTagSubject$.getValue();
    if (!currentTag) return;

    const updatedTag = {
      ...currentTag,
      [prop]: value,
    };

    this.currentTagSubject$.next(updatedTag);
  }

  updateCurrentTagArchived(prop: keyof tagDocType, value: any) {
    const currentTag = this.currentTagSubject$.getValue();
    if (!currentTag) return;
    this.updateCurrentTag(prop, value);

    if (
      currentTag.shared_tags &&
      currentTag.shared_tags.length > 0 &&
      value === true
    ) {
      const dialog = this.dialog.open(DialogPromptBoolComponent, {
        width: '350px',
        data: {
          data: $localize`Êtes vous certain de vouloir archiver cet élément ?
         Les partages seront supprimés immédiatement`,
          labelBtnValider: $localize`Archiver`,
          title: $localize`Archiver ${currentTag.libelle}`,
        },
      });

      dialog.afterClosed().subscribe((bool: Boolean) => {
        if (bool === true) {
          // On archive l'élément et on supprime les partages
          this.doArchivedUpdate(currentTag, prop, value);
        } else {
          // On annule l'archivage
          this.updateCurrentTag('is_archived', false);
        }
      });
    }
  }

  async doArchivedUpdate(
    currentTag: tagDocType,
    prop: keyof tagDocType,
    value: any
  ) {
    await currentTag.shared_tags?.reduce(async (accP, sharedTag) => {
      await this.tagState.removeSharedTag(currentTag, sharedTag);
    }, Promise.resolve());

    this.currentTagSubject$.next({
      ...currentTag,
      [prop]: value,
      shared_tags: [],
    });
  }

  doCheckedTagForUser($event: MatCheckboxChange) {
    const currentTag = this.currentTagSubject$.getValue();
    if (!currentTag?.id) return;

    // Il faut chercher si c'est un tag existant ou un nouveau tag
    const existingTag = this.tagState
      .getTags()
      .find((tag) => tag.id === currentTag.id);

    // Si le tag appartient à un groupe, pas de modification possible
    if (existingTag?.id_groupe) return;

    // On affecte l'utilisateur à l'élément
    this.updateCurrentTag('id_user', $event.checked ? this.idUser : undefined);
    this.updateCurrentTag('id_groupe', undefined);
  }

  cancelCall = () => {
    this.displayListCall();
  };

  displayListCall = () => {
    this.displayList.emit();
  };

  elementRemove = (tag: tagDocType) => {
    this.tagQuickCommonService.confirmTagDeletion(tag);
  };

  elementModifyCall = () => {
    const updatedTag = this.currentTagSubject$.getValue();
    if (!updatedTag?.libelle || updatedTag.libelle === '') {
      this.AT.toastError($localize`Libellé invalide`);
      return;
    }

    if (!updatedTag.id_groupe && !updatedTag.id_user) {
      this.AT.toastError(
        $localize`Il faut obligatoirement un groupe ou un utilisateur`
      );
      return;
    }

    // On vire les partages sans target
    const sharedTags =
      updatedTag.shared_tags?.filter((e) => {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        return e.target_user_id || e.target_group_id;
      }) ?? [];

    this.elementModify.emit({ ...updatedTag, shared_tags: sharedTags });
  };

  addTagShare() {
    const actualTag = this.currentTagSubject$.getValue();
    if (!actualTag) return;
    const actuelLength = actualTag.shared_tags?.length ?? 0;

    this.currentTagSubject$.next({
      ...actualTag,
      shared_tags: [
        ...(actualTag.shared_tags ?? []),
        initNewTagShare({
          id: uuidv4(),
          source_user_id: this.idUser,
          source_group_id: actualTag.id_groupe,
          active: true,
          readonly: false,
        }),
      ],
    });

    this.sharedTagPanelState.togglePanel('shared_tags', actuelLength);
  }
}
