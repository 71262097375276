import { Pipe, PipeTransform } from '@angular/core';
import { IChipItem } from './../../db/interfaces/chip-item.interface';
import { ISelectItem } from './../../db/interfaces/select-item';

@Pipe({
    name: 'selectItemChip',
    standalone: true,
})
export class SelectItemChipPipe implements PipeTransform {
  transform(
    item: IChipItem | ISelectItem | undefined,
    type: 'libelle' | 'id' | 'color' | undefined
  ): string {
    if (!item) return '';
    if (type === 'id') return item.getIdentifier();
    if (type === 'libelle') return item.getLibelle();
    if (type === 'color' && 'getColor' in item) return item.getColor();
    return '';
  }
}
