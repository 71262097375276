import {
  URL_MODAL_DISPLAY_ACTION,
  URL_MODAL_PAGE,
  URL_PANEL_ACTION,
  URL_PANEL_DISPLAY_ACTION,
} from '../enum/url-emplacement';
import { regexUuidString } from './regexUuidString';

// =================================================================================================
// MODAL
// =================================================================================================
export function extractModalRoutingInformation(queryString: string): {
  page: URL_MODAL_PAGE | undefined;
  action: URL_MODAL_DISPLAY_ACTION | undefined;
  idFirst: string | undefined;
  idSecond: string | undefined;
} {
  const page = extractModalPage(queryString);
  const action = extractModalAction(queryString, page);
  const idFirst = extractFirstId(queryString, page);
  const idSecond = extractSecondId(queryString, page);
  return { page, action, idFirst, idSecond };
}

function extractModalPage(queryString: string) {
  for (const page of Object.values(URL_MODAL_PAGE)) {
    const regex = new RegExp(`${page}`);
    const match = queryString.match(regex);
    if (match) {
      return page;
    }
  }

  return undefined;
}

function extractModalAction(
  queryString: string,
  page: URL_MODAL_PAGE | undefined
) {
  if (!page) return undefined;
  for (const display of Object.values(URL_MODAL_DISPLAY_ACTION)) {
    // Soit (page + id + display) | (page + id + id|depot-id + display)
    // Je veux capturer le display
    const regex = new RegExp(
      `(?:${page}${regexUuidString}/${display})|(?:${page}${regexUuidString}/(${regexUuidString}|depot-${regexUuidString})/${display})`,
      'i'
    );
    const match = queryString.match(regex);
    if (match) {
      return display;
    }
  }

  const regex = new RegExp(`${page}${regexUuidString}$`, 'i');
  const match = queryString.match(regex);
  if (match) {
    return URL_MODAL_DISPLAY_ACTION.DETAIL;
  }

  return undefined;
}

function extractSecondId(queryString: string, page: any) {
  const regex = new RegExp(
    `${page}${regexUuidString}/(${regexUuidString}|depot-${regexUuidString})`,
    'i'
  );
  const id = regex.exec(queryString);
  if (id?.[1]) {
    return id[1];
  }
  return undefined;
}

// =================================================================================================
// PANEL
// =================================================================================================
export function extractPanelRoutingInformation(queryString: string): {
  page: URL_PANEL_ACTION | undefined;
  action: URL_PANEL_DISPLAY_ACTION | undefined;
  idEmplacement: string | undefined;
  idEmplacementPhoto: string | undefined;
} {
  const page = extractPanelPage(queryString);
  const action = extractPanelAction(queryString, page);
  const idEmplacement = extractFirstId(queryString, page?.toString());
  const idEmplacementPhoto = extractIdEmplacementPhoto(queryString, page);
  return { page, action, idEmplacement, idEmplacementPhoto };
}

function extractPanelPage(queryString: string) {
  for (const page of Object.values(URL_PANEL_ACTION)) {
    const regex = new RegExp(`${page}`);
    const match = queryString.match(regex);
    if (match) {
      return page;
    }
  }

  return undefined;
}

function extractPanelAction(
  queryString: string,
  page: URL_PANEL_ACTION | undefined
) {
  if (!page) return undefined;
  for (const display of Object.values(URL_PANEL_DISPLAY_ACTION)) {
    const regex = new RegExp(`${page}${regexUuidString}/${display}`, 'i');
    const match = queryString.match(regex);
    if (match) {
      return display;
    }
  }

  const regex = new RegExp(`${page}${regexUuidString}$`, 'i');

  const match = queryString.match(regex);
  if (match) {
    return URL_PANEL_DISPLAY_ACTION.DETAIL;
  }

  return undefined;
}

function extractIdEmplacementPhoto(
  queryString: string,
  page: URL_PANEL_ACTION | undefined
) {
  const regex = new RegExp(
    `${page}${regexUuidString}/${URL_PANEL_DISPLAY_ACTION.PHOTOS}/(${regexUuidString})`,
    'i'
  );
  const idEmplacementPhoto = regex.exec(queryString);
  if (idEmplacementPhoto?.[1]) {
    return idEmplacementPhoto[1];
  }
  return undefined;
}

// =================================================================================================
// Common
// =================================================================================================
function extractFirstId(queryString: string, page: any) {
  const regex = new RegExp(`${page}(${regexUuidString})`, 'i');
  const idEmplacement = regex.exec(queryString);
  if (idEmplacement?.[1]) {
    return idEmplacement[1];
  }
  return undefined;
}
