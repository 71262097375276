import { composantDocType } from "../../db/schemas/emplacement.schema";
import { PRODUCTFIELD_TYPE } from "../../enum/product-fields-type";


export function getDesignationComposant(
  composant: composantDocType
): string | null {
  const name = composant.composant_productfields.reduce((acc, curr) => {
    if (curr.productfield.type === PRODUCTFIELD_TYPE.DESIGNATION.toString() &&
      curr.value) {
      return curr.value;
    }
    return acc;
  }, '');

  if (name) {
    return name;
  }
  return null;
}
