import { getProductsOrder } from '../product/getProductsOrder';
import { composantDocType } from '../../db/schemas/emplacement.schema';
import { ProductFamiliesType } from '@types_custom/ProductType';

export const sortComposant = (choosenTree: ProductFamiliesType[]) => {
  const ProductsOrder = getProductsOrder(choosenTree);
  return (e1: composantDocType, e2: composantDocType) => {
    const id1 = e1.product?.id ?? e1.type ?? undefined;
    const id2 = e2.product?.id ?? e2.type ?? undefined;
    if (id1 && id2) {
      if (ProductsOrder[id1] === ProductsOrder[id2]) {
        return e1.created_at > e2.created_at ? 1 : -1;
      }
      return ProductsOrder[id1] > ProductsOrder[id2] ? 1 : -1;
    }
    return 0;
  };
};
