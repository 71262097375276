import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  typeTagsChoices,
  typeTagsChoicesIntervention,
} from './../../enum/typeTagsChoices';
import { TagTypeSelectItem } from './TagTypeSelectItem';
import { UgauSelectContainer } from '../ugau-select/ugau-select.container';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-ugau-select-tag-type',
    template: `
    <ng-container *ngIf="tagTypeChoices">
      <!-- Cas normal -->
      <app-ugau-select
        *ngIf="currentType !== 'intervention'"
        i18n-label
        label="Type"
        [options]="tagTypeChoices"
        [value]="currentStatusSelectItem"
        (valueChange)="valueChange($event)"
        [readonly]="readOnly"
      ></app-ugau-select>
      <!-- Cas spécifique pour les interventions -->
      <app-ugau-select
        *ngIf="currentType === 'intervention'"
        i18n-label
        label="Type"
        [options]="tagTypeChoicesIntervention"
        [value]="currentStatusSelectItem"
        (valueChange)="valueChange($event)"
        [readonly]="readOnly"
      ></app-ugau-select>
    </ng-container>
  `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, UgauSelectContainer],
})
export class UgauSelectTagTypeContainer implements OnChanges {
  @Input() currentType!: string | null | undefined;
  @Input() readOnly = false;
  currentStatusSelectItem!: TagTypeSelectItem;
  tagTypeChoices: TagTypeSelectItem[] = typeTagsChoices.map((e) => {
    return new TagTypeSelectItem(e);
  });
  tagTypeChoicesIntervention: TagTypeSelectItem[] =
    typeTagsChoicesIntervention.map((e) => {
      return new TagTypeSelectItem(e);
    });

  @Output() typeChange = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentType && this.currentType !== 'intervention') {
      const choice = typeTagsChoices.find((e) => e.id === this.currentType);
      if (choice) this.currentStatusSelectItem = new TagTypeSelectItem(choice);
    }
    if (this.currentType && this.currentType === 'intervention') {
      const choice = typeTagsChoicesIntervention.find(
        (e) => e.id === this.currentType
      );
      if (choice) this.currentStatusSelectItem = new TagTypeSelectItem(choice);
    }
  }

  valueChange($event: TagTypeSelectItem) {
    this.typeChange.emit($event.item.id);
  }
}
