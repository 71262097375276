import { Pipe, PipeTransform } from '@angular/core';
import { tagMetaDocType } from '../../../db/schemas/tag.schema';
import { TagMetaTypeType } from './TagMetaTypeType';

@Pipe({
  name: 'tagMetaHasType',
  standalone: true,
})
export class tagMetaHasTypePipe implements PipeTransform {
  transform(metas: tagMetaDocType[], type: TagMetaTypeType): boolean {
    if (type === 'date_debut') {
      return metas.some((meta) => meta.type === 'date_debut');
    }

    if (type === 'date_fin') {
      return metas.some((meta) => meta.type === 'date_fin');
    }
    return false;
  }
}
