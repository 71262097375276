import {
  toTypedRxJsonSchema,
  ExtractDocumentTypeFromTypedRxJsonSchema,
  RxJsonSchema,
} from 'rxdb';
import { jsonSchema } from './jsonSchema';

export const contactSchemaLiteral = jsonSchema({
  name: 'contact',
  autoMigrate: true,
  title: 'Contact schema',
  version: 1,
  type: 'object',
  required: ['id', 'name', 'groupe_id', 'created_at', 'created_by'],
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    name: {
      type: 'string',
      maxLength: 255,
    },
    email: {
      type: 'string',
      maxLength: 255,
      nullable: true,
    },
    user_id: {
      type: 'string',
      maxLength: 36,
      nullable: true,
    },
    groupe_id: {
      type: 'string',
      maxLength: 36,
    },
    type: {
      type: 'string',
      maxLength: 50,
      nullable: true,
    },
    created_at: {
      type: 'string',
      format: 'date-time',
    },
    updated_at: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    created_by: {
      type: 'string',
      maxLength: 36,
    },
    updated_by: {
      type: 'string',
      maxLength: 36,
      nullable: true,
    },
    deleted_at: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    deleted_by: {
      type: 'string',
      maxLength: 36,
      nullable: true,
    },
    contact_meta: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'key', 'value', 'type'],
        properties: {
          id: {
            type: 'string',
          },
          key: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
          type: {
            type: 'string',
          },
          created_at: {
            type: 'string',
            format: 'date-time',
          },
          updated_at: {
            type: 'string',
            format: 'date-time',
          },
        },
      },
    },
  },
} as const);

const contactSchemaTyped = toTypedRxJsonSchema(contactSchemaLiteral);

export type contactDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof contactSchemaTyped
>;

export const contactSchema: RxJsonSchema<contactDocType> = contactSchemaLiteral;
