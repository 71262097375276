import { inject, Pipe, PipeTransform } from '@angular/core';
import { emplacementDocType } from '../../db/schemas/emplacement.schema';
import { ProductFamiliesTreeState } from '../../state/product-families-tree-state.service';
import { getProductsOrder } from '../../utils/product/getProductsOrder';
import { getEmplacementLibelle } from '../../utils/emplacement/getEmplacementLibelle';
import { EmplacementsStateService } from '../../state/emplacements-state.service';
import { isEmplacementBatiment } from '../../components/stock/isEmplacementBatimentInside';

@Pipe({
  name: 'emplacement',
  standalone: true,
})
export class EmplacementPipe implements PipeTransform {
  private treeState = inject(ProductFamiliesTreeState);
  private productsOrder = getProductsOrder(this.treeState.getOriginalTree());

  transform(
    emplacement: emplacementDocType | undefined,
    type:
      | 'libelle'
      | 'groupe_libelle'
      | 'materiel_libelle'
      | 'batiment_libelle'
      | undefined
  ): string {
    if (type === 'materiel_libelle') {
      // Il ne faut tenir compte que des composants de type materiel
      return '';
    }

    if (type === 'batiment_libelle') {
      // Il ne faut tenir compte que des composants de type batiment
      return '';
    }

    if (type === 'libelle') {
      return getEmplacementLibelle(emplacement, this.productsOrder);
    }
    if (type === 'groupe_libelle') {
      return '';
    }

    return ``;
  }
}

@Pipe({
  name: 'emplacementId',
  standalone: true,
})
export class EmplacementIdPipe implements PipeTransform {
  treeState = inject(ProductFamiliesTreeState);
  productsOrder = getProductsOrder(this.treeState.getOriginalTree());
  emplacementsState = inject(EmplacementsStateService);

  transform(
    id: string | null,
    type: 'libelle' | 'groupe_libelle' | 'libelle_batiment' | undefined
  ): string {
    if (!id) {
      return '';
    }
    const emplacement = this.emplacementsState.getEmplacementById(id);
    if (!emplacement) {
      return '';
    }

    if (type === 'libelle') {
      return getEmplacementLibelle(emplacement, this.productsOrder);
    }
    if (type === 'groupe_libelle') {
      return '';
    }
    if (type === 'libelle_batiment') {
      const families = this.treeState.getTreesByType('batiment');
      const isBatiment = isEmplacementBatiment(emplacement, families);
      if (isBatiment) {
        return getEmplacementLibelle(emplacement, this.productsOrder);
      }
    }

    return ``;
  }
}
