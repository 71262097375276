import { LngLat } from 'mapbox-gl';

/**
 * Retrieves the latitude and longitude from a GeoJSON Point geometry.
 *
 * @param {GeoJSON.Point} geometry - The GeoJSON Point geometry object.
 * @returns {Object} An object containing:
 * - `lat` {number}: The latitude value.
 * - `lng` {number}: The longitude value.
 * - `latlng` {LngLat}: An instance of LngLat with the longitude and latitude.
 */
export function getLatLngFromPoint(geometry: GeoJSON.Point) {
  return {
    lat: geometry.coordinates[1],
    lng: geometry.coordinates[0],
    latlng: new LngLat(geometry.coordinates[0], geometry.coordinates[1]),
  };
}
