import { Injectable } from '@angular/core';
import { LngLatBounds, Map, Marker, Popup } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { emplacementDocType } from '../db/schemas/emplacement.schema';
import { getShortcutPr } from '../pipes/getShortcutPr';
import { getInfoPrFromIgn } from './mapbox/mapbox-ign.service';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  public mapGlobal: Map | null = null;

  private showNumeroSubject$ = new BehaviorSubject(false);
  showNumero$ = this.showNumeroSubject$.asObservable();
  toggleShowNumero() {
    const actualValue = !this.showNumeroSubject$.getValue();
    this.showNumeroSubject$.next(actualValue);
  }
  getShowNumero() {
    return this.showNumeroSubject$.getValue();
  }
  hideShowNumero() {
    this.showNumeroSubject$.next(false);
  }

  private showNumeroEnsembleSubject$ = new BehaviorSubject(false);
  showNumeroEnsemble$ = this.showNumeroEnsembleSubject$.asObservable();
  toggleShowNumeroEnsemble() {
    const actualValue = !this.showNumeroEnsembleSubject$.getValue();
    this.showNumeroEnsembleSubject$.next(actualValue);
  }
  getShowNumeroEnsemble() {
    return this.showNumeroEnsembleSubject$.getValue();
  }
  hideShowNumeroEnsemble() {
    this.showNumeroEnsembleSubject$.next(false);
  }

  flyToEmplacement(emplacement: emplacementDocType | null) {
    if (
      !emplacement ||
      (emplacement.geometry.type !== 'Polygon' &&
        emplacement.geometry.type !== 'MultiPolygon' &&
        emplacement.geometry.type !== 'MultiLineString' &&
        emplacement.geometry.type !== 'LineString' &&
        emplacement.geometry.type !== 'Point')
    )
      return;

    const bounds = new LngLatBounds();
    if (emplacement.geometry.type === 'LineString') {
      emplacement.geometry.coordinates.forEach((e: any) => {
        bounds.extend(e);
      });
    }
    if (emplacement.geometry.type === 'Polygon') {
      emplacement.geometry.coordinates.forEach((e: any) => {
        e.forEach((f: any) => {
          bounds.extend(f);
        });
      });
    }
    if (emplacement.geometry.type === 'Point') {
      bounds.extend(emplacement.geometry.coordinates as any);
    }

    if (emplacement.geometry.type === 'MultiLineString') {
      emplacement.geometry.coordinates.forEach((line: any[]) => {
        line.forEach((e: any) => {
          bounds.extend(e);
        });
      });
    }

    if (emplacement.geometry.type === 'MultiPolygon') {
      emplacement.geometry.coordinates.forEach((polygon: any[]) => {
        polygon.forEach((ring: any[]) => {
          ring.forEach((f: any) => {
            bounds.extend(f);
          });
        });
      });
    }

    if (!bounds.isEmpty()) this.mapGlobal?.fitBounds(bounds, { padding: 50 });
  }

  zoomOnPR(pr: any) {
    if (!pr) return;

    const coords = pr.position;
    const id = coords.join('_');

    const bounds = new LngLatBounds();
    bounds.extend(coords);
    if (bounds.isEmpty()) return;

    this.mapGlobal?.fitBounds(bounds, { padding: 50, maxZoom: 18 });
    // Next time the map is idle, we add the marker
    this.mapGlobal?.once('idle', () => {
      if (!this.mapGlobal) return;

      if (!markerCache[id]) {
        markerCache[id] = new Marker().setLngLat(coords).addTo(this.mapGlobal);

        getInfoPrFromIgn(coords[1], coords[0], this.mapGlobal.getZoom())
          .then((text) => {
            let isRemoving = false; // Le delete du marker retrigger le close du popup
            const popup = new Popup({ offset: 25 }).on('close', function () {
              if (isRemoving) {
                return;
              }

              isRemoving = true;
              markerCache[id]?.remove();
              markerCache[id] = undefined;
              isRemoving = false;
            });

            if (text.includes('<h5>')) {
              // Si text contient un <h5> alors c'est un PR IGN
              popup.setHTML(text);
            } else {
              // Fallback avec le shortcut
              popup.setText(getShortcutPr(pr));
            }
            markerCache[id]?.setPopup(popup).togglePopup();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  }

  popupRef: Popup | undefined;
  zoomTemporaryPopup(
    geometry: GeoJSON.Geometry,
    text: string,
    zoom: number = 18
  ) {
    const id = Math.random().toString(36).substr(2, 9); // Générer un ID unique pour la géométrie
    const bounds = new LngLatBounds(); // Calculer les limites pour zoomer sur la géométrie
    const sourceId = `geometry-source-${id}`;
    const layerId = `geometry-layer-${id}`;

    // Extraire les coordonnées et ajuster les bounds en fonction du type de géométrie
    const coords = this.getGeometryCoordinates(geometry);
    coords.forEach((coord) => bounds.extend(coord));

    if (bounds.isEmpty()) return;

    // Ajuster la vue pour contenir la géométrie
    this.mapGlobal?.fitBounds(bounds, { padding: 50, maxZoom: zoom });

    // Ajouter la source GeoJSON à la carte
    if (!this.mapGlobal?.getSource(sourceId)) {
      this.mapGlobal?.addSource(sourceId, {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: geometry,
          properties: {},
        },
      });
    }

    // Créer le layer correspondant en fonction du type de géométrie
    switch (geometry.type) {
      case 'Point':
        this.mapGlobal?.addLayer({
          id: layerId,
          type: 'circle',
          source: sourceId,
          paint: {
            'circle-radius': 6,
            'circle-color': '#007cbf',
          },
        });
        break;
      case 'MultiLineString':
      case 'LineString':
        this.mapGlobal?.addLayer({
          id: layerId,
          type: 'line',
          source: sourceId,
          paint: {
            'line-width': 4,
            'line-color': '#007cbf',
          },
        });
        break;
      case 'MultiPolygon':
      case 'Polygon':
        this.mapGlobal?.addLayer({
          id: layerId,
          type: 'fill',
          source: sourceId,
          paint: {
            'fill-color': '#007cbf',
            'fill-opacity': 0.9,
          },
        });
        break;
    }

    // Ajouter un popup à la position centrale du polygone ou de la géométrie
    const center = bounds.getCenter();
    if (this.mapGlobal) {
      this.popupRef = new Popup({ offset: 25 })
        .setLngLat(center)
        // .setText(text)
        .addTo(this.mapGlobal);
    }

    // Retirer la géométrie après 5 secondes
    setTimeout(() => {
      if (this.mapGlobal?.getLayer(layerId)) {
        this.mapGlobal.removeLayer(layerId);
      }
      if (this.mapGlobal?.getSource(sourceId)) {
        this.mapGlobal.removeSource(sourceId);
      }
    }, 5000);
  }

  // Fonction utilitaire pour extraire les coordonnées de la géométrie
  getGeometryCoordinates(geometry: GeoJSON.Geometry): [number, number][] {
    switch (geometry.type) {
      case 'Point':
        return [geometry.coordinates as [number, number]];
      case 'LineString':
        return geometry.coordinates as [number, number][];
      case 'Polygon':
        // Prendre l'anneau extérieur du polygone
        return geometry.coordinates[0] as [number, number][];
      case 'MultiPoint':
        return geometry.coordinates as [number, number][];
      case 'MultiLineString':
        return geometry.coordinates.flat() as [number, number][];
      case 'MultiPolygon':
        return geometry.coordinates.flat(2) as [number, number][];
      default:
        return [];
    }
  }
}

const markerCache: Record<string, Marker | undefined> = {};
