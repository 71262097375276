import { Map } from 'mapbox-gl';
import { getDefaultSources } from './getDefaultSources';
import { mapboxSourceOptions } from './mapbox.interface';
import { sourceDoGenericAddOrUpdate } from './sourceDoGenericAddOrUpdate';

/**
 * Adds multiple sources to a Mapbox map using the provided GeoJSON data.
 *
 * @param geoJson - The GeoJSON FeatureCollection to be used as the data source.
 * @param map - The Mapbox map instance to which the sources will be added.
 * @param sources - An optional array of mapboxSourceOptions. If not provided, the default sources will be used.
 */
export function addMapSources(
  geoJson: GeoJSON.FeatureCollection,
  map: Map,
  sources: mapboxSourceOptions[] = getDefaultSources()
) {
  sources.forEach((source) => {
    sourceDoGenericAddOrUpdate(map, geoJson, source);
  });
}
