import { ProductFamiliesType } from '@types_custom/ProductType';

const CACHE = new Map<string, string[]>();

export function getFamilyPath(
  familyId: string,
  families: ProductFamiliesType[]
) {
  const idsFamilies: string = families.map((e) => e.id + e.name).join('.');
  const idCacheFamille = idsFamilies + ' ' + familyId;
  const cached = CACHE.get(idCacheFamille);
  if (cached) {
    return cached;
  }

  const path = recursiveFindFamilyPath(familyId, families);
  CACHE.set(idCacheFamille, path);
  return path;
}

function recursiveFindFamilyPath(
  familyId: string,
  families: ProductFamiliesType[]
): string[] {
  const path: string[] = [];

  for (const [index, family] of families.entries()) {
    if (family.id === familyId) {
      // La famille actuelle est la famille recherchée
      path.push(index.toString());
      break;
    }

    if (
      family.children_productfamilies &&
      family.children_productfamilies.length > 0
    ) {
      // La famille actuelle a des enfants
      const result = recursiveFindFamilyPath(
        familyId,
        family.children_productfamilies
      );

      if (result.length > 0) {
        // La famille a été trouvé dans les enfants
        path.push(index.toString(), 'children_productfamilies', ...result);
        break;
      }
    }
  }

  return path;
}
