<mat-tab-group
  class="custom-tab"
  [selectedIndex]="tabIndex$ | async"
  (selectedIndexChange)="changeIndex($event)"
>
  <mat-tab i18n-label label="LISTE DU MATERIEL">
    <ng-template matTabContent>
      <app-materiel-list></app-materiel-list>
    </ng-template>
  </mat-tab>
  <mat-tab i18n-label label="VUE D'ENSEMBLE">
    <ng-template matTabContent>
      <app-stats-tab-group
        [store$]="store$"
        (showConcernedEmplacement)="showConcernedEmplacement($event)"
      ></app-stats-tab-group>
    </ng-template>
  </mat-tab>
</mat-tab-group>
