import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RoutingState } from './routing-state.service';

@Injectable({
  providedIn: 'root',
})
export class ComponentItemsOpenedState {
  currentEmplacementId: string | undefined = undefined;

  private composantOpenedInEmplacementView$ = new BehaviorSubject<string[]>([]);
  openedItemIndex$ = this.composantOpenedInEmplacementView$.asObservable();

  constructor(private state: RoutingState) {
    this.state.routing$.subscribe((e) => {
      if (!e.page) return;

      const isNotSameEmplacement =
        this.currentEmplacementId &&
        e.page.id_emplacement !== this.currentEmplacementId;

      if (e.page.hasIdEmplacement() && isNotSameEmplacement) {
        this.closeComposant();
      }

      this.currentEmplacementId = e.page.id_emplacement;
    });
  }

  getComposantsOpened() {
    return this.composantOpenedInEmplacementView$.getValue();
  }

  /**
   * setComposants
   * Sets the composants to be opened in the current emplacement view.
   * @param {string[] | string} composantId - The list of ids or single id of the Composant to be opened.
   */
  setComposants(composantId: string[] | string) {
    const isString = typeof composantId === 'string';
    if (isString) {
      this.composantOpenedInEmplacementView$.next([composantId]);
      return;
    }
    this.composantOpenedInEmplacementView$.next([...composantId]);
  }

  /**
   * openComposants
   * @description Sets the available composants to a given list of strings or string array
   * @param {string[] | string} composantId A string array or single string value representing the composant ids
   */
  openComposants(composantId: string[] | string) {
    const opened = this.composantOpenedInEmplacementView$.getValue();
    const isString = typeof composantId === 'string';
    const newComposants = isString ? [composantId] : composantId;
    const uniqueComposants = Array.from(new Set([...newComposants, ...opened]));
    this.composantOpenedInEmplacementView$.next(uniqueComposants);
  }

  /**
   * closeComposantById
   * @description Close composant by Id
   * @param {string} composantId - the Id of the composant to close
   */
  closeComposantById(composantId: string) {
    const opened = this.composantOpenedInEmplacementView$.getValue();
    if (Array.isArray(opened)) {
      const index = opened.findIndex((e) => e === composantId);
      if (index !== -1) {
        opened.splice(index, 1);
      }
    }

    if (opened.length > 0) {
      this.composantOpenedInEmplacementView$.next(opened);
      return;
    }
    this.composantOpenedInEmplacementView$.next([]);
  }

  closeComposant() {
    this.composantOpenedInEmplacementView$.next([]);
  }
}
