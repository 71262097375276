import { EventData, Map, MapMouseEvent, MapboxGeoJSONFeature } from 'mapbox-gl';

/**
 * Handles the mouse leave event on a Mapbox map.
 *
 * @param map - The Mapbox map instance.
 * @returns A function that handles the mouse leave event, resetting the cursor style.
 */
export function handleMouseleave(map: Map): (
  ev: MapMouseEvent & {
    features?: MapboxGeoJSONFeature[] | undefined;
  } & EventData
) => void {
  return () => {
    map.getCanvas().style.cursor = '';
  };
}
