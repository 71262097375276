import { getProductPropertyValueByName } from '../productproperties-utils.service';
import { PRODUCT_PROPERTIES_NAME } from '../../enum/product-properties-name';
import { productpropertiesDocType } from '../../db/schemas/productproperties.schema';
import { composantDocType } from '../../db/schemas/emplacement.schema';
import { getMaxAgeByClasse } from './getMaxAgeByClasse';


export function getLimiteMaxAgeComposant(
  composant: composantDocType,
  properties: productpropertiesDocType[]
) {
  if (!composant.product?.id) return 0;
  const classe = getProductPropertyValueByName(
    composant.product,
    PRODUCT_PROPERTIES_NAME.CLASSE,
    properties
  );
  if (classe) {
    const classeNum = parseInt(classe.replace(/\D+/g, ''));
    if (!isNaN(classeNum)) return getMaxAgeByClasse(classeNum);
  }
  return 0;
}
