import { ComposantProductType } from '@types_custom/composant';
import { ProductType } from '@types_custom/ProductType';
import { PRODUCT_PROPERTIES_NAME } from '../enum/product-properties-name';
import { PRODUCTFIELD_TYPE } from '../enum/product-fields-type';
import { productpropertiesDocType } from '../db/schemas/productproperties.schema';
import { productfieldDocType } from '../db/schemas/productfields.schema';
import { productDocType } from '../db/schemas/emplacement.schema';

const CACHE_PRODUCTPROPERTYVALUE_BY_ID = new Map<string, string | undefined>();
const CACHE_PRODUCTPROPERTYVALUE_BY_NAME = new Map<
  string,
  string | undefined
>();

type proxyProductType =
  | ProductType
  | ComposantProductType
  | productDocType
  | undefined;

export function getProductPropertyValueByName(
  product: proxyProductType,
  name: PRODUCT_PROPERTIES_NAME,
  properties: productpropertiesDocType[]
): string | undefined {
  if (!product) return undefined;
  const key = `${product.id} ${name}`;

  const cached = CACHE_PRODUCTPROPERTYVALUE_BY_NAME.get(key);
  if (cached) return cached;

  const propId = properties.find((el) => el.name === name.toString())?.id;
  if (!propId) return undefined;

  const value = getProductPropertyValueById(product, propId);
  CACHE_PRODUCTPROPERTYVALUE_BY_NAME.set(key, value);
  return value;
}

export function getProductPropertyValueById(
  product: proxyProductType,
  propId: string
): string | undefined {
  if (!product || !('productpropertyvalues' in product)) return undefined;
  const key = `${product.id} ${propId}`;

  const cached = CACHE_PRODUCTPROPERTYVALUE_BY_ID.get(key);
  if (cached) return cached;

  const productClasseProp = product.productpropertyvalues?.find(
    (el: any) => el.productpropertyvalue.productproperty_id === propId
  );

  const value = productClasseProp?.productpropertyvalue?.value;
  CACHE_PRODUCTPROPERTYVALUE_BY_ID.set(key, value);
  return value;
}

export function findIdFromPropertyName(
  name: string,
  properties: productpropertiesDocType[]
) {
  return properties.find((el) => el.name === name)?.id;
}
export function findIdFromFieldPropertyType(
  type: PRODUCTFIELD_TYPE,
  fields: productfieldDocType[]
) {
  return fields.find((el) => el.type === type)?.id;
}
