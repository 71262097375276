import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { UgauButtonComponent } from '../../ugau-button/ugau-button.component';
import { TagMetaTypeType } from './TagMetaTypeType';
import { tagMetaHasTypePipe } from './has-tagmeta-type.pipe';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-meta-field-menu',
  template: `
    <div class="m1em meta-header d-flex flex-sb flex-ac mr1em">
      <span>{{ title }}</span>
      <app-ugau-button
        [matMenuTriggerFor]="menu"
        *ngIf="!readonly"
        i18n-label
        label=""
        iconName="add"
        [fill]="true"
      ></app-ugau-button>
      <mat-menu #menu="matMenu">
        <ng-container *ngFor="let field of fields; trackBy: trackByItem">
          <button
            mat-menu-item
            *ngIf="
              field.unique
                ? (metas | tagMetaHasType: field.type) === false
                : true
            "
            (click)="doAddMetaField(field.type)"
            i18n
          >
            {{ field.label }}
          </button>
        </ng-container>
      </mat-menu>
    </div>
  `,
  styles: [
    `
      .meta-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 1em;
      }
    `,
  ],
  standalone: true,
  imports: [
    MatMenuModule,
    UgauButtonComponent,
    tagMetaHasTypePipe,
    NgIf,
    NgFor,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaFieldMenuComponent {
  trackByItem = (index: number, item: any) => item;
  @Input() title = $localize`Informations supplémentaires`;
  @Input() metas: any[] = []; // Input property for meta fields
  @Input() readonly: boolean = false; // Input property to determine if the component is readonly
  @Input() fields: {
    type: TagMetaTypeType;
    label: string;
    unique: boolean;
  }[] = [
    {
      type: 'date_debut',
      label: $localize`Date début`,
      unique: true,
    },
    {
      type: 'date_fin',
      label: $localize`Date fin`,
      unique: true,
    },
    {
      type: 'autocomplete',
      label: $localize`Texte`,
      unique: false,
    },
    {
      type: 'textarea',
      label: $localize`Zone de texte`,
      unique: false,
    },
    {
      type: 'date',
      label: $localize`Date`,
      unique: false,
    },
    {
      type: 'number',
      label: $localize`Nombre`,
      unique: false,
    },
    {
      type: 'upload',
      label: $localize`Fichier`,
      unique: false,
    },
    {
      type: 'user',
      label: $localize`Selection d'un utilisateur`,
      unique: false,
    },
  ];

  @Output() addMetaField = new EventEmitter<TagMetaTypeType>(); // Output property to emit meta field changes

  doAddMetaField(type: TagMetaTypeType): void {
    this.addMetaField.emit(type);
  }
}
