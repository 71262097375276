import { REGLEMENTATION_TYPE } from '../../enum/REGLEMENTATION_TYPE';
import { conformiteDocType } from '../../db/schemas/emplacement.schema';
import { reglementationDocType } from './../../db/schemas/reglementation.schema';


export function isConformiteMissingReglementationChoix(
  conformite: conformiteDocType,
  reglementation: reglementationDocType
) {
  return (
    !conformite.id_reglementation_choix &&
    reglementation.type_valeur !== REGLEMENTATION_TYPE.DATE_FABRICATION &&
    reglementation.type_valeur !== REGLEMENTATION_TYPE.TEXTE &&
    reglementation.type_valeur !== REGLEMENTATION_TYPE.RECURRENCE
  );
}
