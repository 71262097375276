import {
  DataComposantProductfieldType,
  DataComposantType,
} from '@types_custom/composant';
import { DataConformiteType } from '@types_custom/conformite';
import {
  conformiteDocType,
  composantProductfieldDocType,
  emplacementDocType,
  composantDocType,
} from '../../db/schemas/emplacement.schema';

export function getDataComposantFromComposant(
  elComp: composantDocType,
  doc: emplacementDocType,
  force: boolean = false
) {
  const dataComposant: DataComposantType = {
    id: elComp.id,
    created_at: elComp.created_at,
    created_by: elComp.created_by ?? null,
    type: elComp.type,
    order_per_type: elComp.order_per_type,
    updated_at: elComp.updated_at ?? null,
    updated_by: elComp.updated_by ?? null,
    deleted_at: elComp.deleted_at ?? null,
    deleted_by: elComp.deleted_by ?? null,
    quantity: elComp.quantity,
    id_emplacement: doc.id,
  } as DataComposantType;

  if (!force && elComp.product?.id) {
    // 25 Jan 2024 : On autorise le composant à ne pas avoir de produit
    dataComposant.id_product = elComp.product.id;
  }

  let dataConformites: DataConformiteType[] = [];
  if (elComp.conformites.length > 0) {
    dataConformites = elComp.conformites.map((elConf: conformiteDocType) => {
      const conformite: DataConformiteType = {} as DataConformiteType;
      conformite.id = elConf.id;
      conformite.valeur = elConf.valeur;
      conformite.est_conforme = elConf.est_conforme;
      conformite.commentaire = elConf.commentaire ?? '';
      conformite.created_at = elConf.created_at;
      conformite.created_by = elConf.created_by ?? null;
      conformite.deleted_at = elConf.deleted_at ?? null;
      conformite.deleted_by = elConf.deleted_by ?? null;

      if (elConf.id_reglementation) {
        conformite.id_reglementation = elConf.id_reglementation.toString();
      } else {
        throw new Error(
          'Error (Emplacement' +
            doc.id +
            ' PUSH QUERY) : Conformite without reglementation '
        );
      }

      if (elConf.id_reglementation_choix) {
        conformite.id_reglementation_choix =
          elConf.id_reglementation_choix.toString() || null;
      } else {
        conformite.id_reglementation_choix = null;
      }

      return conformite;
    });
  }

  dataComposant.conformites = {
    data: dataConformites,
    on_conflict: {
      constraint: 'conformite_pkey',
      update_columns: [
        'deleted_at',
        'deleted_by',
        'commentaire',
        'est_conforme',
        'id_reglementation_choix',
        'valeur',
      ],
    },
  };

  const dataComposantProductfield = elComp.composant_productfields.map(
    (el: composantProductfieldDocType) => {
      return {
        id: el.id,
        value: el.value,
        created_at: el.created_at,
        updated_at: el.updated_at,
        deleted_at: el.deleted_at,
        productfield_id: el.productfield.id,
      } as DataComposantProductfieldType;
    }
  );
  dataComposant.composant_productfields = {
    data: dataComposantProductfield,
    on_conflict: {
      constraint: 'composant_productfields_pkey',
      update_columns: ['value', 'updated_at', 'deleted_at'],
    },
  };

  return dataComposant;
}
