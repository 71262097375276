import { storage } from './../../../services/nhost';

export function forceDownloadFile(
  completeUrl: string,
  downloadFileName: string
) {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.target = '_blank';
  link.rel = 'noopener noreferrer';

  // Faire une requête pour obtenir le fichier en tant que Blob
  try {
    fetch(completeUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        link.href = blobUrl;
        link.download = downloadFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Libérer l'URL du blob
        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error('Erreur lors du téléchargement du fichier :', error);
        openUrlInNewTab(completeUrl);
      });
  } catch (error) {
    console.log('Erreur lors du téléchargement du fichier :', error);
    openUrlInNewTab(completeUrl);
  }
}

export function forceDownloadFileFromIdOrHttps(
  chemin: string,
  name: string,
  forceNewTab = false
) {
  // Détermine l'URL complète en fonction de la présence de 'http'
  const completeUrl = chemin.includes('http')
    ? chemin
    : storage.getPublicUrl({ fileId: chemin });

  if (forceNewTab) {
    openUrlInNewTab(completeUrl);
    return;
  }

  forceDownloadFile(completeUrl, name);
}

const openUrlInNewTab = (url: string) => {
  window.open(url, '_blank');
};
