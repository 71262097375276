import { ICON_MAP_BLUE } from 'src/app/utils/emplacement/ICON_MAP_BLUE';
import { mapboxLayerOptions } from './mapbox.interface';

export function getDefaultLayersEmplacement(): mapboxLayerOptions[] {
  return [
    // POINT SYMBOL
    {
      id: 'point-symbol',
      source: 'markers_source',
      type: 'symbol',
      layer: {
        id: 'point-symbol',
        source: 'markers_source',
        type: 'symbol',
        layout: {
          'icon-image': ICON_MAP_BLUE,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          visibility: 'visible',
          'icon-offset': [0, -15],
        },
      },
    },
    {
      id: 'line-features',
      source: 'lines_source',
      type: 'line',
      layer: {
        id: 'line-features',
        source: 'lines_source',
        type: 'line',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
          visibility: 'visible',
        },
        paint: {
          'line-color': '#BF93E4',
          'line-width': 5,
        },
      },
      zIndex: 'z-index-2',
    },
    // POLYGON LINE
    {
      id: 'polygon-lines',
      source: 'polygons_source',
      type: 'line',
      layer: {
        id: 'polygon-lines',
        source: 'polygons_source',
        type: 'line',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
          visibility: 'visible',
        },
        paint: {
          'line-color': '#BF93E4',
          'line-width': 5,
        },
      },
      zIndex: 'z-index-2',
    },
    // POLYGON FILL
    {
      id: 'polygon-features',
      source: 'polygons_source',
      type: 'fill',
      layer: {
        id: 'polygon-features',
        source: 'polygons_source',
        type: 'fill',
        layout: {},
        paint: {
          'fill-color': '#BF93E4',
          'fill-opacity': 0.5,
        },
      },
      zIndex: 'z-index-2',
    },
  ];
}
